/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-self-assign */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

import { useEffect, useState, useMemo, useRef } from 'react'
import {
  BsArrowLeft,
  BsCheckLg,
  BsFillCheckCircleFill,
  BsPencil,
  BsPencilSquare,
  BsTrash,
  BsWrench,
  BsX,
  BsXLg
} from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../config'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../../components/Globals'
import { logout } from '../../../state/actions'
import ReactTooltip from 'react-tooltip'
import Button from 'react-bootstrap/Button'
import VitrinasModal from './DynamicForm/VitrinasModal'
import Service from '../../../services'
import Navbar from 'react-bootstrap/Navbar'
import { TooltipPanel } from '../../Globals/TooltipPanel'
// import { TableDraggable } from 'reactjs-table-draggable';
import { MaterialReactTable } from 'material-react-table'
import { Box, IconButton, darken } from '@mui/material'
import { useClientContext } from '../../../contexts/ClientContext'

const VitrinasShelvesGridTable = ({
  options,
  headers,
  editar,
  classLoading,
  optionSelected,
  fncShowGridVitrinas
}: any) => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()
  const [keySectionId, setKeySectionId] = useState<any>('')
  const [checkbox, setCheckbox] = useState<any>('')
  const [checked, setChecked] = useState<any>('')
  const [reload, setReload] = useState<any>('')
  const [appendClass, setAppendClass] = useState<any>('')

  const [gondolaSelected, setGondolaSelected] = useState<any>()
  const [gondolaCreated, setGondolaCreated] = useState<any>()
  const [nameGondolaSelected, setNameGondolaSelected] = useState<any>()
  const [viewSpinner, setViewSpinner] = useState<boolean>(false)

  const [vitrinas, setVitrinas] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [editPrioriy, setEditPrioriy] = useState<boolean>(false)
  const [priority, setPriority] = useState<any>('')

  const dispatch = useDispatch()
  const [isModalOpen, setModalState] = useState(false)
  const toggleModal = () => setModalState(!isModalOpen)

  const intl = useIntl()

  /* ******  */
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => <span>{row.action.template_titulo}</span>, // alternate way
        header: intl.formatMessage(
          { id: 'app.vitrinas.table.column1' },
          { defaultMessage: 'TITULO' }
        ),
        // muiTableHeadCellProps: { sx: { color: 'black' } },
        size: 100, // decrease the width of this column
        enableResizing: true
      },
      {
        // accessorKey: 'name', // simple recommended way to define a column
        accessorFn: (row: any) => <span>{row.nameEndPoint}</span>, // alternate way
        header: intl.formatMessage(
          { id: 'app.vitrinas.table.column2-1' },
          { defaultMessage: 'REGLA' }
        )
        // muiTableHeadCellProps: { sx: { color: 'green' } }, // optional custom props
        // Cell: ( cell:any ) => <span>{cell.name}</span>, // optional custom cell render
      },
      {
        // accessorKey: 'name', // simple recommended way to define a column
        accessorFn: (row: any) => <span>{row.keySectionId}</span>,
        header: intl.formatMessage(
          { id: 'app.vitrinas.table.column3' },
          { defaultMessage: 'ID' }
        )
        // muiTableHeadCellProps: { sx: { color: 'green' } }, // optional custom props
        // Cell: ( cell:any ) => <span>{cell.name}</span>, // optional custom cell render
      },

      {
        accessorFn: (row: any) => <span className="">{row.status}</span>,
        header: 'Estado' // simple recommended way to define a column
      },
      /* 
        {
          header: 'Estado', // simple recommended way to define a column
          accessorKey: (row:any) => 
              {row.status =='Sin vencimiento' 
                    ? <span className="badge badge-info"  >{row.status}</span>
                    : row.status =='En curso'
                      ? <span className="badge badge-success">{row.status}</span>
                      : row.status =='Finalizada' 
                        ? <span className="badge badge-warning" >{row.status}</span>
                        : <span className="badge badge-dark" >{row.status}</span>
              }
        }, */
      {
        // accessorKey: 'name', // simple recommended way to define a column
        // accessorFn: (row:any) => <span>{row.keySectionId}</span>, // alternate way
        header: intl.formatMessage(
          { id: 'app.audiencias' },
          { defaultMessage: 'AUDIENCIAS' }
        ),
        size: 200, // decrease the width of this column
        enableResizing: true,
        // muiTableHeadCellProps: { sx: { color: 'green' } }, // optional custom props
        accessorFn: (row: any) =>
          row.segments.map((segment: any) => {
            return <span key={segment.value}>{segment.label + ' | '}</span>
          })
      },
      {
        accessorFn: (row: any) => row.priority, // alternate way
        id: 'prio', // id required if you use accessorFn instead of accessorKey
        size: 50, // decrease the width of this column
        header: intl
          .formatMessage(
            { id: 'app.audiencias.tit' },
            { defaultMessage: 'Prioridad' }
          )
          .toUpperCase()
        // Header: () => <i>Age</i>, // optional custom header render
      }
    ],
    []
  )

  // optionally, you can manage any/all of the table state yourself
  const [rowSelection, setRowSelection] = useState({})

  // Or, optionally, you can get a reference to the underlying table instance
  const tableInstanceRef = useRef(null)

  /* ******* */

  const tableHeaders = [
    {
      id: 0,
      name: intl.formatMessage(
        { id: 'app.vitrinas.table.column1' },
        { defaultMessage: 'TITULO' }
      )
    },
    {
      id: 1,
      name: intl.formatMessage(
        { id: 'app.vitrinas.table.column2-1' },
        { defaultMessage: 'REGLA' }
      )
    },
    {
      id: 2,
      name: intl.formatMessage(
        { id: 'app.vitrinas.table.column3' },
        { defaultMessage: 'ID' }
      )
    },
    {
      id: 3,
      name: intl.formatMessage(
        { id: 'app.audiencias' },
        { defaultMessage: 'AUDIENCIAS' }
      )
    },
    {
      id: 4,
      name: intl.formatMessage(
        { id: 'app.audiencias.tit' },
        { defaultMessage: 'Prioridad' }
      )
    },
    {
      id: 5,
      name: intl.formatMessage(
        { id: 'app.vitrinas.table.column4' },
        { defaultMessage: 'ACCIONES' }
      )
    }
  ]

  const EnabledEditInline = (elem: string, pvalue: string, show: string) => {
    const inputBox = document.getElementById('inp-' + elem) as HTMLInputElement
    const btnSave = document.getElementById(
      'btn-s-' + elem
    ) as HTMLButtonElement
    const btnCanc = document.getElementById(
      'btn-c-' + elem
    ) as HTMLButtonElement
    const btnEdit = document.getElementById(
      'btn-e-' + elem
    ) as HTMLButtonElement
    const lblEdit = document.getElementById('lbl-e-' + elem) as HTMLHtmlElement

    if (show == 'show') {
      inputBox.classList.add('disp-block')
      inputBox.classList.remove('disp-none')
      btnSave.classList.add('disp-block')
      btnSave.classList.remove('disp-none')
      btnCanc.classList.add('disp-block')
      btnCanc.classList.remove('disp-none')
      btnEdit.classList.add('disp-none')
      btnEdit.classList.remove('disp-block')
      lblEdit.classList.add('disp-none')
      // lblEdit.classList.remove('display-block');
    } else {
      inputBox.classList.add('disp-none')
      inputBox.classList.remove('disp-block')
      btnSave.classList.add('disp-none')
      btnSave.classList.remove('disp-block')
      btnCanc.classList.add('disp-none')
      btnCanc.classList.remove('disp-block')
      btnEdit.classList.add('disp-block')
      btnEdit.classList.remove('disp-none')
      // lblEdit.classList.add('display-block');
      lblEdit.classList.remove('disp-none')
    }
    inputBox.value = pvalue
    setEditPrioriy(!editPrioriy)
  }

  const SavePriorityInline = (elem: string) => {
    const inputBox = document.getElementById('inp-' + elem) as HTMLInputElement
    const value = inputBox.value
    // antes reviso que el valor no esté repetido
    const repetido = vitrinas.filter((x: any) => x.priority == value)
    if (repetido.length > 0) {
      MyAlert('El valor de Prioridad ingresado ya existe en otra vitrina', 3)
    } else {
      if (value != '') {
        Service.post<any, any>(
          `/ManageCarrousel/UpdatePriorityGondoleCdp?idHashScript=${selectedScript?.idHashScript}&keySectionId=${elem}&value=${value}`,
          '',
          {
            method: 'POST',
            headers: {
              token: user.data.token,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
          .then((res) => {
            if (res.data?.StatusCode < 0 || res.data < 0) {
              if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.api.error' },
                    {
                      defaultMessage:
                        'Ocurrió un Error al actualizar los datos.'
                    }
                  ),
                  2
                )
            } else {
              EnabledEditInline(elem, '', 'hide')
              MyAlert('Se modificó correctamente la prioridad.', 1)
              setReload('recargar-' + value) // recargo grilla
            }
          })
          .catch((error) => {
            if (error.response.data === -6) {
              dispatch(logout(user.data.token))
            } else {
              MyAlert('API-ERROR: ' + `${error}`, 3)
            }
          })
      }
    }
  }

  const ConfirmCreateVitrina = () => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.create-vitrina-audiencia.title-question' },
        { defaultMessage: 'Agregar Vitrina personalizada' }
      ),
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.cdp.create-vitrina-audiencia.message-question' },
          {
            defaultMessage:
              '¿Confirma que desea crear una vitrina personalizada?'
          }
        ) +
        '</span>',
      inputLabel: '',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.continuar' },
        { defaultMessage: 'Continuar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cancelar' },
        { defaultMessage: 'Cancelar' }
      ),
      width: 600
    })
      .then((result) => {
        if (result.isConfirmed) {
          fncAgregarNuevaVitrina('123456')
        }
      })
      .catch((error) => {
        MyAlert('API-ERROR: ' + `${error}`, 3)
      })
  }

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      // toast: true,
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = (idsec: string, enabled: boolean) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'El token no fue informado.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        updateGondole(idsec, enabled, result.value)
      }
    })
  }

  const DeleteAlertToken = (keySectionId: string, idVitrina: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'El token no fue informado.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        deleteGondole(keySectionId, idVitrina, result.value)
      }
    })
  }

  const buttonHandler = (event: any, enabled: any) => {
    setCheckbox(event.target)
    setChecked(event.target.checked)

    setKeySectionId(event.target.dataset.keysectionid)
    AlertToken(event.target.dataset.keysectionid, event.target.checked)
  }

  const fncAgregarNuevaVitrina = (tokenGoogle: string) => {
    Service.post<any, any>(
      `/ManageCarrousel/CreateGondole?idHashScript=${selectedScript?.idHashScript}&keySectionId=${optionSelected.keySectionId}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          username: user.data.username,
          tokenGoogle, // no es necesario enviar esto
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          let codc = res.data.keySectionId.split('[cdp')[1]
          codc = res.data.action.template_appendClass + '[cdp' + codc
          setGondolaCreated(res.data)
          MyAlert(
            'Se creó correctamente la vitrina personalizada: ' +
              codc +
              ' . Ahora podrá editar y asociar Audiencia.',
            1,
            6000
          )

          setReload('recargar') // recargo grilla
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  useEffect(() => {
    if (viewSpinner || currentPage == 1) {
      setCurrentPage(44)
    }
    // options = options
    if (optionSelected.keySectionId !== '') {
      setViewSpinner(true)
      Service.get<any>(
        `/ManageCarrousel/GetGondolesCustomizedCdp?idHashScript=${selectedScript?.idHashScript}&keySectionId=${optionSelected.keySectionId}`,
        { headers: { token: user.data.token } }
      )
        .then((response) => {
          if (response.headers.statuscode === '0') {
            setVitrinas(response.data)
            setCurrentPage(1)
          } else {
            setVitrinas('')
          }
          setViewSpinner(false)
        })
        .catch((error) => {
          if (error.response.data === -6) {
            dispatch(logout(user.data.token))
          } else {
            MyAlert('API-ERROR: ' + `${error}`, 3)
          }
        })

      /* vitrinas.filter(function (section: any) {
        if (section.keySectionId == keySectionId) {
          section.action.template_enabled = checked == true ? '1' : '0'
          checkbox.checked = checked
          setReload('')
          }
        }) */
    }

    // Una vez creada la vitrina personalizada, lo redireccionamos al form de editar, para que agregue una audiencia
    if (gondolaCreated != undefined && gondolaCreated != null) {
      editar(gondolaCreated)
    }

    console.log('class spinner:' + `${classLoading}`)
  }, [reload])

  const openModalVitrinas = (option: any) => {
    setGondolaSelected(option)
    setNameGondolaSelected(option.nameEndPoint)
    setModalState(!isModalOpen)
  }

  const updateGondole = (
    keySectionId: any,
    enabled: boolean,
    tokenGoogle: string
  ) => {
    const action = {
      keySectionId,
      action: {
        template_enabled: enabled ? '1' : '0'
      }
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        username: user.data.username,
        tokenGoogle,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(action)
    }
    fetch(
      `${config.base_server_path}/ManageCarrousel/EnabledGondole?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
      .then(async (response) => await response.json())
      .then((result) => {
        if (result < 0) {
          if (result == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (result != -5 && result != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          setReload('recargar')
          MyAlert('Se actualizó con éxito la vitrina: ', 1)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const deleteGondole = (
    keySectionId: string,
    idVitrina: string,
    tokenGoogle: string
  ) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        username: user.data.username,
        tokenGoogle,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      }
    }
    fetch(
      `${config.base_server_path}/ManageCarrousel/DeleteGondole?idHashScript=${selectedScript?.idHashScript}&keySectionId=${keySectionId}`,
      requestOptions
    )
      .then(async (response) => await response.json())
      .then((result) => {
        if (result < 0) {
          if (result == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (result != -5 && result != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          setReload('re-recargar' + keySectionId)
          MyAlert(
            'Se eliminó con éxito la vitrina personalizada: ' + idVitrina,
            1
          )
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const handleEditarPriority = (e: any) => {
    /* if (e.target.value.match('^[0-9]*$') != null) {      
      const idx = e.target.dataset.index
      vitrinas[idx].priority = e.target.value
      setVitrinas(vitrinas)
    } */
  }

  const saveOrderPriority = (array: any) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(array)
    }
    fetch(
      `${config.base_server_path}/ManageCarrousel/ReorderPriorityGondoleCdp?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
      .then(async (response) => await response.json())
      .then((result) => {
        if (result < 0) {
          if (result != -5 && result != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          const rna = Math.random() * 100
          setReload('re-recargar-' + rna)
          console.log('ordenado!')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  return (
    <div className="mt-40">
      <div>
        <div className="contenedor-btn-grid2 mb-40">
          <Navbar bg="light" expand="lg" className="title-grid-gondola-grid">
            <Button
              variant="success"
              onClick={() => ConfirmCreateVitrina()}
              className="style-btn-volver"
            >
              <FormattedMessage
                id="app.audiencias.carrusel.add"
                defaultMessage="Agregar Carrusel Audiencia"
              />
            </Button>{' '}
            <TooltipPanel
              ObjectTooltip={
                user.data.tooltips.filter(
                  (t: any) =>
                    t.idTooltip ===
                    'app.cdp.grid-vitrina-cdp.title-add-new.tooltip'
                )[0]
              }
            />{' '}
            <h6 className="ml-20 ">
              {optionSelected.action.template_titulo} (
              {optionSelected.action.template_bwsection})
            </h6>
          </Navbar>
        </div>
      </div>

      {/*
      <table
        className={
          classLoading !== ''
            ? `${classLoading}` +
              ' table table-sm table-striped table-bordered '
            : ' table table-sm table-striped table-bordered'
        }
      >
        <thead>
          <tr>
            {tableHeaders.map((header: any) => {
              return (
                <th                  
                  className={header.id==3 ? "text-center p-20 ancho-340": "text-center p-20"}
                  scope="col"
                  key={header.id}                  
                >
                  {header.name}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {vitrinas.map((option: any, index: 1) => {
              let itemIndexSe = 0
              const esPlp = option.keySectionId.includes('-plp-')
              if(option.action != null && option.action.additionalFields.length >0){
                itemIndexSe = option.action.additionalFields.findIndex(
                  (x: { labelId: string }) => x.labelId == 'NameCategorySelect'
                )
              }
              const categoria = 
                  itemIndexSe > 0 && esPlp === true
                    ? ' [' +
                      `${option.action.additionalFields[itemIndexSe].value}` +
                      ']'
                    : ''
              let idCdp = option.keySectionId.split("[cdp")[1]
              idCdp = "[cdp" + idCdp
              const prodId = option.action.template_appendClass + idCdp // usado como ID para mostrar en alerts
              let strAudiencias = '';
              for(let i = 0; i < option.segments.length; i++) {
                strAudiencias += option.segments[i].label+','
              }              

            return (
              <tr
                key={option.keySectionId}
                onClick={()=>buttonHandler} // QUITAR ESTO
                className="tr_fila"
                data-dbid={option.keySectionId}
                style={{
                  background:
                    option.typeGondole == 'default' ? 'rgb(236 247 243)' : ''
                }}
              >
                <td>
                  {option.action.template_titulo}
                  <br />
                  {categoria}
                </td>
                <td>
                  {option.nameEndPoint}
                  {option.segments.map((s: any) =>{
                      <h4> esto: {s.label} y eto: {s.value}</h4>
                   })
                  }
                </td>
                <td>
                  {option.action.template_appendClass}                 
                  <br/>
                    <span className="badge badge-warning">{idCdp.toUpperCase()}</span>
                </td>
                <td>                  
                  <small>{strAudiencias.replaceAll(',',' | ')}</small>
                </td>
                <td>
                    <>
                      <small
                        id={'lbl-e-'+option.keySectionId}
                        >
                        {option.priority}
                      </small>
                    </>
                    { option.segments.length > 0 ? (
                        <>
                        <button
                          id={'btn-e-'+option.keySectionId}
                          className="btn btn-default disp-block"
                          onClick={() => EnabledEditInline(option.keySectionId, option.priority, 'show')}
                          // style={{ display: 'inherit' }}
                          title={intl.formatMessage(
                            { id: 'app.audiencias-tabla.title-editar-prioridad' },
                            { defaultMessage: 'Editar prioridad' }
                          )}
                          data-tip={intl.formatMessage(
                            { id: 'app.audiencias-tabla.title-editar-prioridad' },
                            { defaultMessage: 'Editar prioridad' }
                          )}
                        >
                          <BsPencil                              
                            data-tip={intl.formatMessage(
                              { id: 'app.audiencias-tabla.title-editar-prioridad' },
                              { defaultMessage: 'Editar prioridad' }
                            )}
                          />                                
                          <ReactTooltip
                            delayShow={10}
                            data-backgroundColor={'#2fad7e'}
                          />
                      </button>
                      
                      <input
                        id={'inp-'+option.keySectionId}
                        name={'inp-'+option.keySectionId}
                        type={'text'}                    
                        className="input automation__input-w100 input vtexskuconsult__input edit-inline-vitrinas disp-none"
                        onChange={handleEditarPriority}
                        data-index={index}                        
                      />
                      <button
                        id={'btn-s-'+option.keySectionId}
                        className="manage-grid-button disp-none"
                        onClick={() => SavePriorityInline(option.keySectionId)}
                        style={{                        
                          fontSize: '27px',                          
                        }}
                        title={intl.formatMessage(
                          { id: 'app.modal-regla.guardar' },
                          { defaultMessage: 'guardar' }
                        )}
                      >
                        <BsCheckLg                          
                          data-tip={intl.formatMessage(
                            { id: 'app.modal-regla.guardar' },
                            { defaultMessage: 'guardar' }
                          )}
                        />
                      </button>
                      <ReactTooltip
                        delayShow={10}
                        data-backgroundColor={'#2fad7e'}
                      />
                      <button
                        id={'btn-c-'+option.keySectionId}
                        className="btn btn-default disp-none"
                        onClick={() => EnabledEditInline(option.keySectionId, option.priority, 'hide')}
                        style={{ 
                          fontSize: '18px',
                          marginBottom: '5px'
                        }}
                        title={intl.formatMessage(
                          { id: 'app.audiencias-tabla.title-cancelar' },
                          { defaultMessage: 'Cancelar' }
                        )}
                        data-tip={intl.formatMessage(
                          { id: 'app.audiencias-tabla.title-cancelar' },
                          { defaultMessage: 'Cancelar' }
                        )}
                      >
                        <BsXLg                          
                          data-tip={intl.formatMessage(
                            { id: 'app.audiencias-tabla.title-cancelar' },
                            { defaultMessage: 'Cancelar' }
                          )}
                        />                                
                        <ReactTooltip
                          delayShow={10}
                          data-backgroundColor={'#2fad7e'}
                        />
                      </button>
                    </>
                    ) : null
                  }
                      
                  
                </td>

                <td>                  
                <label className="switch margin-left-12"
                    data-tip={useIntl().formatMessage(
                      { id: 'app.vitrinas-enabled.tooltipo' },
                      { defaultMessage: 'Habilitar/Desabilitar vitrina' }
                    )}>
                    <input
                      type="checkbox"
                      key={index.toString()}
                      onChange={(e) => {
                        buttonHandler(e, option.action.template_enabled)
                        setAppendClass(option.action.template_appendClass)
                      }}
                      id={'checkbox-' + JSON.stringify(index++)}
                      data-chk={option.action.template_enabled}
                      data-keySectionId={option.keySectionId}
                      checked={
                        option.action.template_enabled == '0' ? false : true
                      }
                      
                    />
                    <span className="slider round"></span>                    
                  </label>
                  <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

                  <button
                    className="manage-grid-button"
                    style={{ marginLeft: '15px;' }}
                    onClick={() => editar(option)}
                    data-tip={useIntl().formatMessage(
                      { id: 'app.vitrinas-editar.vitrina' },
                      { defaultMessage: 'Editar vitrina' }
                    )}
                  >
                    <BsPencilSquare
                      title={intl.formatMessage(
                        { id: 'app.vitrinas-editar.vitrina' },
                        { defaultMessage: 'Editar vitrina' }
                      )}
                      size={25}
                    />
                  </button>
                  <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

                  ****** MODAL: editar las audiencias sin ir a editar el carrusel. DESACTIVADO 
                  <button
                    className="manage-grid-button hide"
                    style={{ marginLeft: '15px;' }}
                    onClick={() => openModalVitrinas(option)}                    
                    data-tip={useIntl().formatMessage(
                      { id: 'app.modal-regla.guardar' },
                      { defaultMessage: 'Guardar' }
                    )}
                  >
                    <BsWrench
                      title={intl.formatMessage(
                        { id: 'app.modal-regla.guardar' },
                        { defaultMessage: 'Guardar' }
                      )}
                      size={25}
                    />
                  </button>
                  <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
                  ******* DESACTIVADO  

                  <button
                    className="manage-grid-button-del btn-grilla-del"
                    data-id={option.keySectionId}
                    onClick={() => {
                      DeleteAlertToken(option.keySectionId, prodId)
                    }}
                    data-tip={useIntl().formatMessage(
                      { id: 'app.audiencias-tabla.title-cancelar' },
                      { defaultMessage: 'Cancelar' }
                    )}
                  >
                    <BsTrash                      
                      color='rgb(230 114 60)'
                      title={intl.formatMessage(
                        { id: 'app.audiencias-tabla.title-cancelar' },
                        { defaultMessage: 'Cancelar' }
                      )}
                      size={25}
                    />
                  </button>
                  <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />


                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      */}

      <MaterialReactTable
        // toda la doc:https://www.material-react-table.com/docs
        autoResetPageIndex={false}
        columns={columns}
        data={vitrinas}
        enableRowOrdering
        enableSorting={false}
        enablePagination={true} // disable a default feature
        // onRowSelectionChange={setRowSelection} // hoist internal state to your own state (optional)
        // state={{ rowSelection }} // manage your own state, pass it back to the table (optional)
        tableInstanceRef={tableInstanceRef} // get a reference to the underlying table instance (optional)
        // drag and drop
        enableRowDragging={true}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState() // draggingRow->origen | hoveredRow->destino
            if (hoveredRow != null && draggingRow != null) {
              vitrinas.splice(
                (hoveredRow as any).index,
                0,
                vitrinas.splice(draggingRow.index, 1)[0]
              )
              // recorro para armar array
              const arraySend: Array<{
                keySectionId: string
                order: number
              }> = []
              vitrinas.map((vitrina: any, index: any) => {
                arraySend.push({
                  keySectionId: vitrina.keySectionId,
                  order: index + 1
                })
              })
              saveOrderPriority(arraySend)
              setVitrinas([...vitrinas])
            }
          }
        })}
        // Oculto los botones de arriba a la derecha
        enableColumnFilterModes={false}
        enableExpandAll={true}
        enableFilters={false}
        enableFullScreenToggle={true}
        enableGlobalFilter={false}
        enableGlobalFilterModes={false}
        enableHiding={true}
        enableDensityToggle={true}
        icons={{
          // change sort icon, connect internal props so that it gets styled correctly
          ArrowDownwardIcon: (props: any) => (
            <BsPencilSquare title="ocote" size={25} />
          ),
          CloseIcon: (props: any) => <BsPencilSquare title="ocote" size={25} />

          // SearchIcon: () => <FontAwesomeIcon icon={faSearch} />,
          // SortIcon: (props) => (<FontAwesomeIcon icon={faArrowDownWideShort} {...props} />), //best practice
        }}
        // Estilos de la tabla
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '5',
            border: '1px solid #e0e0e0'
          }
        }}
        muiTableBodyProps={{
          sx: (theme) => ({
            '& tr:nth-of-type(odd)': {
              backgroundColor: darken(theme.palette.background.default, 0.04)
            }
          })
        }}
        // columna de Acciones
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ cell, row, table }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <label
              className="switch margin-left-12 mt-8"
              data-tip={useIntl().formatMessage(
                { id: 'app.vitrinas-enabled.tooltipo' },
                { defaultMessage: 'Habilitar/Desabilitar vitrina' }
              )}
            >
              <input
                type="checkbox"
                key={cell.id.toString()}
                onChange={(e) => {
                  buttonHandler(e, row.original.action.template_enabled)
                  setAppendClass(row.original.action.template_appendClass)
                }}
                id={'checkbox-' + JSON.stringify(cell.id)}
                data-chk={row.original.action.template_enabled}
                data-keySectionId={row.original.keySectionId}
                checked={
                  row.original.action.template_enabled == '0' ? false : true
                }
              />
              <span className="slider round"></span>
            </label>
            <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

            <IconButton
              className="manage-grid-button svg"
              style={{ marginLeft: '15px;' }}
              onClick={() => editar(row.original)}
              data-tip={useIntl().formatMessage(
                { id: 'app.vitrinas-editar.vitrina' },
                { defaultMessage: 'Editar vitrina' }
              )}
            >
              <BsPencilSquare
                title={intl.formatMessage(
                  { id: 'app.vitrinas-editar.vitrina' },
                  { defaultMessage: 'Editar vitrina' }
                )}
                size={25}
              />
            </IconButton>
            <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

            <IconButton
              color="error"
              className="manage-grid-button-del btn-grilla-del"
              data-id={row.original.keySectionId}
              onClick={() => {
                let idCdp = row.original.keySectionId.split('[cdp')[1]
                idCdp = '[cdp' + idCdp
                const prodId = row.original.action.template_appendClass + idCdp // usado como ID para mostrar en alerts
                DeleteAlertToken(row.original.keySectionId, prodId)
              }}
              data-tip={useIntl().formatMessage(
                { id: 'app.audiencias-tabla.title-eliminar' },
                { defaultMessage: 'eliminar' }
              )}
            >
              <BsTrash
                color="rgb(230 114 60)"
                title={intl.formatMessage(
                  { id: 'app.audiencias-tabla.title-eliminar' },
                  { defaultMessage: 'eliminar' }
                )}
                size={25}
              />
            </IconButton>
            <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
          </Box>
        )}
        localization={{
          actions: 'ACCIONES',
          and: 'y',
          cancel: 'Cancelar',
          clickToCopy: 'Click para copiar',
          rowsPerPage: 'MOSTRANDO',
          of: 'De',
          move: 'Mover'
          // ... and many more - see link below for full list of translation keys
        }}
      />
      <br />
      <BsArrowLeft
        size={25}
        className="mr-20"
        onClick={() => fncShowGridVitrinas(false)}
        cursor={'pointer'}
      />
      <Button
        variant="default"
        onClick={() => fncShowGridVitrinas(false)}
        className="style-btn-volver2"
      >
        <FormattedMessage
          id="app.related-categories.add-cross-selling.volver"
          defaultMessage="VOLVER"
        />
      </Button>

      <VitrinasModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        gondolaSelected={gondolaSelected}
        nameGondolaSelected={nameGondolaSelected}
      />
    </div>
  )
}

export default VitrinasShelvesGridTable
