/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  @typescript-eslint/restrict-plus-operands */
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import { RootState } from '../../../state/store'
import Service from '../../../services'
import Card from 'react-bootstrap/Card'
import { ShowAlert } from '../../Globals'

import { logout, photoUpdate } from '../../../state/actions'
import { useEffect, useState } from 'react'

import './system.css'
import { BsTrash, BsUpload } from 'react-icons/bs'
import axios from 'axios'

import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const ProfileForm = () => {
  const intl = useIntl()
  const [tokenValue, setTokenValue] = useState<string>('')
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [imagePhoto, setImagePhoto] = useState('')

  const dispatch = useDispatch()

  interface FormData {
    IdDashboardUser: number
    name: string
    lastname: string
    email: string
    username: string
    photo: string
    active: number
  }

  const MyAlert = (text: string, iconStr: number) => {
    ShowAlert({
      title: text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: 3000,
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = (data: FormData) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        setTokenValue(result.value)
        SaveUserData(result.value, data)
      }
    })
  }

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      IdDashboardUser: user.data?.IdDashboardUser,
      name: user.data?.name ?? '',
      lastname: user.data?.lastname ?? '',
      email: user.data?.email ?? '',
      username: user.data?.username ?? '',
      photo: user.data?.photo ?? '',
      active: user.data?.active
    }
  })

  const onSubmit = (data: FormData) => {
    console.log(data)
    AlertToken(data)
  }

  const SaveUserData = (tokenGoogle: string, data: FormData) => {
    data.active = data.active ? 1 : 0
    Service.post<any, any>(
      `/Account/UpdateMyDataUserPanel?idHashScript=${selectedScript?.idHashScript}`,
      data,
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          tokenGoogle,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ), 3 )
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert('Ocurrió un Error al actualizar los datos.'+ res.data.Message, 2 )
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.update.ok' },
              { defaultMessage: 'Se actualizaron con éxito los datos.' }
            ),
            1
          )
          setTokenValue('')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const handlerRemoveImage = () => {
    Service.post<any, any>(
      `/Account/RemoveImageAvatarUser?idHashScript=${selectedScript?.idHashScript}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          userId: user.data.IdDashboardUser,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          setImagePhoto('')
          /* ======  FRAN: actualizar el estado, cambiar el atributo photo por este ** */
          dispatch(photoUpdate(''))
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.update.ok' },
              { defaultMessage: 'Se actualizaron con éxito los datos.' }
            ),
            1
          )
          setTokenValue('')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const handlerBtnfile = () => {
    document.getElementById('btnfile')?.click()
  }

  const UploadImage = (file: any) => {
    console.log(file)

    const formData = new FormData()
    formData.append('file', file)
    axios
      .post(
        `${process.env.REACT_APP_BASE_PATH_SERVER}/Account/UploadImageUserPanel?idHashScript=${selectedScript?.idHashScript}&userId=${user.data.IdDashboardUser}`,
        formData,
        {
          headers: {
            token: user.data.token,
            'Content-Type': 'multipart/form-data',
            Accept: '*/*',
            credentials: 'include'
          }
        }
      )
      .then((response) => {
        if (response.status == 200 && response.data.StatusCode == 0) {
          setImagePhoto(response.data.Message)
          /* ======  FRAN: actualizar el estado, cambiar el atributo photo por este ** */
          dispatch(photoUpdate(response.data.Message))
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.update.ok' },
              { defaultMessage: 'Se actualizaron con éxito los datos.' }
            ),
            1
          )
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.error' },
              { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
            ),
            2
          )
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const handlerUploadImage = (e: any) => {
    if (e.target.files[0].size > 1000000) {
      // 1MB
      MyAlert(
        intl.formatMessage(
          { id: 'app.suscribir.file.zise_1' },
          {
            defaultMessage:
              'El archivo que intenta subir supera el máximo permitido de 1MB.'
          }
        ),
        3
      )
    } else {
      if (
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/png'
      ) {
        const file = e.target.files[0]
        UploadImage(file)
      } else {
        MyAlert(
          intl.formatMessage(
            { id: 'app.suscribir.file.ext_1' },
            {
              defaultMessage: 'Las extensiones permitidas son *.jpg o *.png'
            }
          ),
          3
        )
      }
    }
  }

  useEffect(() => {
    const img = user.data?.photo ? user.data?.photo : ''
    setImagePhoto(img)
  }, [])

  return (
    <div
      className="container mg-t-0 container table-bordered "
      style={{ display: 'block' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="rule-config__wrapper sombra_caja">
          <div className="rule-config__title-container row">
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
            <div className="automation__wrapper">
              <div className="row">
                <div className=" col-lg-8 col-md-8 col-sm-8 col-xs-8">
                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <div className="shelves__endpoint-selector-container">
                        <label className="shelves__endpoint-selector-text mb-2">
                          <FormattedMessage
                            id="app.system.profile-form-nombre"
                            defaultMessage="Nombre"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                      <input
                        className="input vtexskuconsult__input col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        type="text"
                        {...register('name')}
                        defaultValue={user.data?.name ?? ''}
                        key="name2"
                      />
                    </div>
                  </div>

                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <div className="shelves__endpoint-selector-container">
                        <label className="shelves__endpoint-selector-text mb-2">
                          <FormattedMessage
                            id="app.system.profile-form-apellido"
                            defaultMessage="Apellido"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                      <input
                        className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        type="text"
                        {...register('lastname')}
                        defaultValue={user.data?.lastname ?? ''}
                        key="lastname"
                      />
                    </div>
                  </div>

                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <div className="shelves__endpoint-selector-container">
                        <label className="shelves__endpoint-selector-text mb-2">
                          <FormattedMessage
                            id="app.system..profile-form.email"
                            defaultMessage="Email"
                          />
                          {' '}
                          <span className="text-danger">*</span>

                          <TooltipPanel 
                            ObjectTooltip={user.data.tooltips.filter((
                              (t:any) => t.idTooltip === 'app.form.profile.field.user.tooltip')
                            )[0]}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                      <input
                        type="email"
                        {...register('email')}
                        defaultValue={user.data?.email ?? ''}
                        className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        key="email"
                      />
                      <br/>
                      <span className="small text-warning">El Email será utlizado para registro y recuperación de cuenta del panel</span>
                    </div>
                  </div>

                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <div className="shelves__endpoint-selector-container">
                        <label className="shelves__endpoint-selector-text mb-2">
                          <FormattedMessage
                            id="app.system..profile-form.username"
                            defaultMessage="Nombre de Usuario"
                          />
                          {' '}
                          <br/>
                          <span className="small text-danger">*</span>
                          <TooltipPanel 
                            ObjectTooltip={user.data.tooltips.filter((
                              (t:any) => t.idTooltip === 'app.form.profile.field.email.tooltip')
                            )[0]}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                      <input
                        className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        type="text"
                        {...register('username')}
                        defaultValue={user.data?.username ?? ''}
                        key="text1"
                      />
                      <br/>
                      <span className="small text-warning">El Username será utlizado para el ingreso al panel</span>
                    </div>
                  </div>

                  {/* <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <div className="shelves__endpoint-selector-container">
                          <label className="shelves__endpoint-selector-text mb-2">
                            <FormattedMessage
                              id="app.system..profile-form.active"
                              defaultMessage="Activo"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                        <label className="switch">                      
                          <input
                            type="checkbox"
                            {...register('active')}
                            defaultValue={user.data?.active ?? ''}
                            key="checkbox1"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div> */}
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    { /*
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="shelves__endpoint-selector-container">
                        <label className="h5 mb-2">
                          <FormattedMessage
                            id="app.system..profile-form.user.foto"
                            defaultMessage="Foto de perfil"
                          />
                        </label>
                      </div>
                    </div>
                    */ }
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <input
                        type="file"
                        className="input col-lg-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 display-none"
                        {...register('photo')}
                        key={'photo1'}
                        onChange={handlerUploadImage}
                        id={'btnfile'}
                      />
                      <Card
                        style={{ width: '18rem', padding: '1rem' }}
                        key={'light'}
                        text={'light'}
                      >
                        {user.data?.photo != '' ? (
                          <Card.Img
                            variant="top"
                            src={imagePhoto}
                            bsPrefix={'card-img-overlay'}
                            className={'border-round rounded-circle-black rounded-circle'}
                          />
                        ) : (
                          ''
                        )}

                        <Card.Body>
                          {/*
                            <Card.Title>{user.data?.username ?? ''}</Card.Title>
                            
                            <Card.Text>
                              {user.data?.name ?? ''} {user.data?.lastname ?? ''}
                            </Card.Text>
                            */}
                          <a
                            className=" btn btn-success col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            id="saveRemovePhoto"
                            onClick={handlerBtnfile}
                            key={'saveRemovePhoto'}
                          >
                            <BsUpload />
                            &nbsp;
                            <FormattedMessage
                              id="app.cdp.remove-data.upload"
                              defaultMessage="Sbir foto"
                            />
                          </a>
                          {user.data?.photo != '' ? (
                            <a
                              className=" btn btn-ligth col-lg-12 col-md-12 col-sm-12 col-xs-12 text-danger"
                              id="deleteRemovePhoto"
                              onClick={handlerRemoveImage}
                              key="deleteRemovePhoto"
                            >
                              <BsTrash />
                              &nbsp;
                              <FormattedMessage
                                id="app.cdp.remove-data.delete.photo"
                                defaultMessage="Eliminar foto"
                              />
                            </a>
                          ) : (
                            ''
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row shelves button botonera">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
            <div className="shelves__preview-button">
              <div className="shelves__preview-button">
                <button
                  className="ppal_button btn btn-primary"
                  id="saveBrandPriority"
                >
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.guardar"
                    defaultMessage="GUARDAR"
                  />
                </button>
                <div className="shelves__preview-button"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProfileForm
