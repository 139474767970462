/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback, useEffect, useState } from 'react'
import { CiEdit } from 'react-icons/ci'
import { FiCheckCircle } from 'react-icons/fi'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { FaChartPie } from 'react-icons/fa'
import { ImSpinner8 } from 'react-icons/im'

import { PlansOptions, PlansPrice } from './MshopContainerFacturacion'
import { useMshopContext } from '../../../contexts/MshopContext'
import Service from '../../../services'
import { RootState } from '../../../state/store'
import { logout } from '../../../state/actions'
import { useClientContext } from '../../../contexts/ClientContext'

interface PropsOption {
  keyProp: number
  textAlign?: string
  visibility?: string
  color?: string
  select?: string
}

interface PlanContainerProps {
  selected: boolean
}

const loadingSpin = keyframes`
  to {
    transform: rotate(360deg);
}
`

const Loading = styled(ImSpinner8)`
  animation: ${loadingSpin} 1.5s linear infinite;
  margin-left: 10px;
`
const Button = styled.a`
  background-color: #00868b !important;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin: 10px 8px;
  width: 175px;
  // height: 34px;
  color: #fff !important;
  &:hover {
    color: white !important;
  }
`

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderTitle = styled.h1`
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #dbdbdb !important;
  margin: 0px 0px 0px 5px;
  color: #666;
  text-transform: uppercase;
`

export const RowWrapper = styled.div`
  background-color: #fff;
  padding: 1rem;
`

const PlansContainer = styled.div`
  margin-top: 60px;
  /* display: flex; */
  display: grid;
  column-gap: 70px;
  padding-inline: 45px;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(6, auto);
  justify-content: space-evenly;
  position: relative;
  align-items: end;
`
export const ContainerIcon = styled.div`
  background-color: #00868b;
  padding: 0.54rem;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-basis: 30%; */
  grid-area: 1 / 1 / 4 / 6;
`

const PlanOption = styled.div<PropsOption>`
  font-size: 14px;
  color: ${(props) => props.color ?? '#666'};
  user-select: ${(props) => props.select ?? 'auto'};
  padding: 6px 12px;
  background-color: ${(props) =>
    props.keyProp % 2 === 0 ? '#E8E8ED' : '#F9FAFB'};
  margin: 8px 0px;
  box-shadow: 0px 1px 3px rgba(151, 151, 151, 50%);
  text-align: ${(props) => props.textAlign ?? ''};
`
const PlanContainer = styled.div<PlanContainerProps>`
  cursor: pointer;
  flex-basis: 16%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => (props.selected ? '#6529A1' : '#e8e8ed')};
  border-width: 1px !important;
  border-radius: 10px;
  padding-inline: 10px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    height: 50px;
    width: 100px;
    border-radius: 75px 75px 0 0;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid ${(props) => (props.selected ? '#6529A1' : '#e8e8ed')};
    border-bottom: #fff;
    background-color: white;
  }
`

const IconContainer = styled.div`
  display: table;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -34px;
  z-index: 2;
`

const PlanTitleContainer = styled.div`
  text-transform: uppercase;
  text-align: center;
  color: #666;
  font-size: 18px;
  margin-top: 8px;
`

const PlanPriceContainer = styled.div`
  color: #2bae7e;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  align-self: self-start;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PlanPrice = styled.span`
  margin-right: 5px;
  font-size: 30px;
  color: #6529a1;
`

const PlanPriceSwitchContainer = styled.div`
  grid-area: 5 / 9 / 6 / 12;
  justify-self: center;
  margin-top: 8px;
  align-self: center;
  padding: 0.5rem;
`

export const SectionContainer = styled.div`
  margin-top: 60px;
`

export const PlanTextHeader = styled.h3`
  font-size: 20px;
  margin: 0;
  margin-left: 8px;
  font-weight: 400;
  text-transform: uppercase;
`
export const SendSvg = ({
  width,
  height,
  fill
}: {
  width: string | number
  height: string | number
  fill: string
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.706 0.301508C29.4045 0 29.103 0 28.8015 0L0.603015 13.8693C0.301508 14.1709 0 14.4724 0 15.0754C0 15.6784 0.301508 15.9799 0.603015 16.2814L8.40452 20.804V29.5477C8.40452 29.8492 8.70603 30.1508 8.70603 30.1508H9.00754C9.20854 30.1508 9.40955 30.0503 9.61055 29.8492L14.4121 24.1206L20.4121 27.4372H21.309C21.6106 27.4372 21.9121 27.1357 22.206 26.5327L30 1.20603C30 0.904523 30 0.603015 29.6985 0.301508M1.19849 15.0754L27 2.11055L9.30151 19.8995L1.19849 15.0754ZM9.60301 27.7387V21.407L13.206 23.5176L9.60301 27.7387ZM21 26.2312L10.5 20.5025L28.206 2.71357L21.0075 26.2312H21Z"
      fill={fill}
    />
  </svg>
)

export const DiamondSvg = ({
  fill,
  width,
  height
}: {
  width: string | number
  height: string | number
  fill: string
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0406 30H16.6998C16.6998 29.6507 16.359 29.6507 16.359 29.6507L0 8.02613V7.32746L5.7938 0.349332L6.13463 0H28.2874C28.2874 0 28.6282 -1.26962e-07 28.6282 0.349332L34.0812 7.32746V7.6768V8.02613L17.7222 29.6507C17.3814 29.6507 17.3814 30 17.0406 30ZM11.9284 8.37546L17.0406 26.856L22.1528 8.37546H11.9284ZM23.8569 8.37546L18.7447 25.8165L32.0363 8.37546H23.8569ZM2.04484 8.37546L15.3366 25.8165L10.5651 8.37546H2.04484ZM24.5385 6.98665H32.0363L27.9466 1.75518L24.5385 6.98665ZM12.61 6.98665H21.812L17.0406 1.75518L12.61 6.98665ZM2.04484 6.98665H9.54275L6.47541 1.75518L2.04484 6.98665ZM18.7447 1.40585L23.1752 6.63732L26.9241 1.40585H18.7447ZM7.49786 1.40585L10.906 6.63732L15.6773 1.40585H7.49786Z"
      fill={fill}
    />
  </svg>
)

export const Plans = () => {
  const { planStatus } = useMshopContext()
  const { selectedClient } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [userData, setUserData] = useState<UserMshop | null>(null)
  const [loading, setLoading] = useState(false)
  const formatDate = useCallback((date) => {
    const Idate = new Date(date)
    const day = Idate.getDate()
    const month = Idate.getMonth() + 1
    const year = Idate.getFullYear()
    return `${day}/${month}/${year}`
  }, [])
  const dispatch = useDispatch()
  const [selectPlan, setSelectPlan] = useState<PlansOptions>(() => {
    // revisar y mejorar
    switch (planStatus?.IdPlan) {
      case 1:
        return PlansOptions.TRIAL
      case 2:
        return PlansOptions.BASIC
      case 3:
        return PlansOptions.ADVANCED_MONTH
      case 4:
        return PlansOptions.ADVANCED_SEMI_ANNUAL
      default:
        return PlansOptions.TRIAL
    }
  })
  const [planPrice, setPlanPrice] = useState<PlansPrice>(() => {
    if (selectPlan == PlansOptions.ADVANCED_MONTH) {
      return PlansPrice.SEMI_ANNUAL
    }
    if (selectPlan == PlansOptions.ADVANCED_SEMI_ANNUAL) {
      return PlansPrice.MONTH
    }
    return PlansPrice.SEMI_ANNUAL
  })

  const planOptions = [
    'Carrusel  últimos navegados en Home',
    'Carruseles personalizados en Home',
    'Carruseles personalizados en Categoría',
    'Carruseles personalizados en Ficha de Producto',
    'Carruseles personalizados en Búsqueda Positiva',
    'Carruseles personalizados en Búsqueda Negativa'
  ]

  const getUserInfo = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await Service.get<any>('/Account/UserProfile', {
        headers: { Token: user.data.token }
      })
      if (data) {
        if (!data.isActiveAppMshop) {
          dispatch(logout(user.data.token))
          return
        }
        setUserData(data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [user])

  const CheckIfClientIsValid = useCallback(async () => {
    try {
      if (!selectedClient) return
      setLoading(true)
      const { data } = await Service.get<any>('/Account/UserProfile', {
        headers: { Token: user.data.token }
      })
      const { data: planDetailData } = await Service.get<any>(
        `/Mshop/planDetail?idClient=${selectedClient.idClient}`
      )
      if (data && planDetailData && planDetailData.Status == 'Ok') {
        setUserData(data)
        if (!data.isActiveAppMshop) {
          dispatch(logout(user.data.token))
          return
        }
        if (
          data.isActiveAppMshop &&
          planDetailData &&
          planDetailData.Response?.IdPlan < 3
        ) {
          planPrice === PlansPrice.SEMI_ANNUAL
            ? (window.location.href =
                'https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c938084910f95b601912d7cb64908e7')
            : (window.location.href =
                'https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c938084910f959d01912d788a7f08f8')
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [planPrice, user, selectedClient])
  useEffect(() => {
    setSelectPlan(() => {
      switch (planStatus?.IdPlan) {
        case 1:
          return PlansOptions.TRIAL
        case 2:
          return PlansOptions.BASIC
        case 3:
          return PlansOptions.ADVANCED_MONTH
        case 4:
          return PlansOptions.ADVANCED_SEMI_ANNUAL
        default:
          return PlansOptions.TRIAL
      }
    })
  }, [planStatus])

  useEffect(() => {
    getUserInfo()

    // if (document.getElementById('mshop-script')) {
    //   const script = document.getElementById('mshop-script')
    //   script && document.body.removeChild(script)
    // }
    // const script = document.createElement('script')
    // script.id = 'mshop-script'
    // script.type = 'text/javascript'
    // script.async = true
    // script.src =
    //   document.location.protocol + '//secure.mlstatic.com/mptools/render.js'
    // // Append the script to the 'body' and remove it when the component unmounts
    // document.body.appendChild(script)

    // return () => {
    //   document.body.removeChild(script)
    // }
  }, [])
  return (
    <>
      {planStatus && (
        <div
          className="container"
          style={{ display: 'block', marginTop: '30px' }}
        >
          <RowWrapper className="row">
            <div
              className="col col-xs-12 d-flex"
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div
                className="col-sm-3 col-md-4 d-flex"
                style={{ flexDirection: 'column' }}
              >
                <div className="d-flex" style={{ alignItems: 'center' }}>
                  <ContainerIcon>
                    <FaChartPie size={'30px'} color="#fff" />
                  </ContainerIcon>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <PlanTextHeader>
                      <FormattedMessage
                        id="app.facturacion.title"
                        defaultMessage="Plan"
                      />{' '}
                      :{' '}
                      <span
                        style={{ fontWeight: 600, textTransform: 'capitalize' }}
                      >
                        {planStatus.PlanName}
                      </span>
                    </PlanTextHeader>
                    {planStatus.IdPlan === 1 && (
                      <p
                        style={{
                          margin: 0,
                          fontSize: 16,
                          fontWeight: 300,
                          marginLeft: 8
                        }}
                      >
                        Prueba Gratuita del Plan Avanzado
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 d-flex"
                style={{ alignItems: 'center', justifyContent: 'end' }}
              >
                {(planStatus?.ExpirationDate ??
                  planStatus?.NextPaymentDate) && (
                  <p className="mr-2" style={{ margin: 0 }}>
                    {planStatus?.ExpirationDate
                      ? 'Se expira el'
                      : 'Se Renueva el'}{' '}
                    <span style={{ color: '#00868B', fontWeight: 'bold' }}>
                      {formatDate(
                        planStatus?.ExpirationDate ??
                          planStatus?.NextPaymentDate
                      )}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </RowWrapper>
        </div>
      )}
      <SectionContainer className="container">
        <RowWrapper className="row">
          <HeaderTitleContainer>
            <CiEdit size={24} color="#108D92" />
            <HeaderTitle>Actualizar Plan</HeaderTitle>
          </HeaderTitleContainer>
          <PlansContainer>
            <OptionsContainer>
              {planOptions.map((opt, idx) => (
                <PlanOption key={idx} keyProp={idx}>
                  {opt}
                </PlanOption>
              ))}
            </OptionsContainer>
            <PlanContainer
              style={{ gridArea: '1 / 6 / 4 / 9' }}
              selected={selectPlan === PlansOptions.BASIC}
              // onClick={() => setSelectPlan(PlansOptions.BASIC)}
            >
              <IconContainer>
                <SendSvg
                  height={31}
                  width={30}
                  fill={
                    selectPlan === PlansOptions.BASIC ? '#6529A1' : '#979797'
                  }
                />
              </IconContainer>
              <PlanTitleContainer>Plan Básico</PlanTitleContainer>
              {planOptions.map((opt, idx) => {
                if (idx === 0) {
                  return (
                    <PlanOption key={idx} keyProp={idx} textAlign="center">
                      <FiCheckCircle color="#6529A1" />
                    </PlanOption>
                  )
                }
                return (
                  <PlanOption
                    key={idx}
                    keyProp={idx}
                    textAlign="center"
                    select="none"
                    color="transparent"
                  >
                    {idx}
                  </PlanOption>
                )
              })}
            </PlanContainer>
            <PlanContainer
              style={{ gridArea: '1 / 9 / 4 / 12' }}
              selected={selectPlan !== PlansOptions.BASIC}
              // onClick={() => setSelectPlan(PlansOptions.ADVANCED)}
            >
              <IconContainer>
                <DiamondSvg
                  height={30}
                  width={35}
                  fill={
                    selectPlan !== PlansOptions.BASIC ? '#6529A1' : '#979797'
                  }
                />
              </IconContainer>
              <PlanTitleContainer>Plan Avanzado</PlanTitleContainer>
              {planOptions.map((opt, idx) => (
                <PlanOption key={idx} keyProp={idx} textAlign="center">
                  <FiCheckCircle color="#6529A1" />
                </PlanOption>
              ))}
            </PlanContainer>
            <PlanPriceContainer
              style={{ gridArea: '4 / 6 / 5 / 9', alignSelf: 'center' }}
            >
              <PlanPrice style={{ fontSize: 20, fontWeight: 'normal' }}>
                Gratis
              </PlanPrice>
            </PlanPriceContainer>
            <PlanPriceContainer
              style={{ gridArea: '4 / 9 / 4 / 12', flexDirection: 'column' }}
            >
              {(selectPlan === PlansOptions.BASIC ||
                selectPlan === PlansOptions.TRIAL) && (
                <>
                  <PlanPrice>
                    {planPrice === PlansPrice.SEMI_ANNUAL
                      ? userData?.country.toLocaleLowerCase() == 'argentina'
                        ? '$ 42.000'
                        : 60
                      : userData?.country.toLocaleLowerCase() == 'argentina'
                      ? '$ 10.000'
                      : 40}
                  </PlanPrice>{' '}
                  {userData?.country.toLocaleLowerCase() == 'argentina'
                    ? ''
                    : 'u$d'}
                  {'  '}
                  {planPrice === PlansPrice.SEMI_ANNUAL && (
                    <div style={{ color: '#108D92' }}>Ahorra 30%</div>
                  )}
                </>
              )}
            </PlanPriceContainer>
            <PlanPriceSwitchContainer>
              {(selectPlan === PlansOptions.BASIC ||
                selectPlan === PlansOptions.TRIAL) && (
                <>
                  <span
                    style={{
                      textTransform: 'uppercase',
                      fontSize: 13,
                      color:
                        planPrice === PlansPrice.SEMI_ANNUAL
                          ? '#6529A1'
                          : '#2E2E2E'
                    }}
                  >
                    Semestral
                  </span>

                  <label className="switch_mode" style={{ margin: '0px 8px' }}>
                    <input
                      className=""
                      type="checkbox"
                      id="switch-modes"
                      onChange={(e) => {
                        if (e.target.checked) {
                          return setPlanPrice(PlansPrice.MONTH)
                        }
                        return setPlanPrice(PlansPrice.SEMI_ANNUAL)
                      }}
                      checked={planPrice === PlansPrice.MONTH}
                    />
                    <span className={'slider-mode round slider-bckg-on'} />
                  </label>

                  <span
                    style={{
                      textTransform: 'uppercase',
                      fontSize: 13,
                      color:
                        planPrice === PlansPrice.MONTH ? '#6529A1' : '#2E2E2E'
                    }}
                  >
                    Mensual
                  </span>
                </>
              )}
            </PlanPriceSwitchContainer>
            {(selectPlan === PlansOptions.BASIC ||
              selectPlan === PlansOptions.TRIAL) && (
              <Button
                style={{
                  width: '80%',
                  fontSize: 14,
                  marginTop: 10,
                  gridArea: '6 / 9 / 7 / 12',
                  placeSelf: 'center',
                  opacity: !loading ? 1 : 0.5
                }}
                onClick={(e) => {
                  e.preventDefault()
                  if (!loading) {
                    CheckIfClientIsValid()
                  }
                }}
                // href={

                // }
                // name="MP-payButton"
                className="btn"
              >
                ¡Suscribirme!
                {loading && <Loading />}
              </Button>
            )}
            {/* {planStatus && planStatus?.IdPlan === 3 && ( */}
            <Button
              style={{
                width: '80%',
                fontSize: 14,
                marginTop: 10,
                gridArea: '6 / 6 / 7 / 9',
                placeSelf: 'center',
                backgroundColor: '#fff'
              }}
              className="btn"
            >
              ¿ Como Darme de Baja ?
            </Button>
            {/* )} */}
          </PlansContainer>
        </RowWrapper>
      </SectionContainer>
    </>
  )
}
