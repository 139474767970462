import { USER_LOGOUT, USER_LOGIN_FAIL } from '../constants/userConstants'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { RootState } from '../store'
import { USER_AUTH_LOGOUT } from '../constants/authConstants'
import { CLIENT_RESET } from '../constants/clientConstants'
import { SCRIPT_RESET } from '../constants/scriptConstants'
import { useUserLogout } from '../../hooks/useUsers'

export const logout =
  (token: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      dispatch({
        type: USER_LOGOUT
      })
      useUserLogout(token)
      localStorage.clear()
      dispatch({
        type: USER_AUTH_LOGOUT
      })
      localStorage.clear()
      dispatch({
        type: CLIENT_RESET
      })
      dispatch({
        type: SCRIPT_RESET
      })
    } catch (error: any) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response.data.message !== ''
            ? error.response.data.message
            : error.message
      })
    }
  }
