/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback } from 'react'
import Select from 'react-select'

import './style.css'
import { useClientContext } from '../../contexts/ClientContext'

const PlatformSelector = () => {
  const { selectedClient, platforms, selectedPlatform, setPlatform, clients } =
    useClientContext()

  const handleScriptChange = useCallback(
    (plat) => {
      setPlatform(plat, clients)
    },
    [clients]
  )

  return (
    <div className="col col-2 col-md-2 col-sm-2 col-xs-2">
      {selectedClient?.scripts?.length && (
        <Select
          classNamePrefix="select"
          value={{
            value: selectedPlatform,
            label: selectedPlatform
          }}
          isDisabled={!selectedClient}
          // isSearchable={true}
          options={platforms.map((platform) => ({
            label: platform,
            value: platform
          }))}
          onChange={(e) => e && handleScriptChange(e?.value)}
          styles={{
            control: (prevStyles) => ({
              ...prevStyles,
              // minHeight: '33.5px',
              ':hover': {
                ...prevStyles[':hover'],
                borderColor: 'hsl(0, 0%, 80%)'
              },
              // height: '33.5px',
              backgroundColor: '#fff !important',
              boxShadow: 'none'
            }),
            valueContainer: (prevStyles) => ({
              ...prevStyles,
              padding: '0px 8px'
            }),
            placeholder: (prevStyles) => ({
              ...prevStyles,
              fontSize: 14
            }),
            singleValue: (prevStyles) => ({
              ...prevStyles,
              fontSize: 14
            }),
            option: (prevStyles, { isSelected }) => ({
              ...prevStyles,
              ':hover': {
                ...prevStyles[':hover'],
                backgroundColor: isSelected
                  ? '#6529A1'
                  : 'rgb(101 41 161 / 70%)',
                color: '#fff'
              },
              backgroundColor: isSelected ? '#6529A1' : '#fff',
              ':active': {
                ...prevStyles[':active'],
                backgroundColor: '#6529A1',
                color: '#fff'
              }
            })
          }}
        />
      )}
    </div>
  )
}

export default PlatformSelector
