import React, { useEffect } from 'react'
import AsrticlesSliderContainer from './AsrticlesSliderContainer'
import './Style.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import Service from '../../../services'
import { useNavigate } from 'react-router-dom'

const HomeSiteContainer = () => {
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const navigate = useNavigate()
  useEffect(() => {
    Service.get<any>(`/Account/ProfileStatus`, {
      headers: { token: token.data.token }
    })
      .then((response) => {
        if (
          response.data.Status !== 'OK' &&
          response.data.Response.Platform === 'MercadoShop' &&
          response.data.Response.Message !== 'Los datos están completos'
        ) {
          navigate('/dashboardmshop/profile')
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [])

  return (
    <div className="container mg-t-30">
      {/* SLIDER */}
      <AsrticlesSliderContainer />
      <br />

      {/* --ARTICULOS -- 
      <ArticlesContainer /> */}

      {/* --LINKEDIN-- */}
      <iframe
        src="https://widgets.sociablekit.com/linkedin-page-posts/iframe/101856"
        width="100%"
        height="800"
        id="framsocial"
        name="framsocial"
      ></iframe>

      {/* --YOUTUBE-- */}
      <div className="video-responsive video-home">
        <iframe
          id="framevideo"
          className="iframe-video"
          width="80%"
          height="480"
          src={`https://www.youtube.com/embed/3B5tSNKXr6c`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  )
}

export default HomeSiteContainer
