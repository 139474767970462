import { FiCheckCircle } from 'react-icons/fi'

const MshopInstructivo = () => {
  return (
    <div className="ms-instructivo">
      <div className="ms_has-animations">
        <div className="ms_body-wrap">
          <header className="ms_site-header">
            <div className="landing_ms_container">
              <div className="ms_site-header-inner">
                <div className="landing_ms_brand header-brand">
                  <h1 className="m-0">
                    <a href="#">
                      <img
                        className="ms_header-logo-image"
                        src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/17/logo_claim.svg"
                        alt="Logo"
                      />
                    </a>
                  </h1>
                </div>
              </div>
            </div>
          </header>

          <section className="ms_features section">
            <div className="ms_features-inner ms_section-inner2">
              <div className="ms_features-wrap">
                <div className="ms_feature text-center ms_feature2">
                  <h4 className="color_primary mt-4">GUÍA DE INSTALACIÓN</h4>
                  <h5 className="ms_feature-title mb-0">¡Ya falta poco!</h5>
                  <>
                    <div className="text-left texto-bullet">
                      <h5 className="ms_text-sm mb-0 mt-4">
                        <b>
                          Antes de comenzar, repasemos el recorrido de
                          instalación:
                        </b>
                      </h5>

                      <h5 className="ms_feature-title mb-0 mt-4">PASO 1</h5>
                      <p className="ms_text-sm mb-0">
                        <b>Personalizá la url de tu Shop</b>
                        <br />
                        Ingresa a &quot;Configuración&quot; desde tu cuenta en
                        Mercado Shops. Haz clic en &quot;Configuraciones
                        generales&quot; y luego haz clic en &quot;Dominio&quot;.
                      </p>
                      <div className="text-center">
                        <img
                          className="mockup-ms mt-4 mb-4"
                          src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/06/06/mckconfigdom.svg"
                        />
                      </div>

                      <h5 className="ms_feature-title mb-0 mt-4">PASO 2</h5>
                      <p className="ms_text-sm mb-0">
                        <b>Permisos mercado libre</b>
                        <br />
                        Autoriza a que la aplicación Brain se conecte con tu
                        cuenta Mercado Libre
                      </p>
                      <div className="text-center">
                        <img
                          className="mockup-ms mt-4 mb-4"
                          src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/27/mckautorizaMeli.svg"
                        />
                      </div>

                      <h5 className="ms_feature-title mb-0 mt-4">PASO 3</h5>
                      <p className="ms_text-sm mb-0">
                        <b>Google Authenticator</b>
                        <br />
                        Descarga la app Google Authenticator en tu dispositivo
                        móvil, desde <a href="">Google Play</a> o{' '}
                        <a href="">App Store</a>
                      </p>
                      <div className="text-center">
                        <img
                          className="mockup-ms mt-4 mb-4"
                          src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/27/mckGooAuth.svg"
                        />
                      </div>

                      <h5 className="ms_feature-title mb-0 mt-4">PASO 4</h5>
                      <p className="ms_text-sm mb-0">
                        <b>Accesos</b>
                        <br />
                        Revisa tu casilla de correo y abre el email de
                        bienvenida. Allí encontrarás toda la información
                        necesaria para acceder al panel, incluyendo un código
                        QR, tu nombre de usuario, contraseña y un enlace al
                        panel. Esta información también se te proporcionará al
                        finalizar la instalación.
                      </p>
                      <div className="text-center">
                        <img
                          className="mockup-ms mt-4 mb-4"
                          src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/27/mckmail.svg"
                        />
                      </div>
                      <p className="ms_text-sm mb-0 mt-3 ml-2">
                        <FiCheckCircle color="#6529A1" />
                        <b className="ml-1">Código QR</b>
                        <br />
                        Escannea desde la app Google Authenticator el código QR
                        para generar tu código token
                      </p>
                      <p className="ms_text-sm mb-0 mt-3 ml-2">
                        <FiCheckCircle color="#6529A1" />
                        <b className="ml-1">Login</b>
                        <br />
                        Accede al <a href="">panel de Brain</a>, ingresando el
                        usuario y contraseña que recibiste.
                      </p>
                      <p className="ms_text-sm mb-0 mt-3 ml-2">
                        <FiCheckCircle color="#6529A1" />
                        <b className="ml-1">Código Token</b>
                        <br />
                        Ingresa el código Token generado por Google
                        Authenticator
                      </p>
                    </div>
                  </>
                </div>
              </div>
              <div className="ms_cta-cta mt-3 ">
                <a
                  className="ms_button ms_button-primary ms_button-wide-mobile"
                  href={`https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=7605895153273357&redirect_uri=${process.env.REACT_APP_BASE_PATH_FRONT}/mshopthankspage`}
                  target="_blank"
                  rel="noreferrer"
                >
                  INSTALAR
                </a>
              </div>
            </div>
          </section>

          <footer className="ms_site-footer">
            <div className="landing_ms_container">
              <div className="ms_site-footer-inner">
                <div className="landing_ms_brand footer-brand">
                  <a href="#">
                    <img
                      className="ms_header-logo-image"
                      src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/17/logo_claim.svg"
                      alt="Logo"
                    />
                  </a>
                </div>
                <div className="ms_footer-copyright mt-4">
                  &copy; 2024 Brain, todos los derechos reservados.
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default MshopInstructivo
