import React from 'react'

const Check: React.FC<{ id: string; title: string; value: any }> = ({
  id,
  title,
  value
}) => {
  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const limitNumber = parseInt(event.target.value)
    value(limitNumber)
  }
  return (
    <div className="automation__endpoint-selector-container">
      <label className="automation__endpoint-selector-text mb-2">{title}</label>
      <input
        id={id}
        key={id}
        className="input automation__input-w100"
        type={'checkbox'}
        placeholder={title}
        onChange={inputOnChange}
      />
    </div>
  )
}

export default Check
