import { useState } from 'react'
import { TooltipPanel } from '../../../Globals/TooltipPanel'
import {  useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'

interface OrderOptionsProps {
  title: string
  orderOptions: OrderOptionsObj[]
  values: any  
}

interface OrderOptionsObj {
  id: number
  order: string
  value: number
}

const OrderInput: React.FC<OrderOptionsProps> = ({
  title,
  orderOptions,
  values
}) => {
  const [order, setOrder] = useState<number | undefined>(undefined)
  const valueParams = values
    .filter((x: any) => x[0] == 'order')
    .map((item: any) => {
      return item[1]
    })
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const orderNumber = parseInt(event.target.value)
    setOrder(orderNumber)
  }

  const user = useSelector<RootState, any>((state) => state.userAuth)

  return (
    <div className="rule-config-subComponents__container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="row a-center">
            <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
              {title}
              <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.rules.modal.order.input.tooltip')
                  )[0]}
                />              
            </h6>
            <select
              value={order}
              className="col-lg-8 col-md-8 col-sm-8 col-xs-8  ancho-alto-select"
              id="order"
              onChange={handleChange}
            >
              <option selected>Seleccionar</option>
              {orderOptions.map((option) => {
                return (
                  <option
                    value={option.value}
                    key={option.id}
                    selected={
                      !!(
                        valueParams !== undefined &&
                        valueParams[1] == option.value
                      )
                    }
                  >
                    {option.order}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderInput
