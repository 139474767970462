import { useSelector } from 'react-redux'
import { RootState } from '../state/store'

const useAuth = () => {
  const data = useSelector<RootState, StateResponse<UserLoggedIn>>(
    (state: RootState) => state.userAuth
  )

  return data.loading
}

export default useAuth
