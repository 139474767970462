/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Modal } from '../../../Globals/Modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import React, { useEffect, useState } from 'react'
import RowsAudienceRelated from '../RowsAudienceRelated'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { ShowAlert } from '../../../../components/Globals'
import Service from '../../../../services'
import { logout } from '../../../../state/actions'
import { MdPadding } from 'react-icons/md'
import { useClientContext } from '../../../../contexts/ClientContext'

const VitrinasModal = ({
  isOpen,
  toggleModal,
  gondolaSelected,
  nameGondolaSelected
}: any) => {
  const { selectedScript } = useClientContext()
  const auth = useSelector<RootState, any>((state) => state.userAuth)

  const [audiences, setAudiences] = useState<any>([])
  const [arrayAudiencesForSelect, setArrayAudiencesForSelect] = useState<any>([])
  const [loadingAudiences, setLoadingAudiences] = useState(false)

  const intl = useIntl()
  const dispatch = useDispatch()

  const [arrayAudienciasRelated, setArrayAudienciasRelated] = useState<any>([])

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const fncOnKeyDownSelect = (event: any, a:any, b: any) => {
    console.log(event)
  }
  const ConfirmDeleteAudience = (action: string, idDelete: number) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.audiencias.asoc' },
        { defaultMessage: 'defaultMessage="Asociar Audiencia' }
      ),        
      html:
        '<span class="m-4 text-left">' +
          action == 'CLONE' 
          ? intl.formatMessage(
            { id: 'app.cdp.related.audience.message-question' },
            {  defaultMessage: '¿Confirma que desea clonar la vitrina?' }
            ) 
          : intl.formatMessage(
            { id: 'app.cdp.remove.related.audience.message-question' },
            {  defaultMessage: '¿Confirma que desea Eliminar la audiencia de la vitrina?.' }
          ) +
        '</span>',
      inputLabel: '',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.continuar' },
        { defaultMessage: 'Continuar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cancelar' },
        { defaultMessage: 'Cancelar' }
      ),
      width: 600
    })
      .then((result) => {
        if (result.isConfirmed) {
          if(action == 'CLONE'){            
            getLoadAllAudiences()            
          }          
          if(action == 'DELETE'){            
            setArrayAudienciasRelated(
              arrayAudienciasRelated.filter((e:any) => e.value !== idDelete)
            )            
          }
        }
      })
      .catch((error) => {
        MyAlert('API-ERROR: ' + `${error}`, 3)
      })
  }


  const selectOnChangeAudiences = (event: any) => {
    const res = arrayAudienciasRelated.findIndex((elem: any) => elem.value === event.value)
    if(res >= 0){  
      alert('Ya existe una audiencia '+event.label+'relacionada para esta vitrina ')
    }else{
      const foundIndex = arrayAudienciasRelated.findIndex( (x:any) => x.value == 0)
      if(foundIndex >= 0){
        arrayAudienciasRelated[foundIndex].value = event.value
        arrayAudienciasRelated[foundIndex].label = event.label
      }
      
    }
  }

  const getIndexAudiences = (elem: string) => {
    const itemIndexSe = audiences.findIndex((x: any) => x.AliasName === elem)    
    return itemIndexSe
  }

  const AddAudience = () => {
    if(arrayAudienciasRelated.length >= 5){
      MyAlert(
        intl.formatMessage(
          { id: 'app.cdp.add.related.audience.message-limit' },
          { defaultMessage: 'La cantidad máxima de audiencias relacionadas es de 5.' }
        ), 3, 4000
      )
    }else{
      const rows = [
        ...arrayAudienciasRelated,
        {
          IdSegment: 0,
          AliasName: '',
        }
      ]
      setArrayAudienciasRelated(rows)
    }
  }
  
  const getLoadAllAudiences = async (    
  ) => {
    setLoadingAudiences(true)
    Service.get<any>(
      `Automation/GetAllSegmentsCDP?idHashScript=${
        selectedScript?.idHashScript
      }&page=1&per_page=10000&filterText=&sortField=aliasName&sortDirection=desc`,
      {
        headers: {
          token: auth.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {          
          if (response.data.Entities.length > 0) {
            setAudiences(response.data.Entities)            
            response.data.Entities.forEach((item: any, index: any) =>
              arrayAudiencesForSelect.push({
                value: item.IdSegment,
                label: item.AliasName                
              })
            )
            setArrayAudiencesForSelect(arrayAudiencesForSelect)
          }
          setTimeout(() => {
            setLoadingAudiences(false)
          }, 1000)
        } else {
          setArrayAudiencesForSelect('')
        }
        setLoadingAudiences(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(auth.data.token))
        }
        setLoadingAudiences(false)
      })
  }

  const saveAudiencias = () => {
    MyAlert('Se grabo correctamente',1)
    setTimeout(() => {
      toggleModal()
    }, 2000)
    
  }

  useEffect(() => {
    if(arrayAudienciasRelated.length > 0){
      setArrayAudienciasRelated(gondolaSelected.segments)
      getLoadAllAudiences()      
    }
  }, [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={toggleModal}
        title={intl.formatMessage(
          { id: 'app.vitrinas-editar.vitrina-audiencia' },
          { defaultMessage: 'CONFIGURAR Audiencia' }
        )}
        myWidth={'65%'}
        content={''}
      >
        <div className="row">
          <div className="row panel">
            <h6>{nameGondolaSelected}</h6>
          </div>

          <div className="">
                  <div className="rule-config__title-container row">
                    <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">                      
                    
                    {arrayAudienciasRelated.map((item: any, index: any) => {
                      return ( 
                        <>
                          <RowsAudienceRelated
                            selectedIndex={getIndexAudiences(item.label)}
                            item={item}                            
                            arrayAudiencesForSelect={arrayAudiencesForSelect}
                            loadingAudiences={loadingAudiences}
                            arrayAudienciasRelated={arrayAudienciasRelated}
                            fncDeleteItemAudiencia = {ConfirmDeleteAudience}
                            fncOnChangeAudiences = {selectOnChangeAudiences}
                            key={item.value}                            
                            fncOnBlurSelectAudiencias={fncOnKeyDownSelect}
                          />
                        </>
                      )})
                    }
                    
                    </div>
                    <div
                        className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        style={{ position: 'relative', left: '3%' }}
                      >
                        <a
                          className={arrayAudienciasRelated.length >= 5 ? "manage-grid-button btn btn-border btn-agregar disabled": "manage-grid-button btn btn-border btn-agregar"}
                          onClick={AddAudience}
                        >
                          <BsFillPlusCircleFill size={25} /> &nbsp;
                          {intl.formatMessage(
                            { id: 'app.audiencias.add' },
                            { defaultMessage: 'Agregar audiencia' }
                          )}                          
                        </a>
                        <span className={arrayAudienciasRelated.length >= 5 ? 'text-danger small' : 'text-danger small display-none'}>
                          <FormattedMessage
                            id="app.cdp.add.related.audience.message-limit"
                            defaultMessage="La cantidad máxima de audiencias relacionadas es de 5."
                          />
                        </span>
                    </div>
                    
                  </div>
                </div>
                <div className='row'>
                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                  <div className="shelves__preview-button center-btn-save">
                    <a
                      className="ppal_button btn btn-primary"
                      id="saveRule"
                      style={{padding: '10px 44px'}}
                      onClick={() => {
                        saveAudiencias()
                      }}
                    >
                      <FormattedMessage
                        id="app.vitrinas-editar.guardar"
                        defaultMessage="GUARDAR"
                      />
                    </a>
                  </div>

                  </div>
                </div>

        </div>
      </Modal>
    </>
  )
}

export default VitrinasModal

