/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  @typescript-eslint/restrict-plus-operands */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CiEdit } from 'react-icons/ci'

import { RootState } from '../../../state/store'
import Service from '../../../services'
import { HeaderTitle, RowWrapper } from './Plans'
import styled from 'styled-components'
import { logout } from '../../../state/actions'
import { useClientContext } from '../../../contexts/ClientContext'

const Button = styled(Link)`
  background-color: #00868b !important;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  width: 175px;
  height: 34px;
  margin-top: 10px;
  color: #fff !important;
  &:hover {
    color: white !important;
  }
`

const FacturacionInfo = ({ detailsPlan }: any) => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [userData, setUserData] = useState<UserMshop | null>(null)
  const { selectedClient } = useClientContext()

  const dispatch = useDispatch()

  const getGetBillingData = useCallback(async () => {
    try {
      if (!selectedClient) {
        return
      }
      const { data } = await Service.get<any>(
        `/Mshop/GetBillingData?idClient=${selectedClient.idClient}`,
        {
          headers: { token: user.data.token }
        }
      )
      if (data) {
        setUserData(data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [user, selectedClient])

  const getUserInfo = useCallback(async () => {
    try {
      // setLoading(true)
      const { data } = await Service.get<any>('/Account/UserProfile', {
        headers: { Token: user.data.token }
      })
      if (data) {
        if (!data.isActiveAppMshop) {
          dispatch(logout(user.data.token))
          return
        }
        setUserData(data)
      }
      // setLoading(false)
    } catch (error) {
      console.log(error)
      //  setLoading(false)
    }
  }, [user])

  useEffect(() => {
    if (user.data.userType !== 1 && user.data.Platform === 'MercadoShop') {
      getUserInfo()
    } else {
      getGetBillingData()
    }
  }, [user])
  return (
    <RowWrapper className="row mt-4">
      <div className="col-sm-3">
        <HeaderTitle style={{ display: 'inline-flex', marginBottom: 32 }}>
          Detalles de facturación
        </HeaderTitle>
      </div>
      <div className="col-sm-12">
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '4px 0'
          }}
        >
          <p
            style={{
              flex: 1,
              margin: 0
            }}
          >
            {userData?.companyName}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '4px 0'
          }}
        >
          <p
            style={{
              flex: 1,
              margin: 0
            }}
          >
            {userData?.urlCompany}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '4px 0'
          }}
        >
          <p
            style={{
              flex: 1,
              margin: 0
            }}
          >
            {userData?.cuit}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '4px 0'
          }}
        >
          <p
            style={{
              flex: 1,
              margin: 0
            }}
          >
            {userData?.taxCondition}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '4px 0'
          }}
        >
          <p
            style={{
              flex: 1,
              margin: 0
            }}
          >
            {userData?.country} - {userData?.state} - {userData?.city}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '4px 0'
          }}
        >
          <p
            style={{
              flex: 1,
              margin: 0
            }}
          >
            {userData?.address}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '4px 0'
          }}
        >
          <p
            style={{
              flex: 1,
              margin: 0
            }}
          >
            {userData?.postalCode}
          </p>
        </div>
        {user.data.userType !== 1 && user.data.Platform === 'MercadoShop' ? (
          <Button to="/dashboardmshop/profile" className="btn">
            <CiEdit size={16} color="#fff" style={{ marginRight: 7 }} />
            Editar Datos
          </Button>
        ) : null}
      </div>
    </RowWrapper>
  )
}

export default FacturacionInfo
