import React, { useState } from 'react'
import { BsPencilSquare } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import ExportLogsForm from './ExportLogsForm'
import ExportLogsTable from './ExportLogsTable'
import Accordion from 'react-bootstrap/Accordion'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import {  useSelector } from 'react-redux'
import { RootState } from '../../../state/store'

import './system.css'

const ExportLogsContainer = () => {
  const [dateFromFilter, setDateFromFilter] = useState('')
  const [dateToFilter, setDateToFilter] = useState('')
  const [filter, setFilter] = useState(false)

  const user = useSelector<RootState, any>((state) => state.userAuth)

  const handleFiltrar = () => {
    const activitiesLogsAccordion = document.getElementById('activitiesLogs')
      ?.children[0] as HTMLButtonElement
    const inputDateFrom = document.getElementById('dateTo') as HTMLInputElement
    const inputDateTo = document.getElementById('dateFrom') as HTMLInputElement
    activitiesLogsAccordion.click()
    setFilter(!filter)
    if (filter) {
      setDateFromFilter('')
      setDateToFilter('')
      inputDateFrom.value = ''
      inputDateTo.value = ''
    }
  }
  const intl = useIntl()
  return (
    <div
      className="container mg-t-30 container table-bordered "
      style={{ display: 'block' }}
    >
      <div className="cdp_upload__title-container">
        <div className="rule-config__title-icon">
          <BsPencilSquare />
        </div>
        <h1 className="rule-config__title-text">
          <FormattedMessage
            id="app.submenu.logs"
            defaultMessage="Log de Activiades"
          />
        </h1>
      </div>
      <div className="container mg-b-15">
        <p>
          {intl.formatMessage(
            { id: 'app.tooltips.header.p.logs' },
            { defaultMessage: 'Logs' }
          )}
        </p>
      </div>

      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FormattedMessage
              id="app.submenu.logs-export"
              defaultMessage="Exportar Logs"
            />
            <TooltipPanel 
                ObjectTooltip={user.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.form.acordion.export.title.tooltip')
                )[0]}
              />
          </Accordion.Header>
          <Accordion.Body>
            <div className="rule-config__wrapper sombra_caja">
              <div className="rule-config__title-container row">
                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <ExportLogsForm
                    dateFromFilter={dateFromFilter}
                    dateToFilter={dateToFilter}
                    setDateFromFilter={setDateFromFilter}
                    setDateToFilter={setDateToFilter}
                    filter={filter}
                    handleFiltrar={handleFiltrar}
                  />
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header id="activitiesLogs">
            <FormattedMessage
              id="app.submenu.logs"
              defaultMessage="Logs de Actividades"
            />
            <TooltipPanel 
                ObjectTooltip={user.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.grid.logs.views.acordion.title.tooltip')
                )[0]}
              />
          </Accordion.Header>
          <Accordion.Body>
            <div className="rule-config__wrapper sombra_caja">
              <div className="rule-config__title-container row">
                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <ExportLogsTable
                    dateFromFilter={dateFromFilter}
                    dateToFilter={dateToFilter}
                    filter={filter}
                  />
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default ExportLogsContainer
