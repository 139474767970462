/* eslint-disable no-unneeded-ternary */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ClientSelector from './ClientSelector'
import HashSelector from './HashSelector'
import PlatformSelector from './PlatformSelector'
import User from './User'
import LanguageSelector from './LanguageSelector'
import { RootState } from '../../state/store'
import { logout } from '../../state/actions'
import { useClientContext } from '../../contexts/ClientContext'

const ContainerRow = () => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { mode, changeModeAlertToken } = useClientContext()

  const dispatch = useDispatch()

  useEffect(() => {
    const initDate = new Date()
    const expiredDate = new Date(
      initDate.getFullYear(),
      initDate.getMonth(),
      user.data.last_login.date,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      user.data.last_login.hour + 4,
      50
    )
    if (new Date() >= expiredDate) {
      dispatch(logout(user.data.token))
    }
  }, [user])

  return (
    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
      <div className="row justify-content-end align-items-center head_height">
        {user.data.Platform !== 'MercadoShop' && (
          <>
            <PlatformSelector />
            <ClientSelector />
            <HashSelector />
          </>
        )}
        {user.data.userType == 1 ? (
          <label className="switch_mode col-1 col-lg-1 col-md-1 col-sm-1 col-xs-1">
            <input
              className=""
              type="checkbox"
              id="switch-modes"
              onChange={() => {
                changeModeAlertToken()
              }}
              checked={mode}
            />
            <span
              className={
                mode
                  ? 'slider-mode round slider-bckg-on'
                  : 'slider-mode round slider-bckg-off'
              }
            >
              <small className="switch_m">{mode ? 'ON' : 'OFF'}</small>
            </span>
          </label>
        ) : (
          ''
        )}
        <LanguageSelector />
        <User />
      </div>
    </div>
  )
}

export default ContainerRow
