/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import Service from '../../../../services'
import { useIntl } from 'react-intl'

import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import { TooltipPanel } from '../../../Globals/TooltipPanel'
import { useClientContext } from '../../../../contexts/ClientContext'

const matchgroups = [
  {
    id: 0,
    name: 'MatchGroup 1',
    endpoint: '<%matchgroup_1%>'
  },
  {
    id: 1,
    name: 'MatchGroup 2',
    endpoint: '<%matchgroup_2%>'
  },
  {
    id: 2,
    name: 'MatchGroup 3',
    endpoint: '<%matchgroup_3%>'
  },
  {
    id: 3,
    name: 'MatchGroup 4',
    endpoint: '<%matchgroup_4%>'
  },
  {
    id: 4,
    name: 'Prefix CrossCategory',
    endpoint: 'crosscategoryproduct_'
  },
  {
    id: 5,
    name: 'Prefix CrossCheckOut',
    endpoint: 'crosscategorycheckout_'
  }
]

const IdCategory: React.FC<{ values: any; param: any;  }> = ({
  values,
  param
}) => {
  const valueParams = values
    .map((item: any) => {
      if (item[0] == param) {
        return item
      }
    })
    .filter((x: any) => x !== undefined)[0]
  // const [option, setOption] = useState<any>([])
  const [categoriesArrayObj, setCategoriesArrayObj] = useState<any>([])
  const [loadingCategories, setLoadingCategories] = useState(false)

  const categoriesArray: Array<{
    value: any
    label: any
    disabled: any
  }> = []

  const user = useSelector<RootState, any>((state) => state.userAuth)

  const [categoryValue, setCategoryValue] = useState<any>(
    valueParams == undefined ? '<%IDCATEGORY%>' : valueParams[1]
  )
  const [crossCategory, setCrossCategory] = useState<string>('')
  const handleChange = (
    event: any // React.ChangeEvent<HTMLSelectElement>
    ) => {
    // setCategoryValue(event.target.value)
    setCategoryValue(event.value)
    setCrossCategory('')
  }
  const inputOnChangeCategory = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCategoryValue(event.target.value)
    setCrossCategory('')
  }
  const handleButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const button: HTMLButtonElement = event.currentTarget
    if (button.name == 'crosscategoryproduct_') {
      setCrossCategory('crosscategoryproduct_')
      setCategoryValue('')
    } else if (button.name == 'crosscategorycheckout_') {
      setCrossCategory('crosscategorycheckout_')
      setCategoryValue('')
    } else {
      setCategoryValue(button.name)
    }
  }
  const { selectedScript } = useClientContext()

  useEffect(() => {
    setLoadingCategories(true)
    Service.get<any>(
      `/ConfigurationRule/GetAllCategoriesVtex?idHashScript=${selectedScript?.idHashScript}`,
      { headers: { token: user.data.token } }
    ).then((response) => {
      // setOption(response.data)
      categoriesArray.push({
          value: '<%IDCATEGORY%>',
          label: intl.formatMessage(
            {id: 'app.related-categories.add-cross-selling.select-category'},
            { defaultMessage: 'Seleccionar Categoria' }
          ),
          disabled: false
        })
      response.data.forEach((item: any, index: any) =>
          categoriesArray.push({
              value: item.id,
              label: item.categoryPathString,
              disabled: false
            })
          )
          setCategoriesArrayObj(categoriesArray)
          setLoadingCategories(false)
    })
  }, [selectedScript])
  const intl = useIntl()
  return (
    <div className="rule-config-subComponents__container">
      <div className="row a-center">
        <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
          Categoría [{param}]
          <TooltipPanel 
            ObjectTooltip={user.data.tooltips.filter((
              (t:any) => t.idTooltip === 'app.related.categories.add-cross-selling.select.category.tooltip')
            )[0]}
          />
        </h6>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
              { /*
              <select
                id="obCategorySelector"
                className={'select-categos'}
                onChange={handleChange}
              >
                <option value={'<%IDCATEGORY%>'}>Seleccione</option>
                {option.map((endpoint: any) => {
                  return (
                    <option
                      value={endpoint.id}
                      key={endpoint.id}
                      selected={
                        !!(
                          valueParams !== undefined &&
                          valueParams[1] == endpoint.id
                        )
                      }
                    >
                      {endpoint.categoryPathString}
                    </option>
                  )
                })}
              </select> */}

              { categoriesArrayObj.length > 0 
                ? (
                  <Select            
                    className="basic-single select2-categos col-lg-12 col-md-12 col-sm-12 col-xs-12 "
                    classNamePrefix="select"
                    defaultValue={valueParams !== undefined ? categoriesArrayObj[valueParams[1]] : categoriesArrayObj[0]}
                    isDisabled={false}
                    isLoading={loadingCategories}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="categoriasSlt"
                    id="categoriasSlt"
                    
                    options={categoriesArrayObj}
                    placeholder={intl.formatMessage(
                      {id: 'app.related-categories.add-cross-selling.select-category'},
                      { defaultMessage: 'Seleccionar Categoria' }
                    )} 
                    onChange={handleChange}            
                  />
                  ) 
                : <Spinner animation="border" size="sm" />                   

              }
              

            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <input
                className="vtexskuconsult__input"
                id={param}
                type="text"
                defaultValue={valueParams !== undefined ? valueParams[1] : ''}
                value={`${crossCategory}` + `${categoryValue}`}
                onChange={inputOnChangeCategory}
              />                            
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 match-group-1">
              {matchgroups.map((option) => {
                return (
                  <button
                    onClick={handleButton}
                    name={option.endpoint}
                    value={option.name}
                    style={{ padding: 'initial' }}
                    className="shelves__title-icon"
                    key={option.id}
                  >
                    {option.name}
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdCategory
