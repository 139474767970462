/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import './Campain.css'
import zoomPlugin from 'chartjs-plugin-zoom'
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Plugin,
  ChartData
} from 'chart.js'
import Chart from 'chart.js/auto'
import { Bar, Line, Doughnut } from 'react-chartjs-2'
import { es } from 'date-fns/locale'
import { format, subDays } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import './custom-datepicker.css'
import Select, { components } from 'react-select'
import { Loading } from '../Globals/Loading'
import {
  getDoughnutDataSex,
  getDoughnutDataAge,
  getDoughnutDataDevice,
  optionsDoughnut,
  titleGraphicOptions,
  revenueOptions
} from './AnalyticsCharOptions'
import Service from '../../services'
import useOutsideClick from './UseOutsideClick'
import { useSelector } from 'react-redux'
import { RootState } from '../../state/store'
import DataTable from 'react-data-table-component'
import { useClientContext } from '../../contexts/ClientContext'
import { TooltipPanel } from '../Globals/TooltipPanel'

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
)

interface OptionType {
  value: string
  label: string
}

interface Metric {
  id: number
  month: string
  date: string
  delivery: number
  opens: number
  clics: number
  bounced: number
}

interface MetricRevenue {
  id: number
  month: string
  revenue: number
  transactions: number
  year: number
  date: string
}

const textAlignPlugin: Plugin = {
  id: 'textAlignPlugin',
  afterDraw: (chart) => {
    const ctx = chart.ctx
    ctx.save()
    if (
      chart.canvas.id === 'horizontalBarChart1' ||
      chart.canvas.id === 'horizontalBarChart2'
    ) {
      const yAxis = chart.scales.y
      if (yAxis) {
        yAxis.ticks.forEach((value: any, index: any) => {
          const y = yAxis.getPixelForTick(index)
          ctx.textAlign = 'left'
          ctx.font = '14px Poppins'
          ctx.fillStyle = '#666666'
          ctx.fillText(value.label, yAxis.left - 150, y + 5)
        })
      }
    }
    ctx.restore()
  }
}

const hoverPlugin = {
  id: 'hoverPlugin',
  afterEvent: (chart: any, event: any) => {
    const yAxis = chart.scales.y
    const ctx = chart.ctx
    const labels = chart.data.labels
    const purchase = chart.config.data.purchase

    if (event.type === 'mousemove' && yAxis) {
      const mouseX = event.x
      const mouseY = event.y
      const yAxisBounds = yAxis.left - 150

      let hoveredIndex = -1

      yAxis.ticks.forEach((value: any, index: any) => {
        const y = yAxis.getPixelForTick(index)
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        if (
          mouseX < yAxisBounds + ctx.measureText(value.label).width &&
          mouseX > yAxisBounds &&
          mouseY > y - 10 &&
          mouseY < y + 10
        ) {
          hoveredIndex = index
        }
      })

      if (hoveredIndex !== -1) {
        const fullLabel = purchase.salesRankingByProduct[hoveredIndex].name
        labels[hoveredIndex] = fullLabel
      } else {
        purchase.salesRankingByProduct.forEach((metric: any, index: any) => {
          labels[index] =
            metric.name.length > 15
              ? `${metric.name.substring(0, 15)}...`
              : metric.name
        })
      }
      chart.update('none')
    }
  }
}

interface FileData {
  name: string
  file: File | null
}

const Analytics = () => {
  const initialEndDate = subDays(new Date(), 1)
  const initialDate: Date = subDays(initialEndDate, 7)
  const today = new Date()

  const calendarRef = useRef(null)

  useOutsideClick(calendarRef, () => setCalendarVisible(false))

  const [, setLoading] = useState<boolean>(false)
  const chartRef = useRef(null)
  const [campaigns, setCampaigns] = useState([])
  const [campaignsInfo, setCampaignsInfo] = useState<any>('')
  const [loadingAudiences, setLoadingAudiences] = useState<any>('')
  const [audiences, setAudiences] = useState<any>('')
  const [titleGraphic, setTitleGraphic] = useState<Metric[]>([])
  const [purchase, setPurchase] = useState<any>([])
  const [revenue, setRevenue] = useState<MetricRevenue[]>([])
  const [suscribeTotal, setSuscribeTotal] = useState<any>('')
  const [unsubscribeTotal, setUnsubscribeTotal] = useState<any>()
  const [labelsTituloGrafico, setLabelsTituloGrafico] = useState<string[]>([])
  const [dataSetsTituloGrafico, setDataSetsTituloGrafico] = useState<
    ChartData<'bar'>['datasets']
  >([])
  const [labelsGraficoIngreso, setLabelsGraficoIngreso] = useState<string[]>([])
  const [dataSetsGraficoIngreso, setDataSetsGraficoIngreso] = useState<
    ChartData<'line'>['datasets']
  >([])
  const [labelsGraficoTransacciones, setLabelsGraficoTransacciones] = useState<
    string[]
  >([])
  const [dataSetsGraficoTransacciones, setDataSetsGraficoTransacciones] =
    useState<ChartData<'line'>['datasets']>([])
  const [dataSetsRankingProductos, setDataSetsRankingProductos] = useState<
    ChartData<'bar'>
  >({
    labels: [],
    datasets: []
  })
  const [rankProductsOptions, setRankProductsOptions] = useState({})
  const [rankCategoryOptions, setRankCategoryOptions] = useState({})
  const [dataSetsRankingCategoria, setDataSetsRankingCategoria] = useState<
    ChartData<'bar'>
  >({
    labels: [],
    datasets: []
  })

  const [dataSetsDoughRegion, setDataSetsDoughRegion] = useState<
    ChartData<'doughnut'>
  >({
    labels: [],
    datasets: []
  })
  const [selectedOptions, setSelectedOptions] = useState<OptionType>({
    label: '',
    value: ''
  })
  const dataDoughnutSex = getDoughnutDataSex(purchase)
  const dataDoughnutAge = getDoughnutDataAge(purchase)
  const dataDoughnutDevice = getDoughnutDataDevice(purchase)
  const [startMonth, setStartMonth] = useState(today)
  const [endMonth, setEndMonth] = useState(today)
  const [isCalendarVisible, setCalendarVisible] = useState(false)
  const [startDate, setStartDate] = useState(initialDate)
  const [endDate, setEndDate] = useState(initialEndDate)
  const [savedDates, setSavedDates] = useState<any>('')
  const [fileData] = useState<FileData>({ name: '', file: null })
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()
  const handleStartDateSelect = (date: any) => {
    if (date == undefined) {
      return
    }
    setStartDate(date)
  }

  const handleEndDateSelect = (date: any) => {
    if (date == undefined) {
      return
    }
    setEndDate(date)
  }

  const handleInputClick = () => {
    setCalendarVisible(!isCalendarVisible)
  }

  const handleStartMonthChange = (newMonth: any) => {
    setStartMonth(newMonth)
  }

  const handleEndMonthChange = (newMonth: any) => {
    setEndMonth(newMonth)
  }

  const handleClear = () => {
    const initialStartDate = new Date()
    setStartDate(initialStartDate)
    setEndDate(initialDate)
  }

  const formatRange = (startDate: any, endDate: any) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return `${format(startDate, 'dd MMM yyyy', { locale: es })} - ${format(
      endDate ? endDate : new Date(),
      'dd MMM yyyy',
      { locale: es }
    )}`
  }

  const handleSave = () => {
    if (startDate != null && endDate !== null) {
      setStartDate(startDate)
      setEndDate(endDate)
      setSavedDates(formatRange(startDate, endDate))
      handleInputClick()
    }
  }

  const years = []
  for (let i = 2020; i <= 2030; i++) {
    years.push(i)
  }

  const formatDate = (dateString: any) => {
    const date = new Date(dateString)
    return date.toISOString()
  }

  const handleChange = (selected: any) => {
    setSelectedOptions(selected)
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setRowsPerPage(Number(event.target.value))
  }

  useEffect(() => {
    ChartJS.register(textAlignPlugin)

    return () => {
      ChartJS.unregister(textAlignPlugin)
    }
  }, [])

  const CheckboxOption = (props: any) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    )
  }

  const columns = [
    { name: 'Audiencia', selector: (row: any) => row.Name, sortable: true },
    {
      name: 'Delivery',
      selector: (row: any) => row.Delivery?.valueFormat,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const parseDelivery = (delivery: any) => {
          if (!delivery) return 0

          const cleanedValue = delivery.replace(/\./g, '').replace(',', '.')

          return parseFloat(cleanedValue)
        }

        const a = parseDelivery(rowA.Delivery?.valueFormat)
        const b = parseDelivery(rowB.Delivery?.valueFormat)

        return a - b
      }
    },
    {
      name: 'Open',
      selector: (row: any) => row.Opens.valueFormat,
      sortable: true
    },
    {
      name: 'Clicks',
      selector: (row: any) => row.Clics.valueFormat,
      sortable: true
    },
    { name: 'OR', selector: (row: any) => row.OR.valueFormat, sortable: true },
    {
      name: 'CTOR',
      selector: (row: any) => row.CTOR.valueFormat,
      sortable: true
    },
    {
      name: 'Sesiones',
      selector: (row: any) => row.Sessions?.valueFormat,
      sortable: true
    },
    {
      name: 'Tasa de Conversion',
      selector: (row: any) => row.ConversionRate?.valueFormat,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const parseIngreso = (ingreso: any) => {
          if (!ingreso) return 0

          const cleanedValue = ingreso.replace(/\./g, '').replace(',', '.')
          return parseFloat(cleanedValue)
        }

        const parseClicks = (clicks: any) => {
          if (!clicks) return 0
          return parseFloat(clicks.replace(',', '.'))
        }

        const aIngreso = parseIngreso(rowA.ConversionRate?.valueFormat)
        const bIngreso = parseIngreso(rowB.ConversionRate?.valueFormat)

        if (aIngreso === bIngreso) {
          const aClicks = parseClicks(rowA.Clics?.valueFormat)
          const bClicks = parseClicks(rowB.Clics?.valueFormat)
          return bClicks - aClicks
        }

        return bIngreso - aIngreso
      }
    },
    {
      name: 'Transacciones',
      selector: (row: any) => row.Transactions,
      sortable: true
    },
    {
      name: 'Total de ingresos',
      id: 'totalRevenues',
      selector: (row: any) => row.TotalRevenues?.valueFormat,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const parseIngreso = (ingreso: any) => {
          if (!ingreso) return 0

          if (ingreso.includes('millones')) {
            return (
              parseFloat(ingreso.replace(' millones', '').replace(',', '.')) *
              1000000
            )
          }
          if (ingreso.includes('mil')) {
            return (
              parseFloat(ingreso.replace(' mil', '').replace(',', '.')) * 1000
            )
          }
          return parseFloat(ingreso.replace(',', '.'))
        }

        const a = parseIngreso(rowA.TotalRevenues?.valueFormat)
        const b = parseIngreso(rowB.TotalRevenues?.valueFormat)

        return a - b
      }
    }
  ]

  const customStyles = {
    header: {
      style: {
        fontSize: '14px',
        color: '#000',
        backgroundColor: 'transparent',
        minHeight: '56px',
        paddingLeft: '16px',
        paddingRight: '8px'
      }
    },
    subHeader: {
      style: {
        justifyContent: 'space-between',
        marginBottom: '1rem',
        padding: '0 1rem !important'
      }
    },
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px !important', // override the cell padding for head cells
        paddingRight: '20px !important'
      },
      draggingStyle: {
        cursor: 'move'
      }
    },
    cells: {
      style: {
        margingLeft: '10px', // override the cell padding for data cells
        margingRight: '10px',
        wordBreak: undefined,
        overflow: 'wrap'
      },
      draggingStyle: {},
      class: 'text-center'
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      '&:nth-of-type(n)': {
        color: '#fff',
        backgroundColor: '#666',
        borderBottomColor: '#ccc'
      }
    },
    highlightOnHoverStyle: {
      color: '#dfdfdf',
      backgroundColor: '#eee',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: '#000',
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: '#efefef'
    },
    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    pagination: {
      style: {
        color: '#000',
        fontSize: '14px',
        minHeight: '56px',
        backgroundColor: 'transparent',
        // borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#ddd'
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '5px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#fff',
        // fill: theme.button.default,
        backgroundColor: '#e9ecef',
        '&:disabled': {
          cursor: 'unset',
          color: '#2fad7e'
          // fill: theme.button.disabled,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: '#2fad7e',
          color: '#fff'
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#fff'
        }
      }
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: 'MOSTRANDO',
    rangeSeparatorText: 'DE'
  }

  const getAllCampaigns = async () => {
    await Service.get<any>(
      `/Automation/GetAllCampaigns?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response: any) => {
        const campaignOptions = response.data?.Entities.map((entity: any) => ({
          label: entity.Name,
          value: entity.Name
        }))
        setCampaigns(campaignOptions)
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  const getCampaignInfo = async () => {
    const dateStart = new Date(startDate)
    const dateEnd = new Date(endDate)
    await Service.get<any>(
      `/AWS/GetMetricData?idHashScript=${
        selectedScript?.idHashScript
      }&nameCampaign=${
        selectedOptions == null ? '' : selectedOptions.value
      }&dateFrom=${dateStart.toISOString()}&dateTo=${formatDate(
        dateEnd.toISOString()
      )}`
    )
      .then((response: any) => {
        setCampaignsInfo(response.data.data)
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }
  useEffect(() => {
    ChartJS.register(textAlignPlugin)
    ChartJS.register(hoverPlugin)

    return () => {
      ChartJS.unregister(textAlignPlugin)
      ChartJS.unregister(hoverPlugin)
    }
  }, [])

  useEffect(() => {
    const canvas: any = chartRef.current

    const handleMouseMove = (event: any) => {
      const chart: any = Chart.getChart(canvas)
      const ctx = chart.ctx
      const rect = canvas.getBoundingClientRect()
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      const yAxis = chart.scales.y
      const labels = chart.data.labels
      const purchase = chart.config.data.purchase

      let hoveredIndex = -1
      yAxis.ticks.forEach((value: any, index: any) => {
        const labelY = yAxis.getPixelForTick(index)
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        if (
          x < yAxis.left + ctx.measureText(value.label).width &&
          x > yAxis.left &&
          y > labelY - 10 &&
          y < labelY + 10
        ) {
          hoveredIndex = index
        }
      })

      if (hoveredIndex !== -1) {
        const fullLabel = purchase.salesRankingByProduct[hoveredIndex].name
        labels[hoveredIndex] = fullLabel
      } else {
        purchase.salesRankingByProduct.forEach((metric: any, index: any) => {
          labels[index] =
            metric.name.length > 15
              ? `${metric.name.substring(0, 15)}...`
              : metric.name
        })
      }
      chart.update('none')
    }

    if (canvas) {
      canvas.addEventListener('mousemove', handleMouseMove)
      return () => {
        canvas.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [dataSetsRankingProductos])

  const getAudiences = async () => {
    setLoadingAudiences(true)
    const dateStart = new Date(startDate)
    const dateEnd = new Date(endDate)
    await Service.get<any>(
      `/AWS/GetAudiences?idHashScript=${
        selectedScript?.idHashScript
      }&nameCampaign=${
        selectedOptions == null ? '' : selectedOptions.value
      }&dateFrom=${dateStart.toISOString()}&dateTo=${formatDate(
        dateEnd.toISOString()
      )}`
    )
      .then((response: any) => {
        const data = response.data.Audiences
        setAudiences(data)
        setLoadingAudiences(false)
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  const getMetricsGraphInfo = () => {
    Service.get<any>(
      `/AWS/GetMetricsGraphInfo?idHashScript=${
        selectedScript?.idHashScript
      }&nameCampaign=${
        selectedOptions == null ? '' : selectedOptions.value
      }&dateFrom=${formatDate(startDate)}&dateTo=${formatDate(endDate)}`
    )
      .then((response: any) => {
        const graphicData = response.data
        graphicData.data.sort((a: any, b: any) => {
          const dateA = new Date(a.date)
          const dateB = new Date(b.date)
          return dateA.getTime() - dateB.getTime()
        })
        setTitleGraphic(graphicData.data)
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  const getPurchaseData = () => {
    Service.get<any>(
      `/G4/GetPurchaseData?idHashScript=${
        selectedScript?.idHashScript
      }&nameCampaign=${
        selectedOptions == null ? '' : selectedOptions.value
      }&dateFrom=${formatDate(startDate)}&dateTo=${formatDate(endDate)}`
    )
      .then((response: any) => {
        setPurchase(response.data.data)
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  const getTotalSubscribe = () => {
    Service.post<any, any>(
      `/automation/GetTotalSubscribe?idHashScript=${selectedScript?.idHashScript}`,
      JSON.stringify({
        hash: selectedScript?.idHashScript,
        dateFrom: format(new Date(startDate), 'yyyy-MM-dd'),
        dateTo: format(new Date(endDate), 'yyyy-MM-dd'),
        total: 0
      }),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response: any) => {
        setSuscribeTotal(response.data.Entity?.Total.valueFormat)
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  const getTotalUnSubscribe = () => {
    Service.post<any, any>(
      `/automation/GetTotalUnSubscribe?idHashScript=${selectedScript?.idHashScript}`,
      JSON.stringify({
        hash: selectedScript?.idHashScript,
        dateFrom: format(new Date(startDate), 'yyyy-MM-dd'),
        dateTo: format(new Date(endDate), 'yyyy-MM-dd'),
        total: 0
      }),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response: any) => {
        setUnsubscribeTotal(response.data.Entity?.Total.value)
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  const getRevenueGraphInfo = () => {
    Service.get<any>(
      `/g4/GetRevenueGraphInfo?idHashScript=${
        selectedScript?.idHashScript
      }&dateFrom=${formatDate(startDate)}&dateTo=${formatDate(endDate)}`
    )
      .then((response: any) => {
        setRevenue(response.data.data.revenueGraphInfo)
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  const getAudiencesCSV = () => {
    const formData = new FormData()
    formData.append('File', fileData.file as Blob)
    Service.post<any, any>(
      `/AWS/ExportAudiences?idHashScript=${
        selectedScript?.idHashScript
      }&dateFrom=${formatDate(startDate)}&dateTo=${formatDate(endDate)}`,
      formData,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response: any) => {
        console.log('ResponseList', response.data)

        const blob = new Blob([response.data], { type: 'application/pdf' })

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'analytics.csv')
        document.body.appendChild(link)
        link.click()
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  useEffect(() => {
    setLoading(true)
    setCampaigns([])
    setCampaignsInfo('')
    setTitleGraphic([])
    setPurchase([])
    setRevenue([])
    const loadData = async () => {
      try {
        await Promise.all([
          getTotalSubscribe(),
          getTotalUnSubscribe(),
          getAllCampaigns(),
          getCampaignInfo(),
          getAudiences(),
          getMetricsGraphInfo(),
          getPurchaseData(),
          getRevenueGraphInfo()
        ])
      } catch (error) {
        console.error('Error loading data:', error)
      } finally {
        setLoading(false)
        setCalendarVisible(false)
      }
    }

    loadData()
  }, [savedDates, selectedOptions, selectedOptions == null])

  useEffect(() => {
    if (titleGraphic?.length > 0) {
      setLabelsTituloGrafico(titleGraphic.map((metric: any) => metric.date))
      setDataSetsTituloGrafico([
        {
          label: 'Delivery',
          data: titleGraphic.map((metric: any) => metric.delivery),
          backgroundColor: 'rgba(0, 134, 139, 1)',
          borderRadius: 50,
          barThickness: 25,
          borderSkipped: false
        },
        {
          label: 'Opens',
          data: titleGraphic.map((metric: any) => metric.opens),
          backgroundColor: 'rgba(76, 31, 121, 1)',
          borderRadius: 50,
          barThickness: 25,
          borderSkipped: false
        },
        {
          label: 'Clics',
          data: titleGraphic.map((metric: any) => metric.clics),
          backgroundColor: 'rgba(51, 194, 199, 1)',
          borderRadius: 50,
          barThickness: 25,
          borderSkipped: false
        }
      ])
    }

    if (revenue?.length > 0) {
      setLabelsGraficoIngreso(revenue.map((metric: any) => metric.date))
      setLabelsGraficoTransacciones(revenue.map((metric: any) => metric.date))
      setDataSetsGraficoIngreso([
        {
          label: 'Ingresos',
          data: revenue.map((metric: any) => metric.revenue),
          borderColor: 'rgba(0, 134, 139, 1)',
          backgroundColor: 'rgba(0, 134, 139, 1)'
        }
      ])
      setDataSetsGraficoTransacciones([
        {
          label: 'Transacciones',
          data: revenue.map((metric: any) => metric.transactions),
          borderColor: 'rgba(76, 31, 121, 1)',
          backgroundColor: 'rgba(76, 31, 121, 1)'
        }
      ])
    }

    if (purchase?.salesRankingByProduct?.length > 0) {
      const labels = purchase.salesRankingByProduct.map((metric: any) => {
        return metric.name.length > 15
          ? `${metric.name.substring(0, 15)}...`
          : metric.name
      })
      const data = purchase.salesRankingByProduct.map(
        (metric: any) => metric.scope
      )

      setDataSetsRankingProductos({
        labels,
        datasets: [
          {
            data: data,
            backgroundColor: 'rgba(76, 31, 121, 1)',
            borderRadius: 50,
            barThickness: 25,
            borderSkipped: false
          }
        ]
      })
      setRankProductsOptions({
        responsive: true,
        indexAxis: 'y',
        layout: {
          padding: {
            left: 150
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              align: 'start'
            },
            grid: {
              lineWidth: 0
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: true,
              drawTicks: false,
              lineWidth: (context: any) => (context.index === 0 ? 0 : 1),
              color: (context: any) =>
                context.index !== undefined
                  ? 'rgba(0, 0, 0, 0.1)'
                  : 'rgba(0, 0, 0, 0)'
            },
            ticks: {
              display: false,
              padding: 40,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0
            }
          }
        },
        elements: {
          bar: {
            borderWidth: 2
          }
        },
        plugins: {
          legend: {
            display: false
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'y'
            },
            zoom: {
              pinch: {
                enabled: true
              },
              wheel: {
                enabled: true,
                speed: 0.5
              },
              mode: 'y'
            }
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const index = context.dataIndex
                const fullLabel = purchase.salesRankingByProduct[index].name
                const value = context.raw
                return `${fullLabel}: ${value}`
              },
              title: function () {
                return ''
              }
            }
          }
        }
      })
    }

    if (purchase?.salesRankingByCategory?.length > 0) {
      const labels = purchase.salesRankingByCategory.map((metric: any) => {
        return metric.name.length > 15
          ? `${metric.name.substring(0, 15)}...`
          : metric.name
      })
      const data = purchase.salesRankingByCategory.map(
        (metric: any) => metric.scope
      )

      setDataSetsRankingCategoria({
        labels,
        datasets: [
          {
            label: 'Sales Ranking by Category',
            data: data,
            backgroundColor: 'rgba(76, 31, 121, 1)',
            borderRadius: 50,
            barThickness: 25,
            borderSkipped: false
          }
        ]
      })

      setRankCategoryOptions({
        responsive: true,
        indexAxis: 'y',
        layout: {
          padding: {
            left: 150
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              align: 'start'
            },
            grid: {
              lineWidth: 0
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: true,
              drawTicks: false,
              lineWidth: (context: any) => (context.index === 0 ? 0 : 1),
              color: (context: any) =>
                context.index !== undefined
                  ? 'rgba(0, 0, 0, 0.1)'
                  : 'rgba(0, 0, 0, 0)'
            },
            ticks: {
              display: false,
              padding: 40,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0
            }
          }
        },
        elements: {
          bar: {
            borderWidth: 2
          }
        },
        plugins: {
          legend: {
            display: false
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'y'
            },
            zoom: {
              pinch: {
                enabled: true
              },
              wheel: {
                enabled: true,
                speed: 0.5
              },
              mode: 'y'
            }
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const index = context.dataIndex
                const fullLabel = purchase.salesRankingByCategory[index].name
                const value = context.raw
                return `${fullLabel}: ${value}`
              },
              title: function () {
                return ''
              }
            }
          }
        }
      })
    }

    if (purchase?.customerSummary?.region.length > 0) {
      const labels = purchase.customerSummary.region.map(
        (metric: any) => metric.name
      )
      const data = purchase.customerSummary.region.map(
        (metric: any) => metric.scope
      )
      setDataSetsDoughRegion({
        labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              'rgba(76, 31, 121, 1)',
              'rgba(51, 194, 199, 1)',
              'rgba(0, 134, 139, 1)',
              'rgba(76, 31, 121, 0.2)',
              'rgba(51, 194, 199, 0.2)',
              'rgba(0, 134, 139, 0.2)'
            ],
            borderColor: [
              'rgba(76, 31, 121, 1)',
              'rgba(51, 194, 199, 1)',
              'rgba(0, 134, 139, 1)',
              'rgba(76, 31, 121, 1)',
              'rgba(51, 194, 199, 1)',
              'rgba(0, 134, 139, 1)'
            ],
            borderWidth: 2
          }
        ]
      })
    }
  }, [titleGraphic, revenue, purchase, savedDates, selectedOptions])

  return (
    <>
      <div className="justify-content-evenly align-items-center mt-3 mx-5">
        <div
          className="justify-content-center mt-3  row"
          style={{ padding: '30px' }}
        >
          <div className="col-md-12">
            <div className="d-flex" style={{ float: 'right' }}>
              {isCalendarVisible && (
                <div ref={calendarRef}>
                  <div className="calendar-container-start">
                    <DayPicker
                      mode="single"
                      selected={startDate}
                      onSelect={handleStartDateSelect}
                      month={startMonth}
                      onMonthChange={handleStartMonthChange}
                      locale={es}
                      captionLayout="dropdown"
                      fromMonth={new Date(2020, 0)}
                      toMonth={new Date()}
                      disabled={{ after: endDate }}
                      modifiersClassNames={{
                        selected: 'my-selected',
                        range_start: 'my-selected',
                        range_end: 'my-selected',
                        in_range: 'my-selected'
                      }}
                      className="custom-day-picker"
                    />
                  </div>
                  <div className="calendar-container-end">
                    <DayPicker
                      mode="single"
                      selected={endDate}
                      onSelect={handleEndDateSelect}
                      month={endMonth}
                      onMonthChange={handleEndMonthChange}
                      locale={es}
                      captionLayout="dropdown"
                      fromMonth={new Date(2020, 0)}
                      toMonth={new Date()}
                      disabled={{ before: startDate, after: initialEndDate }}
                      modifiersClassNames={{
                        selected: 'my-selected',
                        range_start: 'my-selected',
                        range_end: 'my-selected',
                        in_range: 'my-selected'
                      }}
                      className="custom-day-picker"
                      footer={
                        <div className="custom-actions">
                          <button className="clear-btn" onClick={handleClear}>
                            BORRAR
                          </button>
                          <button className="save-btn" onClick={handleSave}>
                            GUARDAR
                          </button>
                        </div>
                      }
                    />
                  </div>
                </div>
              )}
              <div className="mr-5" style={{ position: 'relative' }}>
                <input
                  value={formatRange(startDate, endDate)}
                  readOnly
                  onClick={handleInputClick}
                  style={{
                    width: '18rem',
                    padding: '8px 36px 8px 8px',
                    boxSizing: 'border-box',
                    borderRadius: '8px',
                    border: '1px solid #ddd'
                  }}
                />
                <img
                  className="calendar-icon"
                  src={require('../../img/calendar.png')}
                  onClick={handleInputClick}
                  alt="Calendar icon"
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}
                />
              </div>
              <div style={{ width: '262px' }}>
                <Select
                  isClearable
                  closeMenuOnSelect={false}
                  components={{ Option: CheckboxOption }}
                  options={campaigns}
                  onChange={handleChange}
                  value={
                    selectedOptions == null || selectedOptions.value == ''
                      ? null
                      : selectedOptions
                  }
                  placeholder="Campañas"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <h1
              className="mt-3"
              style={{
                fontFamily: 'Poppins',
                fontSize: '25px',
                fontWeight: '600'
              }}
            >
              Resumen de campañas
            </h1>

            <div className="regla_frame justify-content-center mt-3">
              {campaignsInfo == '' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading textLoading="Cargando datos" />
                </div>
              ) : (
                <div
                  className="row text-center"
                  style={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    borderTop: '2px solid rgb(232, 232, 237)',
                    borderBottom: '2px solid rgb(232, 232, 237)',
                    fontFamily: 'Raleway'
                  }}
                >
                  <div
                    className="col-md-2"
                    style={{ borderRight: '2px solid #E8E8ED' }}
                  >
                    <div style={{ fontWeight: '700' }}>
                      {campaignsInfo.campaignSummary?.Delivery?.valueFormat}{' '}
                    </div>
                    <div>Delivery</div>
                  </div>
                  <div
                    className="col-md-2"
                    style={{ borderRight: '2px solid #E8E8ED' }}
                  >
                    <div style={{ fontWeight: '700' }}>
                      {campaignsInfo.campaignSummary?.Opens?.valueFormat}{' '}
                    </div>
                    <div>Opens</div>
                  </div>
                  <div
                    className="col-md-2"
                    style={{ borderRight: '2px solid #E8E8ED' }}
                  >
                    <div style={{ fontWeight: '700' }}>
                      {campaignsInfo.campaignSummary?.Clics?.valueFormat}{' '}
                    </div>
                    <div>Clics</div>
                  </div>
                  <div
                    className="col-md-2"
                    style={{ borderRight: '2px solid #E8E8ED' }}
                  >
                    <div style={{ fontWeight: '700' }}>
                      {campaignsInfo.campaignSummary?.OR?.valueFormat}
                    </div>
                    <div>OR</div>
                  </div>
                  <div
                    className="col-md-2"
                    style={{ borderRight: '2px solid #E8E8ED' }}
                  >
                    <div style={{ fontWeight: '700' }}>
                      {campaignsInfo.campaignSummary?.CTR?.valueFormat}
                    </div>
                    <div>CTR</div>
                  </div>
                  <div className="col-md-2">
                    <div style={{ fontWeight: '700' }}>
                      {campaignsInfo.campaignSummary?.CTOR?.valueFormat}
                    </div>
                    <div>CTOR</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="justify-content-center mt-3 row"
          style={{ padding: '30px' }}
        >
          <div className="col-md-12">
            <h1
              style={{
                fontFamily: 'Poppins',
                fontSize: '25px',
                fontWeight: '600'
              }}
            >
              Resumen de Ventas
            </h1>
            <div className="row justify-content-between">
              <div className="col-md-2 campaign-card">
                <div className="regla_frame justify-content-center mt-3 sales-card">
                  <div className="justify-content-between d-flex ">
                    <div className="paddingLeft14px">
                      <div className="sales-title">Total de Ingresos</div>
                    </div>
                    <div style={{ color: '#595BD4' }}>
                      <TooltipPanel
                        ObjectTooltip={
                          user.data.tooltips.filter(
                            (t: any) =>
                              t.idTooltip ===
                              'app.automation.totalrevenues.analytics.tooltip'
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="justify-content-center d-flex my-4">
                    <div className="sales-image">
                      <img
                        src={require('../../img/total_ingresos.png')}
                        style={{ width: '30px' }}
                      />
                    </div>
                    <div className="sales-value">
                      {purchase == null ? (
                        <span>Sin Resultados.</span>
                      ) : !purchase?.salesSummary ? (
                        <div className="em-loading">
                          <Loading textLoading="Cargando datos" />
                        </div>
                      ) : (
                        purchase?.salesSummary?.totalRevenues?.valueFormat
                      )}
                    </div>
                  </div>
                  <div className="border-sales" />
                  <div className="text-center pt-2 sales-sub-data">
                    <span
                      style={{
                        fontSize: '17px',
                        fontWeight: '700',
                        fontFamily: 'Raleway'
                      }}
                    >
                      {purchase?.salesSummary?.totalRevenues
                        ?.messageConversion == ''
                        ? '0,00%'
                        : purchase?.salesSummary?.totalRevenues
                            ?.messageConversion}
                    </span>
                    <br />
                    <div className="sales-subtitle">Conversión de mensaje</div>
                  </div>
                </div>
              </div>

              <div className="col-md-2 campaign-card">
                <div className="regla_frame justify-content-center mt-3 sales-card">
                  <div className="justify-content-between d-flex ">
                    <div className="paddingLeft14px">
                      <div className="sales-title">Transacciones</div>
                    </div>
                    <div style={{ color: '#595BD4' }}>
                      <TooltipPanel
                        ObjectTooltip={
                          user.data.tooltips.filter(
                            (t: any) =>
                              t.idTooltip ===
                              'app.automation.transactions.analytics.tooltip'
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="justify-content-center d-flex my-4">
                    <div className="sales-image">
                      <img
                        src={require('../../img/transecciones.png')}
                        style={{ width: '30px' }}
                      />
                    </div>
                    <div className="sales-value">
                      {purchase == null ? (
                        <span>Sin Resultados.</span>
                      ) : !purchase?.salesSummary ? (
                        <div className="em-loading">
                          <Loading textLoading="Cargando datos" />
                        </div>
                      ) : (
                        purchase?.salesSummary?.transactions?.valueFormat
                      )}
                    </div>
                  </div>
                  <div className="border-sales" />
                  <div className="text-center pt-2 sales-sub-data" />
                </div>
              </div>

              <div className="col-md-2 campaign-card">
                <div className="regla_frame justify-content-center mt-3 sales-card">
                  <div className="justify-content-between d-flex ">
                    <div className="paddingLeft11px">
                      <div className="sales-title">Unidades Vendidas</div>
                    </div>
                    <div style={{ color: '#595BD4' }}>
                      <TooltipPanel
                        ObjectTooltip={
                          user.data.tooltips.filter(
                            (t: any) =>
                              t.idTooltip ===
                              'app.automation.soldunits.analytics.tooltip'
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="justify-content-center d-flex my-4">
                    <div className="sales-image">
                      <img
                        src={require('../../img/unidades_vendidas.png')}
                        style={{ width: '30px' }}
                      />
                    </div>
                    <div className="sales-value">
                      {purchase == null ? (
                        <span>Sin Resultados.</span>
                      ) : !purchase?.salesSummary ? (
                        <div className="em-loading">
                          <Loading textLoading="Cargando datos" />
                        </div>
                      ) : (
                        purchase?.salesSummary?.soldUnits
                          ?.messageConversionFormat
                      )}
                    </div>
                  </div>
                  <div className="border-sales" />
                  <div className="text-center justify-content-center pt-2 sales-sub-data">
                    <span
                      style={{
                        fontSize: '17px',
                        fontWeight: '700',
                        fontFamily: 'Raleway'
                      }}
                    >
                      {purchase?.salesSummary?.soldUnits?.messageConversion ==
                      ''
                        ? '0,00%'
                        : purchase?.salesSummary?.soldUnits?.messageConversion}
                    </span>
                    <br />
                    <div className="sales-subtitle">Conversión de mensaje</div>
                  </div>
                </div>
              </div>

              <div className="col-md-2 campaign-card">
                <div className="regla_frame justify-content-center mt-3 sales-card">
                  <div className="justify-content-between d-flex ">
                    <div className="paddingLeft14px">
                      <div className="sales-title">Sesiones</div>
                    </div>
                    <div style={{ color: '#595BD4' }}>
                      <TooltipPanel
                        ObjectTooltip={
                          user.data.tooltips.filter(
                            (t: any) =>
                              t.idTooltip ===
                              'app.automation.sessions.analytics.tooltip'
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="justify-content-center d-flex my-4">
                    <div className="sales-image">
                      <img
                        src={require('../../img/alternate_sync.png')}
                        style={{ width: '30px' }}
                      />
                    </div>
                    <div className="sales-value">
                      {purchase == null ? (
                        <span>Sin Resultados.</span>
                      ) : !purchase?.salesSummary ? (
                        <div className="em-loading">
                          <Loading textLoading="Cargando datos" />
                        </div>
                      ) : (
                        purchase?.salesSummary?.sessions
                          ?.revenuePerMessageFormat
                      )}
                    </div>
                  </div>
                  <div className="border-sales" />
                  <div className="text-center pt-2 sales-sub-data" />
                </div>
              </div>

              <div className="col-md-2 campaign-card">
                <div className="regla_frame justify-content-center mt-3 sales-card">
                  <div className="justify-content-between d-flex ">
                    <div className="paddingLeft14px">
                      <div className="sales-title">Tasa de Conversión</div>
                    </div>
                    <div style={{ color: '#595BD4' }}>
                      <TooltipPanel
                        ObjectTooltip={
                          user.data.tooltips.filter(
                            (t: any) =>
                              t.idTooltip ===
                              'app.automation.conversionrate.analytics.tooltip'
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="justify-content-center d-flex my-4">
                    <div className="sales-image">
                      <img
                        src={require('../../img/alternate_sync.png')}
                        style={{ width: '30px' }}
                      />
                    </div>
                    <div className="sales-value">
                      {purchase == null ? (
                        <span>Sin Resultados.</span>
                      ) : !purchase?.salesSummary ? (
                        <div className="em-loading">
                          <Loading textLoading="Cargando datos" />
                        </div>
                      ) : (
                        purchase?.salesSummary?.conversionRate?.value
                      )}
                    </div>
                  </div>
                  <div className="border-sales" />
                  <div className="text-center justify-content-center pt-2 sales-sub-data">
                    <span
                      style={{
                        fontSize: '17px',
                        fontWeight: '700',
                        fontFamily: 'Raleway'
                      }}
                    >
                      {purchase?.salesSummary?.conversionRate
                        ?.messageConversion == ''
                        ? '0,00%'
                        : purchase?.salesSummary?.totalRevenues
                            ?.messageConversion}
                    </span>
                    <br />
                    <div className="sales-subtitle">Conversión de mensaje</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="justify-content-center mt-3 row"
          style={{ padding: '30px' }}
        >
          {!loadingAudiences && (
            <div className="automation-table-top-buttons mb-4">
              <div className="mb-3 d-flex justify-content-between">
                <p className="show-options__text d-flex">
                  <span style={{ fontSize: '15px' }}>MOSTRAR</span>
                  <select
                    className="show-options__selector select_paginas mx-2"
                    name="options-per-page"
                    id="options-per-page"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    style={{ color: '#8454B4' }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span style={{ fontSize: '15px' }}>RESULTADOS</span>
                </p>
              </div>
              <button
                type="button"
                onClick={getAudiencesCSV}
                className="analytics-button"
                style={{
                  width: '20rem',
                  color: '#00868B',
                  border: '2px solid'
                }}
              >
                DESCARGAR CSV
              </button>
            </div>
          )}
          <div className="col-md-12">
            <DataTable
              key={rowsPerPage}
              columns={columns}
              data={audiences}
              customStyles={customStyles}
              paginationComponentOptions={paginationComponentOptions}
              pagination
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
              progressPending={loadingAudiences}
              progressComponent={<Loading textLoading="Cargando datos" />}
              noDataComponent={'Sin Resultados.'}
              defaultSortFieldId="totalRevenues"
              defaultSortAsc={false}
              responsive
            />
          </div>
        </div>
        <div
          className="justify-content-center mt-3 "
          style={{ padding: '30px' }}
        >
          <div className="regla_frame">
            <div>
              {!titleGraphic.length ? (
                <span>Sin resultados.</span>
              ) : titleGraphic?.length == 0 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading textLoading="Cargando datos" />
                </div>
              ) : (
                <div className="mt-4" style={{ paddingLeft: '15px' }}>
                  <div style={{ height: '20rem' }}>
                    <Bar
                      id="verticalBarChart"
                      data={{
                        labels: labelsTituloGrafico,
                        datasets: dataSetsTituloGrafico
                      }}
                      options={titleGraphicOptions}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="justify-content-center mt-3"
          style={{ padding: '30px' }}
        >
          <div className="regla_frame">
            <div>
              <div className="analytics-title-cards">Gráficos de Ingresos</div>
              {!revenue.length ? (
                <span>Sin resultados.</span>
              ) : revenue?.length == 0 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading textLoading="Cargando datos" />
                </div>
              ) : (
                <div className="mt-4" style={{ paddingLeft: '15px' }}>
                  <div style={{ height: '20rem' }}>
                    <Line
                      options={revenueOptions}
                      data={{
                        labels: labelsGraficoIngreso,
                        datasets: dataSetsGraficoIngreso
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="justify-content-center mt-3"
          style={{ padding: '30px' }}
        >
          <div className="regla_frame">
            <div>
              <div className="analytics-title-cards">
                Gráficos de Transacciones
              </div>
              {!revenue.length ? (
                <span>Sin resultados.</span>
              ) : revenue?.length == 0 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading textLoading="Cargando datos" />
                </div>
              ) : (
                <div className="mt-4" style={{ paddingLeft: '15px' }}>
                  <div style={{ height: '20rem' }}>
                    <Line
                      options={revenueOptions}
                      data={{
                        labels: labelsGraficoTransacciones,
                        datasets: dataSetsGraficoTransacciones
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="justify-content-center mt-3 "
          style={{ padding: '30px' }}
        >
          <div className="row">
            <span
              style={{
                fontFamily: 'Poppins',
                fontSize: '25px',
                fontWeight: '600'
              }}
            >
              Resumen de USUARIOS
            </span>
            <div className="col-md-12 mt-3">
              <div className="regla_frame">
                {campaignsInfo == '' ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loading textLoading="Cargando datos" />
                  </div>
                ) : (
                  <div
                    className="row justify-content-between"
                    style={{ fontFamily: 'Poppins' }}
                  >
                    <div className="col-md-2 sales_resume d-flex">
                      <div className="sales-resume-icon">
                        <img
                          style={{ width: '45px', paddingTop: '15px' }}
                          src={require('../../img/registrados.png')}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: '20px',
                          marginTop: '10px',
                          paddingTop: '5px'
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: '25px',
                              fontWeight: '700',
                              fontFamily: 'Raleway'
                            }}
                          >
                            {suscribeTotal}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: '17px',
                              fontWeight: '400',
                              fontFamily: 'Raleway'
                            }}
                          >
                            Registrados
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 sales_resume d-flex">
                      <div className="sales-resume-icon">
                        <img
                          style={{ width: '45px', paddingTop: '15px' }}
                          src={require('../../img/desuscriptos.png')}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: '20px',
                          marginTop: '10px',
                          paddingTop: '5px'
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: '25px',
                              fontWeight: '700',
                              fontFamily: 'Raleway'
                            }}
                          >
                            {(
                              unsubscribeTotal -
                              campaignsInfo.userSummary?.rebounds
                            )?.toLocaleString('es-AR')}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: '17px',
                              fontWeight: '400',
                              fontFamily: 'Raleway'
                            }}
                          >
                            Desuscriptos
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 sales_resume d-flex">
                      <div className="sales-resume-icon">
                        <img
                          style={{ width: '45px', paddingTop: '15px' }}
                          src={require('../../img/rebotes.png')}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: '20px',
                          marginTop: '10px',
                          paddingTop: '5px'
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: '25px',
                              fontWeight: '700',
                              fontFamily: 'Raleway'
                            }}
                          >
                            {campaignsInfo.userSummary?.reboundsFormat}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: '17px',
                              fontWeight: '400',
                              fontFamily: 'Raleway'
                            }}
                          >
                            Rebotes
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 sales_resume d-flex">
                      <div className="sales-resume-icon">
                        <img
                          style={{ width: '45px', paddingTop: '15px' }}
                          src={require('../../img/rebotes.png')}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: '20px',
                          marginTop: '10px',
                          paddingTop: '5px'
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: '25px',
                              fontWeight: '700',
                              fontFamily: 'Raleway'
                            }}
                          >
                            {campaignsInfo.userSummary?.bounceRate}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: '17px',
                              fontWeight: '400',
                              fontFamily: 'Raleway'
                            }}
                          >
                            % de Rebotes
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className=" justify-content-center mt-3"
          style={{ padding: '30px' }}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="regla_frame justify-content-center mt-3">
                <h1 className="analytics-title-cards">
                  Ranking de vtas. por productos
                </h1>
                {!purchase.salesRankingByProduct?.length ? (
                  <span>Sin resultados.</span>
                ) : purchase.salesRankingByProduct?.length == 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loading textLoading="Cargando datos" />
                  </div>
                ) : (
                  <>
                    <div
                      className="justify-content-center"
                      style={{
                        fontSize: '13px',
                        fontWeight: '400',
                        color: '#B5B5B5'
                      }}
                    >
                      <div className="d-flex">
                        <div>
                          <span className="mr-5">Productos</span>
                        </div>
                        <div style={{ marginLeft: '70px' }}>
                          <span>Unidades vendidas</span>
                        </div>
                      </div>
                    </div>
                    <div className="justify-content-center">
                      {dataSetsRankingProductos && (
                        <Bar
                          id="horizontalBarChart1"
                          options={rankProductsOptions}
                          data={dataSetsRankingProductos}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="regla_frame justify-content-center mt-3">
                <h1 className="analytics-title-cards">
                  Ranking de vtas. por categoría
                </h1>
                {!purchase.salesRankingByCategory?.length ? (
                  <span>Sin resultados.</span>
                ) : purchase.salesRankingByCategory?.length == 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loading textLoading="Cargando datos" />
                  </div>
                ) : (
                  <>
                    <div
                      className="justify-content-center"
                      style={{
                        fontSize: '13px',
                        fontWeight: '400',
                        color: '#B5B5B5'
                      }}
                    >
                      <div className="d-flex">
                        <div>
                          <span className="mr-5">Categoría</span>
                        </div>
                        <div style={{ marginLeft: '70px' }}>
                          <span>Unidades vendidas</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Bar
                        id="horizontalBarChart2"
                        options={rankCategoryOptions}
                        data={dataSetsRankingCategoria}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 row" style={{ padding: '30px' }}>
          <div className="col-md-12">
            <div className="row justify-content-between">
              <div className="regla_frame mx-2 col-md-2 doughnut-analytic-card">
                <h1 className="user-info-title">Sexo</h1>
                {purchase?.length == 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loading textLoading="Cargando datos" />
                  </div>
                ) : (
                  <div className="doughnut-analytic-container">
                    <Doughnut
                      data={dataDoughnutSex}
                      options={optionsDoughnut}
                      width={350}
                      style={{ display: 'inline-block' }}
                      plugins={[
                        {
                          id: 'backgroundColorPlugin',
                          beforeDraw: (chart) => {
                            const { ctx, chartArea } = chart
                            ctx.save()
                            ctx.fillStyle = 'rgba(243, 243, 249, 1)'
                            ctx.beginPath()
                            const centerX =
                              (chartArea.left + chartArea.right) / 2
                            const centerY =
                              (chartArea.top + chartArea.bottom) / 2
                            const radius =
                              (Math.min(
                                chartArea.right - chartArea.left,
                                chartArea.bottom - chartArea.top
                              ) /
                                2) *
                              1.2
                            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI)
                            ctx.fill()
                            ctx.restore()
                          }
                        }
                      ]}
                    />
                  </div>
                )}
              </div>
              <div className="regla_frame mx-2 col-md-2 doughnut-analytic-card">
                <h1 className="user-info-title">Edad</h1>
                {purchase?.length == 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loading textLoading="Cargando datos" />
                  </div>
                ) : (
                  <div className="doughnut-analytic-container">
                    <Doughnut
                      data={dataDoughnutAge}
                      options={optionsDoughnut}
                      width={350}
                      style={{ display: 'inline-block' }}
                      plugins={[
                        {
                          id: 'backgroundColorPlugin',
                          beforeDraw: (chart) => {
                            const { ctx, chartArea } = chart
                            ctx.save()
                            ctx.fillStyle = 'rgba(243, 243, 249, 1)'
                            ctx.beginPath()
                            const centerX =
                              (chartArea.left + chartArea.right) / 2
                            const centerY =
                              (chartArea.top + chartArea.bottom) / 2
                            const radius =
                              (Math.min(
                                chartArea.right - chartArea.left,
                                chartArea.bottom - chartArea.top
                              ) /
                                2) *
                              1.2
                            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI)
                            ctx.fill()
                            ctx.restore()
                          }
                        }
                      ]}
                    />
                  </div>
                )}
              </div>
              <div className="regla_frame mx-2 col-md-2 doughnut-analytic-card">
                <h1 className="user-info-title">Dispositivo</h1>
                {purchase?.length == 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loading textLoading="Cargando datos" />
                  </div>
                ) : (
                  <div className="doughnut-analytic-container">
                    <Doughnut
                      data={dataDoughnutDevice}
                      options={optionsDoughnut}
                      width={350}
                      style={{ display: 'inline-block' }}
                      plugins={[
                        {
                          id: 'backgroundColorPlugin',
                          beforeDraw: (chart) => {
                            const { ctx, chartArea } = chart
                            ctx.save()
                            ctx.fillStyle = 'rgba(243, 243, 249, 1)'
                            ctx.beginPath()
                            const centerX =
                              (chartArea.left + chartArea.right) / 2
                            const centerY =
                              (chartArea.top + chartArea.bottom) / 2
                            const radius =
                              (Math.min(
                                chartArea.right - chartArea.left,
                                chartArea.bottom - chartArea.top
                              ) /
                                2) *
                              1.2
                            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI)
                            ctx.fill()
                            ctx.restore()
                          }
                        }
                      ]}
                    />
                  </div>
                )}
              </div>
              <div className="regla_frame mx-2 col-md-2 doughnut-analytic-card">
                <h1 className="user-info-title">Región</h1>
                {purchase?.length == 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loading textLoading="Cargando datos" />
                  </div>
                ) : (
                  <div className="doughnut-analytic-container">
                    <Doughnut
                      data={dataSetsDoughRegion}
                      options={optionsDoughnut}
                      width={350}
                      style={{ display: 'inline-block' }}
                      plugins={[
                        {
                          id: 'backgroundColorPlugin',
                          beforeDraw: (chart) => {
                            const { ctx, chartArea } = chart
                            ctx.save()
                            ctx.fillStyle = 'rgba(243, 243, 249, 1)'
                            ctx.beginPath()
                            const centerX =
                              (chartArea.left + chartArea.right) / 2
                            const centerY =
                              (chartArea.top + chartArea.bottom) / 2
                            const radius =
                              (Math.min(
                                chartArea.right - chartArea.left,
                                chartArea.bottom - chartArea.top
                              ) /
                                2) *
                              1.2
                            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI)
                            ctx.fill()
                            ctx.restore()
                          }
                        }
                      ]}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Analytics
