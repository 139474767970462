import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { BsSearch, BsEraser } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'

const TextField = styled.input`
  border: none;
  background: none;
  border-bottom: 2px solid #ccc !important;
  height: 32px;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: text;
  }
`

interface FilterProps {
  filterText: string
  onFilter: React.Dispatch<React.SetStateAction<string>>
  onClear: () => void
  setPerPage: any
  perPage: number
}

export const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  setPerPage,
  perPage
}: FilterProps) => {
  const intl = useIntl()
  return (
    <>
      <div className="show-options__text">
        <FormattedMessage id="app.vitrinas.mostrar" defaultMessage="MOSTRAR" />
        <select
          onChange={setPerPage}
          className="show-options__selector select_paginas"
          name="options-per-page"
          id="options-per-page"
        >
          <option value="5" selected={perPage === 5 ? true : false}>
            5
          </option>
          <option value="10" selected={perPage === 10 ? true : false}>
            10
          </option>
          <option value="15" selected={perPage === 15 ? true : false}>
            15
          </option>
          <option value="20" selected={perPage === 20 ? true : false}>
            20
          </option>
          <option value="100" selected={perPage === 100 ? true : false}>
            100
          </option>
        </select>
        <FormattedMessage
          id="app.vitrinas.entradas"
          defaultMessage="ENTRADAS"
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '50%',
          alignItems: 'center',
          justifyContent: 'end'
        }}
      >
        <TextField
          id="search"
          type="text"
          placeholder={intl.formatMessage({
            id: 'app.smart.images.filter.table.search',
            defaultMessage: 'Buscar por etiqueta'
          })}
          onChange={(e) => onFilter(e.target.value)}
          value={filterText}
          data-tip={intl.formatMessage({
            id: 'app.smart.images.filter.table.search.tooltip',
            defaultMessage: 'Ingresa la etiqueta'
          })}
        />
        <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

        {filterText === '' ? (
          <BsSearch
            onClick={onClear}
            title={
              'Buscar ' +
              intl.formatMessage({
                id: 'app.smart.images.table.column2',
                defaultMessage: 'Etiquetas'
              })
            }
            size={20}
            cursor={'pointer'}
          />
        ) : (
          <BsEraser
            title="Limpiar búsqueda"
            size={25}
            onClick={onClear}
            cursor={'pointer'}
          />
        )}
      </div>
    </>
  )
}
