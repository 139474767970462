import React from 'react'

const Select: React.FC<{
  id: string
  title: string
  value: any
  handlerChange: any
  itemSelected: any
  disabled: boolean
}> = ({ id, title, value, handlerChange, itemSelected, disabled }) => {
  /* const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCategoryValue(event.target.value);        
    console.log("categoria-seleccionada: " + event.target.value);
  }; */
  return (
    <div className="automation__endpoint-selector-container row">
      {title !== '' ? (
        <label className="automation__endpoint-selector-text mb-2 col-lg-4 col-md-4 col-sm-4 col-xs-4">
          {title}
        </label>
      ) : (
        ''
      )}
      {
        <select
          className="col-lg-8 col-md-8 col-sm-8 col-xs-8 box-select-endpoint select_cross"
          id={id}
          key={id.toString()}
          disabled={disabled}
          style={{
            width: title !== '' ? '33%' : '400px',
            background: disabled ? '#f8f8f8' : '',
            color: disabled ? '#8d8b8b' : '#000'
          }}
          onChange={handlerChange}
        >
          {id != 'slttargets' ? (
            <option value="0">Seleccione...</option>
          ) : (
            <option value="n/a">Seleccione...</option>
          )}

          {value.map((option: any) => {
            return (
              <option
                value={option.id}
                key={option.id.toString()}
                selected={itemSelected === option.id}
              >
                {Object.values(option.name)}
              </option>
            )
          })}
        </select>
      }
    </div>
  )
}

export default Select
