import { useCallback } from 'react'
import { useController } from 'react-hook-form'
import { IoIosClose } from 'react-icons/io'
import styled from 'styled-components'

interface Props {
  name: string
  inline: boolean
}

const ListStyled = styled.ul`
  max-height: 186px;
  overflow-y: auto;
  border: 1px solid #dbdbdb;
  padding: 1rem;
  border-radius: 8px;
  &::-webkit-scrollbar {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #d9d9d9;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }
`
const SelectedContainer = ({ name, inline }: Props) => {
  const {
    field: { value, onChange }
    // formState: { errors }
  } = useController({ name })
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions

  const removeCDP = useCallback(
    (id) => {
      onChange(value.filter((cdp: any) => cdp.value != id))
    },
    [value]
  )
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!value || value.length == 0) {
    return null
  }
  return (
    <div
      style={{
        background: '#fff'
      }}
    >
      <ListStyled style={{ display: inline ? 'flex' : 'block' }}>
        {value.length > 0 &&
          value.map((cdp: any, idx: any) => {
            return (
              <li
                key={idx}
                style={{
                  display: inline ? 'flex' : 'list-item',
                  padding: inline ? '3px' : '5px 10px',
                  alignItems: 'center',
                  backgroundColor: '#f9fafb',
                  marginBottom: '10px',
                  marginRight: inline ? 8 : 0,
                  fontSize: 15,
                  color: '#666'
                }}
              >
                {cdp.label}
                <IoIosClose
                  size={18}
                  color="#444"
                  cursor={'pointer'}
                  style={{ float: 'inline-end' }}
                  onClick={() => removeCDP(cdp.value)}
                />
              </li>
            )
          })}
      </ListStyled>
    </div>
  )
}
export default SelectedContainer
