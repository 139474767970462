const InfoIcon = () => {
  return (
    <>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        style={{ enableBackground: 'new 0 0 48 48', paddingTop: '0px', width: '23px', marginLeft: '8px', display: 'inline-block', height: '21px' }}
        xmlSpace="preserve"
      >
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: '\n\t.st00{fill:none;stroke:#5D6AB2;stroke-width:4;stroke-miterlimit:10;}\n\t.st01{fill:none;stroke:#5D6AB2;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st02{fill:none;stroke:#5D6AB2;stroke-width:4;stroke-linecap:round;stroke-miterlimit:10;}\n\t.st03{fill:#5D6AB2;}\n'
          }}
        />
        <g>
        <path
          className="st00"
          d="M24,4C13,4,4,13,4,24c0,11,9,20,20,20c11,0,20-8.9,20-20C44,13,35,4,24,4z"
        />
        <path
          className="st01"
          d="M21,21h3.5v12.6"
        />
        <path
          className="st02"
          d="M19.7,34h9.6"
        />
        <path
          className="st03"
          d="M24,11.2c-0.6,0-1.1,0.2-1.6,0.5c-0.5,0.3-0.8,0.8-1,1.3c-0.2,0.5-0.3,1.1-0.2,1.6c0.1,0.5,0.4,1.1,0.8,1.4
          c0.4,0.4,0.9,0.7,1.4,0.8c0.5,0.1,1.1,0.1,1.6-0.2c0.5-0.2,1-0.6,1.3-1c0.3-0.5,0.5-1,0.5-1.6c0-0.7-0.3-1.5-0.8-2
          C25.5,11.5,24.7,11.2,24,11.2z"
        />
        </g>
      </svg>
    </>
  )
}

export default InfoIcon
