import React from 'react'
import './style_mshop.css'
import { BiMessageSquareError } from 'react-icons/bi'
interface ModalProps {
  title: string
  msjError: string
  msjRecomendation: string
  btnText: string
  handleModal: any
}
const ModalMshop = ({
  title,
  msjError,
  msjRecomendation,
  btnText,
  handleModal
}: ModalProps) => {
  const redirectToMshop = () => {
    window.location.href = '/mshop'
  }
  return (
    <div>
      <div className="ms_overlay_modal"></div>
      <div className="ms_modal">
        <div>
          <div
            className="ms_modal_close"
            onClick={() => {
              redirectToMshop()
              handleModal()
            }}
          >
            X
          </div>
        </div>

        <BiMessageSquareError
          color="#D32F2F"
          size="80px"
          className="mt-4 mb-2"
        />
        <h2 className="mt-2">{title}</h2>
        <p>{msjError}</p>
        <p>{msjRecomendation}</p>

        <fieldset>
          <button
            type="button"
            className="ms_modal_btn mt-2"
            onClick={() => {
              redirectToMshop()
              handleModal()
            }}
          >
            {btnText}
          </button>
        </fieldset>
      </div>
    </div>
  )
}

export default ModalMshop
