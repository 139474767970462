import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../../state/actions'
import { Link, useNavigate } from 'react-router-dom'
import { FiUserCheck, GiKeyLock, AiOutlineArrowRight } from '../../utils/icons'
import { useIntl } from 'react-intl'
import { useLogged } from '../../hooks'

const LoginForm = (props: any) => {
  const intl = useIntl()
  const isLogged = useLogged()

  const [data, setData] = useState<LoginData>({
    username: '',
    password: ''
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'BrainDW | Login'
  }, [])

  useEffect(() => {
    if (isLogged == true) {
      navigate('/authtoken')
    }
  }, [isLogged])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const handleClick = (event: any) => {
    event.preventDefault()
    dispatch(login(data))
    props.infoLogin(data)
  }

  return (
    <>
      <form onSubmit={handleClick}>
        <div className="input-group-bdw">
          <span className="input-group-addon nk-ic-st-pro">
            <FiUserCheck className="bdw-icon-user" />
          </span>
          <div className="nk-int-st">
            <input
              name="username"
              value={data.username}
              type="text"
              className="form-control"
              placeholder={intl.formatMessage(
                { id: 'app.login.username' },
                { defaultMessage: 'Usuario' }
              )}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="input-group-bdw mg-t-15">
          <span className="input-group-addon nk-ic-st-pro">
            <GiKeyLock />
          </span>
          <div className="nk-int-st">
            <input
              name="password"
              value={data.password}
              type="password"
              className="form-control"
              placeholder={intl.formatMessage(
                { id: 'app.login.password' },
                { defaultMessage: 'Contraseña' }
              )}
              onChange={handleChange}
            />
          </div>
        </div>
        <button className="btn btn-login btn-success btn-float" type="submit">
          <AiOutlineArrowRight className="bdw-icon-arrow" color="#6529A1" />
        </button>
      </form>
      <div>
        <Link
          to={'/PasswordRecovery'}
          style={{ color: '#6529A1', fontSize: 14 }}
        >
          ¿Olvidaste tu contraseña?
        </Link>
      </div>
    </>
  )
}

export default LoginForm
