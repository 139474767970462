import React, { useEffect } from 'react'
import ScrollReveal from 'scrollreveal'
import anime from 'animejs'
import './style_mshop.css'

const MshopContainerRegister: React.FC = () => {
  useEffect(() => {
    const e = document.documentElement
    if (e !== null) {
      e.classList.remove('no-js')
      e.classList.add('js')
      const sr = ScrollReveal()
      sr.reveal('.ms_feature, .ms_pricing-table-inner', {
        duration: 600,
        distance: '20px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        origin: 'bottom',
        interval: 100
      })

      e.classList.add('anime-ready')

      anime
        .timeline({
          targets: '.ms_hero-figure-box-05'
        })
        .add({
          duration: 400,
          easing: 'easeInOutExpo',
          scaleX: [0.05, 0.05],
          scaleY: [0, 1],
          perspective: '500px',
          delay: anime.random(0, 400)
        })
        .add({
          duration: 400,
          easing: 'easeInOutExpo',
          scaleX: 1
        })
        .add({
          duration: 800,
          rotateY: '-15deg',
          rotateX: '8deg',
          rotateZ: '-1deg'
        })

      // Resto del código...

      anime({
        targets:
          '.ms_hero-figure-box-01, .ms_hero-figure-box-02, .ms_hero-figure-box-03, .ms_hero-figure-box-04, .ms_hero-figure-box-08, .ms_hero-figure-box-09, .ms_hero-figure-box-10',
        duration: anime.random(600, 800),
        delay: anime.random(600, 800),
        rotate: [
          anime.random(-360, 360),
          function (e: any) {
            return e.getAttribute('data-rotation')
          }
        ],
        scale: [0.7, 1],
        opacity: [0, 1],
        easing: 'easeInOutExpo'
      })
    }
  }, [])
  return (
    <body className="landing_ms_is-boxed ms_has-animations">
      <div className="ms_body-wrap">
        <header className="ms_site-header">
          <div className="landing_ms_container">
            <div className="ms_site-header-inner mb-3">
              <div className="landing_ms_brand header-brand">
                <h1 className="m-0">
                  <a href="#">
                    <img
                      className="ms_header-logo-image"
                      src="https://storage.cdn.braindw.com/pruebaio_produccion/script/files/2023/03/20/logobraind.png"
                      alt="Logo"
                    />
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </header>

          <section className="ms_features section">
            <div className="landing_ms_container">
              <div className="ms_features-inner ms_section-inner2">
                <div className="ms_features-wrap">
                  <div className="ms_feature text-center ms_is-revealing ms_feature2">
                    <div className="ms_feature-icon mb-4">
                        <img
                          src="https://storage.cdn.braindw.com/pruebaio_produccion/script/files/2023/11/10/featureicon01.svg"
                          alt="Feature 01"
                        />
                    </div>
                    <h4 className="color_primary">NOTIFICACIÓN</h4>
                  </div>
                </div>
                <div className="ms_cta-cta">
                  <a className="ms_button ms_button-primary ms_button-wide-mobile mr-2" href="#">INICIAR SESIÓN</a>
                  <a className="ms_button ms_button-primary ms_button-wide-mobile ml-2" href="#">REGISTRATE</a>
                </div>
              </div>
            </div>
          </section>

        <footer className="ms_site-footer">
          <div className="landing_ms_container">
            <div className="ms_site-footer-inner">
              <div className="landing_ms_brand footer-brand">
                <a href="#">
                    <img
                      className="ms_header-logo-image"
                      src="https://storage.cdn.braindw.com/pruebaio_produccion/script/files/2023/03/20/logobraind.png"
                      alt="Logo"
                    />
                </a>
              </div>
              <div className="ms_footer-copyright mt-4">&copy; 2023 BrainDW, todos los derechos reservados.</div>
            </div>
          </div>
        </footer>
      </div>

      <script src="dist/js/main.min.js"></script>
    </body>
  )
}

export default MshopContainerRegister
