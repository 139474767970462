/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import {
  BsFillBookmarksFill,
  BsPencilSquare,
  BsTrash,
  BsDownload
} from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../config'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import Papa from 'papaparse'
import RelatedSkus from './RelatedSkus'
import '../Global.css'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const RelatedSkusContainer = () => {
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const intl = useIntl()
  const [tokenValue, setTokenValue] = useState<any>('')
  const [reloadDataTable, setReloadDataTable] = useState<boolean>(false)
  const [sectionSelected, setSectionSelected] = useState<any>('')
  const [jsonFile, setJsonFile] = useState<any>([])

  const dispatch = useDispatch()

  const AlertToken = (section: string, items: any = []) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    })
      .then((result) => {
        if (result.isConfirmed && result.value !== '') {
          setTokenValue(result.value)
          if (items.length > 0) {
            postCsv(items, section, result.value).catch((error) =>
              console.log(error)
            )
          } else {
            DeleteRelatedSkuBySection(result.value, section)
          }
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const DeleteRelatedSkuBySection = (tokenGoogle: string, section: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        tokenGoogle,
        username: user.data.username,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      }
    }
    fetch(
      `${config.base_server_path}/ManageCarrousel/DeleteRelatedSkuBySection?idHashScript=${selectedScript?.idHashScript}&section=${section}`,
      requestOptions
    )
      .then(async (response) => await response.json())
      .then((res) => {
        if (res.StatusCode < 0 || res < 0) {
          if (res === -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (res !== -5 && res !== -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          MyAlert(res.Message, 1)
          // reload dataTable
          setReloadDataTable(!reloadDataTable)
        }
        setTimeout(() => {
          setTokenValue('')
        }, 3000)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const MyAlert = (text: string, iconStr: number) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-skus' },
        { defaultMessage: 'SKUS RELACIONADOS' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: 3000,
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertDelete = (text: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-skus' },
        { defaultMessage: 'SKUS RELACIONADOS' }
      ),
      text,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      focusConfirm: false,
      input: 'select',
      inputOptions: {
        Product: 'Product',
        CheckOut: 'CheckOut',
        All: intl.formatMessage(
          { id: 'app.modal.btn.borrar-todos' },
          { defaultMessage: 'Todos' }
        )
      },
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.borrar' },
        { defaultMessage: 'Borrar' }
      ),
      confirmButtonColor: '#3085d6',
      width: 500,
      timerProgressBar: true
    })
      .then((result) => {
        if (result.isConfirmed) {
          setSectionSelected(result.value)
          AlertToken(result.value, [])
        } else if (result.isDenied) {
          alert('Changes are not saved')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const exportarSkus = async (section: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        'Content-Type': 'application/json',
        responseType: 'blob',
        Accept: '*/*',
        credentials: 'include'
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res = await fetch(
      `${config.base_server_path}/ManageCarrousel/ExportRelatedSkus?idHashScript=${selectedScript?.idHashScript}&section=${section}`,
      requestOptions
    )
      .then(async (response) => {
        const respuesta = await response.blob()
        return respuesta
      })
      .then((response: any) => {
        if (response?.type === 'application/json') {
          MyAlert(
            intl.formatMessage(
              { id: 'app.related-skus.export-error' },
              {
                defaultMessage:
                  'No se encontraron registros, para la sección a exportar.'
              }
            ),
            2
          )
        } else {
          const d = new Date()
          const currDate =
            `${d.getFullYear()}` +
            '_' +
            `${d.getMonth() + 1}` +
            '_' +
            `${d.getDate()}` +
            '_' +
            `${d.getHours()}` +
            `${d.getMinutes()}` +
            `${d.getSeconds()}`
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `RelatedSkus_${section}_${currDate}.csv.zip`
          )
          document.body.appendChild(link)
          link.click()
        }
      })
      .catch(() => {
        MyAlert(
          intl.formatMessage(
            { id: 'app.api.error' },
            { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
          ),
          2
        )
      })
  }

  const AlertExport = (text: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-skus' },
        { defaultMessage: 'SKUS RELACIONADOS' }
      ),
      text,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      focusConfirm: false,
      input: 'select',
      inputOptions: {
        Product: 'Product',
        CheckOut: 'CheckOut'
      },
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.related-skus.export' },
        { defaultMessage: 'Exportar' }
      ),
      confirmButtonColor: '#3085d6',
      width: 500,
      timerProgressBar: true
    })
      .then((result) => {
        if (result.isConfirmed) {
          setSectionSelected(result.value)
          exportarSkus(result.value)
        } else if (result.isDenied) {
          alert('Changes are not saved')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const handleFile = (text: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-skus' },
        { defaultMessage: 'SKUS RELACIONADOS' }
      ),
      text,
      input: 'file',
      html: intl.formatMessage(
        { id: 'app.related-skus.add-cross-selling.csv.text' },
        { defaultMessage: 'Seleccione' }
      ),
      inputAttributes: {
        accept: 'text/csv*',
        'aria-label': 'Seleccione el CSV'
      },
      showConfirmButton: true,
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      width: 500,
      timerProgressBar: true
    }).then((result) => {
      if (result.isConfirmed) {
        const skuSection = document.getElementById(
          'csv-section'
        ) as HTMLSelectElement
        let section = 'Product'
        if (skuSection !== undefined) {
          section = skuSection.value
        }
        if (result.value != null) {
          if (result.value.size > 20000000) {
            // 20MB
            MyAlert(
              intl.formatMessage(
                { id: 'app.suscribir.file.zise' },
                { defaultMessage: '20Mb max' }
              ),
              2
            )
          } else {
            if (
              result.value.type === 'text/csv' ||
              result.value.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
              // csv|xls
              Papa.parse(result.value, {
                header: true,
                delimiter: ';',
                skipEmptyLines: true,
                complete: async function (results) {
                  if (results.data.length > 0) {
                    if (
                      results.meta.fields![0] === 'Sku' &&
                      results.meta.fields![1] === 'RelatedSku'
                    ) {
                      console.log('results: ', results.data)
                      setJsonFile(results.data)
                      /****/
                      // await AlertToken(section, results.data);
                      AlertToken(section, results.data)
                      /****/
                    } else {
                      MyAlert(
                        intl.formatMessage(
                          { id: 'app.modal.upload-csv.error-headers' },
                          {
                            defaultMessage:
                              'Los titulos de cabezera deben ser iguales a los que se muestran en el ejemplo'
                          }
                        ),
                        2
                      )
                    }
                  } else {
                    MyAlert(
                      intl.formatMessage(
                        { id: 'app.suscribir.file.empty' },
                        { defaultMessage: '20Mb max' }
                      ),
                      2
                    )
                  }
                }
              })
            } else {
              MyAlert(
                intl.formatMessage(
                  { id: 'app.suscribir.file.ext' },
                  { defaultMessage: '*.cvs file' }
                ),
                2
              )
            }
          }
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.related-categories.add-cross-selling.csv.empty' },
              { defaultMessage: '*.cvs file' }
            ),
            2
          )
        }
      }
    })
  }

  const postCsv = async (d: any, section: string, tokenGoogle: string) => {
    const cross = { items: d }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        username: user.data.username,
        tokenGoogle,
        'Content-Type': 'application/json',
        Accept: '* / *',
        credentials: 'include'
      },
      body: JSON.stringify(cross)
    }
    const res = await fetch(
      `${config.base_server_path}/ManageCarrousel/CreateRelatedSkusFromJson?idHashScript=${selectedScript?.idHashScript}&section=${section}`,
      requestOptions
    )
    const data = await res.json()

    if (data.StatusCode < 0 || data < 0) {
      if (data === -5)
        MyAlert(
          intl.formatMessage(
            { id: 'app.token-invalido' },
            { defaultMessage: 'El token ingresado es inválido.' }
          ),
          3
        )
      if (data === -6) {
        MyAlert(
          intl.formatMessage(
            { id: 'app.token-expirado' },
            { defaultMessage: 'Su sessión ha caducado. vuelve a ingresar.' }
          ),
          3
        )
        dispatch(logout(user.data.token))
      }
      if (data !== -5 && data !== -6)
        MyAlert(
          intl.formatMessage(
            { id: 'app.api.error' },
            { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
          ),
          2
        )
    } else {
      MyAlert(data.Message, 1)
      // reload dataTable
      setReloadDataTable(!reloadDataTable)
      setTimeout(() => {
        setTokenValue('')
      }, 3000)
    }
  }

  return (
    <>
      <div className="container container-body-tab">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
              <button
                className="btn btn-secondary m-lef"
                onClick={() => handleFile('Seleccione le CSV')}
              >
                <BsFillBookmarksFill className="m-rig" width={20} />
                <FormattedMessage
                  id="app.related-skus.add-new-related-skus-by-file"
                  defaultMessage="AGREGAR NUEVOS SKUS RELACIONADOS DESDE ARCHIVO"
                />
              </button>
              <button
                className="btn btn-warning m-lef"
                onClick={() =>
                  AlertDelete(
                    intl.formatMessage(
                      {
                        id: 'app.related-categories.delete-cross-selling-by-section-title'
                      },
                      {
                        defaultMessage:
                          'Seleccione la Sección que desea borrar.'
                      }
                    )
                  )
                }
              >
                <BsTrash className="m-rig" width={20} />
                <FormattedMessage
                  id="app.related-skus.delete-related-skus-by-section"
                  defaultMessage="Eliminar por Sección"
                />
              </button>
              <button
                className="btn btn-success m-lef"
                onClick={() =>
                  AlertExport(
                    intl.formatMessage(
                      { id: 'app.related-skus.export-skus' },
                      { defaultMessage: 'Seleccione lo que desea exportar' }
                    )
                  )
                }
              >
                <BsDownload className="m-rig" width={20} />
                <FormattedMessage
                  id="app.related-skus.export"
                  defaultMessage="Exportar"
                />
              </button>{' '}
              <TooltipPanel
                ObjectTooltip={
                  user.data.tooltips.filter(
                    (t: any) =>
                      t.idTooltip ===
                      'app.grid-related-skus.buttons.adds.tooltip'
                  )[0]
                }
              />
            </div>
          </div>
        </div>
        <div className="container mg-t-30">
          <div className="alert-heading">
            {intl.formatMessage(
              { id: 'app.tooltips.header.title.skuRelacionado' },
              { defaultMessage: 'Sku Relacionado' }
            )}
          </div>
          <p>
            {intl.formatMessage(
              { id: 'app.tooltips.header.p.skuRelacionado' },
              { defaultMessage: 'Sku Relacionado' }
            )}
          </p>
        </div>
        <RelatedSkus reloadDataTable={reloadDataTable} />
      </div>
    </>
  )
}

export default RelatedSkusContainer
