/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { BsFillPlusCircleFill, BsFillTrashFill } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../config'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import { Loading } from '../../Globals/Loading'
import { TooltipPanel } from '../../Globals/TooltipPanel'

import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import { useClientContext } from '../../../contexts/ClientContext'

const BrandPriorityForm = ({ back, idBrandPriority }: any) => {
  const [categories, setCategories] = useState<any>('')
  const [categoryBrands, setCategoryBrands] = useState<any>([])
  const [categoryBrandsIndex, setCategoryBrandsIndex] = useState(1)
  const [isOneRelatedBrand, setIsOneRelatedBrand] = useState<boolean>(false)
  const [oneRelatedBrand, setOneRelatedBrand] = useState<any>('')
  const [termValue, setTermValue] = useState<any>('')
  const [urlRedirectValue, setUrlRedirectValue] = useState<any>('')
  const [productsIdsValue, setProductsIdsValue] = useState<any>('')
  const [skusValue, setSkusValue] = useState<any>('')
  const [tokenValue, setTokenValue] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [loadingCategories, setLoadingCategories] = useState(false)

  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript, selectedClient } = useClientContext()

  const [saveValues, setSaveValues] = useState<any>({})

  const dispatch = useDispatch()
  const intl = useIntl()

  const categoriesArray: Array<{
    value: any
    label: any
  }> = []
  const [categoriesArrayObj, setCategoriesArrayObj] = useState<any>([])

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.brandpriority' },
        { defaultMessage: 'PRIORIDAD DE MARCA' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = () => {
    const saveButton = document.getElementById('saveBrandPriority')
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setTokenValue(result.value)
        saveButton?.classList.add('disabled')
        setLoading(true)
        const requestOptions = {
          method: 'POST',
          headers: {
            token: user.data.token,
            tokenGoogle: result.value,
            username: user.data.username,
            'Content-Type': 'application/json',
            Accept: '*/*',
            credentials: 'include'
          },
          body: JSON.stringify(saveValues)
        }
        fetch(
          `${config.base_server_path}/Search/CreateBrandPriority?idHashScript=${selectedScript?.idHashScript}&term=${termValue}`,
          requestOptions
        )
          .then(async (response) => await response.json())
          .then((res) => {
            if (res.StatusCode < 0 || res < 0) {
              if (res === -5)
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.token-invalido' },
                    { defaultMessage: 'El token ingresado es inválido.' }
                  ),
                  3
                )
              if (res !== -5 && res !== '-6')
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.api.error' },
                    {
                      defaultMessage:
                        'Ocurrió un Error al actualizar los datos.'
                    }
                  ),
                  2
                )
              setLoading(false)
              saveButton?.classList.remove('disabled')
            } else {
              MyAlert(res.Message, 1)
              setLoading(false)
              back()
            }
            setTimeout(() => {
              setTokenValue('')
            }, 3000)
          })
          .catch((error) => {
            if (error.response.data === -6) {
              dispatch(logout(user.data.token))
            } else {
              MyAlert('API-ERROR: ' + `${error}`, 3)
            }
          })
      }
    })
  }

  const validateInputs = () => {
    if (termValue !== '' && Boolean(categoryBrands.length)) {
      AlertToken()
    } else {
      MyAlert(
        intl.formatMessage(
          { id: 'app.related-categories.add-brandpriority.section.empty' },
          {
            defaultMessage:
              'Debe completar los campos: Seccion, Categoria de Origen, Categoria de Destino.'
          }
        ),
        2
      )
    }
  }

  const getIndexCategories = (elem: string) => {
    const itemIndexSe = categories.findIndex((x: string) => x === elem)
    return itemIndexSe
  }

  /*
  const handleSelectCategory = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newArr = [...categoryBrands]
    const index = event.target.id
    
    getBrands(event.target.value, event.target.id)
      .catch((error) =>
        console.log(error)
      ) 
    
    if (client.clientDetail.client === 'megusta')
      getSellers(event.target.value, '', index).catch((error) =>
        console.log(error)
      ) 

    newArr[parseInt(index)].Category = event.target.value
  }
  */

  const handleReactSelectCategory = (selector: any, event: any) => {
    const newArr = [...categoryBrands]

    const index = selector
    console.log(selector)

    getBrands(event.label, index).catch((error) => console.log(error))
    if (!selectedClient) {
      return;
    }
    if (selectedClient.clientName === 'megusta')
      getSellers(event.label, '', index).catch((error) => console.log(error))

    newArr[parseInt(index)].Category = event.label
  }

  const handleSelectBrand = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newArr = [...categoryBrands]
    const index = event.target.id
    if (selectedClient?.clientName === 'megusta')
      getSellers(newArr[parseInt(index)].Category, event.target.value, index)
    newArr[parseInt(index)].Brand = event.target.value
  }

  const handleSelectSeller = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newArr = [...categoryBrands]
    const index = event.target.id
    newArr[parseInt(index)].Seller = event.target.value
    setCategoryBrands(newArr)
  }

  const addCategoryBrand = () => {
    const rows = [
      ...categoryBrands,
      {
        categories,
        brands: [],
        sellers: [],
        Category: '',
        Brand: '',
        Seller: '',
        id:
          'category_BrandDeleteBtn' +
          JSON.stringify(`${categoryBrands.length}` + `${categoryBrandsIndex}`)
      }
    ]
    setCategoryBrands(rows)
    setCategoryBrandsIndex(categoryBrandsIndex + 1)
  }

  const deleteCategoryBrand = (item: any) => {
    setCategoryBrands(categoryBrands.filter((e: { id: any }) => e.id !== item))
  }

  const getCategories = async () => {
    setLoadingCategories(true)
    Service.get<any>(`Search/GetCategories?idHashScript=${selectedScript?.idHashScript}`, {
      headers: { token: user.data.token }
    })
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setCategories(response.data)

          response.data.forEach((item: any, index: any) => {
            console.log(item)
            categoriesArray.push({
              value: item,
              label: item
            })
          })
          setCategoriesArrayObj(categoriesArray)

          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (idBrandPriority)
            getOneRelatedBrand(response.data).catch((error) =>
              console.log(error)
            )
        } else {
          setCategories('')
        }
        setLoadingCategories(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const getBrands = async (category: any, index: any) => {
    const newArr = [...categoryBrands]
    Service.get<any>(
      `Search/GetBrands?idHashScript=${selectedScript?.idHashScript}&category=${category}`,
      { headers: { token: user.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          newArr[index].brands = response.data
          setCategoryBrands(newArr)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const getSellers = async (category: any, brand: any, index: any) => {
    const newArr = [...categoryBrands]
    Service.get<any>(`Search/GetSellers?idHashScript=${selectedScript?.idHashScript}`, {
      headers: {
        token: user.data.token,
        category: category.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        brand: brand.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      }
    })
      .then((response) => {
        if (response.headers.statuscode === '0') {
          newArr[index].sellers = response.data
          setCategoryBrands(newArr)
        }
      })
      .catch((error) => {
        if (error.response.data === '-6') {
          dispatch(logout(user.data.token))
        } else if (error.response.data === '-4') {
          newArr[index].sellers = []
        }
      })
  }

  const getOneRelatedBrand = async (categories: any) => {
    const rows: Array<{
      categories: any
      brands: any
      sellers: any
      Category: string
      Brand: string
      Seller: string
      id: string
    }> = []
    Service.get<any>(
      `Search/GetOneBrandPriority?idHashScript=${selectedScript?.idHashScript}&idBrandPriority=${idBrandPriority}`,
      { headers: { token: user.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setOneRelatedBrand(response.data)
          setTermValue(response.data.Term)
          setUrlRedirectValue(response.data.Url)
          setProductsIdsValue(response.data.ProductIds)
          setSkusValue(response.data.Skus)
          response.data.CategoryBrands.forEach((item: any, index: any) =>
            rows.push({
              categories,
              brands: '',
              sellers: '',
              Category: item.Category,
              Brand: item.Brand,
              Seller: item.Sellers,
              id: 'category_BrandDeleteBtn' + JSON.stringify(index)
            })
          )
          setCategoryBrands(rows)
          setIsOneRelatedBrand(true)
        } else {
          setOneRelatedBrand('')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    const newArr = [...categoryBrands]
    if (
      oneRelatedBrand.CategoryBrands !== undefined &&
      categoryBrands.length > 0
    ) {
      oneRelatedBrand.CategoryBrands.forEach(
        async (item: any, index: any) =>
          await (newArr[index].brands = getBrands(item.Category, index))
      )
      if (!selectedClient) {
        return;
      }
      if (selectedClient.clientName === 'megusta')
        oneRelatedBrand.CategoryBrands.forEach(
          async (item: any, index: any) =>
            await (newArr[index].sellers = getSellers(
              item.Category,
              item.Brand,
              index
            ))
        )
    }
  }, [oneRelatedBrand, isOneRelatedBrand])

  useEffect(() => {
    setSaveValues(
      oneRelatedBrand !== ''
        ? {
            Id: oneRelatedBrand !== '' ? oneRelatedBrand.Id : '',
            Term: termValue,
            CategoryBrands: categoryBrands.map((item: any) => ({
              Brand: item.Brand,
              Category: item.Category,
              Sellers: item.Seller
            })),
            Url: urlRedirectValue,
            Skus: skusValue,
            ProductIds: productsIdsValue,
            IdScriptClient: selectedClient?.idClient,
            UserType: 'ADMIN',
            FlagCreateByAdmin: true,
            FlagModifyByUser: true,
            TermExistByAdmin: true
          }
        : {
            Term: termValue,
            CategoryBrands: categoryBrands.map((item: any) => ({
              Brand: item.Brand,
              Category: item.Category,
              Sellers: item.Seller
            })),
            Url: urlRedirectValue,
            Skus: skusValue,
            ProductIds: productsIdsValue,
            IdScriptClient: selectedClient?.idClient,
            UserType: 'ADMIN',
            FlagCreateByAdmin: true,
            FlagModifyByUser: true,
            TermExistByAdmin: true
          }
    )
  }, [termValue, categoryBrands, productsIdsValue, skusValue])

  return (
    <>
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <h6>
          <FormattedMessage
            id="app.brandpriority.add-new-brandpriority"
            defaultMessage="AGREGAR PRIORIDAD DE MARCA"
          />
        </h6>
      </div>
      <div className="shelves__wrapper">
        <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 jc-flexend">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                  <FormattedMessage
                    id="app.brandpriority.form.term"
                    defaultMessage="Término"
                  />
                  <TooltipPanel 
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.PrioridadMarca.Termino.tooltip')
                    )[0]}
                  />
                </label>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
              <input
                className="input vtexskuconsult__input col-lg-8 col-md-8 col-sm-8 col-xs-8"
                type="text"
                value={termValue}
                onChange={(e) => setTermValue(e.target.value)}
              />
            </div>
          </div>
          {categoryBrands.map((item: any, index: any) => {
            // debugger;
            return (
              <>
                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container box-specification">
                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2 padding-0">
                        <FormattedMessage
                          id="app.brandpriority.form.category"
                          defaultMessage="Categoría"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    {/*
                    <select
                      onChange={handleSelectCategory}
                      id={index}
                      className="col-lg-8 col-md-8 col-sm-8 col-xs-8 box-select-endpoint select_cross"
                    >
                      <option value="seleccionar">
                        {intl.formatMessage(
                          { id: 'app.brandpriority.form.select-category' },
                          { defaultMessage: 'Seleccionar' }
                        )}
                      </option>
                      {item.categories.map((i: any) => {
                        return (
                          <option
                            key={i}
                            value={i}
                            // eslint-disable-next-line no-unneeded-ternary
                            selected={item.Category === i ? true : false}
                          >
                            {i}
                          </option>
                        )
                      })}
                    </select>
                    */}
                    {categoriesArrayObj.length > 0 ? (
                      <Select
                        className="basic-single col-lg-10 col-md-10 col-sm-10 col-xs-10 select_cross "
                        classNamePrefix="select"
                        defaultValue={
                          categoriesArrayObj[getIndexCategories(item.Category)]
                        }
                        isDisabled={false}
                        isLoading={loadingCategories}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        id={index}
                        options={categoriesArrayObj}
                        placeholder={intl.formatMessage(
                          { id: 'app.brandpriority.form.select-category' },
                          { defaultMessage: 'Seleccionar' }
                        )}
                        onChange={(event) =>
                          handleReactSelectCategory(index, event)
                        }
                      />
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2 padding-0">
                        <FormattedMessage
                          id="app.brandpriority.form.brand"
                          defaultMessage="Marca"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <select
                      onChange={handleSelectBrand}
                      id={index}
                      className="col-lg-10 col-md-10 col-sm-10 col-xs-10 box-select-endpoint select_cross"
                    >
                      <option value="">
                        {intl.formatMessage(
                          { id: 'app.brandpriority.form.select-category' },
                          { defaultMessage: 'Seleccionar' }
                        )}
                      </option>
                      {item.brands.length
                        ? item.brands.map((i: any) => {
                            return (
                              <option
                                key={i}
                                value={i}
                                selected={
                                  // eslint-disable-next-line no-unneeded-ternary
                                  item.Brand.toLowerCase() === i.toLowerCase()
                                    ? true
                                    : false
                                }
                              >
                                {i}
                              </option>
                            )
                          })
                        : ''}
                    </select>
                  </div>

                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2 padding-0">
                        <FormattedMessage
                          id="app.brandpriority.form.seller"
                          defaultMessage="Seller"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <select
                      disabled={selectedClient?.clientName !== 'megusta'}
                      onChange={handleSelectSeller}
                      id={index}
                      className="col-lg-8 col-md-8 col-sm-8 col-xs-8 box-select-endpoint select_cross"
                    >
                      <option value="">
                        {intl.formatMessage(
                          { id: 'app.brandpriority.form.select-category' },
                          { defaultMessage: 'Seleccionar' }
                        )}
                      </option>
                      {item.sellers.length
                        ? item.sellers.map((i: any) => {
                            return (
                              <option
                                key={i}
                                value={i}
                                selected={
                                  // eslint-disable-next-line no-unneeded-ternary
                                  item.Seller.toLowerCase() === i.toLowerCase()
                                    ? true
                                    : false
                                }
                              >
                                {i}
                              </option>
                            )
                          })
                        : ''}
                    </select>
                  </div>

                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    <button
                      className="manage-grid-button btn-add-espec"
                      onClick={() => {
                        deleteCategoryBrand(item.id)
                      }}
                    >
                      <BsFillTrashFill size={25} id={'red'} />
                    </button>
                  </div>
                </div>
              </>
            )
          })}
          <div className="tabs-sel">
            {isOneRelatedBrand && categoryBrands.length ? (
              <button
                className="manage-grid-button btn btn-border"
                style={{ position: 'relative', left: '25%' }}
                onClick={addCategoryBrand}
              >
                <BsFillPlusCircleFill size={25} />
                &nbsp;
                {intl.formatMessage(
                  { id: 'app.brandpriority.form.categoryandbrand' },
                  { defaultMessage: 'Categoría y Marca' }
                )}
              </button>
            ) : (
              ''
            )}
            {!isOneRelatedBrand && categories.length ? (
              <button
                className="manage-grid-button btn btn-border"
                style={{ position: 'relative', left: '25%' }}
                onClick={addCategoryBrand}
              >
                <BsFillPlusCircleFill size={25} />
                &nbsp;
                {intl.formatMessage(
                  { id: 'app.brandpriority.form.categoryandbrand' },
                  { defaultMessage: 'Categoría y Marca' }
                )}
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 jc-flexend">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                  <FormattedMessage
                    id="app.brandpriority.form.products-ids"
                    defaultMessage="Products Ids"
                  />
                  <TooltipPanel 
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.PrioridadMarca.ProductosID.tooltip')
                    )[0]}
                  />                  
                </label>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
              <textarea
                className="input vtexskuconsult__input vtexskuconsult__textarea col-lg-8 col-md-8 col-sm-8 col-xs-8"
                rows={6}
                value={productsIdsValue}
                onChange={(e) => {
                  setProductsIdsValue(e.target.value)
                }}
                placeholder={intl.formatMessage(
                  { id: 'app.brandpriority.form.products-ids-placeholder' },
                  { defaultMessage: 'Productos separados por comas' }
                )}
              />
            </div>
          </div>
          <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 jc-flexend">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                  <FormattedMessage
                    id="app.brandpriority.form.Skus"
                    defaultMessage="Skus"
                  />
                  <TooltipPanel 
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.PrioridadMarca.SKU.tooltip')
                    )[0]}
                  />
                </label>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
              <textarea
                className="input vtexskuconsult__input vtexskuconsult__textarea col-lg-8 col-md-8 col-sm-8 col-xs-8"
                rows={6}
                value={skusValue}
                onChange={(e) => {
                  setSkusValue(e.target.value)
                }}
                placeholder={intl.formatMessage(
                  { id: 'app.brandpriority.form.Skus-placeholder' },
                  { defaultMessage: 'Skus separados por comas' }
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row shelves button botonera">
        {loading ? (
          <div style={{ margin: 'inherit', textAlign: 'center' }}>
            <Loading
              textLoading={intl.formatMessage(
                { id: 'app.vitrinas-spinner.espere' },
                { defaultMessage: 'Espere...' }
              )}
            />
          </div>
        ) : (
          ''
        )}
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
          <div className="shelves__preview-button">
            <div className="shelves__preview-button">
              <button
                onClick={validateInputs}
                className="ppal_button btn btn-primary"
                id="saveBrandPriority"
              >
                <FormattedMessage
                  id="app.related-categories.add-cross-selling.guardar"
                  defaultMessage="GUARDAR"
                />
              </button>
            </div>
            <div className="shelves__preview-button">
              <button
                id={'btn-regresar'}
                className="manage-grid-button shelves__title-icon"
                onClick={() => {
                  back()
                }}
              >
                <FormattedMessage
                  id="app.related-categories.add-cross-selling.volver"
                  defaultMessage="VOLVER"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BrandPriorityForm
