export const CountryStateCity = {
  Argentina: [
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Ciudad Autónoma de Buenos Aires',
    'Corrientes',
    'Córdoba',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego',
    'Tucumán'
  ],
  Brazil: [
    'Acre',
    'Alagoas',
    'Amapá',
    'Amazonas',
    'Bahia',
    'Ceará',
    'Distrito Federal',
    'Espírito Santo',
    'Goiás',
    'Maranhão',
    'Mato Grosso',
    'Mato Grosso do Sul',
    'Minas Gerais',
    'Paraná',
    'Paraíba',
    'Pará',
    'Pernambuco',
    'Piauí',
    'Rio Grande do Norte',
    'Rio Grande do Sul',
    'Rio de Janeiro',
    'Rondônia',
    'Roraima',
    'Santa Catarina',
    'Sergipe',
    'São Paulo',
    'Tocantins'
  ],
  Chile: [
    'Aisén del General Carlos Ibañez del Campo',
    'Antofagasta',
    'Arica y Parinacota',
    'Atacama',
    'Biobío',
    'Coquimbo',
    'La Araucanía',
    "Libertador General Bernardo O'Higgins",
    'Los Lagos',
    'Los Ríos',
    'Magallanes y de la Antártica Chilena',
    'Maule',
    'Región Metropolitana de Santiago',
    'Tarapacá',
    'Valparaíso',
    'Ñuble'
  ],
  Colombia: [
    'Amazonas',
    'Antioquia',
    'Arauca',
    'Archipiélago de San Andrés, Providencia y Santa Catalina',
    'Atlántico',
    'Bogotá D.C.',
    'Bolívar',
    'Boyacá',
    'Caldas',
    'Caquetá',
    'Casanare',
    'Cauca',
    'Cesar',
    'Chocó',
    'Cundinamarca',
    'Córdoba',
    'Guainía',
    'Guaviare',
    'Huila',
    'La Guajira',
    'Magdalena',
    'Meta',
    'Nariño',
    'Norte de Santander',
    'Putumayo',
    'Quindío',
    'Risaralda',
    'Santander',
    'Sucre',
    'Tolima',
    'Valle del Cauca',
    'Vaupés',
    'Vichada'
  ],
  Mexico: [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Coahuila de Zaragoza',
    'Colima',
    'Durango',
    'Estado de México',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Michoacán de Ocampo',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz de Ignacio de la Llave',
    'Yucatán',
    'Zacatecas'
  ]
}
