/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-self-assign */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useState } from 'react'
import {
  BsFillPlusCircleFill,
  BsGear,
  BsPencilSquare,
  BsTrash
} from 'react-icons/bs'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../config'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../../components/Globals'
import { logout } from '../../../state/actions'
import ReactTooltip from 'react-tooltip'
import { useClientContext } from '../../../contexts/ClientContext'

const ShelvesOptionsGridTable = ({
  options,
  headers,
  editar,
  borrar,
  classLoading,
  sectionName,
  fncShowGridVitrinas,
  DataReloadGrid
}: any) => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()
  const [keySectionId, setKeySectionId] = useState<any>('')
  const [appendClass, setAppendClass] = useState<any>('')
  const [checkbox, setCheckbox] = useState<any>('')
  const [checked, setChecked] = useState<any>('')
  const [reload, setReload] = useState<any>('')
  const dispatch = useDispatch()

  const intl = useIntl()

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      // toast: true,
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = (idsec: string, enabled: boolean) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'El token no fue informado.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        updateGondole(idsec, enabled, result.value)
      }
    })
  }

  const buttonHandler = (event: any, enabled: any) => {
    setCheckbox(event.target)
    setChecked(event.target.checked)

    setKeySectionId(event.target.dataset.keysectionid)
    AlertToken(event.target.dataset.keysectionid, event.target.checked)
  }

  const DeleteAlertToken = (keySectionId: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'El token no fue informado.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        deleteGondole(keySectionId, result.value)
      }
    })
  }

  const deleteGondole = (keySectionId: string, tokenGoogle: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        username: user.data.username,
        tokenGoogle,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      }
    }
    fetch(
      `${config.base_server_path}/ManageCarrousel/DeleteGondole?idHashScript=${selectedScript?.idHashScript}&keySectionId=${keySectionId}`,
      requestOptions
    )
      .then(async (response) => await response.json())
      .then((result) => {
        if (result < 0) {
          if (result == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (result != -5 && result != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          setReload('re-recargar')
          DataReloadGrid() // recargar toda la grilla
          MyAlert('Se eliminó con éxito la vitrina ' + keySectionId, 1)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  useEffect(() => {
    options = options
    if (reload !== '' && keySectionId !== '') {
      options.filter(function (section: any) {
        if (section.keySectionId == keySectionId) {
          section.action.template_enabled = checked == true ? '1' : '0'
          checkbox.checked = checked
          setReload('')
        }
      })
    }
    console.log('class spinner:' + `${classLoading}`)
  }, [options, reload])

  const updateGondole = (
    keySectionId: any,
    enabled: boolean,
    tokenGoogle: string
  ) => {
    const action = {
      keySectionId,
      action: {
        template_enabled: enabled ? '1' : '0'
      }
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        username: user.data.username,
        tokenGoogle,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(action)
    }
    fetch(
      `${config.base_server_path}/ManageCarrousel/EnabledGondole?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
      .then(async (response) => await response.json())
      .then((result) => {
        if (result < 0) {
          if (result == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (result != -5 && result != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          setReload('recargar')
          MyAlert('Se actualizó con éxito la vitrina: ' + `${appendClass}`, 1)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  return (
    <div className="mt-40">
      <table
        className={
          classLoading !== ''
            ? `${classLoading}` +
              ' table table-sm table-striped table-bordered '
            : ' table table-sm table-striped table-bordered'
        }
      >
        <thead>
          <tr>
            {headers.map((header: any) => {
              return (
                <th className="text-center p-20" scope="col" key={header.id}>
                  {header.name}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {options.map((option: any, index: 1) => {
            const esPlp = option.keySectionId.includes('-plp-')
            const itemIndexSe = option.action.additionalFields.findIndex(
              (x: { labelId: string }) => x.labelId == 'NameCategorySelect'
            )
            const categoria =
              itemIndexSe >= 0 && esPlp === true
                ? ' [' +
                  `${option.action.additionalFields[itemIndexSe].value}` +
                  ']'
                : ''
            const isCdp = option.segments.length > 0 ? true : false
            const arrDfrom =
              option.dateFrom != null ? option.dateFrom.split('-') : null
            const arrDto =
              option.dateTo != null ? option.dateTo.split('-') : null

            return (
              <tr
                key={option.keySectionId}
                className="tr_fila"
                data-dbid={option.keySectionId}
                style={{
                  background:
                    option.typeGondole == 'default' ? 'rgb(236 247 243)' : ''
                }}
              >
                <td style={{ width: '35%' }}>
                  {option.action.template_titulo}
                  <br />
                  {categoria}
                </td>
                <td>
                  {option.status == 'Sin vencimiento' ? (
                    <span
                      className="badge badge-info"
                      data-tip={'Sin caducidad'}
                    >
                      {option.status}
                    </span>
                  ) : option.status == 'En curso' ? (
                    <span
                      className="badge badge-success"
                      data-tip={
                        'Desde: ' +
                        arrDfrom[2] +
                        '/' +
                        arrDfrom[1] +
                        '/' +
                        arrDfrom[0] +
                        '  Hasta: ' +
                        arrDto[2] +
                        '/' +
                        arrDto[1] +
                        '/' +
                        arrDto[0]
                      }
                    >
                      {option.status}
                    </span>
                  ) : option.status == 'Finalizada' ? (
                    <span
                      className="badge badge-warning"
                      data-tip={
                        'Inició: ' +
                        arrDfrom[2] +
                        '/' +
                        arrDfrom[1] +
                        '/' +
                        arrDfrom[0] +
                        ' | Finalizó: ' +
                        arrDto[2] +
                        '/' +
                        arrDto[1] +
                        '/' +
                        arrDto[0]
                      }
                    >
                      {option.status}
                    </span>
                  ) : (
                    <span
                      className="badge badge-dark"
                      data-tip={
                        'Inicia el:' +
                        arrDfrom[2] +
                        '/' +
                        arrDfrom[1] +
                        '/' +
                        arrDfrom[0] +
                        ' | Hasta el:' +
                        arrDto[2] +
                        '/' +
                        arrDto[1] +
                        '/' +
                        arrDto[0]
                      }
                    >
                      {option.status}
                    </span>
                  )}
                  <ReactTooltip
                    delayShow={10}
                    data-backgroundColor={'#2fad7e'}
                  />
                </td>
                <td>{option.nameEndPoint}</td>
                <td>
                  {option.action.template_appendClass}
                  {isCdp ? (
                    <>
                      <br />
                      <span className="badge badge-warning">CDP</span>
                    </>
                  ) : null}
                </td>
                <td style={{ width: '100%' }}>
                  <label
                    className="switch"
                    data-tip={useIntl().formatMessage(
                      { id: 'app.vitrinas-enabled.tooltipo' },
                      { defaultMessage: 'Habilitar/Desabilitar vitrina' }
                    )}
                  >
                    <input
                      type="checkbox"
                      key={index.toString()}
                      onChange={(e) => {
                        buttonHandler(e, option.action.template_enabled)
                        setAppendClass(option.action.template_appendClass)
                      }}
                      id={'checkbox-' + JSON.stringify(index++)}
                      data-chk={option.action.template_enabled}
                      data-keySectionId={option.keySectionId}
                      checked={
                        option.action.template_enabled == '0' ? false : true
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                  <ReactTooltip
                    delayShow={10}
                    data-backgroundColor={'#2fad7e'}
                  />

                  {option.typeGondole != 'default' ? (
                    <>
                      <button
                        className="manage-grid-button"
                        style={{ marginLeft: '15px;' }}
                        onClick={() => {
                          fncShowGridVitrinas(true, option)
                        }}
                        data-tip={useIntl().formatMessage(
                          { id: 'app.vitrinas-editar.vitrina-audiencia' },
                          { defaultMessage: 'Configurar Audiencia' }
                        )}
                      >
                        <BsGear
                          title={intl.formatMessage(
                            { id: 'app.vitrinas-editar.vitrina-audiencia' },
                            { defaultMessage: 'Configurar Audiencia' }
                          )}
                          size={25}
                        />
                      </button>
                      <ReactTooltip
                        delayShow={10}
                        data-backgroundColor={'#2fad7e'}
                      />
                    </>
                  ) : null}

                  {option.typeGondole == 'default' ? (
                    <>
                      <button
                        className="manage-grid-button"
                        style={{ marginLeft: '15px;' }}
                        onClick={() => editar(option)}
                        data-tip={useIntl().formatMessage(
                          {
                            id: 'app.cdp.create-vitrina-audiencia.title-question'
                          },
                          { defaultMessage: 'Agregar vitrina' }
                        )}
                      >
                        <BsFillPlusCircleFill
                          title={intl.formatMessage(
                            {
                              id: 'app.cdp.create-vitrina-audiencia.title-question'
                            },
                            { defaultMessage: 'Agregar vitrina' }
                          )}
                          size={25}
                        />
                        &nbsp;
                        <ReactTooltip
                          delayShow={10}
                          data-backgroundColor={'#2fad7e'}
                        />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="manage-grid-button"
                        style={{ marginLeft: '15px;' }}
                        onClick={() => editar(option)}
                        data-tip={useIntl().formatMessage(
                          { id: 'app.vitrinas-editar.vitrina' },
                          { defaultMessage: 'Editar vitrina' }
                        )}
                      >
                        <BsPencilSquare
                          title={intl.formatMessage(
                            { id: 'app.vitrinas-editar.vitrina' },
                            { defaultMessage: 'Editar vitrina' }
                          )}
                          size={25}
                        />
                      </button>
                      <ReactTooltip
                        delayShow={10}
                        data-backgroundColor={'#2fad7e'}
                      />

                      {esPlp === true ? (
                        <>
                          <button
                            className="manage-grid-button-del btn-grilla-del"
                            data-id={option.keySectionId}
                            onClick={() => {
                              DeleteAlertToken(option.keySectionId)
                            }}
                            data-tip={useIntl().formatMessage(
                              { id: 'app.cdp.remove-data.delete' },
                              { defaultMessage: 'Eliminar' }
                            )}
                          >
                            <BsTrash
                              color="rgb(230 114 60)"
                              title={intl.formatMessage(
                                { id: 'app.cdp.remove-data.delete' },
                                { defaultMessage: 'Eliminar' }
                              )}
                              size={25}
                            />
                          </button>
                          <ReactTooltip
                            delayShow={10}
                            data-backgroundColor={'#2fad7e'}
                          />
                        </>
                      ) : null}
                    </>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ShelvesOptionsGridTable
