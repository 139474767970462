/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react'
import './automation.css'
import {
  BsPencilSquare,
  BsPlusCircle,
  BsArrowClockwise,
  BsArrowCounterclockwise,
  BsTrash,
  BsFillCheckCircleFill
} from 'react-icons/bs'
import Pagination from '../../Globals/Pagination'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/store'
import Papa from 'papaparse'
import Service from '../../../services'
import { config } from '../../../config'
import ReactTooltip from 'react-tooltip'
import { FormattedMessage, useIntl } from 'react-intl'
import { logout } from '../../../state/actions'
import { Loading } from '../../Globals/Loading'
import { ShowAlert } from '../../../components/Globals'
import { useClientContext } from '../../../contexts/ClientContext'

const AudiencesTable = ({ newAudience, setNewAudience }: any) => {
  interface DataAudience {
    IdSegment: number
    Name: string
    Description: string
    Quantity: number
  }

  const [options, setOptions] = useState<any>([])
  const [optionsPerPage, setOptionsPerPage] = useState<number>(25)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { selectedScript, selectedClient } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [jsonFileAgregar, setJsonFileAgregar] = useState<any>([])
  const [jsonFileReemplazar, setJsonFileReemplazar] = useState<any>([])
  const [dataAgregar, setDataAgregar] = useState({} as DataAudience)
  const [dataReemplazar, setDataReemplazar] = useState({} as DataAudience)
  const [editar, setEditar] = useState(false)
  const [newName, setNewName] = useState('')
  const [newDescription, setNewDescription] = useState('')
  const [editId, setEditId] = useState({} as DataAudience)
  const [newIdEdit, setNewIdEdit] = useState('')
  const [tokenValue, setTokenValue] = useState<any>('')

  const [viewSpinner, setViewSpinner] = useState<boolean>(false)
  const dispatch = useDispatch()

  const intl = useIntl()

  const AlertToken = (data: any, action: string) => {
    void ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve(
              intl.formatMessage(
                { id: 'app.token-inexistente' },
                { defaultMessage: 'Debe ingresar el token.' }
              )
            )
          } else {
            resolve('')
          }
        })
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setTokenValue(result.value)
        if (action === 'delete') {
          const audience = { SegmentName: data }
          const requestOptions = {
            method: 'POST',
            headers: {
              token: user.data.token,
              tokenGoogle: result.value,
              username: user.data.username,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            },
            body: JSON.stringify(audience)
          }
          fetch(
            `${config.base_server_path}/Automation/DeletSegment?idHashScript=${selectedScript?.idHashScript}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((res) => {
              getAudiences()
              if (res.StatusCode < 0) {
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.api.error' },
                    {
                      defaultMessage:
                        'Ocurrió un Error al actualizar los datos.'
                    }
                  ),
                  2
                )
              } else {
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.api.delete.ok' },
                    { defaultMessage: 'Se borraron con éxito los datos.' }
                  ),
                  1
                )
              }
              setJsonFileAgregar([])
              setJsonFileReemplazar([])
            })
            .catch(() => {
              MyAlert(
                intl.formatMessage(
                  { id: 'app.api.error' },
                  {
                    defaultMessage: 'Ocurrió un Error al actualizar los datos.'
                  }
                ),
                2
              )
            })
        } else if (action === 'upgrade') {
          const fileSelector = document.getElementById(
            'inputFileReemplazar'
          ) as HTMLInputElement
          handleFileReemplazar(fileSelector?.files)
        } else if (action === 'adds') {
          const fileSelectorAdd = document.getElementById(
            'inputFileAgregar'
          ) as HTMLInputElement
          handleFileAgregar(fileSelectorAdd?.files)
        } else {
          editAudiences(data, result.value)
        }
      }
    })
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    void ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.audiencias' },
        { defaultMessage: 'Audiencias' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: !isToast
    })
  }

  const getAudiences = useCallback(() => {
    setViewSpinner(true)
    Service.get<any>(
      `/Automation/GetAllSegments?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          token: user.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setOptions(response.data.Entities)
        }
        setViewSpinner(false)
      })
      .catch((error) => {
        setViewSpinner(false)
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + error, 3)
        }
      })
  }, [selectedScript])

  const replaceAudiences = useCallback(
    async (data: any) => {
      if (!selectedScript) {
        return
      }
      try {
        const audience = {
          SegmentName: data.SegmentName,
          Description: data.Description,
          Emails: data.Email
        }
        const requestOptions = {
          method: 'POST',
          headers: {
            token: user.data.token,
            tokenGoogle: tokenValue,
            username: user.data.username,
            'Content-Type': 'application/json',
            Accept: '*/*',
            credentials: 'include'
          },
          body: JSON.stringify(audience)
        }
        // eslint-disable-next-line no-debugger
        debugger
        const res = await fetch(
          `${config.base_server_path}/Automation/UpgradeSegment?idHashScript=${selectedScript.idHashScript}`,
          requestOptions
        )
        const dat = await res.json()
        if (dat.StatusCode < 0) {
          MyAlert(dat.Message, 2)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [selectedScript],
  )

  const addAudiences = useCallback(
    async (data: any, cleanSegment = 0, avance = 0) => {
      try {
        if (!selectedScript) {
          return
        }
        const audience = {
          SegmentName: data.SegmentName,
          Description: data.Description,
          CleanAll: cleanSegment,
          Emails: data.Email
        }
        const requestOptions = {
          method: 'POST',
          headers: {
            token: user.data.token,
            'Content-Type': 'application/json',
            Accept: '*/*',
            credentials: 'include',
            tokenGoogle: tokenValue,
            username: user.data.username
          },
          body: JSON.stringify(audience)
        }
        const res = await fetch(
          `${config.base_server_path}/Automation/CreateSegment?idHashScript=${selectedScript.idHashScript}`,
          requestOptions
        )
        const dat = await res.json()
        if (dat.StatusCode < 0) {
          MyAlert(dat.Message, 2)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [selectedScript]
  )

  const editAudiences = useCallback(
    (data: any, tokenGoogle: any) => {
      if (!selectedScript) {
        return
      }
      const audience = {
        SegmentName: data.Name,
        NewSegmentName: newName,
        Description: newDescription
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          token: user.data.token,
          tokenGoogle,
          username: user.data.username,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        },
        body: JSON.stringify(audience)
      }
      fetch(
        `${config.base_server_path}/Automation/UpgradeSegment?idHashScript=${selectedScript.idHashScript}`,
        requestOptions
      )
        .then((result) => result.json())
        .then((res) => {
          if (res.StatusCode < 0) {
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ) + res.Message,
              2
            )
          } else {
            MyAlert(res.Message, 1)
            setEditar(!editar)
          }
          setJsonFileAgregar([])
          setJsonFileReemplazar([])
        })
        .catch((error) => {
          if (error.response.data === -6) {
            dispatch(logout(user.data.token))
          } else {
            MyAlert('API-ERROR: ' + error, 3)
          }
        })
    },
    [selectedScript]
  )


  const handleReemplazar = (data: any) => {
    console.log('data tabla', data)
    document.getElementById('inputFileReemplazar')?.click()
    setDataReemplazar(data)
  }

  const handleAgregar = (data: any) => {
    console.log('data handleagregar', data)
    document.getElementById('inputFileAgregar')?.click()
    setDataAgregar(data)
  }

  const handleFileReemplazar = (e: any) => {
    if (e[0].size > 10000000) {
      // 20MB
      MyAlert(
        intl.formatMessage(
          { id: 'app.suscribir.file.zise' },
          {
            defaultMessage:
              'El archivo que intenta subir supera el máximo permitido de 20MB.'
          }
        ),
        3
      )
    } else {
      if (
        e[0].type === 'text/csv' ||
        e[0].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        // csv/xls
        Papa.parse(e[0], {
          complete: function (results) {
            console.log('results: ', results.data)
            if (results.data.length === 0) {
              MyAlert(
                intl.formatMessage(
                  { id: 'app.suscribir.file.empty' },
                  { defaultMessage: 'Archivo vacio.' }
                ),
                3
              )
            } else {
              setJsonFileReemplazar(results.data)
            }
          }
        })
      } else {
        MyAlert(
          intl.formatMessage(
            { id: 'app.suscribir.file.ext' },
            {
              defaultMessage:
                'Las extensiones permitidas son *.csv o *.xls/xlsx'
            }
          ),
          3
        )
      }
    }
  }

  const handleFileAgregar = (e: any) => {
    if (e[0].size > 10000000) {
      // 10MB
      MyAlert(
        intl.formatMessage(
          { id: 'app.suscribir.file.zise' },
          {
            defaultMessage:
              'El archivo que intenta subir supera el máximo permitido de 20MB.'
          }
        ),
        3
      )
    } else {
      if (
        e[0].type === 'text/csv' ||
        e[0].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        // csv/xls
        Papa.parse(e[0], {
          complete: function (results) {
            console.log('results: ', results.data)
            if (results.data.length === 0) {
              MyAlert(
                intl.formatMessage(
                  { id: 'app.suscribir.file.empty' },
                  { defaultMessage: 'Archivo vacio.' }
                ),
                3
              )
            } else {
              setJsonFileAgregar(results.data)
            }
          }
        })
      } else {
        MyAlert(
          intl.formatMessage(
            { id: 'app.suscribir.file.ext' },
            {
              defaultMessage:
                'Las extensiones permitidas son *.csv o *.xls/xlsx'
            }
          ),
          3
        )
      }
    }
  }

  const handleEdit = (data: any) => {
    setEditId(data)
    setNewName(data.Name)
    setNewDescription(data.Description)
    if (editar) {
      setNewIdEdit(data.Name)
      setEditar(!editar)
    }
    setEditar(!editar)
  }

  const handleEditarName = (e: any) => {
    if (e.target.value.match('^[a-zA-Z0-9_]*$') != null) {
      setNewName(e.target.value.toLowerCase())
    }
  }

  const handleEditarDesc = (e: any) => {
    setNewDescription(e.target.value)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!selectedClient || !selectedScript) {
      return
    }
    if (jsonFileReemplazar.length === 0 && jsonFileAgregar.length === 0) {
      getAudiences()
    }
    if (newAudience === true) {
      getAudiences()
      setNewAudience(false)
    }

    if (jsonFileReemplazar.length > 0) {
      const arrayEmails = jsonFileReemplazar.map(function (es: any[]) {
        return es[0] // el papa.parse, retorna un array dentro de otro array
      })
      const data = {
        SegmentName: dataReemplazar.Name,
        Description: dataReemplazar.Description,
        Email: arrayEmails
      }

      if (data.Email.length > 0) {
        setViewSpinner(true)
        MyAlert(
          intl.formatMessage(
            { id: 'app.suscribir.file.delay' },
            {
              defaultMessage:
                'Espere por favor. El Proceso puede demorar unos segundos según el tamaño del archivo. Si desea puede cerrar esta ventana y recargar la grilla para ver si ya finalizó.'
            }
          ),
          3,
          30000,
          true
        )

        const dataCopy = structuredClone(data)
        const limitSend = 5000

        if (data.Email.length < limitSend) {
          replaceAudiences(data)
          // getAudiences();
        } else {
          let prog1 = 0
          let porc = 0
          for (let i = 0; i <= data.Email.length; i += limitSend) {
            dataCopy.Email = data.Email.slice(i, i + limitSend)
            porc = i + limitSend
            if (i + limitSend > data.Email.length) {
              porc = data.Email.length
            }
            prog1 = Math.round((100 * porc) / data.Email.length)
            if (i === 0) {
              addAudiences(dataCopy, 1, prog1).catch((error) =>
                console.log(error)
              ) // Limpia y agrega nuevos correos
            }
            addAudiences(dataCopy, 0, prog1).catch((error) =>
              console.log(error)
            ) // Solo agrega nuevos correos
          }
        }
        setJsonFileReemplazar([])

        let timeSegs = 6000
        if (data.Email.length >= 10000) {
          timeSegs = 30000
        }
        setTimeout(() => {
          setViewSpinner(false)
          MyAlert('Los correos fueron procesados.', 1)
          getAudiences()
        }, timeSegs)
      } else {
        MyAlert(
          intl.formatMessage(
            { id: 'app.suscribir.file.empty' },
            { defaultMessage: 'El archivo csv no tiene emails o está vacio.' }
          ),
          3
        )
      }
    }

    if (jsonFileAgregar.length > 0) {
      const arrayEmails = jsonFileAgregar.map(function (es: any[]) {
        return es[0] // el papa.parse, retorna un array dentro de otro array
      })
      const data = {
        SegmentName: dataAgregar.Name,
        Description: dataAgregar.Description,
        Email: arrayEmails
      }
      if (data.Email.length > 0) {
        setViewSpinner(true)
        MyAlert(
          intl.formatMessage(
            { id: 'app.suscribir.file.delay' },
            {
              defaultMessage:
                'Espere por favor. El Proceso puede demorar unos segundos según el tamaño del archivo. Si desea puede cerrar esta ventana y recargar la grilla para ver si ya finalizó.'
            }
          ),
          3,
          30000,
          true
        )

        const dataCopy = structuredClone(data)
        const limitSend = 5000

        for (let i = 0; i <= data.Email.length; i += limitSend) {
          dataCopy.Email = data.Email.slice(i, i + limitSend)
          let porc = i + limitSend

          if (i + limitSend > data.Email.length) {
            porc = data.Email.length
          }

          const prog1 = Math.round((100 * porc) / data.Email.length)
          addAudiences(dataCopy, 0, prog1) // Solo agrega nuevos correos
        }

        let timeSegs = 6000
        if (data.Email.length >= 10000) {
          timeSegs = 30000
        }
        setTimeout(() => {
          setJsonFileAgregar([])
          setViewSpinner(false)
          MyAlert('Los correos fueron procesados.', 1)
          getAudiences()
        }, timeSegs)
      } else {
        MyAlert(
          intl.formatMessage(
            { id: 'app.suscribir.file.empty' },
            { defaultMessage: 'El archivo csv no tiene emails o está vacio.' }
          ),
          3
        )
      }
    }
  }, [newAudience, jsonFileAgregar, jsonFileReemplazar, newIdEdit, selectedClient, selectedScript])

  const paginationControl = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const button: HTMLButtonElement = event.currentTarget
    if (
      button.name === 'siguiente' &&
      currentPage < Math.ceil(options.length / optionsPerPage)
    ) {
      setCurrentPage(currentPage + 1)
    }

    if (button.name === 'anterior' && currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const indexOfLastOption = currentPage * optionsPerPage
  const indexOfFirstOption = indexOfLastOption - optionsPerPage
  const currentOptions =
    options !== undefined
      ? options.slice(indexOfFirstOption, indexOfLastOption)
      : []

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleSetOptionsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const number = parseInt(event.target.value)
    setOptionsPerPage(number)
    setCurrentPage(1)
  }

  const hanlderRealoadData = (event: React.MouseEvent<HTMLButtonElement>) => {
    getAudiences()
  }

  return (
    <>
      {viewSpinner ? (
        <div className="spinner_c">
          <Loading
            textLoading={intl.formatMessage(
              { id: 'app.vitrinas-spinner.espere' },
              { defaultMessage: 'Espere...' }
            )}
          />
        </div>
      ) : null}

      {options !== undefined ? (
        <div
          className="automation__wrapper_1"
          style={{ opacity: viewSpinner ? '0.3' : '1' }}
        >
          <div className="row ">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <p className="show-options__text">
                <FormattedMessage
                  id="app.audiencias-tabla.mostrar"
                  defaultMessage="MOSTRAR"
                />
                <select
                  onChange={handleSetOptionsPerPage}
                  className="show-options__selector select_paginas"
                  name="options-per-page"
                  id="options-per-page"
                  value={optionsPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <FormattedMessage
                  id="app.audiencias-tabla.entradas"
                  defaultMessage="ENTRADAS"
                />
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <button
                className="manage-grid-button btn btn-border btn-reload"
                onClick={hanlderRealoadData}
              >
                <BsArrowCounterclockwise size={25} id={'reload-grid'} />
                <FormattedMessage
                  id="app.audiencias-tabla.button-reload"
                  defaultMessage="Actualizar"
                />
              </button>
            </div>
          </div>

          <div>
            <table className="table table-sm table-striped table-bordered">
              <thead>
                <tr>
                  <th
                    style={{ padding: '20px' }}
                    className="text-center"
                    scope="col"
                  >
                    <FormattedMessage
                      id="app.audiencias-tabla.titulo"
                      defaultMessage="TÍTULO DE AUDIENCIA"
                    />
                  </th>
                  <th
                    style={{ padding: '20px' }}
                    className="text-center"
                    scope="col"
                  >
                    <FormattedMessage
                      id="app.audiencias-tabla.totalizador"
                      defaultMessage="TOTALIZADOR"
                    />
                  </th>
                  <th
                    style={{ padding: '20px' }}
                    className="text-center"
                    scope="col"
                  >
                    <FormattedMessage
                      id="app.audiencias-tabla.descripcion"
                      defaultMessage="DESCRIPCIÓN"
                    />
                  </th>
                  <th
                    style={{ padding: '20px' }}
                    className="text-center"
                    scope="col"
                  >
                    <FormattedMessage
                      id="app.audiencias-tabla.acciones"
                      defaultMessage="ACCIONES"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentOptions !== undefined ? (
                  currentOptions.map((option: any) => {
                    return (
                      <tr className="tr_fila" key={option.IdSegment}>
                        {editar && editId.IdSegment === option.IdSegment ? (
                          <>
                            <td>
                              <input
                                type={'text'}
                                className="input automation__input-w100 input vtexskuconsult__input"
                                onChange={handleEditarName}
                                value={newName}
                              />
                            </td>
                            <td>{option.Quantity} </td>
                            <td>
                              <textarea
                                className="input automation__input-w100 input vtexskuconsult__textarea_56"
                                onChange={handleEditarDesc}
                                value={newDescription}
                              />
                            </td>
                            <td>
                              <button
                                className="manage-grid-button"
                                style={{
                                  display: 'inherit',
                                  fontSize: '27px',
                                  margin: '.6rem'
                                }}
                                title={intl.formatMessage(
                                  { id: 'app.audiencias-tabla.title-editar' },
                                  { defaultMessage: 'Editar audiencia' }
                                )}
                              >
                                <BsFillCheckCircleFill
                                  onClick={() => AlertToken(option, 'edit')}
                                  data-tip={intl.formatMessage(
                                    { id: 'app.audiencias-tabla.title-editar' },
                                    { defaultMessage: 'Editar audiencia' }
                                  )}
                                />
                              </button>
                              <ReactTooltip
                                delayShow={10}
                                data-backgroundColor={'#2fad7e'}
                              />
                              <button
                                className="btn btn-default btn-border"
                                onClick={() => setEditar(!editar)}
                                style={{ display: 'inherit' }}
                                title={intl.formatMessage(
                                  { id: 'app.audiencias-tabla.title-cancelar' },
                                  { defaultMessage: 'Cancelar' }
                                )}
                                data-tip={intl.formatMessage(
                                  { id: 'app.audiencias-tabla.title-cancelar' },
                                  { defaultMessage: 'Cancelar' }
                                )}
                              >
                                <FormattedMessage
                                  id="app.audiencias-tabla.title-cancelar"
                                  defaultMessage="Cancelar"
                                />
                                <ReactTooltip
                                  delayShow={10}
                                  data-backgroundColor={'#2fad7e'}
                                />
                              </button>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>{option.Name}</td>
                            <td>{option.EmailCount} </td>
                            <td>{option.Description}</td>
                            <td>
                              <button
                                className="manage-grid-button"
                                style={{
                                  display: 'inherit',
                                  fontSize: '27px',
                                  margin: '.6rem'
                                }}
                                title={intl.formatMessage(
                                  { id: 'app.audiencias-tabla.title-editar' },
                                  { defaultMessage: 'Editar audiencia' }
                                )}
                              >
                                <BsPencilSquare
                                  onClick={() => handleEdit(option)}
                                  data-tip={intl.formatMessage(
                                    { id: 'app.audiencias-tabla.title-editar' },
                                    { defaultMessage: 'Editar audiencia' }
                                  )}
                                />
                              </button>
                              <ReactTooltip
                                delayShow={10}
                                data-backgroundColor={'#2fad7e'}
                              />

                              <button
                                className="manage-grid-button"
                                style={{
                                  display: 'inherit',
                                  fontSize: '27px',
                                  margin: '.6rem'
                                }}
                                title={intl.formatMessage(
                                  {
                                    id: 'app.audiencias-tabla.title-agregar-mails'
                                  },
                                  {
                                    defaultMessage:
                                      'Agregar mails a audiencia existente'
                                  }
                                )}
                              >
                                <BsPlusCircle
                                  onClick={() => handleAgregar(option)}
                                  data-tip={intl.formatMessage(
                                    {
                                      id: 'app.audiencias-tabla.title-agregar-mails'
                                    },
                                    {
                                      defaultMessage:
                                        'Agregar mails a audiencia existente'
                                    }
                                  )}
                                />
                              </button>
                              <ReactTooltip
                                delayShow={10}
                                data-backgroundColor={'#2fad7e'}
                              />
                              <input
                                type={'file'}
                                id={'inputFileAgregar'}
                                style={{ display: 'none' }}
                                // onChange={handleFileAgregar}
                                onChange={() => AlertToken('', 'adds')}
                              />
                              <button
                                className="manage-grid-button"
                                style={{
                                  display: 'inherit',
                                  fontSize: '27px',
                                  margin: '.6rem'
                                }}
                                title={intl.formatMessage(
                                  {
                                    id: 'app.audiencias-tabla.title-reemplazar'
                                  },
                                  {
                                    defaultMessage:
                                      'Reemplazar contenido de audiencia'
                                  }
                                )}
                              >
                                <BsArrowClockwise
                                  onClick={() => handleReemplazar(option)}
                                  data-tip={intl.formatMessage(
                                    {
                                      id: 'app.audiencias-tabla.title-reemplazar'
                                    },
                                    {
                                      defaultMessage:
                                        'Reemplazar contenido de audiencia'
                                    }
                                  )}
                                />
                              </button>
                              <ReactTooltip
                                delayShow={10}
                                data-backgroundColor={'#2fad7e'}
                              />
                              <input
                                type={'file'}
                                id={'inputFileReemplazar'}
                                style={{ display: 'none' }}
                                onChange={() => AlertToken('', 'upgrade')}
                              />
                              <button
                                className="manage-grid-button"
                                style={{
                                  display: 'inherit',
                                  fontSize: '27px',
                                  margin: '.6rem'
                                }}
                                title={intl.formatMessage(
                                  { id: 'app.audiencias-tabla.title-eliminar' },
                                  { defaultMessage: 'Eliminar audiencia' }
                                )}
                              >
                                <BsTrash
                                  onClick={() =>
                                    AlertToken(option.Name, 'delete')
                                  }
                                  data-tip={intl.formatMessage(
                                    {
                                      id: 'app.audiencias-tabla.title-eliminar'
                                    },
                                    { defaultMessage: 'Eliminar audiencia' }
                                  )}
                                />
                              </button>
                              <ReactTooltip
                                delayShow={10}
                                data-backgroundColor={'#2fad7e'}
                              />
                            </td>
                          </>
                        )}
                      </tr>
                    )
                  })
                ) : (
                  <li>
                    <FormattedMessage
                      id="app.audiencias-tabla.no-existen-datos"
                      defaultMessage="No existen datos"
                    />
                  </li>
                )}
              </tbody>
            </table>
          </div>
          <div className="main-pagination-container">
            <div className="pagination-container">
              <h6 className="pagination-container__text">
                {intl.formatMessage(
                  { id: 'app.audiencias-tabla.mostrando' },
                  { defaultMessage: 'Mostrando' }
                ) +
                  ` ${currentOptions.length} ` +
                  intl.formatMessage(
                    { id: 'app.audiencias-tabla.de' },
                    { defaultMessage: 'De' }
                  ) +
                  `  ${options.length}`}
              </h6>
            </div>
            <div className="pagination-container">
              <button
                className="pagination-control"
                name="siguiente"
                onClick={paginationControl}
              >
                <h6 className="pagination-container__text">
                  <FormattedMessage
                    id="app.audiencias-tabla.siguiente"
                    defaultMessage="Siguiente"
                  />
                </h6>
              </button>
              <Pagination
                optionsPerPage={optionsPerPage}
                totalOptions={options.length}
                paginate={paginate}
                currentPage={currentPage}
              />
              <button
                className="pagination-control"
                name="anterior"
                onClick={paginationControl}
              >
                <h6 className="pagination-container__text">
                  <FormattedMessage
                    id="app.audiencias-tabla.anterior"
                    defaultMessage="Anterior"
                  />
                </h6>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="automation__wrapper_1"
          style={{ opacity: viewSpinner ? '0.3' : '1' }}
        >
          <FormattedMessage
            id="app.vitrinas.nodata"
            defaultMessage="Sin Resultados."
          />
        </div>
      )}
    </>
  )
}

export default AudiencesTable
