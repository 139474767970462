import { LANGUAGE_SELECTED } from '../constants/langConstants'

const defaultState: Lang = {
  lang: 'es'
}

export const langSelectedReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case LANGUAGE_SELECTED:
      return {
        lang: action.payload
      }
    default:
      return state
  }
}
