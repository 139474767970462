import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import store, { RootState } from '../store'
import { useGauth } from '../../hooks/useUsers'
import { useTooltips } from '../../hooks/useTooltips'
import { ShowAlert } from '../../components/Globals'
import {
  USER_AUTH_FAIL,
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS
} from '../constants/authConstants'
import { alertAuth } from '../../utils/alertLanguage'

export const gAuth =
  (
    data: GoogleAuth
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      dispatch({
        type: USER_AUTH_REQUEST,
        loading: false
      })

      const initLang = store.getState().langSelected

      const getData = await useGauth(data)
      const alertResponse = alertAuth(initLang)

      if ((getData?.isAuth) === true) {        
        const getDataTooltips = await useTooltips(getData?.data?.token)
        dispatch({
          type: USER_AUTH_SUCCESS,
          payload: {
            token: getData?.data?.token,
            Platform: getData?.data?.Platform,
            isActiveAppMshop: getData?.data?.isActiveAppMshop,
            disabledAppBy: getData?.data?.disabledAppBy,
            RemainingDaysTrial: getData?.data?.RemainingDaysTrial,
            username: getData?.data?.username,
            userType: getData?.data?.userType,
            email: getData?.data?.email,
            name: getData?.data?.name,
            lastname: getData?.data?.lastname,
            IdDashboardUser: getData?.data?.IdDashboardUser,
            active: getData?.data?.active,
            photo: getData?.data?.photo,
            tooltips: getDataTooltips,
            last_login: {
              hour: new Date().getHours(),
              date: new Date().getDate()
            }
          }
        })

        await ShowAlert({
          title: `${alertResponse.titleResponse}`,
          text: `${alertResponse.textRedirectResponse}`,
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        ShowAlert({
          title: 'Oopss....!!!',
          text: `${alertResponse.errorTextResponse}. Revise que el token sea correcto.`,
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
        dispatch({
          type: USER_AUTH_FAIL,
          payload: {
            error: 'Authentication unsuccessful'
          }
        })
      }
    } catch (error: any) {
      dispatch({
        type: USER_AUTH_FAIL,
        payload:
          error.response.data.message !== ''
            ? error.response.data.message
            : error.message
      })
    }
  }
