import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { RootState } from '../store'
import {
  LANGUAGE_SELECTED,
  LANGUAGE_SELECTED_ERROR
} from '../constants/langConstants'

export const langSelected =
  (data: any): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const langData = data

      dispatch({
        type: LANGUAGE_SELECTED,
        payload: langData
      })
    } catch (error: any) {
      dispatch({
        type: LANGUAGE_SELECTED_ERROR,
        payload:
          error.response.data.message !== ''
            ? error.response.data.message
            : error.message
      })
    }
  }
