import React, { useState } from 'react'
import { BsPencilSquare, BsPersonPlus, BsPersonSlash } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'
import UsersGrid from './UsersGrid'
import UsersForm from './UsersForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'

import './system.css'

const UsersContainer = () => {
  const [idUser, setIdUser] = useState<any>('')
  const [createUser, setCreateUser] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<boolean>(false)
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const togglecreateUser = () => {
    setCreateUser(!createUser)
  }
  const toggleeditUser = () => {
    setEditUser(!editUser)
  }

  return (
    <div
      className="container mg-t-30 container table-bordered "
      style={{ display: 'block' }}
    >
      <div className="related_categories__title-container">
        <div className="rule-config__title-icon">
          <BsPencilSquare />
        </div>
        <h1 className="rule-config__title-text">
          <FormattedMessage
            id="app.submenu.user"
            defaultMessage="Usuarios"
          />
        </h1>
      </div>
      
        <div>
          {user.data.userType == 1 || user.data.userType == 9 ? 
            (
              createUser || editUser ?
              ( null )
              :
              (
                <button
                  className="ppal_button btn btn-primary"
                  id="add-new-article"
                  disabled={user.data.userType != 9 && user.data.userType != 1}
                  onClick={() => {
                    togglecreateUser()
                  }}
                >
                  <BsPersonPlus className="m-rig" width={20} />
                  <FormattedMessage
                    id="app.system.user.panel.table.add-user"
                    defaultMessage="Agregar Usuario"
                  />
                </button>
              )
            ) : null
          }
        </div>
        {
          user.data.userType == 1 || user.data.userType == 9 ? 
          (
            createUser || editUser ? (
              <UsersForm 
                create={createUser}
                edit={editUser}
                back={createUser ? togglecreateUser : toggleeditUser}
                idUser={idUser}
              />        
            ) : (
              <UsersGrid
                toggleeditUser={toggleeditUser}          
                setIdUser={setIdUser}          
              />
            )
          ) : (
            <div className="container mg-t-30">              
              <p>
                <BsPersonSlash 
                  size={25}
                  color='orange'/> &nbsp;
                No tiene los <strong>permisos</strong> para ver el listado
              </p>
            </div>
          )
        }
      
    </div>
  )
}

export default UsersContainer
