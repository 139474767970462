import React from 'react'
import MenuContainer from '../../Menu/MenuContainer'
import MenuOptions from '../../Menu/MenuOptions'
import {
  // systemSubMenuOptions,
  systemSubMenuOptionsForUsers
} from '../../../utils/secondMenuOptions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'

const SubMenuMenuSystem = () => {
  const user = useSelector<RootState, any>((state: any) => state.userAuth)

  const handleAction = () => {
    console.log()
  }

  return (
    <>
      <MenuContainer>
        <MenuOptions
          tag="BrainDW | System"
          options={
            user.data.userType === 1
              ? systemSubMenuOptionsForUsers
              : systemSubMenuOptionsForUsers.filter(
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
                  (op) => op.protected === false
                )
          }
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default SubMenuMenuSystem
