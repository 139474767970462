/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { FieldSelectorProps } from 'react-querybuilder'

export const AttributeGroup = ({
  className,
  handleOnChange,
  options,
  title,
  value,
  context
}: FieldSelectorProps) => {
  return (
    <select
      className={className}
      value={value}
      title={title}
      onChange={(e) => handleOnChange(e.target.value)}
    >
      {context.fieldOptions.map((optGroup: any, key: any) => (
        <optgroup key={key} label={optGroup.name}>
          {optGroup.options.map((option: any, key: any) => {
            return (
              <option key={key} value={option.name}>
                {option.label}
              </option>
            )
          })}
        </optgroup>
      ))}
    </select>
  )
}
