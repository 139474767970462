import {
  CREATE_AUDIENCES_REQUEST,
  CREATE_AUDIENCES_SUCCESS,
  CREATE_AUDIENCES_FAIL
} from '../constants/createAudiences'

const defaultState: StateResponse<AudiencesInfo> = {
  loading: false,
  error: undefined,
  data: undefined
}

export const createAudiencesReducer = (
  state = defaultState,
  action: Action
) => {
  switch (action.type) {
    case CREATE_AUDIENCES_REQUEST:
      return {
        loading: true
      }
    case CREATE_AUDIENCES_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case CREATE_AUDIENCES_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
