import Service from '../services'

const useTooltips = async (token: any) => {
   
  const respTools = await Service.get<any>(
                    `/Panel/GetTooltipsPanel?module=`,
                    { headers: { token } }
                  )
    if (respTools.headers.statuscode === '0') {          
      if (respTools.data.Entities.length > 0) {
        return respTools.data.Entities
      }        
    } 

}

export { useTooltips}
