import React from 'react'
import { MdOutlineHouse, MdSavedSearch, BsGearWideConnected } from './icons'
import '../components/Menu/style.css'
import { FormattedMessage } from 'react-intl'

const _class = 'bdw-menu-icon'

export const mainMenuOptionsAdmin = [
  {
    id: 0,
    name: <FormattedMessage id="app.menu.home-bdw" defaultMessage="Inicio" />,
    icon: <MdOutlineHouse className={_class} />,
    link: 'homebdw',
    protected: false
  },
  {
    id: 1,
    name: <FormattedMessage id="app.menu.home" defaultMessage="Inicio" />,
    icon: <MdOutlineHouse className={_class} />,
    link: 'home/manage-carrousel',
    protected: true
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.menu.search"
        defaultMessage="Prioridad de Marca"
      />
    ),
    icon: <MdSavedSearch className={_class} />,
    link: 'search/brand-priority-search',
    protected: true
  },
  {
    id: 7,
    name: <FormattedMessage id="app.menu.cdp" defaultMessage="CDP" />,
    icon: <BsGearWideConnected className={_class} />,
    link: 'cdp/create-audience',
    protected: true
  },
  {
    id: 8,
    name: (
      <FormattedMessage
        id="app.menu.smart-images"
        defaultMessage="BANNERS INTELIGENTES"
      />
    ),
    icon: <MdOutlineHouse className={_class} />,
    link: 'smart-images/smart-images-list',
    protected: false
  },

  {
    id: 17,
    name: (
      <FormattedMessage
        id="app.submenu.articles-panel"
        defaultMessage="Articulos del panel"
      />
    ),
    icon: <MdOutlineHouse className={_class} />,
    link: 'articles-table-panel',
    protected: true
  },
  {
    id: 18,
    name: <FormattedMessage id="app.submenu.mshop" defaultMessage="Mshop" />,
    icon: <MdOutlineHouse className={_class} />,
    link: 'mshop-menu/mshop-carrusel',
    protected: false
  },
  {
    id: 10,
    name: (
      <FormattedMessage
        id="app.submenu.campain-panel"
        defaultMessage="Campañas"
      />
    ),
    icon: <MdOutlineHouse className={_class} />,
    link: 'campain/analytics',
    protected: false
  },
  {
    id: 11,
    name: <FormattedMessage id="app.menu.system" defaultMessage="Sistema" />,
    icon: <BsGearWideConnected className={_class} />,
    link: 'system/user-profile',
    protected: false
  }
]

export const mainMenuOptions = [
  {
    id: 0,
    name: <FormattedMessage id="app.menu.home-bdw" defaultMessage="Inicio" />,
    icon: <MdOutlineHouse className={_class} />,
    link: 'homebdw',
    protected: false
  },
  {
    id: 1,
    name: <FormattedMessage id="app.menu.home" defaultMessage="Inicio" />,
    icon: <MdOutlineHouse className={_class} />,
    link: 'home/manage-carrousel',
    protected: true
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.menu.search"
        defaultMessage="Prioridad de Marca"
      />
    ),
    icon: <MdSavedSearch className={_class} />,
    link: 'search/brand-priority-search',
    protected: true
  },
  {
    id: 7,
    name: <FormattedMessage id="app.menu.cdp" defaultMessage="CDP" />,
    icon: <BsGearWideConnected className={_class} />,
    link: 'cdp/create-audience',
    protected: true
  },
  {
    id: 8,
    name: (
      <FormattedMessage
        id="app.menu.smart-images"
        defaultMessage="BANNERS INTELIGENTES"
      />
    ),
    icon: <MdOutlineHouse className={_class} />,
    link: 'smart-images/smart-images-list',
    protected: false
  },

  {
    id: 17,
    name: (
      <FormattedMessage
        id="app.submenu.articles-panel"
        defaultMessage="Articulos del panel"
      />
    ),
    icon: <MdOutlineHouse className={_class} />,
    link: 'articles-table-panel',
    protected: true
  },
  // {
  //   id: 18,
  //   name: <FormattedMessage id="app.submenu.mshop" defaultMessage="Mshop" />,
  //   icon: <MdOutlineHouse className={_class} />,
  //   link: 'mshop-menu/mshop-carrusel',
  //   protected: false
  // },
  {
    id: 10,
    name: (
      <FormattedMessage
        id="app.submenu.campain-panel"
        defaultMessage="Campañas"
      />
    ),
    icon: <MdOutlineHouse className={_class} />,
    link: 'campain/analytics',
    protected: false
  },
  {
    id: 11,
    name: <FormattedMessage id="app.menu.system" defaultMessage="Sistema" />,
    icon: <BsGearWideConnected className={_class} />,
    link: 'system/user-profile',
    protected: false
  }
]
