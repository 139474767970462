import React from 'react'
import './style_mshop.css'
import logo from '../../img/Brain-Marca.png'

const MshopInactiveLanding: React.FC = () => {
  return (
    <div className="ms_has-animations">
      <div className="ms_body-wrap">
        <header className="ms_site-header">
          <div className="landing_ms_container"></div>
        </header>

        <main>
          <section className="ms_features section">
            <div className="landing_ms_container">
              <div className="ms_section-inner ">
                <div className="ms_feature-icon" style={{ marginBottom: 80 }}>
                  <img src={logo} alt="Feature 03" />
                </div>
                <h2 style={{ color: '#00868B', fontSize: 35, fontWeight: 600 }}>
                  Has desactivado BrainDW
                </h2>
                <div className="ms_cta-cta">
                  <p style={{ color: '#666666', fontSize: 18 }}>
                    La aplicación ha sido desactivada.
                  </p>
                  <p style={{ color: '#666666', fontSize: 18 }}>
                    Para acceder al panel de control deberás volver a activarla
                    desde aquí.
                  </p>
                  <a
                    className="ms_button ms_button-primary ms_button-wide-mobile mr-2"
                    href={`https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=7605895153273357&redirect_uri=${process.env.REACT_APP_BASE_PATH_FRONT}/mshopthankspage`}
                  >
                    ¡ACTIVAR AHORA!
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>

        <footer className="ms_site-footer">
          <div className="landing_ms_container">
            <div className="ms_site-footer-inner">
              <div className="landing_ms_brand footer-brand">
                <a href="#">
                  <img
                    className="ms_header-logo-image mb-4"
                    src={logo}
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="ms_footer-copyright">
                &copy; 2024 BrainDW, todos los derechos reservados.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default MshopInactiveLanding
