/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import Service from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../state/actions'
import { ShowAlert } from '../../Globals'
import { RootState } from '../../../state/store'
import { config } from '../../../config'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useForm } from 'react-hook-form'
import { BsPersonCircle } from 'react-icons/bs'

import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const UsersForm = ({ create, edit, back, idUser }: any) => {
  const [userdata, setUser] = useState<any>('')  
  const [enabled, setEnabled] = useState<any>(0)

  const dispatch = useDispatch()

  const intl = useIntl()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const [tokenValue, setTokenValue] = useState<string>('')
  
  const onSubmit = (data: FormData) => {
    console.log(data)
    if(create == true){
      data.IdDashboardUser = 0
    }
    if(edit == true){
      data.IdDashboardUser = userdata.IdDashboardUser
    }
    AlertToken(data)
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.user' },
        { defaultMessage: 'Usuarios' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: !isToast
    })
  }

  const AlertToken = (data: FormData) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
        inputLabel: '',
        inputPlaceholder: 'Token',
        color: '#1c684c',
        inputValue: tokenValue,
        confirmButtonText: intl.formatMessage(
          { id: 'app.modal.btn.enviar' },
          { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        setTokenValue(result.value)
        SaveUserData(result.value, data)
      }
    })
  }

  const getOneUserdata = (idUser: string) => {
    Service.get<any>(`/Account/GetDataUserById?idHashScript=${selectedScript?.idHashScript}&idUser=${idUser}`,       
      {
      headers: {
        token: user.data.token
      }
    })
      .then((response) => {
        if (response.headers.statuscode === '0') {
          if (response.data.Entity != null) {
            setUser(response.data.Entity)
          }          
          setEnabled(response.data.Entity.active)
          setEnabled(response.data.Entity.active)

          document.getElementById('in_name')?.focus()
          document.getElementById('in_lastname')?.focus()
          document.getElementById('in_username')?.focus()
          document.getElementById('in_email')?.focus()
          
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const SaveUserData = (tokenGoogle: string, data: FormData) => {    
    Service.post<any, any>(
      `/Account/CreateUserPanel?idHashScript=${selectedScript?.idHashScript}`,
      data,
      {
        method: 'POST',        
        headers: {
          token: user.data.token,          
          tokenGoogle,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ), 3)
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),2 )
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.system.user.panel.form.save.ok' },
              { defaultMessage: 'Se registro el usuario de forma correcta.' }
            ), 1 )
          back()
          setTokenValue('')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else if (error.response.data === -5) {
          MyAlert(
            intl.formatMessage(
              { id: 'app.token-invalido' },
              { defaultMessage: 'El token ingresado es inválido.' }
            ), 3 )
        }else{
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  interface FormData {
    IdDashboardUser: number
    name: string
    lastname: string
    email: string
    username: string
    password: string
    photo: string
    active: boolean
    usrTyp: boolean
  }
  
  const formSchemaCreate = yup.object().shape({
      username: yup
        .string()
        .min(6, intl.formatMessage({id: 'app.system.user.create.msg.mintext'}
                ,{ defaultMessage: 'El texto es demasiado corto' }))
        .max(50, intl.formatMessage({id: 'app.system.user.create.msg.maxtext'}
                ,{ defaultMessage: 'El texto es demasiado largo' }))
        .required(intl.formatMessage({id: 'app.system.user.create.msg.empty'}
                ,{ defaultMessage: 'Este dato es requerido' }))
        .matches(
          /^[a-zA-Z0-9_]*$/g,
          intl.formatMessage({id: 'app.system.user.panel.form.format.error'}
                ,{ defaultMessage: 'El formato no es correcto' })
        ),
      name: yup
        .string()
        .min(2, intl.formatMessage({id: 'app.system.user.create.msg.mintext'}
                ,{ defaultMessage: 'El texto es demasiado corto' }))
        .max(50, intl.formatMessage({id: 'app.system.user.create.msg.maxtext'}
                ,{ defaultMessage: 'El texto es demasiado largo' }))
        .required(intl.formatMessage({id: 'app.system.user.create.msg.empty'}
                ,{ defaultMessage: 'Este dato es requerido' })),
      lastname: yup
        .string()
        .min(2, intl.formatMessage({id: 'app.system.user.create.msg.mintext'}
                ,{ defaultMessage: 'El texto es demasiado corto' }))
        .max(50, intl.formatMessage({id: 'app.system.user.create.msg.maxtext'}
                ,{ defaultMessage: 'El texto es demasiado largo' }))
        .required(intl.formatMessage({id: 'app.system.user.create.msg.empty'}
                ,{ defaultMessage: 'Este dato es requerido' })),
      email: yup
        .string()
        .email()
        .required(intl.formatMessage({id: 'app.system.user.create.msg.email'}
                ,{ defaultMessage: 'El email es requerido' })),
      password: yup
        .string()
        .required(
          intl.formatMessage({id: 'app.system.user.changepass.msg.passrequired'}
                ,{ defaultMessage: 'La contraseña nueva es obligatoria' })
        )
        .min(4,
          intl.formatMessage({id: 'app.system.user.changepass.msg.minpass'},
            { defaultMessage: 'La contraseña debe tener al menos 4 caracteres' }
          )
        )
    })
    
  const  formSchemaEdit = yup.object().shape({
    username: yup
      .string()
      .min(6, intl.formatMessage({id: 'app.system.user.create.msg.mintext'}
              ,{ defaultMessage: 'El texto es demasiado corto' }))
      .max(50, intl.formatMessage({id: 'app.system.user.create.msg.maxtext'}
              ,{ defaultMessage: 'El texto es demasiado largo' }))
      .required(intl.formatMessage({id: 'app.system.user.create.msg.empty'}
              ,{ defaultMessage: 'Este dato es requerido' }))
      .matches(
        /^[a-zA-Z0-9_]*$/g,
        intl.formatMessage({id: 'app.system.user.panel.form.format.error'}
                ,{ defaultMessage: 'El formato no es correcto' })
      ),
    name: yup
      .string()
      .min(2, intl.formatMessage({id: 'app.system.user.create.msg.mintext'}
              ,{ defaultMessage: 'El texto es demasiado corto' }))
      .max(50, intl.formatMessage({id: 'app.system.user.create.msg.maxtext'}
              ,{ defaultMessage: 'El texto es demasiado largo' }))
      .required(intl.formatMessage({id: 'app.system.user.create.msg.empty'}
              ,{ defaultMessage: 'Este dato es requerido' })),
    lastname: yup
      .string()
      .min(2, intl.formatMessage({id: 'app.system.user.create.msg.mintext'}
              ,{ defaultMessage: 'El texto es demasiado corto' }))
      .max(50, intl.formatMessage({id: 'app.system.user.create.msg.maxtext'}
              ,{ defaultMessage: 'El texto es demasiado largo' }))
      .required(intl.formatMessage({id: 'app.system.user.create.msg.empty'}
              ,{ defaultMessage: 'Este dato es requerido' })),
    email: yup
      .string()
      .email()
      .required(intl.formatMessage({id: 'app.system.user.create.msg.email'}
              ,{ defaultMessage: 'El email es requerido' }))
  })
  
  const { 
    register, 
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: 
      yupResolver(create==true?formSchemaCreate:formSchemaEdit)
  })

  const defaultValues = {
    IdDashboardUser: userdata?.IdDashboardUser ?? 0,
    username: userdata?.username ?? '',
    password: userdata?.password ?? '',
    name: userdata?.name ?? '',
    lastname: userdata?.lastname ?? '',
    email: userdata?.email ?? '',      
    photo: userdata?.photo ?? '',
    active: userdata?.active ?? false,
    usrTyp: userdata?.userType == 9 ? true: false    
  } 

  useEffect(() => {    
    if (create == false){
       getOneUserdata(idUser)       
      }
  }, [create, edit])

  useEffect(() => {
    
  }, [ enabled])
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="rule-config__wrapper sombra_caja">

          <div className="rule-config__title-container row">
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
            <div className="automation__wrapper">
              <div className="row">

                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system.user.panel.table.name"
                          defaultMessage="Nombre"
                        />
                        {' '}<span className="small text-danger">*</span>
                      </label>                      
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    <>
                                        
                      <input
                        className="input vtexskuconsult__input col-lg-10 col-md-10 col-sm-10 col-xs-10"                      
                        {...register('name')}
                        defaultValue={userdata?.name ?? ''}
                        // onChange={(e: any) => setDataName(e.target.value, userdata)}
                        type="text"
                        id="in_name"
                      />
                      {
                        <div className="error-form-bdw">
                          {errors?.name?.message}
                        </div>
                      }
                      <input                        
                        type="hidden"                        
                        {...register('IdDashboardUser')}
                        defaultValue={userdata?.IdDashboardUser ?? 0}
                      />                        
                    </>
                  </div>
                </div>

                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system.user.panel.table.lastname"
                          defaultMessage="Apellido"
                        />
                        {' '}<span className="small text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    <input
                      className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                      {...register('lastname')}                      
                      defaultValue={userdata?.lastname ?? ''}                      
                      type="text"
                      id="in_lastname"
                    />
                      {
                          <div className="error-form-bdw">
                            {errors?.lastname?.message}
                          </div>
                      }
                  </div>
                </div>

                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system..profile-form.username"
                          defaultMessage="Usuario"
                        />
                        {' '}<span className="small text-danger">*</span>
                        <TooltipPanel 
                            ObjectTooltip={user.data.tooltips.filter((
                              (t:any) => t.idTooltip === 'app.form.profile.field.user.tooltip')
                            )[0]}
                          />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    <input
                      className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"                      
                      {...register('username')}
                      defaultValue={userdata?.username ?? ''}
                      type="text"
                      id="in_username"
                    />
                      {
                          <div className="error-form-bdw">
                            {errors?.username?.message}
                          </div>
                      }
                  </div>
                </div>

                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.cdp.audience.form.export-fields.Email"
                          defaultMessage="Email"
                        />
                        {' '}<span className="small text-danger">*</span>
                        <TooltipPanel 
                            ObjectTooltip={user.data.tooltips.filter((
                              (t:any) => t.idTooltip === 'app.form.profile.field.email.tooltip')
                            )[0]}
                          />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    <input
                      className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"                      
                      {...register('email')}
                      defaultValue={userdata?.email ?? ''}
                      type="text"
                      id="in_email"
                      // onChange={(e: any) => setLinkUrlImage(e.target.value)}                      
                    />
                      {
                          <div className="error-form-bdw">
                            {errors?.email?.message}
                          </div>
                      }
                  </div>
                </div>

                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.login.password"
                          defaultMessage="Contraseña"
                        />
                        {' '}<span className="small text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    
                    <input
                      className={edit == false
                        ? "input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        : "input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10 disabled"}
                      // value={userdata?.password ?? ''}
                      {...register('password')}
                      defaultValue={userdata?.password ?? ''}
                      disabled={edit}
                      type="text"
                    />
                      {
                        <div className="error-form-bdw">
                          {errors?.password?.message}
                        </div>
                      }
                  </div>
                </div>
                
                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system.articles-panel.enabled"
                          defaultMessage="Activado"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">                    
                    { user.data.userType == 9 || user.data.userType == 1? 
                    (
                      <label className="switch">
                        <input
                          type="checkbox"
                          {...register('active')}
                          key={"checkbox2"}                        
                          defaultChecked={userdata?.active === 0 ? false : true}                        
                        />
                        <span className="slider round"></span>
                      </label>
                      ): (
                        <span className="badge badge-dark">{userdata?.active === 0 ? 'Inactivo' : 'Activo'}</span>
                      )
                    }
                  </div>
                </div>

                { user.data.userType == 9 || user.data.userType == 1 ? (
                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <div className="shelves__endpoint-selector-container">
                        <label className="shelves__endpoint-selector-text mb-2">
                          Activar rol <strong>Admin </strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">                                        
                      <label className="switch">
                          <input                          
                            type="checkbox"
                            {...register('usrTyp')}
                            key={"checkbox3"}
                            // checked={(userdata?.userType === 9 || userdata?.userType === 1) ? true : false}
                            defaultChecked={(userdata?.userType === 9 || userdata?.userType === 1) ? true : false}
                          />
                          <span className="slider round"></span>
                        </label>
                    </div>
                  </div>
                ): null 
                }

                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system.user.panel.table.image"
                          defaultMessage="Foto de perfil"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    { userdata?.photo != "" && userdata?.photo != null 
                      ? 
                      <img src={userdata?.photo} style={{ width: '200px', border: '1px solid #000' }} className={'border-round rounded-circle'}/>
                      :
                      <BsPersonCircle size={35} />
                    }                    
                  </div>
                </div>

                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system.user.panel.table.dateAdded"
                          defaultMessage="Fecha de Alta"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                    <label>
                      {userdata?.dateAdded != null && userdata?.dateAdded != "0001-01-01T00:00:00"? userdata?.dateAdded.replace('T', ' ') : ''}
                    </label>
                    <label>
                      <span className="badge badge-dark mr-2">
                        {
                          create == true 
                          ? 'USER' 
                          : userdata?.userType == 2 
                            ? 'USER' 
                            : 'ADMIN'
                        }                        
                      </span>
                    </label>
                    
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        
        <div className="row shelves button botonera">

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
          <div className="shelves__preview-button">
            <div className="shelves__preview-button">
              <button                
                className="ppal_button btn btn-primary"
                id="saveBrandPriority"
                disabled={user.data.userType != 9 && user.data.userType != 1}
                type="submit"
              >
                <FormattedMessage
                  id="app.related-categories.add-cross-selling.guardar"
                  defaultMessage="GUARDAR"
                />
              </button>
              <div className="shelves__preview-button">
                {Boolean(create) || Boolean(edit) ? (
                  <button
                    id={'btn-regresar'}
                    className="manage-grid-button shelves__title-icon"
                    onClick={() => {
                      back()
                    }}
                  >
                    <FormattedMessage
                      id="app.related-categories.add-cross-selling.volver"
                      defaultMessage="VOLVER"
                    />
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      </form>
    </>
  )
}

export default UsersForm
