import React from 'react'

const RefreshMetadata: React.FC<{
  title: string
  isActive: boolean  
}> = ({ title }) => {
  return (
    <div className="rule-config-subComponents__container hiden-rule">
      <div className="row a-center hiden-rule">
        <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal hiden-rule">
          {title}
        </h6>
        <select
          className="col-lg-8 col-md-8 col-sm-8 col-xs-8 ancho-alto-select hiden-rule"
          id="refreshMetaData"
          style={{ width: '64%' }}
        >
          <option value="false">No</option>
          <option value="true" selected>
            Yes
          </option>
        </select>
      </div>
    </div>
  )
}

export default RefreshMetadata
