/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gAuth } from '../../state/actions/authAction'
import { useNavigate } from 'react-router-dom'
import { AiOutlineArrowRight, BsCheck2Square } from '../../utils/icons'
import { RootState } from '../../state/store'

const Auth = (props: any) => {
  /* eslint-enable no-debugger */
  // debugger
  const [data, setData] = useState<GoogleAuth>({
    token: '',
    username: props.loginInfo.username,
    password: props.loginInfo.password
    // Platform: props.logInfo.Platform
  })

  const userAuth = useSelector<RootState, StateResponse<UserLoggedIn>>(
    (state: RootState) => state.userAuth
  )

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'BrainDW | Authenticator'
  }, [])

  useEffect(() => {
    if (userAuth.loading == true && userAuth.data !== undefined) {
      localStorage.removeItem('clientSelected')
      localStorage.removeItem('scriptSelected')
      localStorage.removeItem('platformSelected')
      if (userAuth.data.Platform === 'MercadoShop') {
        if (userAuth.data.isActiveAppMshop) {
          return navigate('/dashboardmshop/carruselesmshop')
        }
        return navigate('/dashboardmshop/mshop-disabled')
      } else {
        return navigate('/dashboard/homebdw')
      }
    }
  }, [userAuth])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const handleClick = (event: any) => {
    event.preventDefault()
    dispatch(gAuth(data))
  }

  return (
    <>
      <form onSubmit={handleClick}>
        <div className="input-group-bdw">
          <span className="input-group-addon nk-ic-st-pro">
            <BsCheck2Square className="bdw-icon-user" />
          </span>
          <div className="nk-int-st">
            <input
              name="token"
              value={data.token}
              type="text"
              inputMode="numeric"
              className="form-control"
              placeholder="Token"
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-login btn-success btn-float">
          <AiOutlineArrowRight className="bdw-icon-arrow" color="#6529A1" />
        </button>
      </form>
    </>
  )
}

export default Auth
