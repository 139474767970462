/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import {  
  BsFillTrashFill  
} from 'react-icons/bs'
import { TooltipPanel } from '../../../Globals/TooltipPanel'

const Select2: React.FC<{
  selectedIndex: string; 
  arrayCategoriesForSelect: any;
  loadingCategories: boolean;
  fncOnChangeCategory: any;
  key: any;
  }> = ({
      selectedIndex,
      arrayCategoriesForSelect,
      loadingCategories,
      fncOnChangeCategory,
      key
}) => {

  const intl = useIntl() 
  const user = useSelector<RootState, any>((state) => state.userAuth)

  return (
    <>
      <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container ">
        <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 jc-flexend">
          <div className="shelves__endpoint-selector-container">
            <label className="shelves__endpoint-selector-text mb-2">
              <FormattedMessage
                id="app.vitrinas-seleccione-only"
                defaultMessage="Seleccione"
              />
              <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.form.audiencias.asociar.tooltip')
                  )[0]}
                />
            </label>
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
        {arrayCategoriesForSelect.length > 0 ? (
          <Select
            className="basic-single box-select-endpoint"
            classNamePrefix="select"
            // defaultValue={              arrayCategoriesForSelect[selectedIndex]            }
            isDisabled={false}
            isLoading={loadingCategories}            
            // isOptionDisabled={(option) => option.disabled === 'si' }            
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            name={"categoriaPlpSlt_"}
            id={"categoriaPlpSlt_"}
            options={arrayCategoriesForSelect}
            placeholder={intl.formatMessage(
              { id: 'app.vitrinas-seleccione-categoria' },
              { defaultMessage: 'Seleccione categoría' }
            )}
            onChange={fncOnChangeCategory}
            key={key}            
          />
        ) : (
          <Spinner animation="border" size="sm" />
        )}
        </div>        
      </div>
    </>
  )
}

export default Select2