/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import DataTable, { Alignment } from 'react-data-table-component'
import { BsEraser, BsSearch, BsZoomIn } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import { Loading } from '../../Globals/Loading'

import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const ExportLogsTable = ({ dateFromFilter, dateToFilter, filter }: any) => {
  const [logs, setLogs] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalRows, setTotalRows] = useState(0)
  const [totalRowsAux, setTotalRowsAux] = useState(0)
  const [perPage, setPerPage] = useState(100)
  const [filterText, setFilterText] = useState<any>('')
  const [filterTextAux, setFilterTextAux] = useState<any>('')
  const [sortField, setSortField] = useState<any>('id')
  const [sortDirection, setSortDirection] = useState<any>('desc')
  const [loading, setLoading] = useState(false)
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false)

  const token = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const dispatch = useDispatch()
  const intl = useIntl()

  const getDataReload = async (
    page: any,
    textSearch: string,
    sortFie: string,
    sortDir: string
  ) => {
    setLoading(true)
    Service.get<any>(
      `ScriptClient/GetLogsByClient?idHashScript=${
        selectedScript?.idHashScript
      }&page=${page}&per_page=${perPage}&filterText=${filterText.toLowerCase()}&sortField=${sortFie}&sortDirection=${sortDir}&dateFrom=${dateFromFilter}&dateTo=${dateToFilter}`,
      {
        headers: {
          token: token.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          if (response.data.Entities.length > 0) {
            setLogs(response.data.Entities)
          }
          setTotalRows(parseInt(response.data.Message))
          setTotalRowsAux(parseInt(response.data.Message))
          setCurrentPage(page)
          setLoading(false)
        } else {
          setLogs('')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        }
      })
  }

  const zoomLog = (log: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.logs' },
        { defaultMessage: 'Logs de Actividades' }
      ),
      html:
        '<textarea disabled style="height:300px; width: -webkit-fill-available">' +
        log +
        '</textarea>',
      color: '#1c684c',
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 900
    })
  }

  const TextField = styled.input`
    width: 25%;
    border: none;
    background: none;
    border-bottom: 2px solid #ccc !important;
    margin: 0px 0px 20px 0px;
    height: 32px;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `

  const ClearButton = styled.button`
    border: none;
    height: 38px;
    width: 38px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  `

  const customStyles = {
    header: {
      style: {
        fontSize: '14px',
        color: '#000',
        backgroundColor: 'transparent',
        minHeight: '56px',
        paddingLeft: '16px',
        paddingRight: '8px'
      }
    },
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px !important', // override the cell padding for head cells
        paddingRight: '20px !important'
      },
      draggingStyle: {
        cursor: 'move'
      }
    },
    cells: {
      style: {
        margingLeft: '10px', // override the cell padding for data cells
        margingRight: '10px',
        wordBreak: undefined,
        overflow: 'wrap'
      },
      draggingStyle: {},
      class: 'text-center'
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      '&:nth-of-type(n)': {
        color: '#fff',
        backgroundColor: '#666',
        borderBottomColor: '#ccc'
      }
    },
    highlightOnHoverStyle: {
      color: '#dfdfdf',
      backgroundColor: '#eee',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: '#000',
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: '#efefef'
    },
    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    pagination: {
      style: {
        color: '#000',
        fontSize: '14px',
        minHeight: '56px',
        backgroundColor: 'transparent',
        // borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#ddd'
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '5px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#fff',
        // fill: theme.button.default,
        backgroundColor: '#e9ecef',
        '&:disabled': {
          cursor: 'unset',
          color: '#2fad7e'
          // fill: theme.button.disabled,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: '#2fad7e',
          color: '#fff'
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#fff'
        }
      }
    }
  }

  const handlerSetChangePerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLoading(true)
    setPerPage(parseInt(event.target.value))
    setCurrentPage(1)
    setFilterText('')

    const selc = document.querySelectorAll('select[aria-label="MOSTRANDO"]').length > 0 
      ? document.querySelectorAll('select[aria-label="MOSTRANDO"]')
      : document.querySelectorAll('select[aria-label="SHOW"]')
    const select = document.getElementById(
      'options-per-page'
    ) as HTMLSelectElement
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].text === event.target.value) {
        const yourSelect = selc[0] as HTMLSelectElement
        yourSelect.options[i].selected = true
        const ev = new Event('change', { bubbles: true })
        yourSelect.dispatchEvent(ev)
        break
      }
    }
    setLoading(false)
  }

  const changeTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTextAux(event.target.value)
    if (event.target.value.length > 2) {
      setFilterText(event.target.value)
    }
  }

  const FilterComponent = ({ filterText, onFilter, onClear }: any) => (
    <>
      <div className="row w-100">
        <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 header-1">
          <p className="show-options__text">
            <FormattedMessage
              id="app.vitrinas.mostrar"
              defaultMessage="MOSTRAR"
            />
            <select
              onChange={handlerSetChangePerPage}
              className="show-options__selector select_paginas"
              name="options-per-page"
              id="options-per-page"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100" selected>100</option>
              <option value="250">250</option>
            </select>
            <FormattedMessage
              id="app.vitrinas.entradas"
              defaultMessage="ENTRADAS"
            />
          </p>
        </div>
        <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 header-2">
          <p className="w-20-pc"></p>
          <TextField
            id="search"
            type="text"
            placeholder={useIntl().formatMessage(
              { id: 'app.vitrinas.buscar' },
              { defaultMessage: 'BUSCAR POR...' }
            )}
            onChange={changeTextSearch}
            data-tip={useIntl().formatMessage(
              { id: 'app.vitrinas.buscar.tooltip' },
              { defaultMessage: 'Ingresa al meno 3 caracteres para búscar' }
            )}
          />
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

          {filterText === '' ? (
            <ClearButton type="button" onClick={onClear}>
              <BsSearch
                title={
                  'Buscar ' +
                  intl.formatMessage(
                    { id: 'app.submenu.logs' },
                    { defaultMessage: 'Logs de Actividades' }
                  )
                }
                size={25}
              />
            </ClearButton>
          ) : (
            <ClearButton type="button" onClick={onClear}>
              <BsEraser title="Limpiar búsqueda" size={25} />
            </ClearButton>
          )}
        </div>
      </div>
    </>
  )

  const columns = [
    {
      id: 'userName',
      name: intl.formatMessage(
        { id: 'app.submenu.logs-table.columns1' },
        { defaultMessage: 'Nombre de Usuario' }
      ),
      selector: (row: any) => row.userName,
      sortable: true,
      reorder: true,
      defaultSortDesc: true,
      center: true,
      sortField: 'userName'
    },
    {
      id: 'Accion',
      name: intl.formatMessage(
        { id: 'app.submenu.logs-table.columns2' },
        { defaultMessage: 'Accion' }
      ),
      selector: (row: any) => {
        switch (row.action) {
          case 1:
            return <span className="badge badge-success mr-2">INSERT</span>
          case 2:
            return <span className="badge badge-info mr-2">UPDATE</span>
          case 3:
            return <span className="badge badge-danger mr-2">DELETE</span>
          case 4:
            return <span className="badge badge-dark mr-2">OTHER</span>
        }
      },
      sortable: true,
      reorder: true,
      defaultSortDesc: true,
      sortField: 'userName',      
      center: true
    },
    {
      id: 'module',
      name: intl.formatMessage(
        { id: 'app.submenu.logs-table.columns3' },
        { defaultMessage: 'Modulo' }
      ),
      // selector: (row: any) => row.enabled == 1 ? '<span className="badge badge-light mr-2">Si</span>' : 'No',
      selector: (row: any) => row.module,
      sortable: true,
      reorder: true,
      defaultSortDesc: true,
      center: true,
      sortField: 'module'
    },
    {
      id: 'dateAdded',
      name: intl.formatMessage(
        { id: 'app.submenu.logs-table.columns4' },
        { defaultMessage: 'Creado' }
      ),      
      selector: (row: any) =>
        `${row.dateAdded.slice(0, row.dateAdded.indexOf('T'))}` +
        '-' +
        `${row.timeAdded}`,
      sortable: true,
      reorder: true,
      defaultSortDesc: true,
      center: true,
      sortField: 'dateAdded'
    },
    {
      id: 'idHashScript',
      name: intl.formatMessage(
        { id: 'app.submenu.logs-table.columns7' },
        { defaultMessage: 'Ambiente' }
      ),      
      selector: (row: any) => row.idHashScript,
      center: true,
      sortable: false,
    },    
    {
      id: 'Actiond',
      name: intl.formatMessage(
        { id: 'app.submenu.logs-table.columns6' },
        { defaultMessage: 'Acciones' }
      ),
      cell: (row: any) => (
        <>
          <button
            className="manage-grid-button btn-grilla-del"
            data-tip={intl.formatMessage(
              { id: 'app.submenu.logs-table.zoom' },
              { defaultMessage: 'Ver datos completo del registro' }
            )}
            data-id={row.Id}
            onClick={() => {
              zoomLog(row.data)
            }}
          >
            <BsZoomIn />
          </button>
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
        </>
      ),
      allowOverflow: true,
      button: true,
      center: true,
      width: '140px'
    }
  ]

  const paginationComponentOptions = {
    rowsPerPageText: intl.formatMessage(
      { id: 'app.vitrinas.mostrando' },
      { defaultMessage: 'MOSTRANDO' }
    ),
    rangeSeparatorText: intl.formatMessage(
      { id: 'app.vitrinas.de' },
      { defaultMessage: ' DE ' }
    )
  }

  const handlePageChange = (page: any) => {
    setCurrentPage(page)
    getDataReload(page, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
    getDataReload(page, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      // if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle)
      setFilterText('')
      setFilterTextAux('')
      // }
    }

    if (filterText === '') {
      setTotalRows(totalRowsAux)
    } else {
      getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
        console.log(error)
      )
      // setTotalRows(totalRows); //(parseInt(filteredItems.length));
      setTimeout(() => {
        const input = document.getElementById('search') as HTMLInputElement
        input.value = filterText
        if (input != null) input.focus()
      }, 200)
    }

    setFilterTextAux(filterText)
    return (
      <FilterComponent
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  useEffect(() => {
    getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }, [resetPaginationToggle, filter])

  const handleSort = (column: any, sortDirection: any) => {
    setSortField(column.sortField)
    setSortDirection(sortDirection)

    getDataReload(1, filterText, column.sortField, sortDirection).catch(
      (error) => console.log(error)
    )
  }
  return (
    <div className="row">
      {logs.length >= 1 ? (
        <>
          <div>
            <DataTable
              subHeaderAlign={Alignment.RIGHT}
              columns={columns}
              data={logs}
              progressPending={loading}
              progressComponent={
                <Loading
                  textLoading={intl.formatMessage(
                    { id: 'app.vitrinas-spinner.espere' },
                    { defaultMessage: 'Espere...' }
                  )}
                />
              }
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationResetDefaultPage={resetPaginationToggle}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={customStyles}
              // defaultSortFieldId={1}
              noDataComponent={intl.formatMessage(
                { id: 'app.vitrinas.nodata' },
                { defaultMessage: 'Sin Resultados.' }
              )}
              responsive
              sortServer
              onSort={handleSort}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default ExportLogsTable
