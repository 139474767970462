/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

// import { useIntl } from 'react-intl'

import './style.css'
import { AiOutlineArrowRight } from '../../utils/icons'
import {
  FormField,
  FormGroup,
  FormLabel
} from '../Home/System/ProfileMshopContainer'
import Service from '../../services'

const PasswordRecoveryFormSchema = yup.object().shape({
  email: yup.string().email().required()
})

// interface EmailRecoveryI {
//   email: string
// }

const BackLink = styled(Link)`
  /* position: absolute;
  top: 30px;
  left: 15px; */
  color: #6529a1;
  background-color: #fff;
  padding: 7px 22px;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block;
  border: 1px solid #6529a1;
  &:hover {
    color: #6529a1;
  }
`

const DivError = styled.div`
  font-size: 12px;
  color: #dc3545;
  text-align: left;
  width: 100%;
  margin-top: 5px;
  padding: 12px;
`

const DivSuccess = styled.div`
  font-size: 13px;
  text-align: left;
  width: 100%;
  margin-top: 5px;
  padding: 12px;
`
const RecoveryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-evenly;
`
const ReSendButton = styled.button`
  color: white;
  background-color: #6529a1;
  padding: 7px 22px;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 15px;
  display: inline-block;
  border: none;
  &:hover {
    color: white;
  }
`

const CountDown = ({
  timer,
  handleReset
}: {
  timer: number
  handleReset: () => void
}) => {
  return (
    <ReSendButton
      // className="btn btn-login btn-success"
      type="submit"
      disabled={timer >= 1}
      style={{ opacity: timer >= 1 ? '60%' : 1 }}
      onClick={() => handleReset()}
    >
      Reenviar
    </ReSendButton>
  )
}

const PasswordRecovery = (props: any) => {
  const [okResponse, setOkResponse] = useState<boolean | null>(null)
  const [time, setTime] = useState<number>(30)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(PasswordRecoveryFormSchema)
  })
  const increment: any = useRef(null)
  useEffect(() => {
    if (time === 30) {
      // eslint-disable-next-line prefer-const
      let timer = setInterval(() => {
        setTime((time) => {
          if (time === 0) {
            clearInterval(timer)
            return 0
          } else return time - 1
        })
      }, 1000)
    }
  }, [time])

  const handleReset = () => {
    handleRecovery({ email: getValues('email') })
    clearInterval(increment.current)
    setTime(60)
  }

  const handleRecovery = useCallback(async ({ email }: { email: string }) => {
    try {
      const { data } = await Service.post<any, any>(
        `/Account/ForgotPassword?email=${email.toLowerCase()}`,
        '',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            credentials: 'include'
          }
        }
      )
      if (data.Status == 'Ok') {
        return setOkResponse(true)
      }
      setOkResponse(false)
      // reset()
    } catch (error) {
      reset()
    }
  }, [])

  const onSubmit = useCallback(async (data) => {
    return await handleRecovery(data)
  }, [])

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  return (
    <>
      <div className="login-content">
        <div className="nk-block toggled" id="l-login">
          <div className="nk-form">
            <h2 className="bdw-login-title">Recupera tu contraseña</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              {okResponse === true ? (
                <>
                  <DivSuccess>
                    Hemos enviado un email con las instrucciones para recuperar
                    tu contraseña a <strong>{getValues('email')}</strong>. Por
                    favor revisa tu bandeja de entrada, y en caso de que no
                    figure allí, revisa la carpeta de correo no deseado.
                  </DivSuccess>
                  <DivSuccess>
                    En caso de no recibir el mail podrás volver a enviarlo en{' '}
                    {time} segundos.
                  </DivSuccess>
                  <BackLink to={'login'}>Volver</BackLink>
                  <CountDown timer={time} handleReset={handleReset} />
                </>
              ) : (
                <RecoveryContainer>
                  <FormGroup style={{ width: '100%' }}>
                    <FormField
                      type="text"
                      id="email"
                      placeholder="tucorreo@gmail.com"
                      {...register('email')}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.user.email"
                        defaultMessage="Correo"
                      />
                    </FormLabel>
                  </FormGroup>
                  {errors.email || okResponse === false ? (
                    <DivError>Correo electrónico no válido</DivError>
                  ) : null}
                  {!okResponse && (
                    <button
                      className="btn btn-login btn-success btn-float"
                      type="submit"
                    >
                      <AiOutlineArrowRight
                        className="bdw-icon-arrow"
                        color="#6529A1"
                      />
                    </button>
                  )}
                  <BackLink to={'login'}>Volver</BackLink>
                </RecoveryContainer>
              )}
            </form>
          </div>
          <div className="bdw-logo">
            <img
              className="logo-img"
              src={require('../../img/Brain-Marca.png')}
              alt="Logo de Braindw"
            />
            <p>
              © {year}{' '}
              <FormattedMessage
                id="app.rights"
                defaultMessage="Todos los Derechos Reservados."
              />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordRecovery
