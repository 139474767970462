const PDPLogoSvg = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="90 220 350 400"
      style={{ enableBackground: 'new 0 0 595.3 841.9' }}
      xmlSpace="preserve"
    >

      <path
        d="M211.2,559.5c3.9,5.4,3.7,6.8-1.6,12.1c-6.8,6.8-13.6,13.6-20.4,20.4c-4,4-6.8,4-10.8,0
	c-19.2-19.1-38.3-38.3-57.4-57.4c-4.1-4.1-4.1-6.8-0.1-10.8c7.2-7.3,14.4-14.5,21.7-21.7c4-4,6.8-3.9,10.8,0.1c2,2,3.9,4.2,6.3,6.7
	c4.4-4.4,8.3-8.8,12.7-12.6c4.4-3.7,5.7-7.9,5.6-13.6c-0.3-21.8-0.1-43.7-0.1-65.5c0-19.2,12.6-31.8,31.9-31.8c5.2,0,10.4,0,16,0
	c0-1.8,0-3.3,0-4.7c0-35.4,0-70.9,0-106.3c0-20.3,12.3-32.7,32.5-32.7c42.3,0,84.6,0,126.9,0c19.7,0,32.2,12.5,32.2,32.3
	c0,90.3,0,180.6,0,271c0,19.5-12.6,32.1-32,32.1c-42.3,0-84.6-0.3-126.9,0.1c-20.1,0.2-33.6-14.9-32.8-31.5c0-0.3-0.1-0.7-0.1-0.7
	C220.9,549.7,216.1,554.6,211.2,559.5z M245.9,475.3c0.7,1.1,1.2,2,1.7,2.8c8.9,14.5,8.2,33.4-3.2,45.9c-5.5,6-7.7,12-6.7,19.6
	c0.1,0.6,0,1.2,0,1.9c0,11.9,7.4,19.4,19.4,19.4c42.9,0,85.8,0,128.7,0c12.2,0,19.6-7.4,19.6-19.6c0-90.7,0-181.4,0-272
	c0-12.5-7.3-19.7-19.8-19.8c-42.7,0-85.3,0-128,0c-12.7,0-19.9,7.3-19.9,20c0,46.9,0,93.8,0,140.7c0,1.2,0,2.4,0,4.3
	c1.5-1.3,2.4-2.1,3.3-2.9c10.9-9,27.1-6.2,34.3,5.9c5.4,9.2,4.1,20.2-3.8,28.2C263.1,458.4,254.5,466.8,245.9,475.3z M201.5,549.5
	c11.4-11,23.3-21.6,34.1-33.4c9.1-10,7.9-25.2-1.1-35.6c-3.8-4.4-3.7-6.8,0.4-10.9c9.1-9.1,18.2-18.2,27.2-27.3
	c5.5-5.5,5.9-12.2,1.2-16.8c-4.6-4.5-11.2-4-16.6,1.3c-6.6,6.6-13.3,13.2-19.8,19.8c-2.9,2.9-5.6,6-7.9,8.6
	c-3.5-3.1-6.3-5.7-9.6-8.7c3.5-3.3,6.8-6.5,10.5-9.9c-4.8-1.3-9.2-2.5-13.6-3.6c1-4,1.9-7.6,2.9-11.4c5.6,1.3,10.9,2.6,16.5,3.9
	c0-5.1,0-9.7,0-14.3c0-4.6,0-9.1,0-13.9c-5.7,0-10.9,0-16.2,0c-12.4,0-19.8,7.3-19.8,19.7c0,24.3-0.1,48.6,0.1,72.9
	c0,3.9-1,6.9-3.9,9.6c-6,5.6-11.7,11.5-17.3,17.1C180,527.9,190.9,538.8,201.5,549.5z M133.6,528.6c17,17,34,34,50.3,50.3
	c4.6-4.6,9.6-9.6,13.9-13.9c-16.6-16.6-33.6-33.6-50.3-50.3C143,519.3,138,524.2,133.6,528.6z"
      />
      <path
        d="M429.7,319.6c3.8,0,7.5,0,11.4,0c0,49.8,0,99.4,0,149.3c-3.7,0-7.4,0-11.4,0
	C429.7,419.4,429.7,369.7,429.7,319.6z"
      />
      <path
        d="M429.6,481.3c3.9,0,7.6,0,11.5,0c0,3.8,0,7.6,0,11.6c-3.8,0-7.5,0-11.5,0C429.6,489.1,429.6,485.4,429.6,481.3z
	"
      />
      <path
        style={{ fill: '#ffffff00' }}
        d="M245.9,475.3c8.6-8.5,17.2-17,25.7-25.6c7.8-8,9.2-19,3.8-28.2c-7.2-12.2-23.4-15-34.3-5.9
	c-0.9,0.8-1.8,1.6-3.3,2.9c0-1.9,0-3.1,0-4.3c0-46.9,0-93.8,0-140.7c0-12.7,7.2-20,19.9-20c42.7,0,85.3,0,128,0
	c12.5,0,19.8,7.3,19.8,19.8c0,90.7,0,181.4,0,272c0,12.2-7.4,19.6-19.6,19.6c-42.9,0-85.8,0-128.7,0c-11.9,0-19.3-7.5-19.4-19.4
	c0-0.6,0.1-1.3,0-1.9c-1-7.6,1.2-13.6,6.7-19.6c11.5-12.5,12.2-31.4,3.2-45.9C247.1,477.3,246.6,476.5,245.9,475.3z M321.7,317.1
	c-4.6-4.6-8.9-8.8-13-13.1c-2.3-2.4-4.9-3.2-8.1-1.8c-13.5,5.8-27,11.6-40.5,17.4c-3.8,1.6-4.9,4.1-3.9,8.1
	c1.8,7.3,3.6,14.5,5.5,21.7c1.4,5.2,3.7,6.6,9.1,5.3c4.9-1.2,9.8-2.4,15-3.6c0,17.1,0,33.7,0,50.3c0,5.8,2,7.8,7.9,7.8
	c18.7,0,37.4,0,56.1,0c5.8,0,7.8-2,7.8-7.9c0-15.3,0-30.7,0-46c0-1.3,0-2.6,0-4.2c5.5,1.4,10.5,2.6,15.4,3.8
	c4.8,1.1,7.3-0.3,8.6-5.1c1.8-7,3.5-14,5.3-21c1.4-5.5,0.5-7.3-4.8-9.6c-12.9-5.6-25.9-11-38.8-16.7c-3.6-1.6-6.4-0.9-9.1,1.9
	C330.2,308.6,326.2,312.6,321.7,317.1z M375.3,481.4c-36,0-71.7,0-107.3,0c0,4.1,0,7.8,0,11.5c35.9,0,71.5,0,107.3,0
	C375.3,489,375.3,485.4,375.3,481.4z M375.3,457.5c-32,0-63.7,0-95.3,0c0,4.1,0,7.8,0,11.5c31.9,0,63.5,0,95.3,0
	C375.3,465.1,375.3,461.4,375.3,457.5z M363.2,516.9c0-4.1,0-7.9,0-11.5c-32,0-63.7,0-95.3,0c0,4,0,7.7,0,11.5
	C299.7,516.9,331.4,516.9,363.2,516.9z M375.3,433.5c-20,0-39.8,0-59.4,0c0,4,0,7.8,0,11.5c19.9,0,39.6,0,59.4,0
	C375.3,441.2,375.3,437.5,375.3,433.5z M315.5,265.9c-3.9,0-7.7,0-11.6,0c0,3.9,0,7.7,0,11.4c4,0,7.8,0,11.6,0
	C315.5,273.4,315.5,269.8,315.5,265.9z M327.7,277.3c4.2,0,7.9,0,11.6,0c0-4,0-7.7,0-11.5c-4,0-7.7,0-11.6,0
	C327.7,269.7,327.7,273.4,327.7,277.3z M291.8,444.9c4.1,0,7.9,0,11.5,0c0-4.1,0-7.8,0-11.5c-4,0-7.7,0-11.5,0
	C291.8,437.3,291.8,441,291.8,444.9z M327.3,552.9c0-3.9,0-7.7,0-11.6c-3.9,0-7.7,0-11.4,0c0,4,0,7.8,0,11.6
	C319.8,552.9,323.3,552.9,327.3,552.9z"
      />
      <path
        style={{ fill: '#ffffff00' }}
        d="M201.5,549.5c-10.6-10.6-21.5-21.6-32.8-32.8c5.6-5.6,11.3-11.5,17.3-17.1c2.9-2.7,3.9-5.7,3.9-9.6
	c-0.1-24.3-0.1-48.6-0.1-72.9c0-12.4,7.3-19.7,19.8-19.7c5.2,0,10.4,0,16.2,0c0,4.8,0,9.3,0,13.9c0,4.6,0,9.2,0,14.3
	c-5.6-1.3-10.8-2.6-16.5-3.9c-1,3.8-1.9,7.4-2.9,11.4c4.4,1.2,8.7,2.3,13.6,3.6c-3.7,3.5-7,6.6-10.5,9.9c3.3,3,6.1,5.5,9.6,8.7
	c2.4-2.5,5-5.6,7.9-8.6c6.6-6.7,13.2-13.3,19.8-19.8c5.3-5.3,11.9-5.8,16.6-1.3c4.7,4.6,4.2,11.4-1.2,16.8
	c-9.1,9.1-18.2,18.2-27.2,27.3c-4.1,4.1-4.1,6.5-0.4,10.9c9,10.4,10.2,25.7,1.1,35.6C224.9,527.8,212.9,538.4,201.5,549.5z"
      />
      <path
        style={{ fill: '#ffffff00' }}
        d="M133.6,528.6c4.4-4.4,9.3-9.3,13.9-13.8c16.7,16.7,33.7,33.7,50.3,50.3c-4.3,4.3-9.3,9.3-13.9,13.9
	C167.6,562.6,150.6,545.6,133.6,528.6z"
      />
      <path
        d="M321.7,317.1c4.4-4.5,8.5-8.6,12.4-12.8c2.6-2.8,5.4-3.5,9.1-1.9c12.9,5.6,25.9,11.1,38.8,16.7
	c5.3,2.3,6.2,4.1,4.8,9.6c-1.7,7-3.5,14-5.3,21c-1.2,4.8-3.8,6.3-8.6,5.1c-5-1.2-9.9-2.4-15.4-3.8c0,1.6,0,2.9,0,4.2
	c0,15.3,0,30.7,0,46c0,5.9-2,7.9-7.8,7.9c-18.7,0-37.4,0-56.1,0c-5.9,0-7.9-2-7.9-7.8c0-16.6,0-33.2,0-50.3
	c-5.2,1.2-10.1,2.4-15,3.6c-5.4,1.3-7.8,0-9.1-5.3c-1.9-7.2-3.7-14.5-5.5-21.7c-1-4.1,0.1-6.5,3.9-8.1c13.5-5.8,27-11.6,40.5-17.4
	c3.2-1.4,5.8-0.6,8.1,1.8C312.8,308.3,317.1,312.5,321.7,317.1z M371.1,342.1c0.9-3.7,1.7-7,2.6-10.2c0.6-2.2,0.2-3.3-2-4.2
	c-9.4-3.9-18.8-7.9-28.1-12c-1.9-0.8-3.1-0.7-4.6,0.8c-4,4.2-8,8.2-12.1,12.2c-3.9,3.8-6.7,3.8-10.6-0.1c-4.1-4-8-8.2-12.2-12.1
	c-0.8-0.8-2.7-1.3-3.7-0.9c-9.9,4.1-19.7,8.3-29.5,12.6c-0.7,0.3-1.7,1.7-1.5,2.4c0.8,3.8,1.8,7.6,2.8,11.6
	c5.8-1.4,11.1-2.8,16.4-4.1c6.1-1.5,9.1,0.8,9.1,7c0,15.7,0,31.4,0,47.1c0,1.6,0,3.2,0,4.9c16.1,0,31.9,0,47.9,0c0-1.6,0-2.9,0-4.2
	c0-16.1,0-32.2,0-48.2c0-5.5,3.2-8,8.5-6.7C359.6,339.2,365.1,340.6,371.1,342.1z"
      />
      <path
        d="M375.3,481.4c0,3.9,0,7.6,0,11.5c-35.8,0-71.4,0-107.3,0c0-3.7,0-7.5,0-11.5
	C303.6,481.4,339.3,481.4,375.3,481.4z"
      />
      <path
        d="M375.3,457.5c0,3.9,0,7.6,0,11.5c-31.8,0-63.4,0-95.3,0c0-3.7,0-7.5,0-11.5
	C311.6,457.5,343.3,457.5,375.3,457.5z"
      />
      <path
        d="M363.2,516.9c-31.8,0-63.5,0-95.3,0c0-3.8,0-7.5,0-11.5c31.6,0,63.3,0,95.3,0
	C363.2,509,363.2,512.8,363.2,516.9z"
      />
      <path
        d="M375.3,433.5c0,4,0,7.7,0,11.5c-19.8,0-39.5,0-59.4,0c0-3.7,0-7.5,0-11.5C335.5,433.5,355.3,433.5,375.3,433.5z
	"
      />
      <path
        d="M315.5,265.9c0,3.9,0,7.5,0,11.4c-3.8,0-7.6,0-11.6,0c0-3.7,0-7.5,0-11.4C307.8,265.9,311.6,265.9,315.5,265.9z
	"
      />
      <path
        d="M327.7,277.3c0-3.9,0-7.6,0-11.5c3.9,0,7.7,0,11.6,0c0,3.8,0,7.5,0,11.5C335.6,277.3,331.9,277.3,327.7,277.3z"
      />
      <path
        d="M291.8,444.9c0-3.9,0-7.6,0-11.5c3.8,0,7.5,0,11.5,0c0,3.7,0,7.5,0,11.5C299.7,444.9,295.9,444.9,291.8,444.9z"
      />
      <path
        d="M327.3,552.9c-3.9,0-7.5,0-11.4,0c0-3.8,0-7.6,0-11.6c3.7,0,7.5,0,11.4,0C327.3,545.2,327.3,549,327.3,552.9z"
      />
      <path
        style={{ fill: '#ffffff00' }}
        d="M371.1,342.1c-6-1.5-11.5-2.9-17.1-4.2c-5.3-1.3-8.5,1.2-8.5,6.7c0,16.1,0,32.2,0,48.2c0,1.3,0,2.7,0,4.2
	c-16,0-31.8,0-47.9,0c0-1.7,0-3.3,0-4.9c0-15.7,0-31.4,0-47.1c0-6.2-3-8.5-9.1-7c-5.3,1.3-10.6,2.6-16.4,4.1c-1-4-2.1-7.8-2.8-11.6
	c-0.1-0.7,0.8-2,1.5-2.4c9.8-4.3,19.6-8.6,29.5-12.6c1-0.4,2.9,0.2,3.7,0.9c4.2,3.9,8.2,8.1,12.2,12.1c3.9,3.8,6.7,3.9,10.6,0.1
	c4.1-4,8.2-8,12.1-12.2c1.5-1.5,2.7-1.7,4.6-0.8c9.3,4.1,18.7,8.1,28.1,12c2.2,0.9,2.7,2.1,2,4.2C372.8,335.1,372,338.4,371.1,342.1
	z"
      />
    </svg>
  )
}

export default PDPLogoSvg
