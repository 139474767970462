import React, { useState } from 'react'
import { BsFillBookmarkPlusFill, BsPencilSquare } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'
import ArticlesPanelForm from './ArticlesPanelForm'
import ArticlesTable from './ArticlesTable'
import './system.css'

const ArticlesTableContainer = () => {
  const [idArticle, setIdArticle] = useState<any>('')
  const [createArticle, setCreateArticle] = useState<boolean>(false)
  const [editArticle, setEditArticle] = useState<boolean>(false)
  const toggleCreateArticle = () => {
    setCreateArticle(!createArticle)
  }
  const toggleEditArticle = () => {
    setEditArticle(!editArticle)
  }

  return (
    <div
      className="container mg-t-30 container table-bordered "
      style={{ display: 'block' }}
    >
      <div className="related_categories__title-container">
        <div className="rule-config__title-icon">
          <BsPencilSquare />
        </div>
        <h1 className="rule-config__title-text">
          <FormattedMessage
            id="app.system.articles-panel.account"
            defaultMessage="Cuenta"
          />
        </h1>
      </div>

      <div>
        {createArticle || editArticle ? (
          ''
        ) : (
          <button
            className="ppal_button btn btn-primary"
            id="add-new-article"
            onClick={() => {
              toggleCreateArticle()
            }}
          >
            <BsFillBookmarkPlusFill className="m-rig" width={20} />
            <FormattedMessage
              id="app.system.articles-panel.table.add-article"
              defaultMessage="AGREGAR ARTÍCULO"
            />
          </button>
          
        )}

      </div>

      {createArticle || editArticle ? (
        <ArticlesPanelForm
          create={createArticle}
          edit={editArticle}
          back={createArticle ? toggleCreateArticle : toggleEditArticle}
          idArticle={idArticle}
        />
      ) : (
        <ArticlesTable edit={toggleEditArticle} setIdArticle={setIdArticle} />
      )}
    </div>
  )
}

export default ArticlesTableContainer
