/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react'
import {
  BsFillPlusCircleFill,
  BsFillTrashFill,
  BsCheck2Circle,
  BsCheckCircleFill,
  BsExclamationTriangleFill,
  BsAsterisk
} from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'

import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'
// import { IdCategory } from '../RuleConfig/RuleConfigSubComponents'

const EditSpecificationsDestiny = ({
  categories,
  categoriesArray,
  loadingCategories,
  setCategoryNotExist,
  categoryOriginsValues,
  setCategoryDestinyValues,
  specificationByCategory,
  brandsByIdCategory,
  oneRelatedCategorie,
  id,
  GetBrandsByIdCategory,
  getSpecificationsByCategory,
  handleAlert
}: any) => {
  const objSpec = oneRelatedCategorie.DestinationSpecification?.map(
    (i: any, index: any) => ({
      specification: [],
      value: [{ v: i.v, vId: i.vId }],
      specs: { k: i.k, kId: i.kId, v: i.v, vId: i.vId },
      id: 'origin_SpecDeleteBtn' + `${index}`,
      oneRelated: true
    })
  )
  const [specificationDestination, setSpecificationsDestination] =
    useState<any>(
      oneRelatedCategorie.DestinationSpecification?.length > 0 ? objSpec : []
    )
  const [specValue, setSpecValue] = useState<any>('')
  const [selectCategorie, setSelectCategorie] = useState<any>(
    oneRelatedCategorie.IdCategoryDestination
  )
  const [idCategoryDestination, setIdCategoryDestination] = useState<any>(
    oneRelatedCategorie.IdCategoryDestination
  )
  const [destinySpec, setDestinySpec] = useState(1)
  const [productBrand, setProductBrand] = useState<any>('')
  const [idClusterProduct, setIdClusterProduct] = useState<any>(
    oneRelatedCategorie.IdClusterProduct !== null
      ? oneRelatedCategorie.IdClusterProduct
      : ''
  )
  const [prioritySku, setPrioritySku] = useState<any>(
    oneRelatedCategorie.prioritySku !== null
      ? oneRelatedCategorie.prioritySku
      : ''
  )
  const intl = useIntl()
  const dispatch = useDispatch()

  const [tabSelected, setTabSelected] = useState<any>(
    oneRelatedCategorie.IdClusterProduct == null ? 'CATEGORÍA' : 'COLECCIÓN'
  )
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const MyAlert = (
    text: string,
    iconStr: number,
    time: number = 3000,
    isToast: boolean = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-categories.cross-selling' },
        { defaultMessage: 'CRUCE DE CATEGORÍAS' }
      ),
      text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const addSpecificationDestination = () => {
    const rows = [
      ...specificationDestination,
      {
        specification: specificationByCategory.map((i: any) => {
          return { kId: i.FieldId, k: i.Name }
        }),
        value: [],
        id:
          'destiny_SpecDeleteBtn' +
          JSON.stringify(
            `${specificationDestination.length}` + `${destinySpec}`
          )
      }
    ]
    setSpecificationsDestination(rows)
    setDestinySpec(destinySpec + 1)
  }

  const deleteSpecificationDestination = (item: any) => {
    setSpecificationsDestination(
      specificationDestination.filter((e: { id: any }) => e.id !== item)
    )
  }

  const getSpecificationsValues = async (fieldId: any, index: any) => {
    const newArr = [...specificationDestination]
    Service.get<any>(
      `ManageCarrousel/GetSpecificationsValues?idHashScript=${selectedScript?.idHashScript}&fieldId=${fieldId}`,
      { headers: { token: token.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          newArr[index].value = response.data.map(
            (i: any) => [{ v: i.Value, vId: i.FieldValueId }][0]
          )
          setSpecificationsDestination(newArr)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const handleSpecification = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newArr = [...specificationDestination]
    const index = event.target.id
    getSpecificationsValues(event.target.value, event.target.id)
    newArr[parseInt(index)].specs = {
      k: event.target.selectedOptions[0].dataset.k,
      kId:
        `${event.target.selectedOptions[0].dataset.kid}` +
        ':' +
        `${event.target.selectedOptions[0].dataset.k}`,
      v: '',
      vId: ''
    }
  }

  const handleValues = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSpecValue(event.target.value)
    const newArr = [...specificationDestination]
    const index = event.target.id
    newArr[parseInt(index)].specs.v = event.target.value
    newArr[parseInt(index)].specs.vId = event.target.value
  }

  const handleCollectionId = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match('^[0-9]*$') != null) {
      setIdClusterProduct(event.target.value)
    }
  }

  const handleNavClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const button: HTMLButtonElement = event.currentTarget
    setTabSelected(button.innerText)
    const buttonDatase = document.querySelector(
      button.dataset.bsTarget!
    ) as HTMLDivElement
    const otherButtons = document.querySelectorAll('.botones_target')
    ;[].forEach.call(otherButtons, function (el: any) {
      el.classList.remove('active')
      document.querySelector(el.dataset.bsTarget).classList.remove('active')
    })
    button.classList.add('active')
    buttonDatase.classList.add('active')
  }

  const AlertToken = (value: any) => {
    ShowAlert({
      title: 'Cambio de categoria',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.select-category.borrar' },
          {
            defaultMessage:
              'Cada vez que cambie de categoría, se eliminaran las especificaciones seleccionadas <br/> Confirma la eliminación de las especificaciones?'
          }
        ) +
        '</span>',
      inputLabel: '',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.continuar' },
        { defaultMessage: 'Continuar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cancelar' },
        { defaultMessage: 'Cancelar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    })
      .then((result) => {
        if (result.isConfirmed) {
          getSpecificationsByCategory(value, 'destiny')
          GetBrandsByIdCategory(value)
          setSelectCategorie(value)
          setIdCategoryDestination(value)
          setSpecificationsDestination([])
        }
      })
      .catch((error) => {
        MyAlert('API-ERROR: ' + `${error}`, 3)
      })
  }

  const handleSelectCategorie = (event: any) => {
    if (specificationDestination.length > 0) {
      // AlertToken(event.target.value)
      AlertToken(event.value)
    } else {
      // setSelectCategorie(event.target.value)
      setSelectCategorie(event.value)
      // setIdCategoryDestination(event.target.value)
      setIdCategoryDestination(event.value)
      // getSpecificationsByCategory(event.target.value, 'destiny')
      getSpecificationsByCategory(event.value, 'destiny')
      // GetBrandsByIdCategory(event.target.value)
      GetBrandsByIdCategory(event.value)
    }
  }

  const handleIdCategorie = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match('^[0-9]*$') != null) {
      setIdCategoryDestination(event.target.value)
    }
  }

  const handleValidateCategoryInput = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const filterCategory = categories.filter(
      (e: { id: any }) => e.id == idCategoryDestination
    )
    if (filterCategory.length > 0) {
      if (specificationDestination.length > 0) {
        AlertToken(idCategoryDestination)
      } else {
        setSelectCategorie(
          categories.filter(
            (e: { id: any }) => e.id == idCategoryDestination
          )[0].id
        )
        getSpecificationsByCategory(idCategoryDestination, 'destiny')
      }
      setCategoryNotExist(false)
      GetBrandsByIdCategory(idCategoryDestination)
      document.querySelector('.check-destiny-success')?.classList.add('success')
      document
        .querySelector('.check-destiny-error')
        ?.classList.remove('success')
    } else {
      setCategoryNotExist(true)
      document
        .querySelector('.check-destiny-success')
        ?.classList.remove('success')
      document.querySelector('.check-destiny-error')?.classList.add('success')
    }
  }
  const handleProductBrand = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProductBrand(event.target.value)
  }

  const handlePrioritySku = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match('^[0-9,]*$') != null) {
      setPrioritySku(event.target.value)
    }
  }

  useEffect(() => {
    const newArr = [...specificationDestination]
    if (newArr.length > 0) {
      specificationDestination.map(
        (item: any, index: any) =>
          (newArr[index].specification = specificationByCategory.map(
            (i: any) => {
              return { kId: i.FieldId, k: i.Name }
            }
          ))
      )
    }

    if (tabSelected == 'CATEGORÍA' || tabSelected == 'CATEGORY') {
      setCategoryDestinyValues({
        IdCategoryDestination: selectCategorie,
        IdBrandDestination: productBrand,
        DestinationSpecification:
          specificationDestination.map((specs: any) => {
            return specs.specs
          })[0] == undefined
            ? null
            : specificationDestination.map((specs: any) => {
                return specs.specs
              }),
        prioritySku
      })
    } else if (tabSelected == 'COLECCIÓN' || tabSelected == 'COLLECTION') {
      setCategoryDestinyValues({
        IdCategoryDestination: undefined,
        IdBrandDestination: undefined,
        IdClusterProduct: idClusterProduct,
        prioritySku
      })
    } else {
      setCategoryDestinyValues({
        prioritySku,
        IdClusterProduct: idClusterProduct
      })
    }
  }, [
    specificationDestination,
    specificationByCategory,
    selectCategorie,
    idCategoryDestination,
    specValue,
    productBrand,
    prioritySku,
    idClusterProduct,
    tabSelected
  ])

  useEffect(() => {
    const newArr = [...specificationDestination]
    specificationDestination.map(async (item: any, index: any) => {
      return await (newArr[index].value = getSpecificationsValues(
        item.specs.kId.replace(/[^0-9]/g, ''),
        index
      ))
    })
    if (
      oneRelatedCategorie.IdClusterProduct == null ||
      oneRelatedCategorie.IdClusterProduct == ''
    ) {
      setTabSelected('CATEGORY')
    } else {
      setTabSelected('COLLECTION')
    }
    if (categoryOriginsValues?.IdCategoryOrigin) {
      categoriesArray.forEach((item: any, index: any) => {
        if (item.value == categoryOriginsValues?.IdCategoryOrigin) {
          item.disabled = true
        }
      })
    }
  }, [])

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            onClick={handleNavClick}
            className={`nav-link ${
              tabSelected == 'CATEGORY' ? 'active' : ''
            } botones_target`}
            id="category-tab"
            data-bs-toggle="tab"
            data-bs-target="#category"
            type="button"
            role="tab"
            aria-controls="category"
            aria-selected="true"
          >
            {intl.formatMessage(
              { id: 'app.related-categories.add-cross-selling.category' },
              { defaultMessage: 'CATEGORÍA' }
            )}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            onClick={handleNavClick}
            className={`nav-link ${
              tabSelected == 'COLLECTION' || '' ? 'active' : ''
            } botones_target`}
            id="collection-tab"
            data-bs-toggle="tab"
            data-bs-target="#collection"
            type="button"
            role="tab"
            aria-controls="collection"
            aria-selected="false"
          >
            {intl.formatMessage(
              { id: 'app.related-categories.add-cross-selling.collection' },
              { defaultMessage: 'COLECCIÓN' }
            )}
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade show ${
            tabSelected == 'CATEGORY' ? 'active' : ''
          }`}
          id="category"
          role="tabpanel"
          aria-labelledby="category-tab"
        >
          <h6 className="mt-4 titulo">
            <FormattedMessage
              id="app.related-categories.add-cross-selling.category-destination"
              defaultMessage="DESTINO DE CATEGORIA"
            />
          </h6>

          <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container ai-baseline">
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 jc-flexend">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.select-category-destination"
                    defaultMessage="Seleccionar Destino de Categoria"
                  />
                  (<BsAsterisk size={8} color={'red'} />)
                  <TooltipPanel 
                    ObjectTooltip={token.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CatRelacionada.CatDestino.tooltip')
                    )[0]}
                  />                  
                </label>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
              <select
                value={selectCategorie}
                onChange={handleSelectCategorie}
                name="IdCategoryDestination_Select"
                className="col-lg-7 col-md-7 col-sm-7 col-xs-7 box-select-endpoint error"
                id="IdCategoryDestination_Select"
              >
                <option value="0">
                  {intl.formatMessage(
                    {
                      id: 'app.related-categories.add-cross-selling.select-category'
                    },
                    { defaultMessage: 'Seleccionar Categoria' }
                  )}
                </option>
                {categories.length > 0
                  ? categories.map((categorie: any, index: any) => (
                      <>
                        <option
                          value={categorie.id}
                          disabled={
                            categoryOriginsValues?.IdCategoryOrigin ==
                            categorie.id
                              ? true
                              : false
                          }
                        >
                          {categorie.categoryPathString}
                        </option>
                      </>
                    ))
                  : ''}
              </select>

              {categoriesArray.length > 0 ? (
                <Select
                  className="basic-single box-select-endpoint"
                  classNamePrefix="select"
                  defaultValue={categoriesArray[parseInt(selectCategorie)]}
                  isDisabled={false}
                  isLoading={loadingCategories}
                  // isOptionDisabled={(option) => option.value == categoryOriginsValues?.IdCategoryOrigin}
                  isOptionDisabled={(option) => option.disabled}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  name="categoriasDestSlt"
                  id="categoriasDestSlt"
                  options={categoriesArray}
                  placeholder={intl.formatMessage(
                    {
                      id: 'app.related-categories.add-cross-selling.select-category'
                    },
                    { defaultMessage: 'Seleccionar Categoria' }
                  )}
                  onChange={handleSelectCategorie}
                />
              ) : (
                <Spinner animation="border" size="sm" />
              )}
            </div>
          </div>
          <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container ai-baseline">
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 jc-flexend">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.id-category-destination"
                    defaultMessage="Id de Destino de Categoria"
                  />
                  <TooltipPanel 
                    ObjectTooltip={token.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CatRelacionada.Id.CatDestino.tooltip')
                    )[0]}
                  />
                </label>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              <input
                className="input vtexskuconsult__input col-lg-7 col-md-7 col-sm-7 col-xs-7"
                type="text"
                value={idCategoryDestination}
                onChange={handleIdCategorie}
              />
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              <button
                className="manage-grid-button btn btn-border"
                onClick={handleValidateCategoryInput}
              >
                <BsCheck2Circle size={25} id={'check-id-categoria'} /> Validar
              </button>
              <TooltipPanel 
                ObjectTooltip={token.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.related.valid.category.destiny.title.especification.tooltip')
                )[0]}
              />
            </div>
            <div
              className="col-lg-1 col-md-1 col-sm-1 col-xs-1 check-destiny-success"
              style={{ display: 'none' }}
            >
              <div className="manage-grid-button">
                <BsCheckCircleFill
                  size={25}
                  color={'green'}
                  id={'check-id-categoria'}
                />
              </div>
            </div>
            <div
              className="col-lg-3 col-md-3 col-sm-3 col-xs-3 check-destiny-error"
              style={{ display: 'none' }}
            >
              <div className="">
                <BsExclamationTriangleFill
                  size={25}
                  color={'red'}
                  id={'check-id-categoria'}
                />{' '}
                No existe la categoría
              </div>
            </div>
          </div>

          <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 jc-flexend">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.product-brand"
                    defaultMessage="Marca de Producto"
                  />
                  <TooltipPanel 
                    ObjectTooltip={token.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CatRelacionada.Marca.CatDestino.tooltip')
                    )[0]}
                  />
                </label>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
              <select
                onChange={handleProductBrand}
                className="col-lg-7 col-md-7 col-sm-7 col-xs-7 box-select-endpoint select_cross"
              >
                {brandsByIdCategory.length > 0
                  ? brandsByIdCategory.map((brand: any) => {
                      return (
                        <option
                          key={brand.Value}
                          value={brand.Value}
                          selected={
                            oneRelatedCategorie.IdBrandDestination ==
                            brand.Value
                              ? true
                              : false
                          }
                        >
                          {brand.Text}
                        </option>
                      )
                    })
                  : ''}
              </select>
            </div>
          </div>

          {specificationDestination.map((item: any, index: any) => {
            return (
              <>
                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container box-specification">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.related-categories.add-cross-selling.specification"
                          defaultMessage="Especificacion"
                        />
                        <TooltipPanel 
                          ObjectTooltip={token.data.tooltips.filter((
                            (t:any) => t.idTooltip === 'app.related.category.specification.destiny.title.especification.tooltip')
                          )[0]}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <select
                      onChange={handleSpecification}
                      id={index}
                      className="col-lg-10 col-md-10 col-sm-10 col-xs-10 box-select-endpoint select_cross"
                    >
                      <option value="seleccionar">
                        {intl.formatMessage(
                          {
                            id: 'app.related-categories.add-cross-selling.select-specification'
                          },
                          { defaultMessage: 'Seleccionar especificación' }
                        )}
                      </option>
                      {item.specification.length > 0
                        ? item?.specification.map((i: any) => {
                            return (
                              <option
                                key={i.kId}
                                data-k={i.k}
                                data-kId={i.kId}
                                value={i.kId}
                                selected={
                                  i.kId ==
                                  item.specs?.kId?.replace(/[^0-9]/g, '')
                                    ? true
                                    : false
                                }
                              >
                                {i.k}
                              </option>
                            )
                          })
                        : ''}
                    </select>
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.related-categories.add-cross-selling.value"
                          defaultMessage="Valor"
                        /> 
                        <TooltipPanel 
                          ObjectTooltip={token.data.tooltips.filter((
                            (t:any) => t.idTooltip === 'app.related.category.specification.destiny.title.value.tooltip')
                          )[0]}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <select
                      onChange={handleValues}
                      id={index}
                      className="col-lg-10 col-md-10 col-sm-10 col-xs-10 box-select-endpoint select_cross"
                    >
                      <option value="">
                        {intl.formatMessage(
                          {
                            id: 'app.related-categories.add-cross-selling.select-specification-value'
                          },
                          { defaultMessage: 'Seleccionar valor' }
                        )}
                      </option>
                      {item.value.length > 0
                        ? item.value.map((i: any, index: any) => {
                            if (index == item.value.length - 1) {
                              return (
                                <>
                                  <option
                                    id={i.vId}
                                    selected={
                                      i.v == item.specs?.v ? true : false
                                    }
                                  >
                                    {i.v}
                                  </option>
                                  <option
                                    value="{{dynamicSpec}}"
                                    selected={
                                      item.specs?.v == '{{dynamicSpec}}'
                                        ? true
                                        : false
                                    }
                                  >
                                    Dinámico
                                  </option>
                                </>
                              )
                            } else {
                              return (
                                <option
                                  key={i.vId}
                                  id={i.vId}
                                  selected={i.v == item.specs?.v ? true : false}
                                >
                                  {i.v}
                                </option>
                              )
                            }
                          })
                        : ''}
                    </select>
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    <button
                      className="manage-grid-button btn-add-espec"
                      onClick={() => deleteSpecificationDestination(item.id)}
                    >
                      <BsFillTrashFill size={25} id={'red'} />
                    </button>
                  </div>
                </div>
              </>
            )
          })}
          <div
            className="col-lg-4 col-md-4"
            style={{ position: 'relative', left: '24%' }}
          >
            <button
              className="manage-grid-button btn btn-border"
              onClick={addSpecificationDestination}
            >
              <BsFillPlusCircleFill size={25} />
              {intl.formatMessage(
                {
                  id: 'app.related-categories.add-cross-selling.specification'
                },
                { defaultMessage: 'Especificación' }
              )}
            </button>
          </div>
        </div>

        <div
          className={`tab-pane fade ${
            tabSelected == 'COLLECTION' ? 'active' : ''
          }`}
          id="collection"
          role="tabpanel"
          aria-labelledby="collection-tab"
        >
          <h6 className="mt-4 titulo">
            <FormattedMessage
              id="app.related-categories.add-cross-selling.select-product-collection"
              defaultMessage="SELECCIONAR COLLECCION DE PRODUCTO"
            />
          </h6>

          <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-2">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.product-collection"
                    defaultMessage="Collecion de Producto"
                  />
                  (<BsAsterisk size={8} color={'red'} />)
                  <TooltipPanel 
                    ObjectTooltip={token.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CatRelacionada.ColeccionDestino.tooltip')
                    )[0]}
                  />
                </label>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
              <input
                className="input vtexskuconsult__input col-lg-7 col-md-7 col-sm-7 col-xs-7"
                type="text"
                onChange={handleCollectionId}
                value={idClusterProduct}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div className="shelves__endpoint-selector-container">
            <label className="shelves__endpoint-selector-text mb-2">
              <FormattedMessage
                id="app.related-categories.add-cross-selling.priority-products-sku"
                defaultMessage="Prioridad de SKU de Productos"
              />
              <TooltipPanel 
                ObjectTooltip={token.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CatRelacionada.PrioridadSKU.CatDestino.tooltip')
                )[0]}
              />
            </label>
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
          <input
            onChange={handlePrioritySku}
            placeholder={intl.formatMessage(
              {
                id: 'app.related-categories.add-cross-selling.priotity-sku-placeholder'
              },
              { defaultMessage: 'Hasta 20 skus con formato 1111,2222,3333...' }
            )}
            value={prioritySku}
            className="input vtexskuconsult__input col-lg-7 col-md-7 col-sm-7 col-xs-7"
            type="text"
          />
        </div>
      </div>
    </>
  )
}

export default EditSpecificationsDestiny
