import React, { useState } from 'react'
import {  useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import { TooltipPanel } from '../../../Globals/TooltipPanel'

const matchgroups = [
  {
    id: 0,
    name: 'MatchGroup 1',
    endpoint: '<%matchgroup_1%>'
  },
  {
    id: 1,
    name: 'MatchGroup 2',
    endpoint: '<%matchgroup_2%>'
  },
  {
    id: 2,
    name: 'MatchGroup 3',
    endpoint: '<%matchgroup_3%>'
  },
  {
    id: 3,
    name: 'MatchGroup 4',
    endpoint: '<%matchgroup_4%>'
  }
]
const SearchTerm: React.FC<{
  title: string
  values: any
}> = ({ title, values }) => {
  const valueParams = values
    .filter((x: any) => x[0] == 'ft')
    .map((item: any) => {
      return item[1]
    })
  const [clusterId, setClusterId] = useState<any>(
    valueParams !== undefined ? valueParams : ''
  )

  const handleButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const button: HTMLButtonElement = event.currentTarget
    setClusterId(button.name)
  }

  const inputOnChangeClusters = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClusterId(event.target.value)
  }
  const user = useSelector<RootState, any>((state) => state.userAuth)
  return (
    <div className="rule-config-subComponents__container">
      <div className="row a-center">
        <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
          {title}
          <TooltipPanel 
            ObjectTooltip={user.data.tooltips.filter((
              (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.termino.busqueda.tooltip')
            )[0]}
          />
          </h6>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div>
            <input
              className="vtexskuconsult__input input-stk-min"
              id="ft"
              type="text"
              style={{ width: '64%', background: '#fcfbfb' }}
              value={clusterId}
              onChange={inputOnChangeClusters}
            />            
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 match-group-2 button_container_rule">
            {matchgroups.map((option) => {
              return (
                <button
                  onClick={handleButton}
                  name={option.endpoint}
                  value={option.name}
                  style={{ padding: 'initial' }}
                  className="shelves__title-icon"
                  key={option.id}
                >
                  {option.name}
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchTerm
