import {
  SCRIPT_RESET,
  SCRIPT_SELECTED,
  SCRIPT_SELECTED_ERROR
} from '../constants/scriptConstants'

const defaultState: Script = {
  scriptData: {
    script: '',
    hash: '',
    vtexIo: 0,
    mode: '',
    companyVtex: '',
    clientPlatform: '',
    scriptMetric: ''
  }
}

export const scriptSelectedReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case SCRIPT_SELECTED:
      return {
        scriptData: action.payload
      }
    case SCRIPT_RESET:
      return {
        scriptData: {
          script: '',
          hash: '',
          vtexIo: 0,
          mode: '',
          companyVtex: '',
          clientPlatform: '',
          scriptMetric: ''
        }
      }
    case SCRIPT_SELECTED_ERROR:
      return {
        script: action.payload
      }
    default:
      return state
  }
}
