import React from 'react'
import './Modal.css'

interface ModalProps {
  title: string
  myWidth: string
  isOpen: boolean
  content: string
  onClose: () => void
}

export const Modal: React.FC<ModalProps> = ({
  title,
  myWidth,
  content,
  isOpen,
  onClose,
  children
}) => {
  const overLayRef = React.useRef(null)

  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.target === overLayRef.current) {
      onClose()
    }
  }

  return isOpen ? (
    <div className={'modal'}>
      <div
        className={'modal_overlay'}
        ref={overLayRef}
        onClick={handleOverLayClick}
      >
        <div className={'modal_box'} style={{ width: myWidth }}>
          <div className={'modal_close-btn'} onClick={onClose}>
            &#10006;
            <div className={'modal_title'} style={{ display: 'flex' }}>
              <div
                className="rule-config__title-icon"
                style={{ lineHeight: 'unset' }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  ></path>
                </svg>
              </div>
              {title}
            </div>
          </div>
          <div className={'modal_content'}>
            <div className="row">
              <h6 className="rule-config__endpoint-selector-text col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {content}
              </h6>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
