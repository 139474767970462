import Swal from 'sweetalert2'
import withReactContent, {
  ReactSweetAlertOptions
} from 'sweetalert2-react-content'

const ShowAlert = async (props: ReactSweetAlertOptions) => {
  const MySwal = withReactContent(Swal)
  return await MySwal.fire(props)
}

export default ShowAlert