/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  ActionWithRulesProps,
  defaultValidator,
  Field,
  QueryBuilder,
  RuleGroupType,
  RuleType,
  RuleValidator,
  ValidationResult
} from 'react-querybuilder'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../config'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import { Loading } from '../../Globals/Loading'
import { AttributeGroup } from './AttributeGroup'
// import ValueEditor from './ValueEditor'
import './CDPStyles.scss'
// import 'react-querybuilder/dist/query-builder.css'
import { CustomValueEditor } from './CustomValueEditor'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import {
  BsDownload,
  BsFillPlusCircleFill,
  BsFilter,
  BsPlus,
  BsTrash
} from 'react-icons/bs'
import { useClientContext } from '../../../contexts/ClientContext'

const CDPForm = ({
  back,
  audienceId,
  audienceName,
  audienceDescription,
  queryEditValues
}: any) => {
  const intl = useIntl()
  // const [queryValues, setQueryValues] = useState<any>('')
  const [audName, setAudName] = useState<any>(audienceName)
  const [audDescription, setAudDescription] = useState<any>(audienceDescription)
  const [queryMockData, setQueryMockData] = useState<any>([])
  const [applyFilterMessage, setApplyFilterMessage] = useState<any>('')
  const [ruleFields, setRuleFields] = useState<any>(['email'])
  const [loading, setLoading] = useState(false)
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript, selectedClient } = useClientContext()
  const dispatch = useDispatch()

  const [query, setQuery] = useState<RuleGroupType>(
    queryEditValues !== ''
      ? JSON.parse(
          queryEditValues
            .replaceAll('condition', 'combinator')
            .replaceAll('AND', 'and')
        )
      : {
          combinator: 'and',
          rules: []
        }
  )

  const context: { fieldOptions: Array<{ name: string; options: Field[] }> } = {
    fieldOptions: queryMockData.length
      ? queryMockData
      : [
          {
            name: 'ClosedCart',
            options: [
              {
                name: 'cartclosedheader._Count',
                type: 'integer',
                input: 'number',
                label: 'ClosedCart._Count',
                operators: [
                  { name: 'less_or_equal', label: 'less or equal' },
                  { name: 'greater_or_equal', label: 'greater or equal' }
                ]
              }
            ]
          }
        ]
  }
  let fields: Field[] = []
  context.fieldOptions.forEach((optGroup) => {
    fields = fields.concat(optGroup.options)
  })
  const validator: RuleValidator = (q): ValidationResult => ({
    valid: q.value === '' ? false : true,
    reasons: ['this field is always invalid']
  })
  const processRule = (
    r: RuleType
  ): RuleType & {
    type?: string
    input?: string
    operator?: string
    validator: any
  } => ({
    field: r.field,
    id: r.id,
    type: fields.find((f) => f.name === r.field)?.type,
    input: fields.find((f) => f.name === r.field)?.input,
    value: r.operator === 'between' ? [r.value][0] : [r.value],
    operator: r.operator,
    validator:
      fields.find((f) => f.name === r.field)?.input == 'text' ||
      fields.find((f) => f.name === r.field)?.input == 'number'
        ? validator(r)
        : null
  })

  const processGroup = (rg: RuleGroupType): RuleGroupType => ({
    combinator: rg.combinator,
    rules: rg.rules.map((r: RuleType | RuleGroupType) => {
      if (r['field' as 'id'] != null) {
        return processRule(r as RuleType)
      }
      return processGroup(r as RuleGroupType)
    })
  })
  const result = processGroup(query)

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title:
        queryEditValues == ''
          ? intl.formatMessage(
              { id: 'app.cdp.create-audience' },
              { defaultMessage: 'Crear Audiencia' }
            )
          : intl.formatMessage(
              { id: 'app.cdp.edit-audience' },
              { defaultMessage: 'Editar Audiencia' }
            ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: !isToast
    })
  }

  const AlertToken = (action: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        switch (action) {
          case 'save':
            createSegmentCDP()
            break
          case 'download':
            downloadSegmentCDP(result.value)
            break
          default:
            break
        }
      }
    })
  }

  const removeGroupsAlert = (props: any, e: any) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.btn.borrar' },
        { defaultMessage: 'Borrar' }
      ),
      html: intl.formatMessage(
        { id: 'app.cdp.remove-group-alert' },
        { defaultMessage: '¿Desea borrar los grupos heredados?' }
      ),
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.si' },
        { defaultMessage: 'Sí' }
      ),
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600
    }).then((result) => {
      if (result.isConfirmed) {
        props.handleOnClick(e)
      }
    })
  }

  const createSegmentCDP = () => {
    const obj = JSON.stringify(result).replaceAll('combinator', 'condition')
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        username: user.data.username,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify({
        Id: audienceId !== '' ? audienceId : 0,
        Name: audName,
        Description: audDescription,
        obj: JSON.parse(obj)
      })
    }
    fetch(
      `${config.base_server_path}/Automation/CreateSegmentCDP?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
      .then((response: any) => response.json())
      .then((res: any) => {
        if (res.StatusCode == 0) {
          MyAlert(res.Message, 1)
          back()
        } else {
          MyAlert(res.Message, 2)
        }
      })
  }

  const applyFilterSegmentCDP = () => {
    setApplyFilterMessage('')
    setLoading(true)
    const obj = JSON.stringify(result).replaceAll('combinator', 'condition')
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(JSON.parse(obj))
    }
    fetch(
      `${config.base_server_path}/Automation/ApplyFilterSegmentCDP?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
      .then((response: any) => response.json())
      .then((res: any) => {
        setLoading(false)
        setApplyFilterMessage(res.Message)
      })
  }

  const downloadSegmentCDP = async (tokenGoogle: string) => {
    // setLoading(true)
    const obj = JSON.stringify(result).replaceAll('combinator', 'condition')
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        userName: user.data.username,
        tokenGoogle,
        'Content-Type': 'application/json',
        responseType: 'blob',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify({
        RuleFields: ruleFields.join(', '),
        obj: JSON.parse(obj)
      })
    }
    const res = await fetch(
      `${config.base_server_path}/Automation/DownloadSegmentCDP?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
    const d = new Date()
    const currDate =
      `${d.getFullYear()}` +
      '_' +
      `${d.getMonth() + 1}` +
      '_' +
      `${d.getDate()}` +
      '_' +
      `${d.getHours()}:` +
      `${d.getMinutes()}:` +
      `${d.getSeconds()}`

    const data = await res.blob()
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `audience_${currDate}.csv.zip`)
    document.body.appendChild(link)
    link.click()
  }

  const getDataQueryBuilder = () => {
    // setLoading(true)
    Service.get<any>(
      `Automation/GetDataQueryBuilder?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          token: user.data.token
        }
      }
    )
      .then((response) => {
        const sums= response.data?.Serializable.concat(response.data?.NonSerializable)
        const objTwoArray = sums.reduce((c: any, v: any) => {
          c[v.optgroup] = c[v.optgroup] || {
            name: v.optgroup,
            options: new Set()
          }
          c[v.optgroup].options.add({
            id: v.input,
            name: v.field,
            type: v.type,
            input: v.input,
            label: v.label,
            operators: v.operators.map((operator: any) => {
              return { name: operator, label: operator }
            }),
            values: v.values !== null ? Object.entries(v.values) : null
          })
          return c
        }, {});
        
        console.log('el ser', objTwoArray)
        
        setQueryMockData(
          Object.values(
            objTwoArray
          ).map((o: any, key: any) => {
            o.options = [...o.options]
            return o
          })
        )
        // setLoading(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        }
        if (error.response.data === -9) {
          MyAlert(
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            intl.formatMessage(
              { id: 'app.menu.client' },
              { defaultMessage: 'Cliente' }
            ) +
              ' ' +
              selectedClient?.clientName +
              ', ' +
              intl.formatMessage(
                { id: 'app.cdp.alert.limit' },
                {
                  defaultMessage:
                    'automation actualmente esta siendo usado por otro usuario'
                }
              ),
            3
          )
        }
      })
  }

  const handleCheckboxExportList = (checkbox: any) => {
    if (checkbox.checked == true) {
      setRuleFields((oldArray: any) => oldArray.concat(checkbox.id))
    } else {
      setRuleFields(ruleFields.filter((e: any) => e !== checkbox.id))
    }
  }

  const validateFields = (action: string) => {
    const validQuery = document.getElementsByClassName(
      'queryBuilder-invalid'
    ).length
    switch (action) {
      case 'save':
        if (audName == '' || validQuery >= 1) {
          MyAlert(
            intl.formatMessage(
              { id: 'app.cdp.audience.form.save-audience-error' },
              {
                defaultMessage:
                  'Para guardar la audiencia, debe indicar su nombre y tener al menos una regla válida.'
              }
            ),
            2
          )
        } else {
          // AlertToken(action)
          createSegmentCDP()
          document
            .getElementById('saveBrandPriority')
            ?.classList.add('disabled')
        }
        break
      case 'download':
        if (ruleFields.length === 0 || validQuery >= 1) {
          MyAlert(
            intl.formatMessage(
              { id: 'app.cdp.audience.form.download-audience-error' },
              {
                defaultMessage:
                  'Para exportar la audiencia, es necesario seleccionar al menos un campo y tener al menos una regla válida.'
              }
            ),
            2
          )
        } else {
          AlertToken(action)
        }
        break
      case 'filter':
        if (validQuery >= 1) {
          MyAlert(
            intl.formatMessage(
              { id: 'app.cdp.audience.form.filter-audience-error' },
              {
                defaultMessage:
                  'Cada regla dentro del Editor debe contener valores.'
              }
            ),
            2
          )
        } else {
          applyFilterSegmentCDP()
        }
        break
      default:
        break
    }
  }

  const AddRuleButton = (props: ActionWithRulesProps) => (
    <button
      type="button"
      data-testid="add-group"
      className="ruleGroup-addGroup"
      title="Add group"
      onClick={(e) => props.handleOnClick(e)}
      // style={{ position: 'relative', left: '60%' }}
    >
      ADD RULE <BsPlus size={25} />
    </button>
  )

  const AddGroupButton = (props: ActionWithRulesProps) => (
    <button
      type="button"
      data-testid="add-group"
      className="ruleGroup-addGroup"
      title="Add group"
      onClick={(e) => props.handleOnClick(e)}
      // style={{ position: 'relative', left: '60%' }}
    >
      ADD GROUP
      <BsFillPlusCircleFill
        size={23}
        style={{ marginLeft: '8px', marginBottom: '1px' }}
      />
    </button>
  )

  const RemoveGroupButton = (props: ActionWithRulesProps) => (
    <button
      onClick={(e) =>
        props.rules !== undefined
          ? props.rules.length > 0
            ? removeGroupsAlert(props, e)
            : props.handleOnClick(e)
          : ''
      }
      style={{
        border: 'none',
        background: 'none'
      }}
    >
      <BsTrash size="25" color="red" />
    </button>
  )

  const RemoveRuleButton = (props: ActionWithRulesProps) => (
    <button
      onClick={(e) => props.handleOnClick(e)}
      style={{
        border: 'none',
        background: 'none'
      }}
    >
      <BsTrash size="25" color="red" />
    </button>
  )

  useEffect(() => {
    getDataQueryBuilder()
  }, [])

  return (
    <>
      <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
        <div className="shelves__wrapper">
          <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
            <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 jc-flexend">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    <FormattedMessage
                      id="app.cdp.audience.form.name"
                      defaultMessage="Nombre"
                    />
                    <TooltipPanel 
                      ObjectTooltip={user.data.tooltips.filter((
                        (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CDP.Nombre.tooltip')
                      )[0]}
                    />
                  </label>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <input
                  className="input vtexskuconsult__input col-lg-8 col-md-8 col-sm-8 col-xs-8"
                  type="text"
                  value={audName}
                  onChange={(e) => setAudName(e.target.value)}
                  placeholder={intl.formatMessage(
                    { id: 'app.cdp.audience.form.name' },
                    { defaultMessage: 'Nombre' }
                  )}
                />
              </div>
            </div>
            <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 jc-flexend">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    <FormattedMessage
                      id="app.cdp.audience.form.description"
                      defaultMessage="Description"
                    />
                    <TooltipPanel 
                      ObjectTooltip={user.data.tooltips.filter((
                        (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CDP.Descripcion.tooltip')
                      )[0]}
                    />
                  </label>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <textarea
                  className="input vtexskuconsult__input vtexskuconsult__textarea col-lg-8 col-md-8 col-sm-8 col-xs-8"
                  rows={6}
                  value={audDescription}
                  onChange={(e) => setAudDescription(e.target.value)}
                  placeholder={intl.formatMessage(
                    { id: 'app.cdp.audience.form.description' },
                    { defaultMessage: 'Descripción' }
                  )}
                />
              </div>
            </div>
            <hr className="my-4 linea-hr" />
            <h1 className="my-3" style={{ fontSize: '17px' }}>
              Editor
              <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CDP.QueryBuilder.tooltip')
                  )[0]}
                />
            </h1>

            <QueryBuilder
              controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
              fields={fields}
              query={query}
              validator={defaultValidator}
              onQueryChange={(q) => {
                console.log('Query G:', q)
                setQuery(q)
                setApplyFilterMessage('')
              }}
              controlElements={{
                fieldSelector: AttributeGroup,
                valueEditor: CustomValueEditor,
                addGroupAction: AddGroupButton,
                addRuleAction: AddRuleButton,
                removeRuleAction: RemoveRuleButton,
                removeGroupAction: RemoveGroupButton
              }}
              context={context}
            />
            <div style={{ marginTop: '10px', display: 'flex' }}>
              <>
              <button
                className="ppal_button btn btn-primary caja-alert"
                id="applyFilter"
                style={{ marginRight: '5%' }}
                onClick={() => validateFields('filter')}
              >
                <BsFilter />
                &nbsp;
                <FormattedMessage
                  id="app.cdp.audience.form.apply-filter"
                  defaultMessage="Aplicar Filtro"
                />
              </button>
              <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.cdp.btn.filter.audience.tooltip')
                  )[0]}
                />
              </>

              {loading ? (
                <div>
                  <Loading
                    textLoading={intl.formatMessage(
                      { id: 'app.vitrinas-spinner.espere' },
                      { defaultMessage: 'Espere...' }
                    )}
                  />
                </div>
              ) : (
                <div
                  role="alert"
                  className={
                    applyFilterMessage
                      ? 'alert alert-primary caja-alert'
                      : 'none'
                  }
                >
                  {applyFilterMessage}
                </div>
              )}
            </div>
            <hr className="my-4 linea-hr" />
            <div>
              <h1 className="my-3" style={{ fontSize: '17px' }}>
                <FormattedMessage
                  id="app.cdp.audience.form.export-list"
                  defaultMessage="Exportar Listado"
                />
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.cdp.form.title.export.list.tooltip')
                  )[0]}
                />
              </h1>
              <div className="shelves__buttons-container">
                <input
                  className="input"
                  id="email"
                  type={'checkbox'}
                  onChange={(e) => handleCheckboxExportList(e.target)}
                  defaultChecked
                />{' '}
                &nbsp;
                <label className="mtr-check mb-2">
                  <FormattedMessage
                    id="app.cdp.audience.form.export-fields.Email"
                    defaultMessage="Email"
                  />
                </label>
                <input
                  className="input"
                  id="name"
                  type={'checkbox'}
                  onChange={(e) => handleCheckboxExportList(e.target)}
                />{' '}
                &nbsp;
                <label className="mtr-check mb-2">
                  <FormattedMessage
                    id="app.cdp.audience.form.export-fields.Name"
                    defaultMessage="Nombre"
                  />
                </label>
                <input
                  className="input"
                  id="lastname"
                  type={'checkbox'}
                  onChange={(e) => handleCheckboxExportList(e.target)}
                />{' '}
                &nbsp;
                <label className="mtr-check mb-2">
                  <FormattedMessage
                    id="app.cdp.audience.form.export-fields.Last-Name"
                    defaultMessage="Apellido"
                  />
                </label>
                <input
                  className="input"
                  id="nationalid"
                  type={'checkbox'}
                  onChange={(e) => handleCheckboxExportList(e.target)}
                />{' '}
                &nbsp;
                <label className="mtr-check mb-2">
                  <FormattedMessage
                    id="app.cdp.audience.form.export-fields.Document"
                    defaultMessage="Documento"
                  />
                </label>
                <input
                  className="input"
                  id="gender"
                  type={'checkbox'}
                  onChange={(e) => handleCheckboxExportList(e.target)}
                />{' '}
                &nbsp;
                <label className="mtr-check mb-2">
                  <FormattedMessage
                    id="app.cdp.audience.form.export-fields.Gender"
                    defaultMessage="Género"
                  />
                </label>
                <input
                  className="input"
                  id="phone"
                  type={'checkbox'}
                  onChange={(e) => handleCheckboxExportList(e.target)}
                />{' '}
                &nbsp;
                <label className="mtr-check mb-2">
                  <FormattedMessage
                    id="app.cdp.audience.form.export-fields.Phone"
                    defaultMessage="Teléfono"
                  />
                </label>
                <input
                  className="input"
                  id="birthday"
                  type={'checkbox'}
                  onChange={(e) => handleCheckboxExportList(e.target)}
                />{' '}
                &nbsp;
                <label className="mtr-check mb-2">
                  <FormattedMessage
                    id="app.cdp.audience.form.export-fields.BirthDay"
                    defaultMessage="Fecha de Nacimiento"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row shelves button botonera">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            style={{ display: 'flex' }}
          >
            <div className="shelves__preview-button centrar">
              <div className="shelves__preview-button">
                <button
                  className="ppal_button btn btn-primary"
                  id="saveBrandPriority"
                  onClick={() => validateFields('save')}
                >
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.guardar"
                    defaultMessage="GUARDAR"
                  />
                </button>
              </div>
              <div className="shelves__preview-button">
                <button
                  id={'btn-descargar'}
                  className="btn btn-success m-lef"
                  onClick={() => {
                    validateFields('download')
                  }}
                >
                  <BsDownload />
                  &nbsp;
                  <FormattedMessage
                    id="app.cdp.audience.form.export-fields.Download"
                    defaultMessage="DESCARGAR"
                  />
                </button>
              </div>
              <div className="shelves__preview-button">
                <button
                  id={'btn-regresar'}
                  className="manage-grid-button shelves__title-icon"
                  onClick={() => {
                    back()
                  }}
                >
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.volver"
                    defaultMessage="VOLVER"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CDPForm
