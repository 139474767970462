import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { langSelected } from '../../state/actions/langAction'
import { RootState } from '../../state/store'
// import { ES, GB } from "country-flag-icons/string/3x2";
import './style.css'

const LanguageSelector = () => {
  const dispatch = useDispatch()
  const initLang = useSelector<RootState, Lang>((state) => state.langSelected)
  const [lang, setLang] = useState(initLang.lang)
  const [buttonClick, setButtonClick] = useState(true)

  useEffect(() => {
    if (initLang.lang === 'es') {
      setButtonClick(true)
    } else {
      setButtonClick(false)
    }
  }, [])

  useEffect(() => {
    if (buttonClick) {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [buttonClick])

  useEffect(() => {
    dispatch(langSelected(lang))
  }, [lang])

  const handleChange = (name: string) => {
    if (name === 'es') {
      setButtonClick(true)
    } else {
      setButtonClick(false)
    }
  }

  return (
    <div className="col-1 lang col-md-1 col-sm-1 col-xs-1 hide">
      <button className="btn-lang" name="es" onClick={() => handleChange('es')}>
        <span className="es">
          <svg
            className="es-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27 27"
            x="0px"
            y="0px"
            width="27px"
            height="27px"
            xmlSpace="preserve"
          >
            <g>
              <path
                style={{ fill: '#FF0000' }}
                d="M13.5,1C8.9,1,4.9,3.5,2.7,7.2h21.5C22.1,3.5,18.1,1,13.5,1z"
              />
              <path
                style={{ fill: '#FF0000' }}
                d="M2.7,19.8C4.9,23.5,8.9,26,13.5,26c4.6,0,8.6-2.5,10.8-6.2H2.7z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFF33' }}
                d="M26,13.5c0-2.3-0.6-4.5-1.7-6.3H2.7C1.6,9,1,11.2,1,13.5s0.6,4.5,1.7,6.3h21.5C25.4,18,26,15.8,26,13.5z"
              />
            </g>
          </svg>
        </span>
      </button>
      <button className="btn-lang" onClick={() => handleChange('en')}>
        <span>
          <svg
            className="us-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27 27"
            x="0px"
            y="0px"
            width="27px"
            height="27px"
            xmlSpace="preserve"
          >
            <g>
              <path
                className="st0"
                style={{ fill: '#000066' }}
                d="M9.2,1.8C7,2.5,5.1,3.9,3.7,5.7l5.5,2.8V1.8z"
              />
              <path
                className="st0"
                style={{ fill: '#000066' }}
                d="M17.8,25.2c2.2-0.8,4.1-2.2,5.5-4l-5.5-2.8V25.2z"
              />
              <path
                className="st0"
                style={{ fill: '#000066' }}
                d="M3.7,21.3c1.4,1.8,3.3,3.2,5.5,4v-6.7L3.7,21.3z"
              />
              <path
                className="st0"
                style={{ fill: '#000066' }}
                d="M23.3,5.7c-1.4-1.8-3.3-3.2-5.5-4v6.7L23.3,5.7z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M17.8,17.8v0.7l5.5,2.8c0.3-0.4,0.7-0.9,0.9-1.4L20,17.8H17.8z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M1.8,17.8c0.2,0.5,0.4,0.9,0.6,1.3l2.6-1.3H1.8z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M25,18.4c0.1-0.2,0.2-0.4,0.2-0.6h-1.4L25,18.4z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M9.2,17.8H8.8l-5.6,2.8c0.2,0.2,0.3,0.5,0.5,0.7l5.5-2.8V17.8z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M22.1,9.2h3.2c-0.2-0.5-0.4-0.9-0.6-1.3L22.1,9.2z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M2,8.6C1.9,8.8,1.8,9,1.8,9.2h1.4L2,8.6z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M9.2,9.2V8.5L3.7,5.7C3.4,6.2,3,6.6,2.8,7.1L7,9.2H9.2z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M17.8,9.2h0.5l5.6-2.8c-0.2-0.2-0.3-0.5-0.5-0.7l-5.5,2.8V9.2z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M10.9,16.1H1.3c0.1,0.6,0.3,1.2,0.5,1.7h3.2h3.8h0.5v0.7v6.7c0.6,0.2,1.1,0.4,1.7,0.5V16.1z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M10.9,10.9V1.3c-0.6,0.1-1.2,0.3-1.7,0.5v6.7v0.7H7H3.2H1.8c-0.2,0.6-0.4,1.1-0.5,1.7H10.9z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M16.1,16.1v9.7c0.6-0.1,1.2-0.3,1.7-0.5v-6.7v-0.7H20h3.8h1.4c0.2-0.6,0.4-1.1,0.5-1.7H16.1z"
              />
              <path
                className="st1"
                style={{ fill: '#FFFFFF' }}
                d="M18.2,9.2h-0.5V8.5V1.8c-0.6-0.2-1.1-0.4-1.7-0.5v9.7h9.7c-0.1-0.6-0.3-1.2-0.5-1.7h-3.2H18.2z"
              />
              <path
                className="st2"
                style={{ fill: '#CC0000' }}
                d="M16.1,10.9V1.3C15.2,1.1,14.4,1,13.5,1c-0.9,0-1.7,0.1-2.6,0.3v9.7H1.3C1.1,11.8,1,12.6,1,13.5
		s0.1,1.7,0.3,2.6h9.7v9.7c0.8,0.2,1.7,0.3,2.6,0.3c0.9,0,1.7-0.1,2.6-0.3v-9.7h9.7c0.2-0.8,0.3-1.7,0.3-2.6s-0.1-1.7-0.3-2.6H16.1z
		"
              />
              <path
                className="st2"
                style={{ fill: '#CC0000' }}
                d="M20,17.8l4.2,2.1c0.3-0.5,0.6-1,0.8-1.5l-1.2-0.6H20z"
              />
              <path
                className="st2"
                style={{ fill: '#CC0000' }}
                d="M7,9.2L2.8,7.1C2.5,7.6,2.2,8.1,2,8.6l1.2,0.6H7z"
              />
              <path
                className="st2"
                style={{ fill: '#CC0000' }}
                d="M4.9,17.8l-2.6,1.3c0.3,0.5,0.5,1,0.9,1.5l5.6-2.8H4.9z"
              />
              <path
                className="st2"
                style={{ fill: '#CC0000' }}
                d="M22.1,9.2l2.6-1.3c-0.3-0.5-0.5-1-0.9-1.5l-5.6,2.8H22.1z"
              />
            </g>
          </svg>
        </span>
      </button>
    </div>
  )
}

export default LanguageSelector
