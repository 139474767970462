import { useSelector } from 'react-redux'
import { RootState } from '../state/store'

const useLogged = () => {
  const data = useSelector<RootState, StateResponse>((state) => state.userLogin)

  return data.loading
}

export default useLogged
