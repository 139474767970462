import React from 'react'

const SearchLogoSvg = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="0 90 550 300"
      style={{ enableBackground: 'new 0 0 595.3 841.9' }}
      xmlSpace="preserve"
    >

      <g>
        <path
          
          d="M365.4,447.7c-9.2-1.9-16-7.5-22.3-14.2c-7.2-7.6-14.8-14.8-22.1-22.3c-1.8-1.9-3.7-2.5-6.2-2.5
		c-24.7,0.1-49.4,0.1-74.1,0.1c-1.2,0-2.5,0.1-3.7-0.1c-4-0.4-6.9-3.8-6.8-7.6c0.1-3.7,3-6.9,6.8-7.2c2.2-0.2,4.5-0.1,6.7-0.1
		c19.6,0,39.2,0,59.3,0c-0.9-1.1-1.4-1.9-2-2.5c-9.7-9.7-19.5-19.4-29.1-29.2c-1.9-1.9-3.2-1.8-5.4-0.7
		c-60.5,30.3-132.8-2.1-150.5-67.4c-16.4-60.5,24.1-122.8,86-132.4c59.2-9.1,113.9,31.6,122,90.7c2.9,21.6-0.6,42.2-10.4,61.7
		c-1.3,2.5-1.1,4,0.9,6c23.9,23.7,47.7,47.6,71.6,71.3c1.3,1.3,3.6,2.2,5.4,2.3c10.7,0.2,21.5,0.1,32.2,0.1c6.2,0,9-2.7,9-8.8
		c0-81.1,0-162.2,0-243.3c0-0.6-0.1-1.2-0.1-2.1c-117.7,0-235.2,0-353.3,0c0,1.3,0,2.6,0,4c0,80.1,0,160.2,0,240.3
		c0,7.6,2.4,10,10.1,10c26.3,0,52.7,0,79,0c3.6,0,6.7,0.8,8.4,4.4c2.3,4.8-0.9,10.2-6.3,10.6c-3.5,0.2-7,0.1-10.5,0.1
		c-22.7,0-45.4-0.4-68.1,0.1c-13.7,0.3-23.7-4.9-27.5-18.9c0-102.3,0-204.6,0-306.9c3.9-14.1,14-18.9,27.5-18.9
		c110.1,0.2,220.3,0.1,330.4,0.1c16.5,0,25.4,8.9,25.4,25.4c0,97.9,0,195.8,0,293.7c0,1.2,0,2.5,0,3.7c-0.4,10.1-7.5,18.7-17.3,20.8
		c-2.8,0.6-5.7,0.6-8.5,0.7c-7.7,0.1-15.3,0-23.1,0c0.3,1.6,0.4,2.7,0.6,3.8c2.5,13.9-5.2,27.7-18.3,32.9c-2.2,0.9-4.4,1.5-6.7,2.2
		C371.4,447.7,368.4,447.7,365.4,447.7z M79.4,124c118,0,235.6,0,353.4,0c0-11.5,0-22.7,0-33.9c0-8.7-2.1-10.7-10.9-10.7
		c-110.5,0-221.1,0-331.6,0c-1.1,0-2.2,0-3.4,0c-4.3,0.3-7.4,3.1-7.4,7.4C79.3,99.1,79.4,111.5,79.4,124z M218.2,357.9
		c50.5,0.1,91.6-40.7,91.7-91c0.1-50.4-40.8-91.5-91.1-91.6c-50.4-0.1-91.5,40.9-91.5,91.2C127.2,316.7,168.1,357.8,218.2,357.9z
		 M307.6,376.5c17.5,17.6,34.8,35.2,52.4,52.4c2.2,2.1,5.9,3.2,9.1,3.6c6.1,0.7,11.8-3.1,14.3-8.8c2.6-5.9,1.5-12.2-3.5-17.1
		c-16.5-16.6-33.1-33.1-49.7-49.7c-0.6-0.6-1.3-1-1.7-1.3C321.5,362.7,314.6,369.5,307.6,376.5z M284.4,352.6
		c3.3,3.3,7.9,7.9,12.3,12.3c6.7-6.7,13.6-13.6,20.3-20.3c-4.4-4.4-9-9-12.3-12.2C298.1,339.1,291.1,346,284.4,352.6z"
        />
        <path
          
          d="M371,223.2c-8.9,0-17.7,0-26.6,0c-5.3,0-8.9-3.1-8.8-7.5c0-4.4,3.6-7.4,9-7.4c18,0,35.9,0,53.9,0
		c5.4,0,8.8,3,8.8,7.6c0,4.5-3.4,7.4-8.9,7.4C389.1,223.2,380.1,223.2,371,223.2z"
        />
        <path
          
          d="M387.8,187.2c-3.7,0-7.5,0.1-11.2,0c-4.7-0.1-8.1-3.4-8-7.6c0.1-4.1,3.4-7.2,7.9-7.3c7.6-0.1,15.2-0.1,22.8,0
		c4.8,0.1,8,3.3,7.9,7.6c-0.1,4.2-3.3,7.2-7.9,7.3C395.5,187.3,391.6,187.2,387.8,187.2z"
        />
        <path
          
          d="M391,244.1c3,0,6-0.1,8.9,0c4.3,0.2,7.3,3.4,7.3,7.4s-3,7.3-7.3,7.4c-6.3,0.2-12.7,0.2-19,0
		c-4.2-0.1-7.3-3.6-7.2-7.6c0.1-3.8,3.1-7,7.1-7.3C384.2,244,387.6,244.1,391,244.1L391,244.1z"
        />
        <path
          
          d="M335.1,187.2c-2.5,0-5,0.1-7.5,0c-4.3-0.2-7.3-3.3-7.3-7.4s2.9-7.3,7.2-7.5c5.2-0.2,10.4-0.2,15.7,0
		c4.3,0.1,7.3,3.3,7.3,7.4s-2.9,7.2-7.2,7.5C340.6,187.3,337.8,187.2,335.1,187.2z"
        />
        <path
          
          d="M211.2,401.3c0,4.1-3.2,7.4-7.3,7.5s-7.7-3.5-7.6-7.6c0.1-4,3.6-7.3,7.6-7.3
		C208,393.9,211.2,397.1,211.2,401.3z"
        />
        <path
          
          d="M319,94.4c25.9,0,51.9,0,77.8,0c1.4,0,2.8-0.1,4.1,0.1c3.6,0.6,6.3,3.7,6.4,7.2c0.1,3.7-2.7,7-6.5,7.5
		c-1.2,0.2-2.5,0.1-3.7,0.1c-52.1,0-104.2,0-156.3,0c-1,0-2,0-3,0c-4.1-0.4-7-3.4-7.1-7.3c-0.1-4,3-7.2,7.2-7.6c1.1-0.1,2.2,0,3.4,0
		C267.1,94.4,293.1,94.4,319,94.4z"
        />
        <path
          
          d="M112.2,94.4c4.1,0,7.6,3.5,7.5,7.6c-0.1,4-3.5,7.3-7.4,7.3c-4.1,0-7.6-3.5-7.5-7.6
		C105,97.7,108.2,94.4,112.2,94.4z"
        />
        <path
          
          d="M145.4,109.3c-4.1,0.1-7.6-3.4-7.6-7.5c0-4,3.3-7.3,7.3-7.4c4.1-0.1,7.6,3.4,7.6,7.5
		C152.7,105.9,149.4,109.2,145.4,109.3z"
        />
        <path
          
          d="M185.7,101.8c0,4.1-3.5,7.6-7.6,7.5c-4-0.1-7.3-3.4-7.3-7.4c0-4.1,3.5-7.6,7.6-7.5
		C182.2,94.5,185.6,97.8,185.7,101.8z"
        />
        <path
          
          d="M218.2,342.9c-42-0.1-76.2-34.5-76-76.7c0.1-41.9,34.5-76,76.5-75.9c42.1,0,76.3,34.4,76.2,76.6
		C294.9,308.9,260.6,343,218.2,342.9z M218.6,327.9c33.8,0,61.3-27.6,61.3-61.4s-27.6-61.3-61.5-61.3c-33.8,0-61.3,27.6-61.3,61.5
		C157.2,300.5,184.9,327.9,218.6,327.9z"
        />
        <path
          
          d="M226.1,283.4c0,4.6,0.1,9.2,0,13.8c-0.1,4.9-3,8.1-7.3,8.2c-4.4,0.1-7.6-3.2-7.6-8.3c-0.1-9.2-0.1-18.4,0-27.6
		c0-4.8,3.1-8.1,7.3-8.2c4.4-0.1,7.6,3.3,7.6,8.3C226.1,274.2,226.1,278.8,226.1,283.4z"
        />
        <path
          
          d="M218.6,227.7c4.1,0.1,7.5,3.6,7.4,7.7c-0.2,4-3.6,7.2-7.6,7.2c-4.1-0.1-7.5-3.6-7.4-7.7
		C211.2,230.9,214.8,227.6,218.6,227.7z"
        />
      </g>
    </svg>
  )
}

export default SearchLogoSvg
