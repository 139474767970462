const UnderConstruction = () => {
  return (
    <div className="ms_ilustraconstr text-center mt-4">
      <img src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/31/enconstruccion.svg" />
      <h4 className="ms_feature-title">En construcción</h4>
    </div>
  )
}

export default UnderConstruction
