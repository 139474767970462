/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
// import { ShowAlert } from "../../Globals";
// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill
} from 'react-icons/bs'
import { useClientContext } from '../../../contexts/ClientContext'

const AsrticlesSliderContainer = () => {
  const [articles, setArticles] = useState<any>([])
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const dispatch = useDispatch()
  const { selectedScript } = useClientContext()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <BsFillArrowRightCircleFill title="Next" size={25} />,
    prevArrow: <BsFillArrowLeftCircleFill title="Previous" size={25} />
  }

  const getArticlesSider = () => {
    Service.get<any>(
      `/Panel/GetArticlesSlider?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          token: token.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setArticles(response.data.Entities)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          // MyAlert('API-ERROR: ' + error, 3);
        }
      })
  }

  useEffect(() => {
    getArticlesSider()
  }, [])

  return (
    <>
      {articles ? (
        <Slider {...settings}>
          {articles.map((article: any) => {
            return (
              <>
                <div>
                  <div className="container mg-t-30">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card" style={{ width: '100%' }}>
                          <div className="row">
                            {article.urlImage ? (
                              <div className="col-sm-4">
                                <img
                                  className="card-img-top"
                                  src={article.urlImage}
                                  alt={article.title}
                                />
                              </div>
                            ) : null}
                            <div className="col-sm-8">
                              <div className="card-body">
                                <h5 className="card-title">{article.title}</h5>
                                {article.resume ? (
                                  <h6 className="card-subtitle mb-2 text-muted">
                                    {article.resume}
                                  </h6>
                                ) : null}
                                {article.text ? (
                                  <p className="card-text">{article.text}</p>
                                ) : null}
                                <a
                                  href={article.link}
                                  target={'_blank'}
                                  title={article.title}
                                  className="card-link"
                                  rel="noreferrer"
                                >
                                  Leer más
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </Slider>
      ) : null}
    </>
  )
}

export default AsrticlesSliderContainer
