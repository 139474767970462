import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Service from '../../../services'
import { RootState } from '../../../state/store'
import ShelvesOptionsGrid from './ShelvesOptionsGrid'
import './styles.css'
import '../Global.css'
import { ShowAlert } from '../../Globals'
import { useIntl } from 'react-intl'
import { logout } from '../../../state/actions'
import { useClientContext } from '../../../contexts/ClientContext'

const ManageShelvesContainer = () => {
  const [option, setOption] = useState<any>('')
  const { selectedScript } = useClientContext()
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const [dataReloadPage, setDataReloadPage] = useState<string>('cargar')
  const [dataShowIframe, setShowIframe] = useState<string>('cargar')

  const handlerDataReloadPage = (e: string) => {
    setDataReloadPage(e)
  }
  const handlerShowIframe = (e: string) => {
    setShowIframe(e)
  }

  const dispatch = useDispatch()
  const intl = useIntl()

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  useEffect(() => {
    Service.get<any>(
      `/ManageCarrousel/GetTargetGondole?idHashScript=${selectedScript?.idHashScript}`,
      { headers: { token: token.data.token } }
    )
      .then((response) => {
        setOption(response.data)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }, [token, dataReloadPage, dataShowIframe])

  console.log(dataShowIframe.length)

  return (
    <>
      <div className="container mg-t-30">
        <div className="alert-heading">
          {intl.formatMessage(
            { id: 'app.tooltips.header.title.carruseles' },
            { defaultMessage: 'Manejo de carruseles' }
          )}
        </div>
        <p>
          {intl.formatMessage(
            { id: 'app.tooltips.header.p.carruseles' },
            { defaultMessage: 'Manejo de carruseles' }
          )}
        </p>
      </div>
      <div className="container mg-t-30">
        <ShelvesOptionsGrid
          shelves={option}
          handlerDataReloadPage={handlerDataReloadPage}
          handlerShowIframe={handlerShowIframe}
        />
      </div>
      {dataShowIframe.length !== 0 &&
      selectedScript?.scriptMetric !== '' &&
      selectedScript?.scriptMetric !== null ? (
        <div className="container mg-t-30 video-responsive video-home">
          <iframe
            className="iframe-video"
            width="900"
            height="600"
            src={selectedScript?.scriptMetric}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      ) : null}
    </>
  )
}

export default ManageShelvesContainer
