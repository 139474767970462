export const alertLogin = (initLang) => {
  const lang = initLang.lang

  const ERROR_TEXT_RESPONSE = {
    es: 'Por favor, Revisa tus Credenciales',
    en: 'Please, Check your Credentials'
  }

  const TITLE_RESPONSE = {
    es: 'Bienvenido/a',
    en: 'Welcome back'
  }

  const TEXT_REDIRECT_RESPONSE = {
    es: 'Ahora serás redireccionado para Autenticarte',
    en: 'You will be redirected to authenticate now'
  }

  const errorTextResponse = ERROR_TEXT_RESPONSE[lang]
  const titleResponse = TITLE_RESPONSE[lang]
  const textRedirectResponse = TEXT_REDIRECT_RESPONSE[lang]

  return {
    errorTextResponse,
    titleResponse,
    textRedirectResponse
  }
}

export const alertAuth = (initLang) => {
  const lang = initLang.lang

  const TITLE_RESPONSE = {
    es: 'Estas Autenticado!',
    en: "You're Authenticated!"
  }

  const TEXT_REDIRECT_RESPONSE = {
    es: 'Bienvenido a Braindw!',
    en: 'Welcome to Braindw!'
  }

  const ERROR_TEXT_RESPONSE = {
    es: 'Algo salió mal con la Autenticación',
    en: 'Authentication Failed'
  }

  const errorTextResponse = ERROR_TEXT_RESPONSE[lang]
  const titleResponse = TITLE_RESPONSE[lang]
  const textRedirectResponse = TEXT_REDIRECT_RESPONSE[lang]

  return {
    errorTextResponse,
    titleResponse,
    textRedirectResponse
  }
}
