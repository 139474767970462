const PLPLogoSvg = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="0 90 550 300"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
        
          d="M100,4780c-20-20-20-33-20-2220s0-2200,20-2220s33-20,2460-20s2440,0,2460,20s20,33,20,2220s0,2200-20,2220
		s-33,20-2460,20S120,4800,100,4780z M4880,4240v-400H2560H240v400v400h2320h2320V4240z M880,3600v-80H560H240v80v80h320h320V3600z
		 M1680,3600v-80h-320h-320v80v80h320h320V3600z M2480,3600v-80h-320h-320v80v80h320h320V3600z M3270,3656c0-33-39-86-80-110
		c-32-19-54-21-292-24l-258-3v80v81h315h315V3656z M4880,2080V480H2560H240v1440v1440h1475c1470,0,1474,0,1530,21
		c105,39,195,162,195,264v35h720h720V2080z"
        />
        <path
        
          d="M535,4461c-87-40-135-120-135-223c1-142,97-238,240-238c144,0,240,96,240,240c0,104-53,187-142,224
		C686,4486,585,4484,535,4461z M690,4290c43-43,11-120-50-120c-38,0-70,32-70,70s32,70,70,70C657,4310,679,4301,690,4290z"
        />
        <path
        
          d="M1175,4461c-87-40-135-120-135-223c1-142,97-238,240-238c144,0,240,96,240,240c0,104-53,187-142,224
		C1326,4486,1225,4484,1175,4461z M1330,4290c11-11,20-33,20-50s-9-39-20-50c-43-43-120-11-120,50c0,38,32,70,70,70
		C1297,4310,1319,4301,1330,4290z"
        />
        <path
        
          d="M1815,4461c-87-40-135-120-135-223c0-74,23-129,71-175c71-67,3-63,1129-63s1058-4,1129,63c48,46,71,101,71,175
		c0,103-48,183-135,223c-38,18-88,19-1065,19S1853,4479,1815,4461z M3865,4306c37-16,52-48,41-92c-14-55,9-54-1026-54
		s-1012-1-1026,54c-10,43,4,76,39,91C1941,4326,3814,4327,3865,4306z"
        />
        <path
        
          d="M4375,4461c-87-40-135-120-135-223c1-142,97-238,240-238c144,0,240,96,240,240c0,104-53,187-142,224
		C4526,4486,4425,4484,4375,4461z M4530,4290c11-11,20-33,20-50s-9-39-20-50s-33-20-50-20s-39,9-50,20s-20,33-20,50s9,39,20,50
		s33,20,50,20S4519,4301,4530,4290z"
        />
        <path
        
          d="M420,3180c-20-20-20-33-20-1260s0-1240,20-1260s33-20,1020-20s1000,0,1020,20s20,33,20,1260s0,1240-20,1260
		s-33,20-1020,20S440,3200,420,3180z M2320,1920V800h-880H560v1120v1120h880h880V1920z"
        />
        <path
        
          d="M1335,2861c-49-23-99-74-119-123c-9-22-16-67-16-106c0-69,0-70-29-75c-17-4-35-16-41-28c-14-26-90-468-90-526
		c0-30,6-49,20-63c19-19,33-20,380-20s361,1,380,20c14,14,20,33,20,63c0,58-76,500-90,526c-6,12-24,24-41,28c-29,5-29,6-29,77
		c-1,111-51,192-142,230C1486,2886,1385,2884,1335,2861z M1480,2700c22-12,40-60,40-106v-34h-80h-80v34
		C1360,2685,1416,2734,1480,2700z M1629,2263c13-76,25-148,28-160l5-23h-222h-222l5,23c3,12,15,84,28,160l22,137h167h167L1629,2263z
		"
        />
        <path d="M720,1680v-80h720h720v80v80h-720H720V1680z" />
        <path d="M720,1360v-80h720h720v80v80h-720H720V1360z" />
        <path d="M720,1040v-80h560h560v80v80h-560H720V1040z" />
        <path d="M2000,1040v-80h80h80v80v80h-80h-80V1040z" />
        <path
        
          d="M2660,3180c-20-20-20-33-20-1260s0-1240,20-1260s33-20,1020-20s1000,0,1020,20s20,33,20,1260s0,1240-20,1260
		s-33,20-1020,20S2680,3200,2660,3180z M4560,1920V800h-880h-880v1120v1120h880h880V1920z"
        />
        <path
        
          d="M3575,2861c-49-23-99-74-119-123c-9-22-16-67-16-106c0-69,0-70-29-75c-17-4-35-16-41-28c-14-26-90-468-90-526
		c0-30,6-49,20-63c19-19,33-20,380-20s361,1,380,20c14,14,20,33,20,63c0,58-76,500-90,526c-6,12-24,24-41,28c-29,5-29,6-29,77
		c-1,111-51,192-142,230C3726,2886,3625,2884,3575,2861z M3720,2700c22-12,40-60,40-106v-34h-80h-80v34
		C3600,2685,3656,2734,3720,2700z M3869,2263c13-76,25-148,28-160l5-23h-222h-222l5,23c3,12,15,84,28,160l22,137h167h167L3869,2263z
		"
        />
        <path
        
          d="M2960,1680v-80h720h720v80v80h-720h-720V1680z"
        />
        <path
        
          d="M2960,1360v-80h720h720v80v80h-720h-720V1360z"
        />
        <path
        
          d="M2960,1040v-80h560h560v80v80h-560h-560V1040z"
        />
        <path d="M4240,1040v-80h80h80v80v80h-80h-80V1040z" />
      </g>
    </svg>
  )
}

export default PLPLogoSvg
