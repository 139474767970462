/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/promise-function-async */
import React, { useEffect, useState } from 'react'
import { ProgressBar } from '../../Globals/ProgressBar'
import Papa from 'papaparse'
import './automation.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import { config } from '../../../config'
import { FormattedMessage, useIntl } from 'react-intl'
import { Loading } from '../../Globals/Loading'
import { ShowAlert } from '../../../components/Globals'
import { Tooltip } from '../../Globals/Tooltip'
import { useClientContext } from '../../../contexts/ClientContext'

const CreateAudiences = ({ newAudience }: any) => {
  const [jsonFile, setJsonFile] = useState<any>([])
  const [nameAudiencia, setNameAudiencia] = useState('')
  const [descAudiencia, setDescAudiencia] = useState('')
  const [dataAudiencia, setDataAudiencia] = useState([] as any)
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [progressBar, setProgresBar] = useState('' as any)
  const [messageResult, setMessageResult] = useState([] as any)
  const [jsonFileInput, setJsonFileInput] = useState('')
  const [tokenValue, setTokenValue] = useState<any>('')

  const [messageResultApi, setMessageResultApi] = useState<string>('')
  const intl = useIntl()

  const [viewSpinner, setViewSpinner] = useState<boolean>(false)

  const AlertToken = () => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve(
              intl.formatMessage(
                { id: 'app.token-inexistente' },
                { defaultMessage: 'Debe ingresar el token.' }
              )
            )
          } else {
            resolve('')
          }
        })
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setTokenValue(result.value)
        crearAudiencia(result.value)
      }
    })
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.audiencias' },
        { defaultMessage: 'Audiencias' }
      ),
      text,
      icon:
        iconStr === 1
          ? 'success'
          : iconStr === 2
          ? 'error'
          : iconStr === 4
          ? 'info'
          : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const handleFile = (e: any) => {
    if (e.target.files[0].size > 10000000) {
      // 10MB
      MyAlert(
        intl.formatMessage(
          { id: 'app.suscribir.file.zise' },
          {
            defaultMessage:
              'El archivo que intenta subir supera el máximo permitido de 20MB.'
          }
        ),
        3
      )
    } else {
      if (
        e.target.files[0].type === 'text/csv' ||
        e.target.files[0].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        // 10MB
        Papa.parse(e.target.files[0], {
          complete: function (results) {
            console.log('results: ', results.data)
            if (results.data.length === 0) {
              MyAlert(
                intl.formatMessage(
                  { id: 'app.suscribir.file.empty' },
                  { defaultMessage: 'Archivo vacio.' }
                ),
                3
              )
            }
            setJsonFile(results.data)
          }
        })
      } else {
        MyAlert(
          intl.formatMessage(
            { id: 'app.suscribir.file.ext' },
            { defaultMessage:'Las extensiones permitidas son *.csv o *.xls/xlsx' }
          ), 3
        )
      }
    }
  }

  const handleName = (e: any) => {
    if (e.target.value.match('^[a-zA-Z0-9_]*$') != null) {
      setNameAudiencia(e.target.value.toLowerCase())
    }
  }

  const handleDescription = (e: any) => {
    setDescAudiencia(e.target.value)
  }

  const crearAudiencia = async (tokenValue: string) => {
    const arrayEmails = jsonFile.map(function (es: any[]) {
      return es[0] // el papa.parse, retorna un array dentro de otro array
    })
    const data = {
      hash: selectedScript?.idHashScript,
      token: user.data.token,
      name: nameAudiencia,
      description: descAudiencia,
      email: arrayEmails
    }

    setDataAudiencia(data)

    if (data.email.length > 0) {
      setViewSpinner(true)

      const dataCopy = structuredClone(data)
      const limitSend = 2000
      for (let i = 0; i <= data.email.length; i += limitSend) {
        dataCopy.email = data.email.slice(i, i + limitSend)

        await postAudiencia(dataCopy, tokenValue)

        let porc = i + limitSend
        if (i + limitSend > data.email.length) porc = data.email.length
        const prog1 = Math.round((100 * porc) / data.email.length)
        if (prog1 <= 100) {
          if (messageResultApi !== 'error api') {
            setProgresBar(prog1)
            MyAlert(`${prog1}% | Procesando ${porc} correos...`, 4, 8000)
            setMessageResult(`${prog1}% | Procesando ${porc} correos...`)
          }
        }
      }

      newAudience(true)
      setViewSpinner(false)

      setTimeout(() => {
        setProgresBar('0')
        setMessageResult([])
        setNameAudiencia('')
        setDescAudiencia('')
        setJsonFileInput('')
      }, 6000)
    } else {
      MyAlert(
        intl.formatMessage(
          { id: 'app.suscribir.file.empty' },
          { defaultMessage: 'El archivo csv no tiene emails o está vacio.' }
        ),
        3
      )
    }
  }

  useEffect(() => {
    if (Object.entries(dataAudiencia).length > 0) {
      // postAudiencia(dataAudienciaCopy);
    }
  }, [dataAudiencia])

  const postAudiencia = async (d: any, tokenGoogle: string) => {
    const audience = {
      SegmentName: d?.name,
      Description: d?.description,
      Emails: d?.email
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        tokenGoogle,
        username: user.data.username,
        'Content-Type': 'application/json',
        Accept: '* / *',
        credentials: 'include'
      },
      body: JSON.stringify(audience)
    }
    const res = await fetch(
      `${config.base_server_path}/Automation/CreateSegment?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
    const data = await res.json()

    if (data.StatusCode < 0) {
      MyAlert(
        intl.formatMessage(
          { id: 'app.api.error' },
          { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
        ) + `${data.Message}`,
        2
      )
      setMessageResultApi('error api')
      setMessageResult(data.Message)
    } else {
      MyAlert(
        intl.formatMessage(
          { id: 'app.api.update.ok' },
          { defaultMessage: 'Se actualizaron con éxito los datos.' }
        ),
        1
      )
      setMessageResultApi('ok api')
    }
  }

  console.log(progressBar)

  return (
    <div className="automation__wrapper border-shadow">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 container-file automation__endpoint-selector-container">
          <div className=" col-lg-7 col-md-7 col-sm-7 col-xs-7">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 jc-flexend mt-2 ">
                <label className=" mb-2">
                  <FormattedMessage
                    id="app.audiencias.audiencia"
                    defaultMessage="AUDIENCIA"
                  />
                  <Tooltip 
                    text={intl.formatMessage(
                    { id: 'app.carrousel.rules.tooltip.Audiencia.Nombre.tooltip' },
                    { defaultMessage: 'Ayuda' }
                    )} 
                  />                  
                </label>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <input
                  className="input automation__input-w100 vtexskuconsult__input"
                  type={'text'}
                  placeholder={intl.formatMessage(
                    { id: 'app.audiencias.audiencia-placeholder' },
                    {
                      defaultMessage:
                        'Solo letras, números y (guión bajo) como separador'
                    }
                  )}
                  value={nameAudiencia}
                  onChange={handleName}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 jc-flexend ">
                <label className="  mt-2">
                  <FormattedMessage
                    id="app.audiencias-tabla.descripcion"
                    defaultMessage="DESCRIPCIÓN"
                  />
                  <Tooltip 
                    text={intl.formatMessage(
                    { id: 'app.carrousel.rules.tooltip.Audiencia.Descripcion.tooltip' },
                    { defaultMessage: 'Ayuda' }
                    )} 
                  />                  
                </label>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <textarea
                  className="input automation__input-w100 vtexskuconsult__textarea"
                  placeholder={intl.formatMessage(
                    { id: 'app.audiencias.descripcion-placeholder' },
                    {
                      defaultMessage:
                        'Solo letras, números y (guión bajo) como separador'
                    }
                  )}
                  value={descAudiencia}
                  onChange={handleDescription}
                  rows={6}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <div className="automation__endpoint-selector-container container-file">
              <label>
                <FormattedMessage
                  id="app.audiencias.archivo"
                  defaultMessage="ARCHIVO"
                />
              </label>
              <input
                className="input fs-14"
                type={'file'}
                key={jsonFileInput}
                onChange={handleFile}
              />
            </div>
            <p className="automation__p">
              <FormattedMessage
                id="app.audiencias.extencion"
                defaultMessage="La extensión se sugiere que sea *.csv/*.xls - Peso máximo 10Mb. Ejemplo de archivo:"
              />{' '}
              <Tooltip 
                    text={intl.formatMessage(
                    { id: 'app.carrousel.rules.tooltip.Audiencia.Descripcion.tooltip' },
                    { defaultMessage: 'Ayuda' }
                    )} 
                  />  
            </p>
            <p className="automation__p text_example_csv_1">
              <FormattedMessage
                id="app.audiencias.usuario"
                defaultMessage="usuario"
              />
              1@
              <FormattedMessage
                id="app.audiencias.mail"
                defaultMessage="correo"
              />
              .com
              <br />
              <FormattedMessage
                id="app.audiencias.usuario"
                defaultMessage="usuario"
              />
              2@
              <FormattedMessage
                id="app.audiencias.mail"
                defaultMessage="correo"
              />
              .com
              <br />
              <FormattedMessage
                id="app.audiencias.usuario"
                defaultMessage="usuario"
              />
              3@
              <FormattedMessage
                id="app.audiencias.mail"
                defaultMessage="correo"
              />
              .com
              <br />
              <FormattedMessage
                id="app.audiencias.usuario"
                defaultMessage="usuario"
              />
              4@
              <FormattedMessage
                id="app.audiencias.mail"
                defaultMessage="correo"
              />
              .com
              <br /> ...{' '}
            </p>
          </div>
        </div>
      </div>

      <div className="row automation button">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 automation__buttons-container">
          <div className="automation__preview-button">
            <button
              type="button"
              disabled={nameAudiencia.length <= 0}
              className="ppal_button btn btn-primary"
              onClick={AlertToken}
            >
              <FormattedMessage
                id="app.audiencias.guardar"
                defaultMessage="GUARDAR"
              />
            </button>
          </div>
        </div>
      </div>

      {Boolean(progressBar) && (
        <ProgressBar bgcolor={'#2FAD7E'} completed={progressBar} />
      )}
      {Object.entries(messageResult).length > 0 ? (
        <h1 className="automation__title-text">{messageResult?.Message}</h1>
      ) : null}
      {viewSpinner ? (
        <Loading
          textLoading={intl.formatMessage(
            { id: 'app.vitrinas-spinner.espere' },
            { defaultMessage: 'Espere...' }
          )}
        />
      ) : null}
    </div>
  )
}

export default CreateAudiences
