/* eslint-disable @typescript-eslint/no-misused-promises */
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ShowAlert } from '../../Globals'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'

import './system.css'
import { useClientContext } from '../../../contexts/ClientContext'
const UserPass = () => {
  interface FormDataPass {
    passactual: string
    passnuevo: string
    passnuevoconfirm: string
  }
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const intl = useIntl()

  const dispatch = useDispatch()
  const [tokenValue, setTokenValue] = useState<string>('')

  const MyAlert = (text: string, iconStr: number) => {
    ShowAlert({
      title: text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: 3000,
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = (data: FormDataPass) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        setTokenValue(result.value)
        SavePassword(result.value, data)
      }
    })
  }

  const SavePassword = (tokenGoogle: string, data: FormDataPass) => {
    Service.post<any, any>(
      `/Account/UpdatePasswordUser?idHashScript=${selectedScript?.idHashScript}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          oldPassword: data.passactual,
          password: data.passnuevo,
          retrypassword: data.passnuevoconfirm,
          userName: user.data.username,
          tokenGoogle,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
          if (res.data == -2) {
            MyAlert(
              intl.formatMessage(
                { id: 'app.system.profile-form-pass-actual-bad' },
                { defaultMessage: 'La Contraseña Actual no es correcta.' }
              ),
              2
            )
          }
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.update.ok' },
              { defaultMessage: 'Se actualizaron con éxito los datos.' }
            ),
            1
          )
          setTokenValue('')
          dispatch(logout(user.data.token))
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const formSchema = yup.object().shape({
    passnuevo: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.passrequired'
          },
          { defaultMessage: 'La contraseña nueva es obligatoria' }
        )
      )
      .min(
        4,
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.minpass'
          },
          { defaultMessage: 'La contraseña debe tener al menos 4 caracteres' }
        )
      ),
    passnuevoconfirm: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.passnewrequierd'
          },
          { defaultMessage: 'La confirmacion de contraseña es obligatoria ' }
        )
      )
      .min(
        4,
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.minpass'
          },
          { defaultMessage: 'La contraseña debe tener al menos 4 caracteres' }
        )
      )
      .oneOf(
        [yup.ref('passnuevo')],
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.passconfirm'
          },
          { defaultMessage: 'Las contraseñas no coinciden' }
        )
      )
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataPass>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      passactual: '',
      passnuevo: '',
      passnuevoconfirm: ''
    }
  })

  const onSubmit = (data: FormDataPass) => {
    console.log(data)
    AlertToken(data)
  }

  useEffect(() => {}, [])

  return (
    <div
      className="container mg-t-0 table-bordered "
      style={{ display: 'block' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="rule-config__wrapper sombra_caja">
          <div className="rule-config__title-container row">
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
            <div className="automation__wrapper">
              <div className="row">
                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system.profile-form-pass-actual"
                          defaultMessage="Contraseña Actual"
                        />{' '}
                        <span className="small text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <input
                      type={'password'}
                      {...register('passactual')}
                      defaultValue={''}
                      className="input vtexskuconsult__input col-lg-10 col-md-10 col-sm-10 col-xs-10"
                    />
                  </div>
                </div>
                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system.profile-form-pass-nueva"
                          defaultMessage="Contraseña Nueva"
                        />{' '}
                        <span className="small text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <input
                      type={'password'}
                      {...register('passnuevo')}
                      defaultValue={''}
                      className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                    />
                    {
                      <div className="error-form-bdw">
                        {errors?.passnuevo?.message}
                      </div>
                    }
                  </div>
                </div>
                <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <div className="shelves__endpoint-selector-container">
                      <label className="shelves__endpoint-selector-text mb-2">
                        <FormattedMessage
                          id="app.system..profile-form.pass-repeat"
                          defaultMessage="Repetir Contraseña"
                        />{' '}
                        <span className="small text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <input
                      type={'password'}
                      {...register('passnuevoconfirm')}
                      defaultValue={''}
                      className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                    />
                    {
                      <div className="error-form-bdw">
                        {errors?.passnuevoconfirm?.message}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row shelves button botonera">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
            <div className="shelves__preview-button">
              <div className="shelves__preview-button">
                <button
                  className="ppal_button btn btn-primary"
                  id="saveBrandPriority"
                >
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.guardar"
                    defaultMessage="GUARDAR"
                  />
                </button>
                <div className="shelves__preview-button"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UserPass
