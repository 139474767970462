/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import {  
  BsFillTrashFill  
} from 'react-icons/bs'
import { TooltipPanel } from '../../Globals/TooltipPanel'

const RowsAudienceRelated: React.FC<{
  selectedIndex: string; 
  item: any; 
  arrayAudiencesForSelect: any;
  loadingAudiences: boolean;
  arrayAudienciasRelated: any;
  fncDeleteItemAudiencia: any;
  fncOnChangeAudiences: any;
  key: any;
  fncOnBlurSelectAudiencias: any;
  }> = ({
      selectedIndex,
      item,      
      arrayAudiencesForSelect,
      loadingAudiences,
      arrayAudienciasRelated,
      fncDeleteItemAudiencia,
      fncOnChangeAudiences,
      key,
      fncOnBlurSelectAudiencias
}) => {

  const intl = useIntl() 
  const user = useSelector<RootState, any>((state) => state.userAuth)

  return (
    <>
      <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container box-specification">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div className="shelves__endpoint-selector-container">
            <label className="shelves__endpoint-selector-text mb-2">
              <FormattedMessage
                id="app.audiencias.asoc"
                defaultMessage="Asociar audiencia"
              />
              <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.form.audiencias.asociar.tooltip')
                  )[0]}
                />
            </label>
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
        {arrayAudiencesForSelect.length > 0 ? (
          <Select
            className="basic-single box-select-endpoint"
            classNamePrefix="select"
            defaultValue={
              arrayAudiencesForSelect[selectedIndex]
            }
            isDisabled={false}
            isLoading={loadingAudiences}            
            isOptionDisabled={(option) => option.disabled === 'si' }            
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            name={"audienciaSlt_"}
            id={"audienciaSlt_"}
            options={arrayAudiencesForSelect}
            placeholder={intl.formatMessage(
              { id: 'app.audiencias.select' },
              { defaultMessage: 'Seleccionar Audiencia' }
            )}
            
            onBlur={fncOnBlurSelectAudiencias}
            onChange={fncOnChangeAudiences}
            key={key}            
          />
        ) : (
          <Spinner animation="border" size="sm" />
        )}
        </div>
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
          <a
            className="manage-grid-button btn-add-espec margin-top-6"
            onClick={() => {
              fncDeleteItemAudiencia('DELETE', item.value)
            }}
          >
            <BsFillTrashFill size={25} id={'red'} />
          </a>
        </div>
      </div>
    </>
  )
}

export default RowsAudienceRelated