import { ChartOptions } from 'chart.js';

export const titleGraphicOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false
      },
      ticks: {
        display: true
      }
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true
      },
      ticks: {
        display: true,
        stepSize: 100000
      }
    }
  }
}

export const revenueOptions: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      min: 0,
      grid: {
        drawTicks: true,
        lineWidth: (context: any) => {
          const value = context.tick.value
          if (
            value === 0 ||
            value === 1000000 ||
            value === 2000000
          ) {
            return 1
          }
          return 0
        }
      }
    },
    x: {
      grid: {
        display: false
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom'
    }
  }
}

export const rankProductsOptions: ChartOptions<'bar'> = {
  responsive: true,
  indexAxis: 'y',
  layout: {
    padding: {
      left: 150,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        align: 'start',
      },
      grid: {
        lineWidth: 0,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        drawOnChartArea: true,
        drawTicks: false,
        lineWidth: (context) => (context.index === 0 ? 0 : 1),
        color: (context) => (context.index !== undefined ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0)'),
      },
      ticks: {
        display: false,
        padding: 40,
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0,
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'y',
      },
      zoom: {
        pinch: {
          enabled: true,
        },
        wheel: {
          enabled: true,
          speed: 0.5,
        },
        mode: 'y',
      },
    },
  },
};
export const rankCategoryProducts: ChartOptions<'bar'> = {
  responsive: true,
  indexAxis: 'y',
  layout: {
    padding: {
      left: 150
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        align: 'start'
      },
      grid: {
        lineWidth: 0
      }
    },
    y: {
      beginAtZero: true,
      grid: {
        drawOnChartArea: true,
        drawTicks: false,
        lineWidth: function (context: any) {
          return context?.index === 0 ? 0 : 1
        },
        color: (context: any) => {
          if (context.index !== undefined) {
            return 'rgba(0, 0, 0, 0.1)'
          }
          return 'rgba(0, 0, 0, 0)'
        }
      },
      ticks: {
        display: false,
        padding: 40,
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0
      }
    }
  },
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  plugins: {
    legend: {
      display: false
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'y'
      },
      zoom: {
        pinch: {
          enabled: true
        },
        wheel: {
          enabled: true,
          speed: 0.5
        },
        mode: 'y'
      }
    }
  }
}


export const optionsDoughnut = {
  responsive: false,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom" as const,
      labels: {
        font: {
          size: 10,
        },
        padding: 20,
        boxWidth: 21,
      },
    }
  }
}

interface CustomerSummary {
  gender: {
    female: number;
    male: number;
    other: number;
  };
  age: {
    young: number;
    middle: number;
    other: number;
  };
  device: {
    desktop: number;
    mobile: number;
    tablet: number;
    smartTv: number;
  };
}

interface Purchase {
  customerSummary: CustomerSummary;
}

export const getDoughnutDataSex = (purchase: Purchase) => ({
  labels: ['Femenino', 'Masculino', 'Otro'],
  datasets: [
    {
      label: '# of Votes',
      data: [
        purchase?.customerSummary?.gender.female,
        purchase?.customerSummary?.gender.male,
        purchase?.customerSummary?.gender.other
      ],
      backgroundColor: [
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)',
        'rgba(76, 31, 121, 0.2)',
        'rgba(51, 194, 199, 0.2)',
        'rgba(0, 134, 139, 0.2)'
      ],
      borderColor: [
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)',
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)'
      ],
      borderWidth: 2
    }
  ]
});

export const getDoughnutDataAge = (purchase: Purchase) => ({
  labels: ['De 20 a 40', 'de 40 a 60', 'Otro'],
  datasets: [
    {
      label: '# of Votes',
      data: [
        purchase?.customerSummary?.age.young,
        purchase?.customerSummary?.age.middle,
        purchase?.customerSummary?.age.other
      ],
      backgroundColor: [
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)',
        'rgba(76, 31, 121, 0.2)',
        'rgba(51, 194, 199, 0.2)',
        'rgba(0, 134, 139, 0.2)'
      ],
      borderColor: [
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)',
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)'
      ],
      borderWidth: 2
    }
  ]
});

export const getDoughnutDataDevice = (purchase: Purchase) => ({
  labels: ['Desktop', 'Mobile', 'Tablet', 'SmartTv'],
  datasets: [
    {
      label: '# of Votes',
      data: [
        purchase?.customerSummary?.device?.desktop,
        purchase?.customerSummary?.device?.mobile,
        purchase?.customerSummary?.device?.tablet,
        purchase?.customerSummary?.device?.smartTv
      ],
      backgroundColor: [
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)',
        'rgba(76, 31, 121, 0.2)',
        'rgba(51, 194, 199, 0.2)',
        'rgba(0, 134, 139, 0.2)'
      ],
      borderColor: [
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)',
        'rgba(76, 31, 121, 1)',
        'rgba(51, 194, 199, 1)',
        'rgba(0, 134, 139, 1)'
      ],
      borderWidth: 2
    }
  ]
});
