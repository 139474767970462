import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { FormattedMessage, useIntl } from 'react-intl'
import UrlBlocksForm from './UrlBlocksForm'
import { Loading } from '../../Globals/Loading'
import './UrlBlocks.css'
import { ShowAlert } from '../../Globals'
import { useClientContext } from '../../../contexts/ClientContext'

const UrlBlocksContainer = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const { selectedScript } = useClientContext()
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const [urlBlocks, setUrlBlocks] = useState<any>('')
  const [loadUrls, setLoadUrls] = useState<any>('')
  const [reload, setRelaod] = useState<any>('')
  const [viewSpinner, setViewSpinner] = useState<boolean>(false)
  const [userChange, setUserChange] = useState<any>('')
  const [dateChange, setDateChange] = useState<any>('')

  const handlerReload = (urlString: string) => {
    setRelaod('recargar')
  }

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.bloqueo-urls.titulo' },
        { defaultMessage: 'BLOQUEO DE URLS' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      // toast: true,
      width: 500,
      timerProgressBar: true
    })
  }

  useEffect(() => {
    setViewSpinner(true)
    Service.get<any>(
      `/ManageCarrousel/GetScriptClientKeyValue?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          token: token.data.token,
          key: 'URLBlocked'
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setUrlBlocks(response.data)
          if (response.data.length > 0) {
            setUserChange(
              response.data[0].IdDashboardUserUpdate !== ''
                ? response.data[0].DashboardUserUpdate
                : response.data[0].DashboardUser
            )
            setDateChange(
              response.data[0].DateLastUpdate !== null
                ? response.data[0].DateLastUpdate
                : response.data[0].DateAdded
            )
          }
          setLoadUrls('carga')
        } else {
          setUrlBlocks('')
          setLoadUrls('')
          setUserChange('')
          setDateChange('')
        }
        setViewSpinner(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }, [reload])

  return (
    <div className="container mb-4 mg-t-30">
      <h1 className="rule-config__title-text">
        <FormattedMessage
          id="app.bloqueo-urls.titulo"
          defaultMessage="BLOQUEO DE URLS"
        />
      </h1>
      <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <p>
            <FormattedMessage
              id="app.bloqueo-urls.subtitulo"
              defaultMessage="En esta sección podrá ingresar hasta 10 URL's donde desee bloquear las vitrinas de BraindW."
            />
          </p>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <h6>
            <FormattedMessage
              id="app.vitrinas-editar.ultimo-usuario-modificado"
              defaultMessage="Último usuario que modificó"
            />
          </h6>
          <p>{userChange}</p>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <h6>
            <FormattedMessage
              id="app.vitrinas-editar.fecha-modificacion"
              defaultMessage="Fecha de modificación"
            />
          </h6>{' '}
          <p>{dateChange.replace('T', ' ')}</p>
        </div>
      </div>

      {viewSpinner ? (
        <Loading
          textLoading={intl.formatMessage(
            { id: 'app.vitrinas-spinner.espere' },
            { defaultMessage: 'Espere...' }
          )}
        />
      ) : null}

      {selectedScript?.vtexIo === 1 ? (
        <UrlBlocksForm
          dataUrlBlocks={urlBlocks}
          loadURl={loadUrls}
          handlerReload={handlerReload}
          classLoading={viewSpinner ? 'spinner_opacity' : ''}
        />
      ) : (
        <h6 className="alert alert-warning">No es un Cliente VtexIO</h6>
      )}
    </div>
  )
}

export default UrlBlocksContainer
