import { BsPencilSquare } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import ConsumptionTableGrid from './ConsumptionTableGrid'
import './style.css'

const ConsumptionCotainer = () => {
 
  const intl = useIntl()
  return (
    <div className="container mg-t-30 container p-0" style={{ display: 'block' }}>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="related_categories__title-container">
            <BsPencilSquare className="manage-grid-button related_categories__title-icon" />
            <h1 className="related_categories__title-text">
              <FormattedMessage
                id="app.submenu.consumos"
                defaultMessage="Consums"
              />              
            </h1>
          </div>        
          <div className="container">
            
            <p>
              {intl.formatMessage(
                { id: 'app.tooltips.header.p.cdp-consumos' },
                { defaultMessage: 'CDP' }
              )}
            </p>
          </div>
          
        </div>
      </div>
      
        <ConsumptionTableGrid />

    </div>
  )
}

export default ConsumptionCotainer
