import { MdOutlineHouse } from './icons'
import '../components/Menu/style.css'
import { FormattedMessage } from 'react-intl'

const _class = 'bdw-menu-icon'

const mainMenuOptionsMshop = [
  {
    id: 0,
    name: <FormattedMessage id="app.menu.home-bdw" defaultMessage="Inicio" />,
    icon: <MdOutlineHouse className={_class} />,
    link: 'homebdw'
  },
  {
    id: 2,
    name: (
      <FormattedMessage id="app.menu.mshop-bind" defaultMessage="Vincular" />
    ),
    icon: <MdOutlineHouse className={_class} />,
    link: 'mshop-disabled'
  },
  {
    id: 3,
    name: <FormattedMessage id="app.menu.home" defaultMessage="Carruseles" />,
    icon: <MdOutlineHouse className={_class} />,
    link: 'carruselesmshop'
  },
  {
    id: 4,
    name: <FormattedMessage id="app.menu.mshop.help" defaultMessage="Ayuda" />,
    icon: <MdOutlineHouse className={_class} />,
    link: 'help'
  }
]

export default mainMenuOptionsMshop
