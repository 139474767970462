/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useState, useMemo, useRef } from 'react'
import DataTable, { Alignment } from 'react-data-table-component'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../Globals/Loading'
import { ShowAlert } from '../../Globals'
import Service from '../../../services'

import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const CDPUploadFile = ({ 
  back,
  fileKeySelected
}: any) => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()
  const [importsData, setImportsData] = useState<any>([])
  const [dataRequest, setDataRequest] = useState<any>([])
  const [EventName, setEventname] = useState<string>('')
  const [UpdateStruct, setUpdateStruct] = useState<boolean>(false)
  const [valueImportSelected, seTvalueImportSelected] = useState<any>('')
  const [valueDataTypeSelected, seTvalueDataTypeSelected] = useState<any>('')
  
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.audience-list' },
        { defaultMessage: 'Lista de audiencias' }
      ),
      html: text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true      
    })
  }

  const customStyles = {
    header: {
      style: {
        fontSize: '14px',
        color: '#000',
        backgroundColor: 'transparent',
        minHeight: '56px',
        paddingLeft: '16px',
        paddingRight: '8px'
      }
    },
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px !important', // override the cell padding for head cells
        paddingRight: '20px !important'
      },
      draggingStyle: {
        cursor: 'move'
      }
    },
    cells: {
      style: {
        margingLeft: '10px', // override the cell padding for data cells
        margingRight: '10px',
        wordBreak: undefined,
        overflow: 'wrap'
      },
      draggingStyle: {},
      class: 'text-center'
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      '&:nth-of-type(n)': {
        color: '#fff',
        backgroundColor: '#666',
        borderBottomColor: '#ccc'
      }
    },
    highlightOnHoverStyle: {
      color: '#dfdfdf',
      backgroundColor: '#eee',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: '#000',
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: '#efefef'
    },
    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    pagination: {
      style: {
        color: '#000',
        fontSize: '14px',
        minHeight: '56px',
        backgroundColor: 'transparent',
        // borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#ddd'
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '5px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#fff',
        // fill: theme.button.default,
        backgroundColor: '#e9ecef',
        '&:disabled': {
          cursor: 'unset',
          color: '#2fad7e'
          // fill: theme.button.disabled,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: '#2fad7e',
          color: '#fff'
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#fff'
        }
      }
    }
  }

  const onChangeImportTo = (event: React.ChangeEvent<HTMLSelectElement>) => {    
    const colname = event.target.dataset.colname
    const selectUno = document.getElementById('slt-uno-'+colname) as HTMLSelectElement
    const selectDos = document.getElementById('slt-dos-'+colname) as HTMLSelectElement
    // habilito/deshabilito select si el dato es de tipo Date
    if (event.target.value=='event_date') {      
      selectDos.classList.remove('hide')
      selectUno.classList.add('hide')      
    }else{
      selectDos.classList.add('hide')
      selectUno.classList.remove('hide')      
    }
    if (event.target.value=='email') {      
      selectUno.setAttribute('disabled', '')
    }else{
      selectUno.removeAttribute('disabled')          
    }
    // busco en el objArray
    const itemIndex = dataRequest.findIndex(
      (x: { ColumnName: string }) => x.ColumnName === colname
    )
    if(itemIndex>=0){
      dataRequest[itemIndex].TargetDataColumns = event.target.value
    }
  }
  
  const onChangeSltDataType1 = (event: React.ChangeEvent<HTMLSelectElement>) => {    
    const colname = event.target.dataset.colname
    const itemIndex = dataRequest.findIndex(
      (x: { ColumnName: string }) => x.ColumnName === colname
    )
    if(itemIndex>=0){
      dataRequest[itemIndex].DataTypes = event.target.value
    }
  }

  const onChangeSltDataType2 = (event: React.ChangeEvent<HTMLSelectElement>) => {    
    const colname = event.target.dataset.colname
    const itemIndex = dataRequest.findIndex(
      (x: { ColumnName: string }) => x.ColumnName === colname
    )
    if(itemIndex>=0){
      dataRequest[itemIndex].ColumnsFormat = event.target.value
    }
  }  

  const onChangeChkCmb = (e: any) => {    
    const sel = e.target.checked ? 'true' : 'false'
    const colname = e.target.dataset.colname
    const itemIndex = dataRequest.findIndex(
      (x: { ColumnName: string }) => x.ColumnName === colname
    )
    if(itemIndex>=0){
      dataRequest[itemIndex].ChkVisible = sel
    }
  }

  const onChangeChkVis = (e: any) => {    
    const sel = e.target.checked ? 'true' : 'false'
    const colname = e.target.dataset.colname    
    const itemIndex = dataRequest.findIndex(
      (x: { ColumnName: string }) => x.ColumnName === colname
    )
    if(itemIndex>=0){
      dataRequest[itemIndex].ChkCombo = sel
    }
  }

  const onChangeChkUpdStr = (e: any) => {
    setUpdateStruct(e.target.checked)
  }
  
  const columns = [
    {
      id: 'ColumnName',
      name: 'Column',
      selector: (row: any) => row.ColumnName,      
      reorder: true,
      center: true,
      minWidth: '80px'
    },
    {
      id: 'Import',
      name: 'Import To',
      center: true,
      minWidth: '200px',      
      cell: (row: any) => (
        <>
          <select 
            id={'slt-impto-'+row.ColumnName} 
            onChange={onChangeImportTo}
            data-colname={row.ColumnName}
            className='select-cdp'>
              {row.TargetDataColumns.map((data: any) => {
                return <option key={data} value={data}>{data}</option>
              })}
          </select>
        </>
      )
    },
    {
      id: 'DataTypes',
      name: 'DataTypes',
      center: true,
      minWidth: '280px',      
      cell: (row: any) => (
        <>
          <select 
            id={'slt-uno-'+row.ColumnName}
            onChange={onChangeSltDataType1}
            data-colname={row.ColumnName}
            className='select-cdp'>
              {row.DataTypes.map((data: any) => {
                return <option key={data} value={data}>{data}</option>
              })}
          </select>
          &nbsp;&nbsp;
          <select 
            id={'slt-dos-'+row.ColumnName} 
            className='hide select-cdp'
            onChange={onChangeSltDataType2}
            data-colname={row.ColumnName}
            >
              {row.ColumnsFormat.map((data: any) => {
                return <option key={data} value={data}>{data}</option>
              })}
          </select>
        </>
      )
    },
    {
      id: 'Visible',
      name: 'Visible',
      selector: (row: any) => row.FileKey,
      center: true,
      minWidth: '100px',      
      cell: (row: any) => (
        <>
          <label className="switch">
            <input
              className="input" 
              key="chkenabled"
              id={'chk-vis-'+row.ColumnName} 
              data-colname={row.ColumnName}
              type="checkbox"
              onChange={(e) => onChangeChkVis(e)}
              defaultChecked={false}
            />
            <span className="slider round"></span>
          </label>          
        </>
      )
    },
    {
      id: 'Combo',
      name: 'Combo',
      selector: (row: any) => row.FileKey,
      center: true,
      minWidth: '100px',
      cell: (row: any) => (
        <>
          <label className="switch">
            <input
              className="input" 
              key="chkenabled"
              id={'chk-combo-'+row.ColumnName} 
              data-colname={row.ColumnName}
              type="checkbox"
              onChange={(e) => onChangeChkCmb(e)}
              defaultChecked={false}
            />
            <span className="slider round"></span>
          </label>
        </>
      )
    }
  ]

  const readCdpFile = (
    FileKey: string
  ) => {    
    setLoading(true)
    Service.post<any, any>(
      `/Automation/ReadCdpFile?idHashScript=${selectedScript?.idHashScript}&FileKey=${FileKey}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          username: user.data.username,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    ).then((response) => {
        if (response.status === 200) {          
            MyAlert('Lectura correcta del archivo en S3', 1, 1000, true)
            if (response.data.length > 0) {
            setImportsData(response.data)
            setDataRequest([])
            response.data.forEach((item: any, index: any) =>
              dataRequest.push({
                  ColumnName: item.ColumnName,
                  DataTypes: 'string',
                  TargetDataColumns: '',
                  ColumnsFormat: '',
                  ChkVisible: 'false',
                  ChkCombo: 'false'
                })
              )
              setDataRequest(dataRequest)
          }
        }else{
          MyAlert('Ocurrió un Error al leer el archivo.',2)
        }
        setLoading(false)        
      })
      .catch((error) => {
        if(error.response.data==6){
          dispatch(logout(user.data.token))
        }else{
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }        
        setLoading(false)
      })      
  }

  const validarDatos = () =>{
    let mje = ''
    if(EventName==''){
      mje = '<br/>- <strong>Nombre del evento</strong>'
    }
    let countEmail = 0
    let countEventDate = 0
    dataRequest.forEach((item: any, index: any) =>{
      if(item.TargetDataColumns=='email'){
        countEmail++
      }
      if(item.TargetDataColumns=='event_date'){
        countEventDate++
      }
    })
    if(countEmail==0){
      mje+='<br/>- atributo Impor to => <strong>email</strong>'
    }
    if(countEventDate==0||countEmail==0){
      mje+='<br/>- atributo Impor to => <strong>event_date</strong>'
    }
    
    return mje
  }

  const sendProcessCdpFile = (
    FileKey: string
  ) => {
    const validacion = validarDatos()
    if(validacion==''){    
      setLoading(true)      
      const objRequest = {
        FileKey,
        EventName,
        UpdateStruct,
        Rows: dataRequest
      }
      Service.post<any, any>(
        `/Automation/ProcessCdpFile?idHashScript=${selectedScript?.idHashScript}`,
        JSON.stringify(objRequest),
        {
          method: 'POST',
          headers: {
            token: user.data.token,
            username: user.data.username,
            'Content-Type': 'application/json',
            Accept: '*/*',
            credentials: 'include'
          }
        }
      ).then((response) => {
          if (response.status === 200) {          
              MyAlert('Lectura correcta del archivo en S3', 1, 1000, true)
              back()
          }        
          setLoading(false)        
        })
        .catch((error) => {
          if(error.response.data==6){
            dispatch(logout(user.data.token))
          }else{
            MyAlert('API-ERROR: ' + `${error}`, 3)
          }        
          back()
          setLoading(false)
        }) 
    }else{
      MyAlert('Algunos de los datos a procesar no son correctos. Revise que tenga '+validacion, 3, 3000, true)
    }     
  }

  const handleEvent = (e: any) => {
    if (e.target.value.match('^[a-zA-Z0-9_]*$') != null) {
      setEventname(e.target.value.toLowerCase())
    }
  }

  useEffect(() => {
    readCdpFile(fileKeySelected.FileKey)
  }, [])

  return (
    <div className="container mg-t-30 container " style={{ display: 'block' }}>
      <div className="automation__wrapper border-shadow">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className="automation__endpoint-selector-container">
            <label className="automation__endpoint-selector-text">
              <FormattedMessage
                id="app.cdp.import-file.event-name"
                defaultMessage="Nombre del Evento"
              />
              <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.form.cdp.file.Upload.label.evento.tooltip')
                  )[0]}
                />
            </label>
            <input
              className="input"
              type={'text'}
              key={'inpevent'}
              style={{width:'60%'}}
              onChange={handleEvent}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className="automation__endpoint-selector-container">
            <label className="automation__endpoint-selector-text">
              <FormattedMessage
                id="app.cdp.import-file.update-query-estructure"
                defaultMessage="Actualizar la estructura de la consulta"
              />
              <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.form.cdp.file.Upload.label.actualizar.estructura.tooltip')
                  )[0]}
                />
            </label>
            <label className="switch">
              <input
                className="input" 
                key="chkupdst"
                id={'chk-updstr'}                 
                type="checkbox"
                onChange={(e) => onChangeChkUpdStr(e)}
                defaultChecked={false}
              />
              <span className="slider round"></span>
            </label>            
          </div>
        </div>
      </div>
      <div className="row">
        <>
              {loading ? (                
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20 ">
                  <div style={{ margin: 'inherit', textAlign: 'center' }}>
                    <Loading
                      textLoading={intl.formatMessage(
                        { id: 'app.vitrinas-spinner.espere' },
                        { defaultMessage: 'Espere...' }
                      )}
                    />
                  </div>
                </div>                
              ) : (
                ''
              )}
          <div>
            <DataTable
              subHeaderAlign={Alignment.RIGHT}
              columns={columns}
              data={importsData}
              progressComponent={
                <Loading
                  textLoading={intl.formatMessage(
                    { id: 'app.vitrinas-spinner.espere' },
                    { defaultMessage: 'Espere...' }
                  )}
                />
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              subHeader
              customStyles={customStyles}              
              noDataComponent={intl.formatMessage(
                { id: 'app.vitrinas.nodata' },
                { defaultMessage: 'Sin Resultados.' }
              )}
              responsive
              sortServer
            />
          </div>
        </>
      </div>
      <div className="row shelves button botonera">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">          
                
          <div className="shelves__preview-button">
            <div className="shelves__preview-button">
              <button
                className="ppal_button btn btn-primary"
                disabled={loading}
                id="saveBrandPriority"
                onClick={()=>sendProcessCdpFile(fileKeySelected.FileKey)}
              >
                GUARDAR
              </button>
            </div>
            <div className="shelves__preview-button">
              <button
                id="btn-regresar"
                className="manage-grid-button shelves__title-icon"
                onClick={back}
              >
                VOLVER
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CDPUploadFile
