import ContainerRow from './ContainerRow'
import Logo from './Logo'
import './style.css'

const HeaderContainer = () => {
  return (
    <div className="header-top-area">
      <div className="container-fluid">
        <div className="row">
          <Logo />
          <ContainerRow />
        </div>
      </div>
    </div>
  )
}

export default HeaderContainer
