import React, { useEffect, useMemo, useState } from 'react'
import DataTable, { Alignment } from 'react-data-table-component'
import {
  BsArrowClockwise,
  BsEraser,
  BsFiles,
  BsPencilSquare,
  BsSearch,
  BsTrash
} from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import { Loading } from '../../Globals/Loading'
import { useClientContext } from '../../../contexts/ClientContext'

const CDPTableGrid = ({
  edit,
  handlerReloadDataTable,
  handleSyncAllTerms,
  syncAllTermsFlag,
  setAudienceId,
  setAudienceName,
  setAudienceDescription,
  setQueryEditValues
}: any) => {
  const [segments, setSegments] = useState<any>([])
  const [totalRows, setTotalRows] = useState(0)
  const [totalRowsAux, setTotalRowsAux] = useState(0)
  const [perPage, setPerPage] = useState(100)
  const [filterText, setFilterText] = useState<any>('')
  const [sortField, setSortField] = useState<any>('idSegment')
  const [sortDirection, setSortDirection] = useState<any>('desc')
  const [loading, setLoading] = useState(false)
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false)

  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const dispatch = useDispatch()
  const intl = useIntl()

  const AlertToken = (id: string, action: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'El token no fue informado.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        if (action === 'delete') deleteSegmentCDP(id, result.value)
      }
    })
  }

  const getDataReload = async (
    page: any,
    textSearch: string,
    sortFie: string,
    sortDir: string
  ) => {
    setLoading(true)
    Service.get<any>(
      `Automation/GetAllSegmentsCDP?idHashScript=${
        selectedScript?.idHashScript
      }&page=${page}&per_page=${perPage}&filterText=${filterText.toLowerCase()}&sortField=${sortFie}&sortDirection=${sortDir}`,
      {
        headers: {
          token: user.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          if (response.data.StatusCode === -1) {
            document
              .getElementById('add-new-audience')
              ?.classList.add('disabled')
          }
          if (response.data.Entities.length > 0) {
            setSegments(response.data.Entities)
            setTotalRows(parseInt(response.data.Message))
            setTotalRowsAux(parseInt(response.data.Message))
          }
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        } else {
          setSegments('')
          document.getElementById('add-new-audience')?.classList.add('disabled')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        }
        if (error.response.data === -9) {
          document.getElementById('add-new-audience')?.classList.add('disabled')
        }
      })
  }

  const duplicateAudience = async (id: string) => {
    setLoading(true)
    Service.get<any>(
      `Automation/DuplicateAudienceCDP?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
      {
        headers: {
          token: user.data.token,
          userName: user.data.username
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          MyAlert(response.data.Message, 1)
          getDataReload(1, filterText, sortField, sortDirection)
        } else {
          MyAlert(response.data.Message, 3)
        }
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        }
      })
  }

  const updateAudienceCDP = (id: string) => {
    setLoading(true)
    Service.get<any>(
      `Automation/UpdateAudienceCDP?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
      {
        headers: {
          token: user.data.token,
          userName: user.data.username
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          MyAlert(response.data.Message, 1)
          getDataReload(1, filterText, sortField, sortDirection)
        } else {
          MyAlert(response.data.Message, 3)
        }
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        }
      })
  }

  const deleteSegmentCDP = (id: string, tokenGoogle: string) => {
    setLoading(true)
    Service.post<any, any>(
      `/Automation/DeleteSegmentCDP?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          username: user.data.username,
          tokenGoogle,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((respone) => {
        getDataReload(1, filterText, sortField, sortDirection)
        setLoading(false)
      })
      .catch((error) => {
        switch (error.response.data) {
          case -6:
            dispatch(logout(user.data.token))
            break
          case -5:
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'Token inválido' }
              ),
              3
            )
            break
          default:
            MyAlert('API-ERROR: ' + `${error}`, 3)
            break
        }
        setLoading(false)
      })
  }

  const syncAllTerms = async () => {
    setLoading(true)
    Service.get<any>(
      `Search/SyncAllTerms?idHashScript=${selectedScript?.idHashScript}`,
      { headers: { token: user.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          MyAlert(response.data.Message, 1)
          setLoading(false)
          handleSyncAllTerms()
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.audience-list' },
        { defaultMessage: 'Lista de audiencias' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      width: 500,
      timerProgressBar: true
    })
  }

  const TextField = styled.input`
    border: none;
    background: none;
    border-bottom: 2px solid #ccc !important;
    height: 32px;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: text;
    }
  `

  const customStyles = {
    header: {
      style: {
        fontSize: '14px',
        color: '#000',
        backgroundColor: 'transparent',
        minHeight: '56px',
        paddingLeft: '16px',
        paddingRight: '8px'
      }
    },
    subHeader: {
      style: {
        justifyContent: 'space-between',
        marginBottom: '1rem',
        padding: '0 1rem !important'
      }
    },
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px !important', // override the cell padding for head cells
        paddingRight: '20px !important'
      },
      draggingStyle: {
        cursor: 'move'
      }
    },
    cells: {
      style: {
        margingLeft: '10px', // override the cell padding for data cells
        margingRight: '10px',
        wordBreak: undefined,
        overflow: 'wrap'
      },
      draggingStyle: {},
      class: 'text-center'
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      '&:nth-of-type(n)': {
        color: '#fff',
        backgroundColor: '#666',
        borderBottomColor: '#ccc'
      }
    },
    highlightOnHoverStyle: {
      color: '#dfdfdf',
      backgroundColor: '#eee',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: '#000',
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: '#efefef'
    },
    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    pagination: {
      style: {
        color: '#000',
        fontSize: '14px',
        minHeight: '56px',
        backgroundColor: 'transparent',
        // borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#ddd'
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '5px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#fff',
        // fill: theme.button.default,
        backgroundColor: '#e9ecef',
        '&:disabled': {
          cursor: 'unset',
          color: '#2fad7e'
          // fill: theme.button.disabled,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: '#2fad7e',
          color: '#fff'
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#fff'
        }
      }
    }
  }

  const handlerSetChangePerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLoading(true)
    setPerPage(parseInt(event.target.value))
    // setFilterText('')

    const selc =
      document.querySelectorAll('select[aria-label="MOSTRANDO"]').length > 0
        ? document.querySelectorAll('select[aria-label="MOSTRANDO"]')
        : document.querySelectorAll('select[aria-label="SHOW"]')
    const select = document.getElementById(
      'options-per-page'
    ) as HTMLSelectElement
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].text === event.target.value) {
        const yourSelect = selc[0] as HTMLSelectElement
        yourSelect.options[i].selected = true
        const ev = new Event('change', { bubbles: true })
        yourSelect.dispatchEvent(ev)

        break
      }
    }
    setLoading(false)
  }

  const changeTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 2) {
      setFilterText(event.target.value)
    }
  }

  const FilterComponent = ({ filterText, onFilter, onClear }: any) => (
    <>
      <div className="show-options__text">
        <FormattedMessage id="app.vitrinas.mostrar" defaultMessage="MOSTRAR" />
        <select
          onChange={handlerSetChangePerPage}
          className="show-options__selector select_paginas"
          name="options-per-page"
          id="options-per-page"
        >
          <option value="5" selected={perPage === 5 ? true : false}>
            5
          </option>
          <option value="10" selected={perPage === 10 ? true : false}>
            10
          </option>
          <option value="15" selected={perPage === 15 ? true : false}>
            15
          </option>
          <option value="20" selected={perPage === 20 ? true : false}>
            20
          </option>
          <option value="100" selected={perPage === 100 ? true : false}>
            100
          </option>
        </select>
        <FormattedMessage
          id="app.vitrinas.entradas"
          defaultMessage="ENTRADAS"
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '50%',
          alignItems: 'center',
          justifyContent: 'end'
        }}
      >
        <TextField
          id="search"
          type="text"
          placeholder={useIntl().formatMessage(
            { id: 'app.vitrinas.buscar' },
            { defaultMessage: 'BUSCAR POR...' }
          )}
          onChange={changeTextSearch}
          data-tip={useIntl().formatMessage(
            { id: 'app.vitrinas.buscar.tooltip' },
            { defaultMessage: 'Ingresa al meno 3 caracteres para búscar' }
          )}
        />
        <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

        {filterText === '' ? (
          <BsSearch
            title={
              'Buscar ' +
              intl.formatMessage(
                { id: 'app.cdp.audience-list' },
                { defaultMessage: 'Lista de audiencias' }
              )
            }
            size={25}
            onClick={onClear}
            cursor={'pointer'}
          />
        ) : (
          <BsEraser
            title="Limpiar búsqueda"
            size={25}
            onClick={onClear}
            cursor={'pointer'}
          />
        )}
      </div>
    </>
  )

  const columns = [
    {
      id: 'IdSegment',
      name: intl.formatMessage(
        { id: 'app.cdp.audience.table.column1' },
        { defaultMessage: 'Id' }
      ),
      selector: (row: any) => row.IdSegment,
      sortable: true,
      reorder: true,
      center: true,
      defaultSortDesc: true,
      sortField: 'idSegment',
      minWidth: '80px'
    },
    {
      id: 'Name',
      name: intl.formatMessage(
        { id: 'app.cdp.audience.table.column2' },
        { defaultMessage: 'Nombre' }
      ),
      selector: (row: any) => row.AliasName,
      center: true,
      minWidth: '250px',
      sortable: true,
      sortField: 'aliasName'
    },
    {
      id: 'Description',
      name: intl.formatMessage(
        { id: 'app.cdp.audience.table.column3' },
        { defaultMessage: 'Descripcion' }
      ),
      selector: (row: any) => row.Description,
      center: true,
      minWidth: '350px'
    },
    {
      id: 'EmailCount',
      name: intl.formatMessage(
        { id: 'app.cdp.audience.table.column4' },
        { defaultMessage: 'Emails' }
      ),
      selector: (row: any) => row.EmailCount,
      center: true,
      sortable: true,
      sortField: 'emailCount'
    },
    {
      id: 'DateAdded',
      name: intl.formatMessage(
        { id: 'app.cdp.audience.table.column7' },
        { defaultMessage: 'Fecha' }
      ),
      selector: (row: any) =>
        row.dateUpdated == null
          ? row.dateAdded.replace('T', ' ')
          : // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            row.dateUpdated.slice(0, 11).replace('T', ' ' + row.timeUpdated),
      reorder: true,
      sortable: true,
      center: true,
      sortField: 'dateAdded',
      minWidth: '140px'
    },
    {
      id: 'acc_categ',
      name: intl.formatMessage(
        { id: 'app.cdp.audience.table.column8' },
        { defaultMessage: 'Acciones' }
      ),
      cell: (row: any) => (
        <>
          <button
            className="manage-grid-button btn-grilla-upd"
            data-id={row.IdSegment}
            onClick={() => updateAudienceCDP(row.IdSegment)}
          >
            <BsArrowClockwise
              size={25}
              title={intl.formatMessage(
                { id: 'app.cdp.audience.table.column5' },
                { defaultMessage: 'Actualizar' }
              )}
            />
          </button>
          <button
            className="manage-grid-button btn-grilla-upd"
            data-id={row.IdSegment}
            onClick={() => {
              duplicateAudience(row.IdSegment)
            }}
          >
            <BsFiles
              size={25}
              title={intl.formatMessage(
                { id: 'app.cdp.audience.table.column6' },
                { defaultMessage: 'Duplicar' }
              )}
            />
          </button>
          <button
            className="manage-grid-button btn-grilla-upd"
            data-id={row.IdSegment}
            onClick={() => {
              edit()
              setAudienceId(row.IdSegment)
              setAudienceName(row.AliasName)
              setAudienceDescription(row.Description)
              setQueryEditValues(row.Query)
            }}
          >
            <BsPencilSquare
              size={25}
              title={intl.formatMessage(
                { id: 'app.audiencias-tabla.title-editar' },
                { defaultMessage: 'Editar audiencia' }
              )}
            />
          </button>
          <button
            className="manage-grid-button btn-grilla-del"
            data-id={row.IdSegment}
            onClick={() => {
              AlertToken(row.IdSegment, 'delete')
            }}
          >
            <BsTrash
              title={intl.formatMessage(
                { id: 'app.audiencias-tabla.title-borrar' },
                { defaultMessage: 'Borrar audiencia' }
              )}
            />
          </button>
        </>
      ),
      allowOverflow: true,
      button: true,
      center: true,
      width: '210px'
    }
  ]

  const paginationComponentOptions = {
    rowsPerPageText: intl.formatMessage(
      { id: 'app.vitrinas.mostrando' },
      { defaultMessage: 'MOSTRANDO' }
    ),
    rangeSeparatorText: intl.formatMessage(
      { id: 'app.vitrinas.de' },
      { defaultMessage: ' DE ' }
    )
  }

  const handlePageChange = (page: any) => {
    getDataReload(page, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
    getDataReload(page, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      setResetPaginationToggle(!resetPaginationToggle)
      setFilterText('')
    }

    if (filterText === '') {
      setTotalRows(totalRowsAux)
    } else {
      getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
        console.log(error)
      )
      setTimeout(() => {
        const input = document.getElementById('search') as HTMLInputElement
        input.value = filterText
        if (input != null) input.focus()
      }, 200)
    }

    return (
      <FilterComponent
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  useEffect(() => {
    getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }, [resetPaginationToggle, handlerReloadDataTable])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (syncAllTermsFlag) syncAllTerms()
  }, [syncAllTermsFlag])

  const handleSort = (column: any, sortDirection: any) => {
    setSortField(column.sortField)
    setSortDirection(sortDirection)

    getDataReload(1, filterText, column.sortField, sortDirection).catch(
      (error) => console.log(error)
    )
  }
  return (
    <div className="row">
      {segments.length >= 1 && totalRows > 0 ? (
        <>
          <div>
            <DataTable
              subHeaderAlign={Alignment.RIGHT}
              columns={columns}
              data={segments}
              progressPending={loading}
              progressComponent={
                <Loading
                  textLoading={intl.formatMessage(
                    { id: 'app.vitrinas-spinner.espere' },
                    { defaultMessage: 'Espere...' }
                  )}
                />
              }
              pagination
              paginationServer
              paginationPerPage={100}
              paginationTotalRows={totalRows}
              paginationResetDefaultPage={resetPaginationToggle}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 100]}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={customStyles}
              // defaultSortFieldId={1}
              noDataComponent={intl.formatMessage(
                { id: 'app.vitrinas.nodata' },
                { defaultMessage: 'Sin Resultados.' }
              )}
              responsive
              sortServer
              onSort={handleSort}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default CDPTableGrid
