import React, { useEffect, useState } from 'react'
import {
  BsArrowRepeat,
  BsFillBookmarkPlusFill,
  BsPencilSquare
} from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import BrandPriorityGrid from './BrandPriorityGrid'
import BrandPriorityForm from './BrandPriorityForm'
import './style.css'

const BrandPriorityContainer = () => {
  const [reloadDataTable] = useState<boolean>(false)
  const [syncAllTerms, setSyncAllTerms] = useState<boolean>(false)
  const [addNewBrandPriority, setAddNewBrandPriority] = useState<boolean>(false)
  const [idBrandPriority, setIdBrandPriority] = useState<string>('')
  const toggleBrandPriority = () => {
    setAddNewBrandPriority(!addNewBrandPriority)
    setIdBrandPriority('')
  }
  const toggleSyncAllTerms = () => {
    setSyncAllTerms(!syncAllTerms)
  }
  const intl = useIntl()

  useEffect(() => {}, [reloadDataTable])

  return (
    <div
      className="container mg-t-30 container table-bordered "
      style={{ display: 'block' }}
    >
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="brandPriority__title-container">
            <BsPencilSquare className="manage-grid-button related_categories__title-icon" />
            <h1 className="related_categories__title-text">
              <FormattedMessage
                id="app.brandpriority"
                defaultMessage="PRIORIDAD DE MARCA"
              />
            </h1>
          </div>
          <div className="container mg-t-0">
            <p>
              {intl.formatMessage(
                { id: 'app.tooltips.header.p.prioridadMarca' },
                { defaultMessage: 'Manejo de carruseles' }
              )}
            </p>
          </div>
          {!addNewBrandPriority ? (
            <div>
              <button
                className="ppal_button btn btn-primary"
                id="add-new-brandpriority"
                onClick={() => toggleBrandPriority()}
              >
                <BsFillBookmarkPlusFill className="m-rig" width={20} />
                <FormattedMessage
                  id="app.brandpriority.add-new-brandpriority"
                  defaultMessage="AGREGAR NUEVA PRIORIDAD DE MARCA"
                />
              </button>
              <button
                className="btn btn-secondary m-lef"
                id="syncallterms"
                onClick={() => toggleSyncAllTerms()}
              >
                <BsArrowRepeat className="m-rig" width={20} />
                <FormattedMessage
                  id="app.brandpriority.syncallterms"
                  defaultMessage="SINCRONIZAR TODOS LOS TERMINOS"
                />
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {addNewBrandPriority ? (
        <BrandPriorityForm
          back={toggleBrandPriority}
          idBrandPriority={idBrandPriority}
        />
      ) : (
        <BrandPriorityGrid
          edit={toggleBrandPriority}
          handlerReloadDataTable={reloadDataTable}
          handleSyncAllTerms={toggleSyncAllTerms}
          syncAllTermsFlag={syncAllTerms}
          setIdBrandPriority={setIdBrandPriority}
        />
      )}
    </div>
  )
}

export default BrandPriorityContainer
