import React, { useEffect, useMemo, useState } from 'react'
import DataTable, { Alignment } from 'react-data-table-component'
import { BsEraser, BsSearch } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import { Loading } from '../../Globals/Loading'
import { useClientContext } from '../../../contexts/ClientContext'

const ConsumptionTableGrid = ({
  handlerReloadDataTable,
  syncAllTermsFlag
}: any) => {
  const [accounts, setAccounts] = useState<any>([])
  const [totalRows, setTotalRows] = useState(0)
  const [totalAudiencia, setTotalAudiencia] = useState(0)
  const [totalRowsAux, setTotalRowsAux] = useState(0)
  const [perPage, setPerPage] = useState(15)
  const [filterText, setFilterText] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false)

  const token = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const dispatch = useDispatch()
  const intl = useIntl()

  const getDataReload = async (page: any) => {
    setLoading(true)
    Service.get<any>(
      `Automation/TargetAccounting?idHashScript=${selectedScript?.idHashScript}&page=${page}&per_page=${perPage}`,
      {
        headers: {
          token: token.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          if (response.data.StatusCode === -1) {
            MyAlert('error1', 2)
          }
          if (response.data.Entities.length > 0) {
            setAccounts(response.data.Entities)
            setTotalRows(parseInt(response.data.Message))
            setTotalRowsAux(parseInt(response.data.Message))
          }
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        } else {
          setAccounts('')
          MyAlert('error2', 2)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        }
        if (error.response.data === -9) {
          document.getElementById('add-new-audience')?.classList.add('disabled')
        }
      })
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.audience-list' },
        { defaultMessage: 'Lista de audiencias' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast ? 'top-end' : 'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const TextField = styled.input`
    width: 25%;
    border: none;
    background: none;
    border-bottom: 2px solid #ccc !important;
    margin: 0px 0px 20px 0px;
    height: 32px;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `
  const ClearButton = styled.button`
    border: none;
    height: 38px;
    width: 38px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  `
  const customStyles = {
    header: {
      style: {
        fontSize: '14px',
        color: '#000',
        backgroundColor: 'transparent',
        minHeight: '56px',
        paddingLeft: '16px',
        paddingRight: '8px'
      }
    },
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px !important', // override the cell padding for head cells
        paddingRight: '20px !important'
      },
      draggingStyle: {
        cursor: 'move'
      }
    },
    cells: {
      style: {
        margingLeft: '10px', // override the cell padding for data cells
        margingRight: '10px',
        wordBreak: undefined,
        overflow: 'wrap'
      },
      draggingStyle: {},
      class: 'text-center'
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      '&:nth-of-type(n)': {
        color: '#fff',
        backgroundColor: '#666',
        borderBottomColor: '#ccc'
      }
    },
    highlightOnHoverStyle: {
      color: '#dfdfdf',
      backgroundColor: '#eee',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: '#000',
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: '#efefef'
    },
    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    pagination: {
      style: {
        color: '#000',
        fontSize: '14px',
        minHeight: '56px',
        backgroundColor: 'transparent',
        // borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#ddd'
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '5px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#fff',
        // fill: theme.button.default,
        backgroundColor: '#e9ecef',
        '&:disabled': {
          cursor: 'unset',
          color: '#2fad7e'
          // fill: theme.button.disabled,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: '#2fad7e',
          color: '#fff'
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#fff'
        }
      }
    }
  }

  const handlerSetChangePerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLoading(true)
    setPerPage(parseInt(event.target.value))

    const selc =
      document.querySelectorAll('select[aria-label="MOSTRANDO"]').length > 0
        ? document.querySelectorAll('select[aria-label="MOSTRANDO"]')
        : document.querySelectorAll('select[aria-label="SHOW"]')

    const select = document.getElementById(
      'options-per-page'
    ) as HTMLSelectElement
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].text === event.target.value) {
        const yourSelect = selc[0] as HTMLSelectElement
        yourSelect.options[i].selected = true
        const ev = new Event('change', { bubbles: true })
        yourSelect.dispatchEvent(ev)

        break
      }
    }
    setLoading(false)
  }

  const changeTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 2) {
      setFilterText(event.target.value)
    }
  }

  const calculateTotalExiting = () => {
    if (accounts.length > 0) {
      /* eslint-disable @typescript-eslint/restrict-plus-operands */
      const sum = accounts.reduce(function (prev: any, current: any) {
        return prev + +current.ExistingAudience
      }, 0)
      setTotalAudiencia(sum)

      const totaldiv = document.getElementById(
        'totalaudexist'
      ) as HTMLSpanElement
      totaldiv.innerHTML = sum
    }
  }

  const FilterComponent = ({ filterText, onFilter, onClear }: any) => (
    <>
      <div className="row w-100">
        <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 header-1">
          <div className="show-options__text">
            <FormattedMessage
              id="app.vitrinas.mostrar"
              defaultMessage="MOSTRAR"
            />
            <select
              onChange={handlerSetChangePerPage}
              className="show-options__selector select_paginas"
              name="options-per-page"
              id="options-per-page"
            >
              <option value="5" selected={perPage === 5 ? true : false}>
                5
              </option>
              <option value="10" selected={perPage === 10 ? true : false}>
                10
              </option>
              <option value="15" selected={perPage === 15 ? true : false}>
                15
              </option>
              <option value="20" selected={perPage === 20 ? true : false}>
                20
              </option>
              <option value="100" selected={perPage === 100 ? true : false}>
                100
              </option>
            </select>
            <FormattedMessage
              id="app.vitrinas.entradas"
              defaultMessage="ENTRADAS"
            />
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 header-2">
          <span>
            <p className="w-20-pc"></p>
            TOTAL{' '}
            <FormattedMessage
              id="app.cdp.consumo.table.column3"
              defaultMessage="AUDIENCIAS EXISTENTES"
            />
            : &nbsp;
            <strong>
              {totalAudiencia != 0 ? (
                totalAudiencia
              ) : (
                <span id={'totalaudexist'}></span>
              )}
            </strong>
          </span>
          <p className="w-20-pc"></p>
          <TextField
            id="search"
            type="text"
            style={{ display: 'none' }}
            placeholder={useIntl().formatMessage(
              { id: 'app.vitrinas.buscar' },
              { defaultMessage: 'BUSCAR POR...' }
            )}
            onChange={changeTextSearch}
            data-tip={useIntl().formatMessage(
              { id: 'app.vitrinas.buscar.tooltip' },
              { defaultMessage: 'Ingresa al meno 3 caracteres para búscar' }
            )}
          />
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

          {filterText === '' ? (
            <ClearButton
              type="button"
              onClick={onClear}
              style={{ display: 'none' }}
            >
              <BsSearch
                title={
                  'Buscar ' +
                  intl.formatMessage(
                    { id: 'app.cdp.audience-list' },
                    { defaultMessage: 'Lista de audiencias' }
                  )
                }
                size={25}
              />
            </ClearButton>
          ) : (
            <ClearButton type="button" onClick={onClear}>
              <BsEraser title="Limpiar búsqueda" size={25} />
            </ClearButton>
          )}
        </div>
      </div>
    </>
  )

  const columns = [
    {
      id: 'Year',
      name: intl.formatMessage(
        { id: 'app.cdp.consumo.table.column1' },
        { defaultMessage: 'YEAR' }
      ),
      selector: (row: any) => row.Year,
      sortable: false,
      reorder: true,
      center: true,
      minWidth: '30px'
    },
    {
      id: 'Month',
      name: intl.formatMessage(
        { id: 'app.cdp.consumo.table.column2' },
        { defaultMessage: 'MONTH' }
      ),
      selector: (row: any) => row.Month,
      center: true,
      minWidth: '30px',
      sortable: false,
      sortField: 'Month'
    },
    {
      id: 'ExistingAudience',
      name: intl.formatMessage(
        { id: 'app.cdp.consumo.table.column3' },
        { defaultMessage: 'AUDIENCIAS EXISTENTES' }
      ),
      selector: (row: any) => row.ExistingAudience,
      center: true,
      minWidth: '60px'
    },
    {
      id: 'SavedAudience',
      name: intl.formatMessage(
        { id: 'app.cdp.consumo.table.column4' },
        { defaultMessage: 'AUDIENCIAS CREADAS' }
      ),
      selector: (row: any) => row.SavedAudience,
      center: true,
      minWidth: '60px',
      sortable: false,
      sortField: 'SavedAudience'
    },
    {
      id: 'CreatedAudience',
      name: intl.formatMessage(
        { id: 'app.cdp.consumo.table.column5' },
        { defaultMessage: 'AUDIENCIAS GUARDADAS' }
      ),
      selector: (row: any) => row.CreatedAudience,
      center: true,
      minWidth: '60px',
      sortable: false,
      sortField: 'CreatedAudience'
    },
    {
      id: 'DownloadedAudience',
      name: intl.formatMessage(
        { id: 'app.cdp.consumo.table.column6' },
        { defaultMessage: 'CANTIDAD DE DESCARGAS' }
      ),
      selector: (row: any) => row.DownloadedAudience,
      center: true,
      minWidth: '60px',
      sortable: false,
      sortField: 'DownloadedAudience'
    },
    {
      id: 'DownloadedAudience',
      name: intl.formatMessage(
        { id: 'app.cdp.consumo.table.column7' },
        { defaultMessage: 'CANTIDAD DE REGISTROS' }
      ),
      selector: (row: any) => row.DownloadedAudience,
      center: true,
      minWidth: '60px',
      sortable: false,
      sortField: 'DownloadedAudience'
    },
    {
      id: 'TotalInRows',
      name: intl.formatMessage(
        { id: 'app.cdp.consumo.table.column8' },
        { defaultMessage: 'TOTAL CONSUMOS' }
      ),
      selector: (row: any) => row.TotalInRows,
      center: true,
      minWidth: '60px',
      sortable: false,
      sortField: 'TotalInRows'
    }
  ]

  const paginationComponentOptions = {
    rowsPerPageText: intl.formatMessage(
      { id: 'app.vitrinas.mostrando' },
      { defaultMessage: 'MOSTRANDO' }
    ),
    rangeSeparatorText: intl.formatMessage(
      { id: 'app.vitrinas.de' },
      { defaultMessage: ' DE ' }
    )
  }

  const handlePageChange = (page: any) => {
    getDataReload(page).catch((error) => console.log(error))
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
    getDataReload(page).catch((error) => console.log(error))
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      setResetPaginationToggle(!resetPaginationToggle)
      setFilterText('')
    }

    if (filterText === '') {
      setTotalRows(totalRowsAux)
    } else {
      getDataReload(1).catch((error) => console.log(error))
      setTimeout(() => {
        const input = document.getElementById('search') as HTMLInputElement
        input.value = filterText
        if (input != null) input.focus()
      }, 200)
    }

    return (
      <FilterComponent
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  useEffect(() => {
    getDataReload(1).catch((error) => console.log(error))
  }, [resetPaginationToggle, handlerReloadDataTable])

  useEffect(() => {
    calculateTotalExiting()
  }, [loading])

  const handleSort = (column: any, sortDirection: any) => {
    getDataReload(1).catch((error) => console.log(error))
  }
  return (
    <div className="row">
      {accounts.length >= 1 && totalRows > 0 ? (
        <>
          <div>
            <DataTable
              subHeaderAlign={Alignment.LEFT}
              columns={columns}
              data={accounts}
              progressPending={loading}
              progressComponent={
                <Loading
                  textLoading={intl.formatMessage(
                    { id: 'app.vitrinas-spinner.espere' },
                    { defaultMessage: 'Espere...' }
                  )}
                />
              }
              pagination
              paginationServer
              paginationPerPage={15}
              paginationTotalRows={totalRows}
              paginationResetDefaultPage={resetPaginationToggle}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 100]}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={customStyles}
              noDataComponent={intl.formatMessage(
                { id: 'app.vitrinas.nodata' },
                { defaultMessage: 'Sin Resultados.' }
              )}
              responsive
              sortServer
              onSort={handleSort}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default ConsumptionTableGrid
