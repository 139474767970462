const PurchaseLogoSvg = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="35px"
      height="35px"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve"
    >

      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          
          d="M1610,5105c-253-56-475-207-619-418c-52-77-119-226-141-317c-15-60-23-132-27-246l-6-162l-44-6
		c-67-9-153-54-209-110c-102-103-80,1-335-1614C-21,650-20,658,20,512C75,314,199,163,382,70C530-4,457-1,1850,2l1245,3l83,32
		c240,93,400,282,448,531c18,97,18,142-6,297l-19,130l718,720c703,704,719,722,757,799l39,80v130c0,128-1,133-34,206
		c-33,72-52,92-455,498c-232,233-443,438-471,456c-113,75-254,101-379,72c-125-30-174-64-382-268l-191-188l-13,86
		c-29,196-160,343-332,372l-43,8l-6,164c-5,123-12,187-28,250c-89,337-348,604-687,706c-78,23-110,27-254,30
		C1722,5119,1657,5115,1610,5105z M2030,4920c232-65,416-220,520-435c64-132,80-202,87-372l6-143h-822h-821v133
		c0,199,37,330,135,477c44,66,141,168,205,216c84,62,230,123,348,144C1765,4954,1946,4944,2030,4920z M2875,3769
		c49-23,101-76,120-123c7-17,23-91,35-165l21-135l-120-120l-121-121v80c0,106-13,148-63,203c-81,87-17,82-921,82
		c-578,0-809-3-837-12c-51-15-114-69-142-122c-22-40-22-47-22-446c0-396,0-406,22-445c28-52,68-89,123-114c43-20,64-21,557-21h512
		l-26-62c-37-91-43-230-15-324c12-38,35-91,53-119c41-66,818-845,892-895c98-66,157-84,277-84c84,0,116,5,159,22c30,12,57,20,59,17
		c24-23,28-218,7-300c-50-190-221-350-409-384c-40-8-441-11-1225-11c-1280,0-1228-2-1353,61c-163,82-287,280-288,457
		c0,47,443,2874,460,2933c16,59,63,114,121,143l53,26h1013C2826,3790,2830,3790,2875,3769z M3962,3782c14-6-174-199-887-912
		c-993-993-921-930-919-800c1,128-33,88,807,927c659,658,768,763,807,776C3816,3789,3931,3794,3962,3782z M4193,3612l57-57l-928-928
		l-927-927l-60,60l-60,60l925,925c509,509,927,925,930,925S4161,3644,4193,3612z M4641,3163c294-297,303-311,304-438
		c0-139,34-99-798-931c-812-811-782-785-909-792c-132-8-138-4-443,297l-271,266l926,928c509,510,927,927,931,927
		C4384,3420,4501,3304,4641,3163z M2611,3271l29-29v-154v-153l-228-228l-228-227l-573,2l-573,3l-19,24c-18,22-19,44-19,378
		c0,232,4,361,11,374c6,11,20,24,32,29s363,9,780,9l759,1L2611,3271z"
        />
        <path
          
          d="M355,916c-36-228-33-305,15-394c38-72,87-119,162-159l53-28l288-3l287-3v85v86H918c-140,0-259,5-281,11
		c-52,14-113,77-128,131c-10,37-8,69,15,218c15,96,29,182,32,191c4,13-6,18-48,23c-29,4-69,9-89,13l-36,5L355,916z"
        />
        <path  d="M1320,415v-85h85h85v85v85h-85h-85V415z" />
        <path
          
          d="M4147,2782l-307-307l185-185l185-185l310,310l310,310l-182,182c-101,101-185,183-188,183S4317,2952,4147,2782z
		 M4523,2782l57-57l-185-185l-185-185l-60,60l-60,60l182,182c101,101,185,183,188,183S4491,2814,4523,2782z"
        />
        <path
          
          d="M3117,1842c-158-158-287-292-287-297c0-6,26-36,58-68l57-57l295,295l295,295l-60,60c-33,33-62,60-65,60
		S3276,2001,3117,1842z"
        />
        <path
          
          d="M3282,1507c-111-111-202-207-202-212c0-13,102-115,115-115s415,402,415,415s-102,115-115,115
		C3489,1710,3394,1619,3282,1507z"
        />
      </g>
    </svg>
  )
}

export default PurchaseLogoSvg
