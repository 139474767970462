import Service from '../services'

const useUsers = async () => {
  const users = await Service.get<UserData>('/dashboarduser/users')

  return users.data
}

export default useUsers

const useUser = async (data: LoginData) => {
  try {
    const user = await Service.post<ApiResponse, LoginData>(
      '/Account/Login',
      data
    )

    if (user.headers.statuscode !== '0') {
      return {
        loading: false
      }
    }

    return {
      loading: true
    }
  } catch (error) {
    console.log(error)
  }
}

const useUserLogout = async (data: any) => {
  try {
    const user = await Service.post<ApiResponse, any>(
      '/Account/Logout',
      '',
      {
        headers:
        {token: data}
      }
    )

    if (user.headers.statuscode !== '0') {
      return {
        loading: false
      }
    }

    return {
      loading: true
    }
  } catch (error) {
    console.log(error)
  }
}

const useGauth = async (data: GoogleAuth) => {
  try {
    const auth = await Service.post<UserLoggedIn, GoogleAuth>(
      '/Account/GoogleAuthenticator',
      data
    )
    if (auth.headers.statuscode !== '0') {
      return {
        isAuth: false
      }
    }
    return {
      isAuth: true,
      data: auth.data
    }
  } catch (error) {
    console.log(error)
  }
}

export { useUsers, useUser, useGauth, useUserLogout }
