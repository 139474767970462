/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import ScrollReveal from 'scrollreveal'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import anime from 'animejs'

import './style_mshop.css'
import ModalMshop from './ModalMshop'
import { Loading } from '../Globals/Loading'
import { RootState } from '../../state/store'
import { logout } from '../../state/actions'

const fetchData = async (
  url: string,
  body: Record<string, any>,
  method: string
) => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      throw new Error(`Error en la respuesta de la API: ${response.statusText}`)
    }

    return await response.json()
  } catch (error) {
    console.error('Error en la consulta:', error)
    throw error // Puedes manejar el error de otra manera si es necesario
  }
}

const MshopContainerTyPage: React.FC = () => {
  useEffect(() => {
    const e = document.documentElement
    if (e !== null) {
      e.classList.remove('no-js')
      e.classList.add('js')
      const sr = ScrollReveal()
      sr.reveal('.ms_feature, .ms_pricing-table-inner', {
        duration: 600,
        distance: '20px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        origin: 'bottom',
        interval: 100
      })

      e.classList.add('anime-ready')

      anime
        .timeline({
          targets: '.ms_hero-figure-box-05'
        })
        .add({
          duration: 400,
          easing: 'easeInOutExpo',
          scaleX: [0.05, 0.05],
          scaleY: [0, 1],
          perspective: '500px',
          delay: anime.random(0, 400)
        })
        .add({
          duration: 400,
          easing: 'easeInOutExpo',
          scaleX: 1
        })
        .add({
          duration: 800,
          rotateY: '-15deg',
          rotateX: '8deg',
          rotateZ: '-1deg'
        })

      // Resto del código...

      anime({
        targets:
          '.ms_hero-figure-box-01, .ms_hero-figure-box-02, .ms_hero-figure-box-03, .ms_hero-figure-box-04, .ms_hero-figure-box-08, .ms_hero-figure-box-09, .ms_hero-figure-box-10',
        duration: anime.random(600, 800),
        delay: anime.random(600, 800),
        rotate: [
          anime.random(-360, 360),
          function (e: any) {
            return e.getAttribute('data-rotation')
          }
        ],
        scale: [0.7, 1],
        opacity: [0, 1],
        easing: 'easeInOutExpo'
      })
    }
  }, [])

  const [code, setCode] = useState<string | null>(null)
  const [mShopHash, setMShopHash] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [userData, setUserData] = useState<{
    usuario: string
    contrasena: string
    qr: string
    qrKey: string
  } | null>(null)
  const dispatch = useDispatch()
  const token = useSelector<RootState, any>((state) => state.userAuth)

  useEffect(() => {
    const fetchDataAndHandleResult = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const codeParam = urlParams.get('code')
      if (codeParam !== null) {
        setLoading(true)
        setCode(codeParam)
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (token?.data?.token) {
          dispatch(logout(token.data.token))
        }
        try {
          const firstApiResponse = await fetchData(
            `${process.env.REACT_APP_BASE_PATH_SERVER}/mshop/MShopInstall`,
            { code: codeParam },
            'POST'
          )
          if (firstApiResponse.Status === 'Error') {
            handleModal(
              firstApiResponse.Error,
              'Error al instalar la APP',
              'Volver',
              'Te recomendamos contactar con el soporte de braindw.'
            )
          } else {
            const mShopHashResult = firstApiResponse.Response.AccessHash
            setMShopHash(mShopHashResult)
            const secondApiResponse = await fetchData(
              `${process.env.REACT_APP_BASE_PATH_SERVER}/mshop/clientAccess`,
              { AccessHash: mShopHashResult },
              'POST'
            )
            if (secondApiResponse !== null) {
              setUserData({
                usuario: secondApiResponse.Email,
                contrasena: secondApiResponse.TempPassword,
                qr: secondApiResponse.QR,
                qrKey: secondApiResponse.ManualKey
              })
            }
            console.log('Resultado de la segunda consulta:', secondApiResponse)
            console.log(code, mShopHash)
          }
          setLoading(false)
        } catch (error) {
          console.error(error)
          setLoading(false)
          handleModal()
        }
      }
    }

    fetchDataAndHandleResult()
  }, [])

  const [openModal, setOpenModal] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState('')
  const [modalErrorTitle, setModalErrorTitle] = useState('')
  const [modalErrorBtnText, setModalErrorBtnText] = useState('')
  const [modalErrorRecom, setModalErrorRecom] = useState('')
  const handleModal = (
    errorMessage?: string,
    title?: string,
    btnText?: string,
    recom?: string
  ) => {
    setModalErrorMessage(errorMessage ?? 'Ha ocurrido un error.')
    setModalErrorTitle(title ?? 'Error en la instalacion')
    setModalErrorBtnText(btnText ?? 'Volver a la tienda')
    setModalErrorRecom(
      recom ?? 'Te recomendamos contactar al equipo de sporte de Braindw.'
    )
    setOpenModal(true)
  }
  return (
    <body className="landing_ms_is-boxed ms_has-animations">
      <div className="ms_body-wrap">
        <header className="ms_site-header">
          <div className="landing_ms_container">
            <div className="ms_site-header-inner mb-3">
              <div className="landing_ms_brand header-brand">
                <h1 className="m-0">
                  <a href="#">
                    <img
                      className="ms_header-logo-image"
                      src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/17/logo_claim.svg"
                      alt="Logo"
                    />
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </header>

        <section className="ms_features section">
          <div className="landing_ms_container">
            <div className="ms_features-inner ms_section-inner2">
              <div className="ms_features-wrap">
                <div className="ms_feature text-center ms_is-revealing ms_feature2 pt-0">
                  {userData !== null ? (
                    <h4 className="ms_feature-title mt-4">
                      La instalación fue realizada con éxito.
                    </h4>
                  ) : (
                    <h4 className="ms_feature-title mt-4">
                      La aplicación Brain se esta instalado aguarde por favor...
                    </h4>
                  )}

                  <p className="ms_text-sm mb-0">
                    Comienza a incrementar tus ventas y comprueba el potencial
                    de Brain.
                  </p>
                  {userData !== null && !loading ? (
                    <>
                      <div className="ms_feature-ilustraexito mt-4">
                        <img src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/31/instalacionexito.svg" />
                      </div>
                      <div className="text-left texto-bullet">
                        <h5 className="ms_text-sm mt-4">
                          <b>
                            Sigue las indicaciones para acceder al panel de
                            configuración.
                          </b>
                        </h5>

                        <p className="ms_text-sm mb-0">
                          <b>Google Authenticator</b>
                          <br />
                          Descarga la app Google Authenticator en tu dispositivo
                          móvil, desde <a href="">Google Play</a> o{' '}
                          <a href="">App Store</a>
                        </p>
                        <div className="text-center">
                          <img
                            className="mockup-ms mt-4 mb-4"
                            src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/27/mckGooAuth.svg"
                          />
                        </div>

                        <p className="ms_text-sm mb-0 mt-5">
                          <b>Código QR</b>
                          <br />
                          Escanea desde Google Authenticator el siguiente Código
                          QR para acceder al generador de códigos Token. Anota
                          el código generado. <br />
                          <span className="text_claim">
                            Figura también en tu casilla de correo, dentro del
                            mail de bienvenida
                          </span>
                        </p>

                        <p className="ms_text-sm mb-0 mt-4">
                          <img src={userData.qr} alt="qr-code" />
                          <p>{userData.qrKey}</p>
                        </p>
                        <p className="ms_text-sm mb-0 mt-5">
                          <b>Login</b>
                          <br />
                          Accede al panel{' '}
                          <Link to={'/'}> http://panel.braindw.com </Link>
                        </p>

                        <p className="ms_text-sm mb-0">
                          Ingresa tu usuario {userData.usuario} y contraseña{' '}
                          {userData.contrasena} <br />
                          <span className="text_claim">
                            Figuran también en tu casilla de correo, dentro del
                            mail de bienvenida
                          </span>
                        </p>
                        <p className="ms_text-sm mb-0 mt-4">
                          <b>Código Token</b>
                          <br />
                          Ingresa el código Token generado por Google
                          Authenticator
                        </p>
                      </div>
                    </>
                  ) : (
                    <Loading textLoading="Espere..." />
                  )}
                </div>
              </div>
              <div className="ms_cta-cta mt-5 mb-5">
                <Link
                  className="ms_button ms_button-primary ms_button-wide-mobile"
                  to="/"
                >
                  ACCEDE AL PANEL
                </Link>
              </div>
            </div>
          </div>
          {openModal && (
            <ModalMshop
              title={modalErrorTitle}
              msjError={modalErrorMessage}
              msjRecomendation={modalErrorRecom}
              btnText={modalErrorBtnText}
              handleModal={handleModal}
            />
          )}
        </section>

        <footer className="ms_site-footer">
          <div className="landing_ms_container">
            <div className="ms_site-footer-inner p-0">
              <div className="landing_ms_brand footer-brand">
                <a href="#">
                  <img
                    className="ms_header-logo-image"
                    src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/17/logo_claim.svg"
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="ms_footer-copyright mt-4">
                &copy; 2024 BrainDW, todos los derechos reservados.
              </div>
            </div>
          </div>
        </footer>
      </div>

      <script src="dist/js/main.min.js"></script>
    </body>
  )
}

export default MshopContainerTyPage
