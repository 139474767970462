/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import Service from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { BsTrash, BsBoxArrowDownLeft } from 'react-icons/bs'
import { Loading } from '../../Globals/Loading'
import { ShowAlert } from '../../../components/Globals'

import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const RemoveData = ({ back }: any) => {
  const [firstRecordDate, setFirstRecordDate] = useState<string>('')
  const [lastRecordDate, setLastRecordDate] = useState<string>('')
  const [recordsToDelete, setRecordsToDelete] = useState<string>('')
  const [totalRecords, setTotalRecords] = useState<string>('')
  const [daysToRetain, setDaysToRetain] = useState<any>('20')
  const [totalDays, setTotalDays] = useState<string>('')
  const [historySegments, setHistorySegments] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const dispatch = useDispatch()
  const { selectedScript } = useClientContext()
  const intl = useIntl()

  const AlertToken = (action: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        switch (action) {
          case 'delete':
            ToDelete(result.value)
            break
          default:
            break
        }
      }
    })
  }

  const ConfirmDelete = () => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.remove-history' },
        { defaultMessage: 'Audiencias' }
      ),
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.cdp.remove-data.message-question' },
          {
            defaultMessage: '¿Confirma que desea eliminar los registros?'
          }
        ) +
        '</span>',
      inputLabel: '',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.continuar' },
        { defaultMessage: 'Continuar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cancelar' },
        { defaultMessage: 'Cancelar' }
      ),
      width: 600
    })
      .then((result) => {
        if (result.isConfirmed) {
          AlertToken('delete')
        }
      })
      .catch((error) => {
        MyAlert('API-ERROR: ' + `${error}`, 3)
      })
  }

  const ToDelete = (tokenG: string) => {
    setLoading(true)
    Service.post<any, any>(
      `Automation/DeleteHistorySegmentCdp?idHashScript=${selectedScript?.idHashScript}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          days: daysToRetain,
          username: user.data.username,
          tokenGoogle: tokenG,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          MyAlert(response.data.Message, 1)
        } else {
          MyAlert(response.data.Message, 2)
        }
        setLoading(false)
      })
      .catch((error) => {
        switch (error.response.data) {
          case -6:
            dispatch(logout(user.data.token))
            break
          case -5:
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'Token inválido' }
              ),
              3
            )
            break
          default:
            MyAlert('API-ERROR: ' + `${error}`, 3)
            break
        }
        setLoading(false)
      })
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    void ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.remove-history' },
        { defaultMessage: 'Audiencias' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: !isToast
    })
  }

  const getHistorySegmentCdp = (days: string) => {
    setLoading(true)
    setIsError(false)
    Service.get<any>(
      `Automation/GetHistorySegmentCdp?idHashScript=${selectedScript?.idHashScript}&days=${days}`,
      {
        headers: {
          token: user.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {          
            setHistorySegments(response.data.Data)

            setFirstRecordDate(response.data.Data.FirstRecordDate)
            setLastRecordDate(response.data.Data.LastRecordDate)
            setRecordsToDelete(response.data.Data.RecordsToDelete)
            setTotalRecords(response.data.Data.TotalRecords)
            setTotalDays(response.data.Data.TotalDays)          
        } else {
          MyAlert(response.data.Message, 2)
          setHistorySegments('')
          setIsError(true)
        }
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        }
        setLoading(false)
        setIsError(true)
      })
  }

  useEffect(() => {
    getHistorySegmentCdp('20')
  }, [])

  return (
    <>
      <div
        className="container mg-t-30 container table-bordered "
        style={{ display: 'block' }}
      >
        <div className="cdp_upload__title-container">
          <div className="rule-config__title-icon">
            <BsTrash />
          </div>
          <h1 className="rule-config__title-text">
            <FormattedMessage
              id="app.cdp.remove-history"
              defaultMessage="Remover Historial"
            />
          </h1>
        </div>
        <div className="container mg-b-15">
          <p>
            {intl.formatMessage(
              { id: 'app.tooltips.header.p.cdp-removerHistorial' },
              { defaultMessage: 'CDP' }
            )}
          </p>
        </div>
        <div className="rule-config__wrapper sombra_caja">
          <div className="rule-config__title-container row">
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
            <div className="automation__wrapper">
              {loading ? (
                <div
                  className="container mg-t-30"
                  style={{ textAlign: 'center' }}
                >
                  <Loading
                    textLoading={intl.formatMessage(
                      { id: 'app.vitrinas-spinner.espere' },
                      { defaultMessage: 'Espere...' }
                    )}
                  />
                </div>
              ) : (
                <div className="row">
                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <div className="shelves__endpoint-selector-container">
                        <label className="shelves__endpoint-selector-text mb-2">
                          <FormattedMessage
                            id="app.cdp.remove-data.days-held-to-date"
                            defaultMessage="Cantidad de días retenidos hasta la fecha"
                          />
                          <TooltipPanel 
                              ObjectTooltip={user.data.tooltips.filter((
                                (t:any) => t.idTooltip === 'app.cdp.remove.data.days.retenidos.tooltip')
                              )[0]}
                            />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <input
                        className="input-disabled col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        value={totalDays}
                        // onChange={(e: any) => setFirstRecordDate(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <div className="shelves__endpoint-selector-container">
                        <label className="shelves__endpoint-selector-text mb-2">
                          <FormattedMessage
                            id="app.cdp.remove-data.active-registrations-to-date"
                            defaultMessage="Cantidad de registros activos hasta la fecha"
                          />
                          <TooltipPanel 
                              ObjectTooltip={user.data.tooltips.filter((
                                (t:any) => t.idTooltip === 'app.cdp.remove.data.days.activos.tooltip')
                              )[0]}
                            />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <input
                        className="input-disabled col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        value={totalRecords}
                        // onChange={(e: any) => setLastRecordDate(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <div className="shelves__endpoint-selector-container">
                        <label className="shelves__endpoint-selector-text mb-2">
                          <FormattedMessage
                            id="app.cdp.remove-data.days-to-retain"
                            defaultMessage="Cantidad de días a retener"
                          />
                          <TooltipPanel 
                              ObjectTooltip={user.data.tooltips.filter((
                                (t:any) => t.idTooltip === 'app.cdp.remove.data.days.a.retener.tooltip')
                              )[0]}
                            />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <input
                        className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        value={daysToRetain}
                        type="number"
                        onChange={(e: any) => setDaysToRetain(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <button
                        className={
                          isError
                            ? 'btn-disabled-cdp secondary-yellow '
                            : 'secondary-yellow '
                        }
                        onClick={() => getHistorySegmentCdp(daysToRetain)}
                      >
                        <BsBoxArrowDownLeft />
                        &nbsp;
                        <FormattedMessage
                          id="app.cdp.remove-data.calculate"
                          defaultMessage="Calcular"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                    <div>
                      <div className="jumbotron">
                        <p className="lead">
                          <FormattedMessage
                            id="app.cdp.remove-data.message-text"
                            defaultMessage="Para la cantidad a días a retener indicado, existen"
                          />
                          <span className="badge badge-light">
                            {recordsToDelete}
                          </span>
                          <FormattedMessage
                            id="app.cdp.remove-data.message-rec"
                            defaultMessage="registros"
                          />
                        </p>
                        <hr className="my-4" />
                        <p>
                          <FormattedMessage
                            id="app.cdp.remove-data.message-ques1"
                            defaultMessage="¿Confirma que desea eliminar"
                          />
                          <span className="badge badge-danger">
                            {recordsToDelete}
                          </span>
                          <FormattedMessage
                            id="app.cdp.remove-data.records-for-a-total-of"
                            defaultMessage="registros de un total de"
                          />
                          <span className="badge badge-light">
                            {totalRecords}
                          </span>
                          ?
                        </p>
                        <p className="lead">
                          <button
                            className={
                              isError
                                ? 'btn-disabled-cdp btn btn-danger'
                                : 'btn btn-danger'
                            }
                            onClick={() => {
                              ConfirmDelete()
                            }}
                          >
                            <BsTrash />
                            <FormattedMessage
                              id="app.cdp.remove-data.delete"
                              defaultMessage="Eliminar"
                            />
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RemoveData
