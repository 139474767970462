import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { RootState } from '../store'
import {   USER_UPDATE_PHOTO } from '../constants/authConstants'


export const photoUpdate =
  (data: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
        dispatch({
            type: USER_UPDATE_PHOTO,
            payload: {
              photo: data
            }
          })
    } catch (error: any) {
    console.log(error)
    }
  }
