/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { HeaderContainer, MenuContainer } from '../components'
import MenuOptions from '../components/Menu/MenuOptions'
import options from '../utils/mainMenuOptionsMeli'
import { RootState } from '../state/store'
import ExpireAlert from '../components/Mshop/expireAlert'
// import Service from '../services'
import { useMshopContext } from '../contexts/MshopContext'
import { useClientContext } from '../contexts/ClientContext'
import { Loading } from '../components/Globals/Loading'

const DashboardMshop = () => {
  const [menuOption, setMenuOption] = useState<boolean>(false)
  const { planStatus } = useMshopContext()
  const { clientLoading } = useClientContext()
  const handleAction = () => {
    setMenuOption(!menuOption)
  }

  const user = useSelector<RootState, any>((state: any) => state.userAuth)
  const filterOptions = useMemo(() => {
    if (!user.data.isActiveAppMshop && user.data.userType !== 1) {
      if (
        user.data.disabledAppBy === 'Mshop' ||
        user.data.disabledAppBy === 'PanelBdw'
      ) {
        return options.filter((opt) => opt.id !== 3)
      } else {
        console.log('[BDW] - ERROR - DisabledApp MSHOP')
        return options
      }
    }
    if (user.data.isActiveAppMshop && user.data.userType !== 1) {
      return options.filter((opt) => opt.id !== 2)
    }
    return options
  }, [options])

  const expiredPlan = (date: string | null) => {
    if (date === null) return true

    const daysDiff = daysFromExpire(date)

    if (daysDiff && daysDiff <= 5) {
      return true
    }
  }

  const daysFromExpire = (date: string | null) => {
    if (!date) return null

    const filteredDate = date
      .split(' ')[0]
      .split('/')
      .map((d) => parseInt(d))
    const firstDate = new Date(
      filteredDate[2],
      filteredDate[0] - 1,
      filteredDate[1]
      // 9
    )
    const secondDate = new Date()

    const firstDateInMs = firstDate.getTime()
    const secondDateInMs = secondDate.getTime()

    const differenceBtwDates = secondDateInMs - firstDateInMs
    const aDayInMs = 24 * 60 * 60 * 1000

    const daysDiff = Math.ceil(Math.abs(differenceBtwDates / aDayInMs))

    return daysDiff
  }
  return (
    <>
      <HeaderContainer />
      <div className="main-menu-dashboard">
        <MenuContainer option={menuOption}>
          <MenuOptions
            tag="BrainDW |  Dashboard"
            options={filterOptions}
            onAction={handleAction}
          />
        </MenuContainer>
      </div>
      {planStatus?.ExpirationDate &&
        expiredPlan(planStatus?.ExpirationDate) && (
          <ExpireAlert
            // expired={planStatus?.PlanName === 'Basico'}
            days={daysFromExpire(planStatus?.ExpirationDate)}
          />
        )}
      {clientLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 200
          }}
        >
          <Loading textLoading="Cargando datos" />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default DashboardMshop
