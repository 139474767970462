import React from 'react'
import {  useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import { TooltipPanel } from '../../../Globals/TooltipPanel'

const DbGroupSkuId: React.FC<{
  title: string
  values: any  
}> = ({ title, values }) => {
  const valueParams = values
    .filter((x: any) => x[0] == 'dbGroupSkuId')
    .map((item: any) => {
      return item[1]
    })

    const user = useSelector<RootState, any>((state) => state.userAuth)
  return (
    <div className="rule-config-subComponents__container">
      <div className="row a-center">
        <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
          {title}
          <TooltipPanel 
            ObjectTooltip={user.data.tooltips.filter((
              (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.DbGroupSkuId.tooltip')
            )[0]}
          />
        </h6>
        <input
          className="col-lg-9 col-md-9 col-sm-9 col-xs-9 vtexskuconsult__input"
          id="dbGroupSkuId"
          style={{ width: '64%', background: '#fcfbfb' }}
          type="text"
          defaultValue={valueParams !== undefined ? valueParams : ''}
        />
      </div>
    </div>
  )
}

export default DbGroupSkuId
