/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { config } from '../../config'
import InfoIcon from '../Globals/svg/InfoIcon'
import { ShowAlert } from '../../components/Globals'
import { useIntl } from 'react-intl'
import { RootState } from '../../state/store'
import { useSelector } from 'react-redux'
import { useTooltips } from '../../hooks/useTooltips'

export const TooltipPanel = ({ ObjectTooltip }: any) => {
  const intl = useIntl()
  const local = useSelector<RootState, Lang>((state) => state.langSelected)
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const MyAlertAdmin = (text: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.carrousel.rules.tooltip.title' },
        { defaultMessage: 'Ayuda' }
      ),
      toast: true,
      html: ObjectTooltip.text_es + '<hr/>',

      inputValue: ObjectTooltip.text_es,
      input: 'textarea',
      // inputLabel: 'Modificá el contenido desde aquí',
      inputPlaceholder: 'Ingresá el contenido',

      inputAttributes: {
        autocapitalize: 'off'
      },
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal-regla.guardar' },
        { defaultMessage: 'Guardar' }
      ),
      color: '#1c684c',
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      icon: 'info',
      position: 'top-end',
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        saveTooltip(result.value)
      }
    })
  }

  const MyAlertUser = (text: string, time = 25000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.carrousel.rules.tooltip.title' },
        { defaultMessage: 'Ayuda' }
      ),
      toast: true,
      timer: time,
      html: text,

      icon: 'info',
      showConfirmButton: false,
      showCloseButton: true,
      position: 'top-end',
      width: 500,
      timerProgressBar: true
    })
  }

  const saveTooltip = (text: string) => {
    try {
      const objData = {
        idTooltip: ObjectTooltip.idTooltip,
        lang: local.lang,
        text_es: text,
        text_en: text,
        module: '',
        userUpdated: user.data.IdDashboardUser
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          token: user.data.token,
          userName: user.data.username,
          'Content-Type': 'application/json',
          Accept: '* / *',
          credentials: 'include'
        },
        body: JSON.stringify(objData)
      }
      fetch(
        `${config.base_server_path}/Panel/UpdateTooltipPanel`,
        requestOptions
      )
        .then(async (response) => await response.json())
        .then(async (result) => {
          if (result.StatusCode < 0 || result < 0) {
            if (result !== -5 && result !== -6)
              MyAlert(
                intl.formatMessage(
                  { id: 'app.api.error' },
                  {
                    defaultMessage:
                      'Ocurrió un Error al actualizar los datos de Tooltips.'
                  }
                ),
                2
              )
          } else {
            MyAlert(
              'Se actualizó con éxito el texto del tooltip: ' +
                `${ObjectTooltip.idTooltip}`,
              1
            )
            if (local.lang === 'es') {
              user.data.tooltips.find(
                (t: any) => t.idTooltip === ObjectTooltip.idTooltip
              ).text_es = text
            }
            if (local.lang === 'en') {
              user.data.tooltips.find(
                (t: any) => t.idTooltip === ObjectTooltip.idTooltip
              ).text_en = text
            }
            // Si desea, se puede traer todos los tooltips de nuevo y recargar
            // user.data.tooltips = []
            // user.data.tooltips = await useTooltips(user.data.token)
          }
        })
    } catch (error) {
      console.log('API ERROR: ' + `${error}`, 3)
    }
  }

  // useEffect(() => {
  //   console.log(ObjectTooltip)
  // }, [])

  return (
    <>
      <a
        id={'btn-nada'}
        key={'btn-nada'}
        style={{
          cursor: 'pointer',
          display: 'contents'
        }}
        onClick={() => {
          if (ObjectTooltip == null || ObjectTooltip == undefined) {
            MyAlert(
              'No ha sido definido aun un id  para almacenar el texto de este tooltip',
              2
            )
          } else {
            if (user.data.userType == 1) {
              MyAlertAdmin(
                local.lang == 'es'
                  ? ObjectTooltip.text_es
                  : ObjectTooltip.text_en
              )
            } else {
              MyAlertUser(
                local.lang == 'es'
                  ? ObjectTooltip.text_es
                  : ObjectTooltip.text_en
              )
            }
          }
        }}
      >
        <InfoIcon />
      </a>
    </>
  )
}
