import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth, useLogged } from '../hooks'
import { useSelector } from 'react-redux'
import { RootState } from '../state/store'

const ProtectedAuthRoute = () => {
  const isAuth = useLogged()
  const location = useLocation()

  return isAuth == true ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

const ProtectedDashRoute = ({
  role,
  userType
}: {
  role: string | number
  userType: string | number
}) => {
  const isAuth = useAuth()
  const location = useLocation()
  const user = useSelector<RootState, any>((state: any) => state.userAuth)
  // console.log(location)
  // console.log(isAuth)
  if (isAuth === true && user.data.Platform !== 'MercadoShop') {
    // console.log(user.data.userType)
    // console.log(user.data.Platform)
    return <Outlet />
  }
  if (isAuth === true && user.data.Platform === 'MercadoShop') {
    return <Navigate to="/dashboardmshop/homebdw" />
  }
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  // if (isAuth) {
  //   return <Navigate to="/authtoken" state={{ from: location }} replace />
  // }

  return <Navigate to="/login" state={{ from: location }} replace /> // <Navigate to={location.state?.from} />
}

const ProtectedRouteMercadoShop = () => {
  const isAuth = useAuth()
  const user = useSelector<RootState, any>((state: any) => state.userAuth)
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (isAuth === true && user.data.Platform === 'MercadoShop') {
    return <Outlet />
  }
  if (isAuth === true && user.data.Platform !== 'MercadoShop') {
    return <Navigate to="/dashboard" replace />
  }
  return <Navigate to="/login" replace />
}

export { ProtectedAuthRoute, ProtectedDashRoute, ProtectedRouteMercadoShop }
