import React from 'react'
import MenuContainer from '../../Menu/MenuContainer'
import MenuOptions from '../../Menu/MenuOptions'
import {
  cdpSubMenuOptions,
  cdpSubMenuOptionsForUsers
} from '../../../utils/secondMenuOptions'
import { RootState } from '../../../state/store'
import { useSelector } from 'react-redux'

const SubMenuCDP = () => {
  const handleAction = () => {
    console.log()
  }
  const user = useSelector<RootState, any>((state) => state.userAuth)

  return (
    <>
      <MenuContainer>
        <MenuOptions
          tag="BrainDW | System"
          options={
            user.data?.userType == 0
              ? cdpSubMenuOptionsForUsers
              : cdpSubMenuOptions
          }
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default SubMenuCDP
