/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BsLockFill, BsPersonSquare } from 'react-icons/bs'
import { useIntl, FormattedMessage } from 'react-intl'
import '../RelatedCategories/relatedCategories.css'
import '../Global.css'
import { RootState } from '../../../state/store'
import ProfileForm from './ProfileForm'
import UserPass from './UserPass'


const ProfileAndPassUser = () => {
  
  const intl = useIntl()
  const dispatch = useDispatch()
  const [tabRelates, settabRelates] = useState(false)
  const [tokenValue, setTokenValue] = useState<string>('')

  const handleTab = () => {
    settabRelates(!tabRelates)
  }


  useEffect(() => {}, [])

  return (
    <>
      <div className="container mg-t-30">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="subscription__title-container">
              <div
                className={
                  tabRelates
                    ? 'subscription__wrapper_disable_pass col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex mt-3'
                    : 'subscription__wrapper_pass d-flex col-lg-6 col-md-6 col-sm-6 col-xs-6'
                }
                onClick={handleTab}
              >
                <BsPersonSquare className="manage-grid-button related_categories__title-icon" />
                <h1 className="subscription__title-text">
                  <FormattedMessage
                    id="app.system-tab-profile"
                    defaultMessage="Perfil de Usuario"
                  />
                </h1>
              </div>
              <div
                className={
                  tabRelates
                    ? 'subscription__wrapper_pass d-flex col-lg-6 col-md-6 col-sm-6 col-xs-6'
                    : 'subscription__wrapper_disable_pass d-flex mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-6'
                }
                onClick={handleTab}
              >
                <BsLockFill className="manage-grid-button related_categories__title-icon" />
                <h1 className="subscription__title-text">
                  <FormattedMessage
                    id="app.system-tab-pass"
                    defaultMessage="Cambiar contraseña"
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!tabRelates ? <ProfileForm /> : <UserPass />}
    </>
  )
}

export default ProfileAndPassUser
