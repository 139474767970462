import { useEffect, useState } from 'react'
import { IntlProvider, MessageFormatElement } from 'react-intl'
import Spanish from '../../utils/es.json'
import English from '../../utils/en.json'
import { useSelector } from 'react-redux'
import { RootState } from '../../state/store'

let lang: Record<string, string> | Record<string, MessageFormatElement[]>

const LanguageProvider = ({ children }: any) => {
  const local = useSelector<RootState, Lang>((state) => state.langSelected)

  const [messages, setMessages] = useState(lang)

  useEffect(() => {
    if (local.lang === 'es') {
      lang = Spanish
      setMessages(lang)
    } else {
      lang = English
      setMessages(lang)
    }
  }, [local.lang])

  return (
    <IntlProvider messages={messages} locale={local.lang}>
      {children}
    </IntlProvider>
  )
}

export default LanguageProvider
