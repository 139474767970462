import React from 'react'
import './style_mshop.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../state/store'
import logo from '../../img/Brain-Marca.png'
const MshopInvalidGrantLanding: React.FC = () => {
  const user = useSelector<RootState, any>((state: any) => state.userAuth)
  return (
    <div className="ms_has-animations">
      <div className="ms_body-wrap">
        <main>
          <section className="ms_features section">
            <div className="landing_ms_container">
              <div className="ms_section-inner ">
                <div className="ms_feature-icon" style={{ marginBottom: 80 }}>
                  <img src={logo} alt="Feature 03" />
                </div>

                <h2
                  className=""
                  style={{ color: '#00868B', fontSize: 35, fontWeight: 600 }}
                >
                  Has quitado los permisos
                </h2>
                <div className="ms_cta-cta">
                  <p style={{ color: '#666666', fontSize: 18 }}>
                    La aplicación ya no cuenta con permisos de Mercado Shops.
                  </p>
                  <p style={{ color: '#666666', fontSize: 18 }}>
                    Para acceder al panel deberás volver a instalarla desde
                    aquí.
                  </p>
                  <br></br>
                  <div className="ms_footer-copyright">
                    <p style={{ color: '#666666' }}>
                      Te quedan{' '}
                      <strong className="color_primary">
                        {user.data.RemainingDaysTrial} días
                      </strong>{' '}
                      para finalizar tu período de prueba.
                    </p>
                  </div>
                  <a
                    className="ms_button ms_button-primary ms_button-wide-mobile mr-2"
                    href={`https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=7605895153273357&redirect_uri=${process.env.REACT_APP_BASE_PATH_FRONT}/mshopthankspage`}
                  >
                    ¡INSTALAR AHORA!
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>

        <footer className="ms_site-footer">
          <div className="landing_ms_container"></div>
        </footer>
      </div>
    </div>
  )
}

export default MshopInvalidGrantLanding
