/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
BsBell,
BsBellSlash,
BsPencilSquare
} from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'
import './relatedCategories.css'
import '../Global.css'
import { RootState } from '../../../state/store'
import RelatedCategoriesContainer from './RelatedCategoriesContainer'
import RelatedSkusContainer from '../RelatedSkus/RelatedSkusContainer'

const RelatedCategoriesAndSkusContainer = () => {

  const [tabRelates, settabRelates] = useState(false)

  const handleTab = () => {
    settabRelates(!tabRelates)
  }

  useEffect(() => {}, [])

  return (
    <>
    <div className="container mg-t-30">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 container-tab">
            <div className="subscription__title-container">
              <div
                className={
                  tabRelates
                    ? 'subscription__wrapper_disable col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex mt-3'
                    : 'subscription__wrapper-bor d-flex col-lg-6 col-md-6 col-sm-6 col-xs-6'
                  }
                  onClick={handleTab}
                >
                <BsPencilSquare className="manage-grid-button related_categories__title-icon" />
                <h1 className="subscription__title-text">
                  <FormattedMessage
                    id="app.related-categories.config-cross-selling"
                    defaultMessage="CONFIGURACIÓN DE VENTA CRUZADA"
                  />
                </h1>
              </div>
              <div
                className={
                  tabRelates
                    ? 'subscription__wrapper-bor d-flex col-lg-6 col-md-6 col-sm-6 col-xs-6'
                    : 'subscription__wrapper_disable d-flex mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-6'
                  }
                  onClick={handleTab}
                >
                <BsPencilSquare className="manage-grid-button related_categories__title-icon" />
                <h1 className="subscription__title-text">
                  <FormattedMessage
                    id="app.config-related-skus"
                    defaultMessage="CONFIGURACIÓN DE SKUS RELACIONADOS"
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!tabRelates ? (          
          <RelatedCategoriesContainer />          
        ) : (
          <RelatedSkusContainer />
        ) 
      }

      
    </>
  )
}

export default RelatedCategoriesAndSkusContainer
