import { RefObject, useState } from 'react'
import { IMaskMixin } from 'react-imask'

const IMaskInput = IMaskMixin(({ inputRef, ...props }) => (
  <input ref={inputRef as RefObject<HTMLInputElement>} {...props} />
))

const MaskedInput = ({
  defaultValue,
  onChange,
  mask,
  placeholder,
  style
}: // ...props
{
  defaultValue?: any
  onChange: (...event: any[]) => void
  mask: any
  placeholder: string
  style?: any
  // props: any
}) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const [value, setValue] = useState(defaultValue?.toString() || '')
  const handleAccept = (v: any) => {
    setValue(v)
    onChange(v)
  }

  return (
    <IMaskInput
      // {...props}
      style={style}
      mask={mask}
      unmask
      onAccept={handleAccept}
      placeholder={placeholder}
      value={value}
    />
  )
}

export default MaskedInput
