/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAtom } from 'jotai'
import { RxDragHandleDots2 } from 'react-icons/rx'
import styled from 'styled-components'
import { useSprings, animated, config } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import clamp from 'lodash.clamp'
import swap from 'lodash-move'
import Select from 'react-select'

import Service from '../../../services'
import { RootState } from '../../../state/store'
import { Loading } from '../../Globals/Loading'
import { pdpAtom, pdpAtomOrderChange } from '../../../atoms/carrousel'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const HeadBlock = styled.div`
  margin: auto;
  font-weight: 600;
  font-size: 13px;
`
const BodyBlock = styled.div`
  margin: auto;
  font-weight: 300;
  color: #444;
  font-size: 12px;
`

const CarrouselTabPDP = () => {
  const { selectedClient } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [loading, setLoading] = useState<boolean>(false)
  const [blocks, setBlocks] = useState<any[]>([])
  const { block } = useParams()
  const [titles, setTitles] = useState(blocks.map((block) => block.Title))
  const [actives, setActives] = useState(blocks.map((block) => block.Active))
  const [pdpBlocks, setPdpBlocks]: any = useAtom(pdpAtom)
  const [atomOrderChange, setAtomOrderChange] = useAtom(pdpAtomOrderChange)

  const handleTitleChange = (e: any, index: any) => {
    const nuevosTitulos = [...titles]
    nuevosTitulos[index] = e.target.value
    setTitles(nuevosTitulos)
    const nuevosBlocks = [...blocks]
    nuevosBlocks[index] = { ...nuevosBlocks[index], Title: e.target.value }
    setBlocks(nuevosBlocks)
    setAtomOrderChange(true)
  }

  const handleLocationChange = (selectedLocation: any, index: any) => {
    const nuevosBlocks = [...blocks]
    const newLocation = { ...nuevosBlocks[index].Location }

    Object.keys(newLocation).forEach((key) => {
      newLocation[key] = false
    })

    newLocation[selectedLocation] = true

    nuevosBlocks[index] = {
      ...nuevosBlocks[index],
      Location: newLocation
    }

    setBlocks(nuevosBlocks)
    setAtomOrderChange(true)
  }

  const handleSwitchChange = (index: any) => {
    const nuevosActivos = [...actives]
    nuevosActivos[index] = !nuevosActivos[index]
    setActives(nuevosActivos)
    const nuevosBlocks = [...blocks]
    nuevosBlocks[index] = {
      ...nuevosBlocks[index],
      Active: !nuevosBlocks[index].Active
    }
    setBlocks(nuevosBlocks)
    setAtomOrderChange(true)
  }

  useEffect(() => {
    Service.get<any>(
      `/Mshop/carrouselsList?idClient=${selectedClient?.idClient}`
    ).then((response: any) => {
      setLoading(!loading)
      if (response.data.length > 0) {
        const pdpData = response.data.find(
          (item: any) => item.Section === 'Pdp'
        )
        if (pdpData) {
          const newTitles = pdpData.Carrusels.map(
            (carrusel: any) => carrusel.Title
          )
          const newActives = pdpData.Carrusels.map(
            (carrusel: any) => carrusel.Active
          )
          const carruselsArray = pdpData.Carrusels
          carruselsArray.sort(function (a: any, b: any) {
            return a.Order - b.Order
          })
          if (!atomOrderChange) {
            setBlocks(carruselsArray)
            setTitles(newTitles)
            setActives(newActives)
            setLoading(false)
          } else {
            setBlocks(
              pdpBlocks.sort(function (a: any, b: any) {
                return a.Order - b.Order
              })
            )
            setLoading(false)
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    if (blocks.length > 0) {
      setPdpBlocks(blocks)
    }
  }, [blocks])

  const fn =
    (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
    (index: number) =>
      active && index === originalIndex
        ? {
            y: curIndex * 80 + y,
            scale: 1.05,
            zIndex: 1,
            shadow: 15,
            immediate: (key: string) => key === 'zIndex',
            config: (key: string) =>
              key === 'y' ? config.stiff : config.default
          }
        : {
            y: order.indexOf(index) * 90,
            scale: 1,
            zIndex: 0,
            shadow: 1,
            immediate: false
          }

  const handleOrder = useCallback(
    (newOrder: number[], lastOrder: number[]) => {
      if (lastOrder.toString() === newOrder.toString()) {
        return
      }
      newOrder.forEach((img, idx) => {
        blocks[img].priority = idx + 1
        blocks[img].Order = idx + 1
      })
      setBlocks([...blocks])
      setAtomOrderChange(true)
      //   handleUpdatePriority([...blocks])
    },
    [blocks]
  )

  const order = useMemo(
    () => ({ current: blocks.map((_: any, index: any) => index) }),
    [blocks.length]
  )
  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    if (activeRowIndex) {
      const curIndex = order.current.indexOf(originalIndex)
      const curRow = clamp(
        Math.round((curIndex * 100 + y) / 100),
        0,
        blocks.length - 1
      )
      const newOrder = swap(order.current, curIndex, curRow)
      api.start(fn(newOrder, active, originalIndex, curIndex, y))
      if (!active) {
        const lastOrder = order.current
        order.current = newOrder
        handleOrder(newOrder, lastOrder)
      }
    }
  })

  const [springs, api] = useSprings(blocks.length, fn(order.current))

  const [activeRowIndex, setActiveRowIndex] = useState(false)
  const spanishBlockNames: any = {
    LastCategory: 'Última categoría visitada',
    ProductView: 'Últimos productos visitados',
    AddToCart: 'Agregados al carrito',
    LastSearch: 'Última búsqueda'
  }

  return (
    <>
      <div className="mt-3">
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loading textLoading="Cargando datos" />
          </div>
        ) : (
          <div
            style={{
              backgroundColor: '#fff',
              padding: '.5rem 2rem',
              marginTop: 20,
              height: (blocks.length + 1) * 100,
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '80px',
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(6,1fr)',
                gap: '6px'
              }}
            >
              <HeadBlock>
                MOVER
                <TooltipPanel
                  ObjectTooltip={
                    user.data.tooltips.filter(
                      (t: any) =>
                        t.idTooltip === 'app.carrousel.Mshop.toolTip.Mover'
                    )[0]
                  }
                />
              </HeadBlock>

              <HeadBlock>
                ORDEN
                <TooltipPanel
                  ObjectTooltip={
                    user.data.tooltips.filter(
                      (t: any) =>
                        t.idTooltip === 'app.carrousel.Mshop.toolTip.Orden'
                    )[0]
                  }
                />
              </HeadBlock>
              <HeadBlock>
                CARRUSEL
                <TooltipPanel
                  ObjectTooltip={
                    user.data.tooltips.filter(
                      (t: any) =>
                        t.idTooltip === 'app.carrousel.Mshop.toolTip.Vitrina'
                    )[0]
                  }
                />
              </HeadBlock>
              <HeadBlock>
                TÍTULO
                <TooltipPanel
                  ObjectTooltip={
                    user.data.tooltips.filter(
                      (t: any) =>
                        t.idTooltip === 'app.carrousel.Mshop.toolTip.Titulo'
                    )[0]
                  }
                />
              </HeadBlock>
              <HeadBlock>
                UBICACIÓN
                <TooltipPanel
                  ObjectTooltip={
                    user.data.tooltips.filter(
                      (t: any) =>
                        t.idTooltip === 'app.carrousel.Mshop.toolTip.Ubicacion'
                    )[0]
                  }
                />
              </HeadBlock>
              <HeadBlock>
                ON/OFF
                <TooltipPanel
                  ObjectTooltip={
                    user.data.tooltips.filter(
                      (t: any) =>
                        t.idTooltip === 'app.carrousel.Mshop.toolTip.onOff'
                    )[0]
                  }
                />
              </HeadBlock>
            </div>
            {springs.map(({ zIndex, shadow, y, scale }, i) => (
              <animated.div
                {...bind(i)}
                key={i}
                style={{
                  zIndex,
                  boxShadow: shadow.to(
                    (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                  ),
                  y,
                  scale,
                  position: 'absolute',
                  height: '80px',
                  width: '95%',
                  backgroundColor:
                    blocks[i].priority % 2 === 0 ? '#E8E8ED' : '#F9FAFB',
                  touchAction: 'none'
                }}
              >
                <div
                  style={{
                    height: 80,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(6,1fr)'
                  }}
                >
                  <BodyBlock>
                    <RxDragHandleDots2
                      cursor={'grab'}
                      onMouseDown={() => setActiveRowIndex(true)}
                      onMouseUp={() => setActiveRowIndex(false)}
                    />
                  </BodyBlock>
                  <BodyBlock>
                    <span>
                      {blocks[i].priority
                        ? blocks[i].priority
                        : blocks[i].Order}
                    </span>
                  </BodyBlock>
                  <BodyBlock style={{ fontSize: 12, textAlign: 'left' }}>
                    {spanishBlockNames[blocks[i].Name]}
                  </BodyBlock>

                  <BodyBlock>
                    <input
                      required
                      className="input vtexskuconsult__input col-lg-12 col-md-10 col-sm-12 col-xs-12"
                      type="text"
                      key="lastname"
                      value={blocks[i].Title}
                      onChange={(e) => handleTitleChange(e, i)}
                    />
                  </BodyBlock>
                  <BodyBlock>
                    <Select
                      isDisabled={activeRowIndex}
                      options={Object.keys(blocks[i].Location).map((key) => ({
                        value: key,
                        label: key
                      }))}
                      isSearchable={false}
                      menuPortalTarget={document.getElementById('root')}
                      menuPosition={'fixed'}
                      defaultValue={{
                        label: Object.keys(blocks[i].Location).find(
                          (key) => blocks[i].Location[key]
                        ),
                        value: Object.keys(blocks[i].Location).find(
                          (key) => blocks[i].Location[key]
                        )
                      }}
                      styles={{
                        menu: (prev) => ({
                          ...prev,
                          zIndex: 999,
                          // top: -20,
                          marginTop: 0,
                          marginBottom: 0
                        }),
                        control: (prev) => ({
                          ...prev,
                          minHeight: 28,
                          minWidth: 100
                        }),
                        dropdownIndicator: (prev) => ({
                          ...prev,
                          padding: '0px 6px !important;'
                        }),
                        menuList: (prev) => ({
                          ...prev,
                          padding: 0
                        }),
                        option: (prev) => ({
                          ...prev,
                          cursor: 'pointer',
                          fontSize: '12px'
                        })
                      }}
                      onChange={(e) => e && handleLocationChange(e.value, i)}
                    />
                  </BodyBlock>

                  <BodyBlock style={{ display: 'flex' }}>
                    <label className="switch_mode col-1 col-lg-1 col-md-1 col-sm-1 col-xs-1">
                      <input // prettier-ignore
                        type="checkbox"
                        style={{ marginRight: 10, cursor: 'pointer' }}
                        id="switch-modes"
                        data-tip={'Activar / Desactivar'}
                        checked={blocks[i].Active}
                        onChange={() => handleSwitchChange(i)}
                      />
                      <span
                        className={
                          blocks[i].Active
                            ? 'slider-mode round slider-bckg-on'
                            : 'slider-mode round slider-bckg-off'
                        }
                      >
                        <small className="switch_m">
                          {blocks[i].Active ? 'ON' : 'OFF'}
                        </small>
                      </span>
                    </label>
                  </BodyBlock>
                </div>
              </animated.div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}
export default CarrouselTabPDP
