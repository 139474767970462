/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/array-type */
import React, { useEffect, useState } from 'react'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import { Check, InputText, Select, Select2 } from './DynamicForm'
import { RuleModal } from '../RuleConfig/RuleConfigSubComponents'
import Service from '../../../services'
import Numeric from './DynamicForm/Numeric'
import { config } from '../../../config'
import { useIntl, FormattedMessage } from 'react-intl'
import { ShowAlert } from '../../../components/Globals'
import { logout } from '../../../state/actions'
import { BsCheck2All, BsPersonCheckFill, BsFillPlusCircleFill, BsFiles, BsClock } from 'react-icons/bs'
import { Loading } from '../../Globals/Loading'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import Accordion from 'react-bootstrap/Accordion'
import RowsAudienceRelated from './RowsAudienceRelated'
import Card from 'react-bootstrap/Card';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useClientContext } from '../../../contexts/ClientContext'

const FormCarrusel = ({
  editar,
  dataCarrusel,
  handlerDataReloadPage,
  handlerTextSearch
}: any) => {
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const local = useSelector<RootState, Lang>((state) => state.langSelected)

  const [dataGondola, setDataGondola] = useState<any>([])
  
  const [arrayVerify, setArrayVerify] = useState<any>([])
  const [showVerify, setShowVerify] = useState<boolean>(false)

  const [idEndpoint, setIdEndpoint] = useState<any>('')
  const [templatetitulo, setTemplateTitulo] = useState<any>([])
  const [templatelinkboton, setTemplateLinkBoton] = useState<any>([])
  const [templateappendClass, setTemplateAppendClass] = useState<string>('')
  const [templatebwsection, setTemplateBwSection] = useState<string>('')
  const [templatebwsectionAdic, setTemplateBwSectionAdic] = useState<string>('')
  const [templateenabled, setTemplateEnabled] = useState<any>([])
  const [templateurlObjectResult, setUrlObjectResult] = useState<any>([])
  const [templaTarget, setTemplaTarget] = useState<any>([])
  const [typeGondole, setTypeGondole] = useState<any>([])
  const [categorySelected] = useState<any>([])
  
  const [gondoleStatus, setGondoleStatus] = useState<any>([])
  const [dateFrom, setDateFrom] = useState<string>('')
  const [dateTo, setDateTo] = useState<string>('')
  const [hourFrom, setHourFrom] = useState<string>('')
  const [hourTo, setHourTo] = useState<string>('')
  
  const [priority, setPriority] = useState<string>('')

  const [optionsList, setOptionList] = useState<any>([])
  const [additionalfields, setAdditionalfields] = useState<any>([])
  
  const [audiencesCdp, setAudiencesCdp] = useState<any>([])
  const [isGondolaCdp, setIsGondolaCdp] = useState<boolean>(false)
  const [editPlpDefault, setEditPlpDefault] = useState<boolean>(false)

  const [arrayAudiencesForSelect, setArrayAudiencesForSelect] = useState<any>([])
  const [loadingAudiences, setLoadingAudiences] = useState(false)
  const [showAcordionAudiences, setShowAcordionAudiences] = useState(false)

  const [arrayCategoriesForSelect, setArrayCategoriesForSelect] = useState<any>([])
  const [loadingCategories, setLoadingCategories] = useState(false)

  const [showEditCategory, setShowEditCategory] = useState<boolean>(false)

  const [arrayAudienciasRelated, setArrayAudienciasRelated] = useState<any>([
    /* {
      value: 211,
      label: 'BOLSOS y VALIJAS_30D+1V-NC1',
    },
    {
      value: 303,
      label: 'TV-30D+1V-NC1 copy GG',
    } */
  ])
  const [arrayAudienciasRelatedempty, setArrayAudienciasRelatedEmpty] = useState<any>([])

  const [isModalOpen, setModalState] = useState(false)
  const toggleModal = () => setModalState(!isModalOpen)

  const [copyUrl, setCopyUrl] = useState<boolean>(false)

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const intl = useIntl()

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const MyAlertToast = (text: string, iconStr: number = 1, time = 25000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.create-vitrina-plp.title-button' },
        { defaultMessage: 'Personalizar Vitrina' }
      ),
      toast: true,
      timer: time,
      html: text,

      icon: iconStr === 1 ? 'info' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: false,
      showCloseButton: true,
      position: 'top-end',
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = () => {
    const saveButton = document.getElementById('saveRule')
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (!result) {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        saveButton?.classList.add('disabled')
        setLoading(true)
        actualizarVitrina(result.value)
      }
    })
  }

  const setShowPanelAudiences = () =>{
    getLoadAllAudiences([]) // cargo todas las audiencias de cliente
    setShowAcordionAudiences(true) // muestro el panel
  }

  const ConfirmDeleteAudience = (action: string, idDelete: number) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.audiencias.asoc' },
        { defaultMessage: 'defaultMessage="Asociar Audiencia' }
      ),        
      html:
        '<span class="m-4 text-left">' +
          action == 'CLONE' 
          ? intl.formatMessage(
            { id: 'app.cdp.related.audience.message-question' },
            {  defaultMessage: '¿Confirma que desea clonar la vitrina?' }
            ) 
          : intl.formatMessage(
            { id: 'app.cdp.remove.related.audience.message-question' },
            {  defaultMessage: '¿Confirma que desea Eliminar la audiencia de la vitrina?.' }
          ) +
        '</span>',
      inputLabel: '',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.continuar' },
        { defaultMessage: 'Continuar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cancelar' },
        { defaultMessage: 'Cancelar' }
      ),
      width: 600
    })
      .then((result) => {
        if (result.isConfirmed) {
          if(action == 'CLONE'){            
            getLoadAllAudiences([])
            setShowAcordionAudiences(true)
          }          
          if(action == 'DELETE'){
            setLoading(true)
            setArrayAudienciasRelated(
              arrayAudienciasRelated.filter((e:any) => e.value !== idDelete)
            )
            // actualizo el select para quitar desabilitados
            fncUpdateArrayAudiencesForSelect(arrayAudienciasRelated.filter((e:any) => e.value !== idDelete), idDelete)
            setLoading(false)
          }
        }
      })
      .catch((error) => {
        MyAlert('API-ERROR: ' + `${error}`, 3)
      })
  }

  const targetsType = [
    {
      id: 'plp',
      name: 'Category'
    },
    {
      id: 'plpc',
      name: 'Collection'
    },
    {
      id: 'pdp',
      name: 'Product'
    },
    {
      id: 'home',
      name: 'Home'
    },
    {
      id: 'sr',
      name: 'Search'
    },
    /* {
      id: 'snr',
      name: 'Searchn'
    }, */
    {
      id: 'checkout',
      name: 'Checkout'
    },
    {
      id: 'landing',
      name: 'Landings'
    }
  ]

  const handleChangeTarget = () => {
    const select = document.getElementById('slttargets') as HTMLSelectElement
    const texto =
      select.value === 'n/a' ? '' : select.options[select.selectedIndex].text
    setTemplaTarget(texto)
  }

  const handlerEditarCategoria = () => {
    setShowEditCategory(!showEditCategory)
  }

  const handleChangeCategory = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const idCat = parseInt(event.target.value)
    if (idCat > 0) {
      const newObjCat = {
        label: 'Select Category',
        labelId: 'NameCategorySelect',
        value: event.target.options[event.target.selectedIndex].text.replaceAll(
          ',',
          ';'
        ),
        type: 'service'
      }
      const newObjIdCat = {
        label: 'Id Category',
        labelId: 'IdCategorySelect',
        value: idCat,
        type: 'numeric'
      }
      setAdditionalfields([newObjCat, newObjIdCat])
      dataGondola.action.additionalFields.push(newObjCat)
      dataGondola.action.additionalFields.push(newObjIdCat)
      setEditPlpDefault(true)
      setLoadingCategories(true)      
    } else {
      setAdditionalfields([])
      setEditPlpDefault(false)
    }
  }

  const handleEditarName = (e: any) => {
    const pattern = /[^A-Za-z0-9 ñÑáéíóúÁÉÍÓÚüÜ-]/
    if (!pattern.test(e.target.value)) {
      setTemplateTitulo(e.target.value)
    }
  }

  const handleLinkTitulo = (e: any) => {
    setTemplateLinkBoton(e.target.value)
  }

  const handlePriority = (e: any) => {
    setPriority(e.target.value)
  }

  const handleAppendClass = (e: any) => {
    if (e.target.value.match('^[a-zA-Z0-9-]*$') != null) {
      setTemplateAppendClass(e.target.value)
    }
  }

  const handleBwSection = (e: any) => {
    if (e.target.value.match('^[a-zA-Z0-9-]*$') != null) {
      setTemplateBwSection(e.target.value)
    }
  }

  const handleBwSectionAdic = (e: any) => {
    if (e.target.value.match('^[a-zA-Z0-9-]*$') != null) {
      setTemplateBwSectionAdic(e.target.value)
    }
  }

  const handlerActivar = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const sel = e.target.checked ? '1' : '0'
    dataGondola.action.template_enabled = sel
    setTemplateEnabled(sel)
  }

  const handlerExpiration = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const sel = e.target.checked ? '1' : '0'
    dataGondola.action.template_enabled = sel
    setGondoleStatus(sel)
  }  

  const handleDateFrom = (e: any) => {
    setDateFrom(e.target.value)
  }

  const handleDateTo = (e: any) => {
    setDateTo(e.target.value)
  }

  const validHourMin = (value: string) =>{
    const  isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(value);
      if (isValid) {
        return true
      }else{
        return false
      } 
  }

  const handleHourFrom = (e: any) => {
    let hrs= e.target.value
    if(e.target.value.length == 2){
      hrs = hrs+':'
    }
    setHourFrom(hrs)

    if(e.target.value.length > 4){
      if(validHourMin(e.target.value)){        
        e.target.style.backgroundColor="#dcf9ef";
      }else{
        e.target.style.backgroundColor="#fff1c7";
      } 
    }
    

  }

  const handleHourTo = (e: any) => {
    let hrs= e.target.value
    if(e.target.value.length == 2){
      hrs = hrs+':'
    }
    setHourTo(hrs)
    
    if(e.target.value.length > 4){
      if(validHourMin(e.target.value)){        
        e.target.style.backgroundColor="#dcf9ef";
      }else{
        e.target.style.backgroundColor="#fff1c7";
      } 
    }
  }

  const inputOnChange = (e: any) => {
    const itemIndex = dataGondola.action.additionalFields.findIndex(
      (x: { labelId: string }) => x.labelId === e.target.id
    )
    dataGondola.action.additionalFields[itemIndex].value =
      e.target.value.replaceAll(',', ';')
  }

  const registerAction = () => {
    Service.post<any, any>(
      `/Account/RegisterAction?idHashScript=${selectedScript?.idHashScript}&action=${dataCarrusel.keySectionId}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          username: user.data.username,
          userType: user.data.userType === 1 ? 'admin' : 'user',
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -9) {
            // existe otro user editando esta vitrina
            MyAlert(res.data?.Message, 3, 4500)
            document.getElementById('saveRule')?.classList.add('disabled')
            setTimeout(() => {
              document.getElementById('btn-regresar')?.click()
            }, 3000)
          }
          if (res.data?.StatusCode == -10) {
            // existía otro usuario editando esta vitrina, pero lo saqué
            MyAlert(res.data?.Message, 3, 4500)
          }
        } else {
          console.log(res.data?.Message)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const fncOnChangeCategory = (event: any) => {

    if (event.value > 0) {
      // cuando se elige una categoría, se agregan dos atributos adicionales
      // el IdCategorySelect y el NameCategorySelect
      const newObjCat = {
        label: 'Select Category',
        labelId: 'NameCategorySelect',
        value: event.label.replaceAll(',',';'),
        type: 'service'
      }
      const newObjIdCat = {
        label: 'Id Category',
        labelId: 'IdCategorySelect',
        value: event.value,
        type: 'numeric'
      }
      setAdditionalfields([newObjCat, newObjIdCat])
      dataGondola.action.additionalFields.push(newObjCat)
      dataGondola.action.additionalFields.push(newObjIdCat)
      setEditPlpDefault(true)
      setLoadingCategories(true)

      MyAlertToast('Vas a configurar un nueva vitrina basada en la categoría que acabas de seleccionar....')
    } else {
      setAdditionalfields([])
      setEditPlpDefault(false)
      setLoadingCategories(false)
    }
    
  }

  const fncOnBlurSelectAudiencias = (event: any, a:any, b: any) => {
    const allInputs = document.getElementsByName('audienciaSlt_')       
    const newArray: { value: string; label: string }[] = []
    setArrayAudienciasRelated(arrayAudienciasRelatedempty)
    for (let i = 0; i < allInputs.length; i++) {
      const cosito = allInputs[i] as HTMLInputElement            
      newArray.push({
        value: cosito.value,
        label: ''
      })
    }
    // reemplazo la lista de auduencias
    setArrayAudienciasRelated(newArray)
    // Actualizo la lista de audiencas usada en los Select para deshabilitar los ya seleccionados
    fncUpdateArrayAudiencesForSelect(newArray, 0)
    
  }

  const fncUpdateArrayAudiencesForSelect = (audienciasRelated: any, idDelete: number) => {
    
    // todos en no
    arrayAudiencesForSelect.forEach((item: any) => {
      item.disabled = 'no'
    });
    // re set los si
    audienciasRelated.forEach((item: any, index: any) => {
      const founded = arrayAudiencesForSelect.findIndex( (rel:any) => rel.value == item.value)
      if(founded>=0){
        arrayAudiencesForSelect[founded].disabled = 'si'
      }      
    })
    // quitar la audiencia elimianda
    if(idDelete >0 ){
      const founded_ = arrayAudiencesForSelect.findIndex( (rel:any) => rel.value == idDelete)
      if(founded_>=0 ){
        arrayAudiencesForSelect[founded_].disabled = 'no'
      }      
    }
    
  }

  const onChangeSelectAudiences = (event: any, a:any, b: any) => {
    const repetidoIdx = arrayAudienciasRelated.findIndex((elem: any) => elem.value === event.value)
    if(repetidoIdx >= 0){  
      alert('Ya existe una audiencia '+event.label+' Igual.')
    }else{
      // Si es nuevo value == 0
      const foundIndex0 = arrayAudienciasRelated.findIndex( (x:any) => x.value == 0)
      if(foundIndex0 >= 0){
        arrayAudienciasRelated[foundIndex0].value = event.value
        arrayAudienciasRelated[foundIndex0].label = event.label        
      }      
    }
    
  }

  const AddAudience = () => {
    if(arrayAudienciasRelated.length >= 5){
      MyAlert(
        intl.formatMessage(
          { id: 'app.cdp.add.related.audience.message-limit' },
          { defaultMessage: 'La cantidad máxima de audiencias relacionadas es de 5.' }
        ), 3, 4000
      )
    }else{
      const rows = [
        ...arrayAudienciasRelated,
        {
          value: 0,
          label: '',
        }
      ]
      setArrayAudienciasRelated(rows)
    }
  }

  const canIsaveAction = () => {   

    if(gondoleStatus == "0" && dateFrom == ""){      
        // MyAlert('Revise la fecha desde cuando se mostrará esta vitrina. No puede estar vacía si seleccionó que tiene vencimiento.', 2)
        MyAlertToast('Revise la <strong>fecha desde</strong>. No puede estar vacía si deseleccionó <i>Sin vencimiento</i>.', 2, 3000)
        return null      
    }    
    if(gondoleStatus == "0" && dateTo == ""){
      // if(!validHourMin(hourTo)){
        MyAlertToast('Revise la <strong>fecha hasta</strong>. No puede estar vacía si deseleccionó <i>Sin vencimiento</i>.', 2, 3000)
        return null
      // }
    }

    Service.post<any, any>(
      `/Account/CanISaveAction?idHashScript=${selectedScript?.idHashScript}&action=${dataCarrusel.keySectionId}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          username: user.data.username,
          userType: user.data.userType === 1 ? 'admin' : 'user',
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert(res.data?.Message, 2)
          cleanRegisterAction() // borra registro de uso de vitrina, para que otro pueda editarla
          editar() // Regresar a la grilla
        } else {
          console.log(res.data?.Message)          
          AlertToken()
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const cleanRegisterAction = () => {
    Service.post<any, any>(
      `/Account/CleanRegisterAction?idHashScript=${selectedScript?.idHashScript}&action=${dataCarrusel.keySectionId}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          username: user.data.username,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        console.log(res.data)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const getLoadAllAudiences = async ( arrayExclude: any ) => {
    setLoadingAudiences(true)
    Service.get<any>(
      `Automation/GetAllSegmentsCDPub?idHashScript=${
        selectedScript?.idHashScript
      }&page=1&per_page=10000&filterText=&sortField=aliasName&sortDirection=desc`,
      {
        headers: {
          token: user.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {          
          if (response.data.Entities.length > 0) {
            setAudiencesCdp(response.data.Entities)            
            response.data.Entities.forEach((item: any, index: any) => {
                let founded = 0
                if(arrayExclude && arrayExclude.length>0){
                  founded = arrayExclude.findIndex( (rel:any) => rel.value == item.IdSegment)
                }else{
                  founded = arrayAudienciasRelated.findIndex( (rel:any) => rel.value == item.IdSegment)
                }                
                arrayAudiencesForSelect.push({
                  value: item.IdSegment,
                  label: item.AliasName,
                  disabled: founded>=0 ? 'si' : 'no'
                })
              }
            )
            setArrayAudiencesForSelect(arrayAudiencesForSelect)
          }
          setTimeout(() => {
            setLoadingAudiences(false)
          }, 1000)
        } else {
          setArrayAudiencesForSelect('')
        }
        setLoadingAudiences(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        }
        setLoadingAudiences(false)
      })
  }

  const VerifyResultsRule = () => {
    setLoading(true)
    if (templateurlObjectResult !== '') {
      let url = templateurlObjectResult.replace('<%CLIENT.BRANCHOFFICE%>','1').replace('<%CLIENT.WHITELABEL%>','').replace('qa.','.')
      if(url.indexOf('&minstock=&')>0){
        url = templateurlObjectResult.replace('&minstock=&','&minstock=1&')
      }
      axios.get(`${url}`,
        {
          headers: {            
            Accept: '*/*',
            type: 'application/json'
          }
        }).then((response) => {
          console.log(response.data)
          setArrayVerify(response.data)
          setShowVerify(!showVerify)
          setLoading(false)
        }).catch(() => {   
            setLoading(false)             
            MyAlert('Ocurrio un error. Revise la URL si es correcta.', 3)
        })    
      
    }
  }

  const handleShowBlockVerify = () => {
    setShowVerify(!showVerify)
  }

  useEffect(() => {
    if (selectedScript?.idHashScript !== '') {
      registerAction()

      Service.get<any>(
        `/ManageCarrousel/GetOneContentGondole?idHashScript=${selectedScript?.idHashScript}&keySectionId=${dataCarrusel.keySectionId}`,
        { headers: { token: user.data.token } }
      )
      .then((response) => {
        console.log('response', response)
        console.log('response.data', response.data)
        setDataGondola(response.data)
        const arr =
          response.data.action.template_bwsection.length > 0
            ? response.data.action.template_bwsection.split('_')
            : response.data.action.template_appendClass
        setTemplateTitulo(response.data.action.template_titulo)
        setTemplateLinkBoton(response.data.action.template_link_boton)

        setTemplateAppendClass(response.data.action.template_appendClass)
        setTemplateBwSection(response.data.action.template_appendClass)
        setTemplateBwSectionAdic(arr[1])

        setTemplateEnabled(response.data.action.template_enabled)
        setUrlObjectResult(response.data.action.urlObjectResult)
        setTemplaTarget(response.data.action.Target)
        setTypeGondole(response.data.typeGondole)
        setAdditionalfields(response.data.action.additionalFields)
        setIdEndpoint(response.data.idEndpoint)

        setGondoleStatus(response.data.expiration)
        if(response.data.dateFrom != null){          
          setDateFrom(response.data.dateFrom.split(' ')[0])
        }        
        setHourFrom(response.data.hourFrom !=null ? response.data.hourFrom.substring(0, 5): null)
        if(response.data.dateTo != null){          
          setDateTo(response.data.dateTo.split(' ')[0])
        }        
        setHourTo(response.data.hourTo !=null ? response.data.hourTo.substring(0, 5) : null)
        
        setPriority(response.data.priority)

        if(response.data.typeGondole === 'cdp' || dataCarrusel.keySectionId.indexOf('[cdp')>0){
          setIsGondolaCdp(true)
        }
        setArrayAudienciasRelated(response.data.segments)

        if(response.data.segments.length > 0){
          getLoadAllAudiences(response.data.segments)
          setShowAcordionAudiences(true)
        }
        const select = document.getElementById(
          'slttargets'
        ) as HTMLSelectElement
        if (response.data.action.Target != null && select != null) {
          for (let i = 0; i < select.options.length; i++) {
            if (select.options[i].text === response.data.action.Target) {
              select.options[i].selected = true
              break
            }
          }
        } else {
          if (select != null) {
            select.options[0].selected = true
          }
        }

        const chkin = document.getElementById(
          'chkenabled'
        ) as HTMLInputElement
        chkin.checked =
          response.data.action.template_enabled === '1' ? true : false

        if (response.data.typeGondole === 'default') {
          Service.get<any>(
            `/ManageCarrousel/SelectOptions?idHashScript=${selectedScript?.idHashScript}&type=category`,
            { headers: { token: user.data.token } }
          )
            .then((response) => {
              if (response.headers.statuscode === '0') {
                setOptionList(response.data)
                response.data.forEach((item: any, index: any) => {                                  
                  arrayCategoriesForSelect.push({
                    value: item.id,
                    label: item.name
                  })
                })
                setArrayCategoriesForSelect(arrayCategoriesForSelect)                
              }
            })
            .catch((error) => {
              if (error.response.data === -6) {
                dispatch(logout(user.data.token))
              } else {
                MyAlert('API-ERROR: ' + `${error}`, 3)
              }
            })
        }

      })
      .catch(() => {
        MyAlert(
          intl.formatMessage(
            { id: 'app.api.error' },
            { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
          ),
          2
        )
      })
    }

  }, [dataCarrusel])

  useEffect(() => {
    if (!showEditCategory) {
      setAdditionalfields([])
    }
  }, [showEditCategory])

  const actualizarVitrina = async (tokenValue: string) => {
    const saveButton = document.getElementById('saveRule')
    
    try {
      const objData = {
        keySectionId: dataCarrusel.keySectionId,
        typeGondole,
        idEndpoint,
        action: {
          template_titulo: templatetitulo,
          template_link_boton: templatelinkboton,
          template_enabled: templateenabled,
          template_appendClass: templateappendClass,
          template_bwsection: templatebwsectionAdic != undefined ? templateappendClass + '_' + templatebwsectionAdic : templateappendClass,
          idtemplate: dataCarrusel.action.idtemplate,
          cachetime: dataCarrusel.action.cachetime,
          objectResultRenderId: dataCarrusel.action.objectResultRenderId,
          urlObjectResult: templateurlObjectResult,
          TemplateType: dataCarrusel.action.TemplateType,
          idEndpoint: dataCarrusel.action.idEndpoint,
          Target: templaTarget,
          products: [],
          additionalFields: dataGondola.action.additionalFields
        },
        segments: arrayAudienciasRelated.filter((e:any) => e.value > 0),
        priority,
        actionPlpDefault: editPlpDefault? 'create' : 'update',
        expiration: gondoleStatus == '0' ? 1 : 0,
        dateFrom: dateFrom,
        dateto: dateTo,
        hourFrom: hourFrom,
        hourTo: hourTo
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          token: user.data.token,
          userName: user.data.username,
          tokenGoogle: tokenValue,
          'Content-Type': 'application/json',
          Accept: '* / *',
          credentials: 'include'
        },
        body: JSON.stringify(objData)
      }
      fetch(
        `${config.base_server_path}/ManageCarrousel/UpdateGondole?idHashScript=${selectedScript?.idHashScript}`,
        requestOptions
      )
        .then(async (response) => await response.json())
        .then((result) => {
          if (result.StatusCode < 0 || result < 0) {
            if (result === -5)
              MyAlert(
                intl.formatMessage(
                  { id: 'app.token-invalido' },
                  { defaultMessage: 'El token ingresado es inválido.' }
                ),
                3
              )
            if (result !== -5 && result !== -6)
              MyAlert(
                intl.formatMessage(
                  { id: 'app.api.error' },
                  {
                    defaultMessage: 'Ocurrió un Error al actualizar los datos.'
                  }
                ),
                2
              )
            setLoading(false)
            saveButton?.classList.remove('disabled')
          } else {
            setLoading(false)
            MyAlert(
              'Se actualizó con éxito la vitrina: ' + `${templateappendClass}`,
              1
            )

            if (dataCarrusel.action.Target !== templaTarget) {
              handlerDataReloadPage(templaTarget) // cambio el target, debe recargar toda la botonera
            }
            setTimeout(() => {
              handlerTextSearch('') // borra texto de búsqueda de la grilla para resetearla
              cleanRegisterAction() // borra registro de uso de vitrina, para que otro pueda editarla

              editar()
            }, 3000)
          }
        })
        .catch((error) => {
          if (error.response.data === -6) {
            dispatch(logout(user.data.token))
          } else {
            MyAlert('API-ERROR: ' + `${error}`, 3)
          }
        })
    } catch (error) {
      MyAlert('ERROR: ' + `${error}`, 3)
    }
  }

  const getIndexAudiences = (elem: string) => {
    const itemIndexSe = audiencesCdp.findIndex((x: any) => x.AliasName === elem)    
    return itemIndexSe
  }

  return (
    <>
      <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <h6>
            <FormattedMessage
              id="app.vitrinas-editar.configuracion"
              defaultMessage="Configuración"
            />
            :
          </h6>{' '}
          <p data-id={dataCarrusel.keySectionId}>
            {dataCarrusel.action.template_titulo}
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <h6>
            <FormattedMessage
              id="app.vitrinas-editar.ultimo-usuario-modificado"
              defaultMessage="Último usuario que modificó"
            />
            :
          </h6>{' '}
          <p>{dataCarrusel.userName}</p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <h6>
            <FormattedMessage
              id="app.vitrinas-editar.fecha-modificacion"
              defaultMessage="Fecha de modificación"
            />
            :
          </h6>{' '}
          <p>
            {dataCarrusel.dateLastUpdated
              ? dataCarrusel.dateLastUpdated.replace('T', ' ')
              : ''}
          </p>
        </div>
      </div>
      <div className="shelves__wrapper">
        {typeGondole === 'default' ? (
          <>
          <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 jc-flexend">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                <FormattedMessage
                  id="app.cdp.create-vitrina-plp.title-button"
                  defaultMessage="Personalizar vitrina"
                />
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.cdp.create-vitrina-plp.title-button.tooltip')
                  )[0]}
                />
                </label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <button
                id={'btn-crear-endpoint-categoria'}
                key={'btn-crear-endpoint-categoria'}
                className="ppal_button btn btn-primary"
                onClick={() => handlerEditarCategoria()}
              >
                <FormattedMessage
                  id="app.vitrinas-editar.crear-endpoint-por-categoria"
                  defaultMessage="PLP perzonalizadas"
                />
              </button>
            </div>
          </div>          
          </>
        ) : null}
        {showEditCategory ? (
          <>
          <div className="shelves panel-addtional-fields button mt-4 mb-4 box-specification">
            <div className="row mb-4 shelves__endpoint-selector-container">
              <div className="row">
                <div
                  key={'sltChangeCategory'}
                  className="shelves__endpoint-selector-container row"
                >
                  <Select2
                    selectedIndex={"1"}
                    arrayCategoriesForSelect={arrayCategoriesForSelect}
                    loadingCategories={loadingCategories}
                    fncOnChangeCategory={fncOnChangeCategory}
                    key={'sltChangeCategory'}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr/>
          </>
        ) : null}
        <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container" 
             style={{display: typeGondole != 'default' || loadingCategories?'flex':'none'}}>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 jc-flexend">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                <FormattedMessage
                  id="app.vitrinas.table.column2-2"
                  defaultMessage="Regla de Negocios"
                />
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.Form.Titulo.tooltip')
                  )[0]}
                />
              </label>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <button
              onClick={toggleModal}
              className="ppal_button btn btn-primary"
            >
              <FormattedMessage
                id="app.vitrinas-editar.configurar-regla"
                defaultMessage="Configurar Regla"
              />
            </button>
            <RuleModal
              isOpen={isModalOpen}
              toggleModal={toggleModal}
              endConfigured={setUrlObjectResult}
              setIdEndpoint={setIdEndpoint}
              templateurlObjectResult={templateurlObjectResult}
              idEndpoint={dataCarrusel.idEndpoint}
              keySectionId={dataCarrusel.keySectionId}
            />
          </div>
        </div>

        <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container"
             style={{display: typeGondole != 'default' || loadingCategories?'flex':'none'}}>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 jc-flexend">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                <FormattedMessage
                  id="app.vitrinas-editar.titulo"
                  defaultMessage="Título"
                />
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.tooltip.Form.Titulo.tooltip')
                  )[0]}
                />                
              </label>
            </div>
          </div>
          <div className="col-lg-7 col-md-8 col-sm-8 col-xs-8">
            <div className="shelves__endpoint-selector-container">
              <input
                className="input vtexskuconsult__input col-lg-8 col-md-8 col-sm-8 col-xs-8"
                onChange={handleEditarName}
                type={'text'}
                value={templatetitulo}
              />
            </div>
          </div>
        </div>

        { /**
         *  AUDIENCIAS. Usuarios ADMIN y OWNER solo pueden agregar vitrinas personalizadas
         */ 
        }
        { (typeGondole === 'cdp' || isGondolaCdp ) && (user.data.userType == 9 || user.data.userType == 1) ? (
          <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container box-specification">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right box-icon-admin">
              <BsPersonCheckFill title="Admin" size={25} /> <small>Admin</small>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 jc-flexend margin-32">
              <div className="shelves__endpoint-selector-container">
                <label className="shelves__endpoint-selector-text mb-2">
                  <FormattedMessage
                    id="app.submenu.audiencias"
                    defaultMessage="Audiencias"
                  />
                  <TooltipPanel                     
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.audiencias.panel.tooltip')
                    )[0]}
                  />                  
                </label>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
              <button
                onClick={() => setShowPanelAudiences() /* ConfirmDeleteAudience('CLONE', 0) */}
                className={arrayAudienciasRelated.length > 0 ? "ppal_button btn btn-primary disabled" : "ppal_button btn btn-primary"}
              >
                <FormattedMessage
                  id="app.audiencias.asoc"
                  defaultMessage="Asociar Audiencia"
                />
              </button>          
              &nbsp;&nbsp;&nbsp;&nbsp;
              <FormattedMessage
                id="app.audiencias.tit"
                defaultMessage="Prioridad"              
              />
              <TooltipPanel                     
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.form.audiencias.prioridad.tooltip')
                    )[0]}
                  />    
              <input
                className="input vtexskuconsult__input ml-20"
                type={'text'}
                onChange={handlePriority}
                value={priority}
                size={14}
              />            
            </div>
          </div>
          ) : null
        }

        { /* *** BLOQUE ACTIVAR **** */ }
        <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container ai-baseline"
             style={{display: typeGondole != 'default' || loadingCategories?'flex':'none'}}>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 jc-flexend">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                <FormattedMessage
                  id="app.vitrinas-editar.activar"
                  defaultMessage="Activar"
                />
                <TooltipPanel                     
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.edit.form.date.activar.tooltip')
                    )[0]}
                  />    
              </label>
            </div>
          </div>
          <div className="col-lg-7 col-md-8 col-sm-8 col-xs-8">
            <div className="shelves__endpoint-selector-container">
              <label className="switch">
                <input
                  key="chkenabled"
                  id="chkenabled"
                  data-chk={templateenabled}
                  type="checkbox"
                  onChange={(e) => handlerActivar(e)}
                  defaultChecked={templateenabled === '1' ? true : false}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>

        { /* *** BLOQUE FECHA-HORA INICIO Y FIN **** */ }
            <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container ai-baseline"
                  style={{display: typeGondole != 'default' || loadingCategories?'flex':'none'}}>

              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 jc-flexend">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    Vecimiento
                    <TooltipPanel                     
                        ObjectTooltip={user.data.tooltips.filter((
                          (t:any) => t.idTooltip === 'app.carrousel.edit.form.date.inicio.tooltip')
                        )[0]}
                      />    
                  </label>
                </div>
              </div>
              

              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <div className="shelves__endpoint-selector-container">
                  <label className="switch">
                    <input
                      key="chkstatus"
                      id="chkstatus"
                      data-chk={gondoleStatus}
                      type="checkbox"
                      onChange={(e) => handlerExpiration(e)}
                      defaultChecked={gondoleStatus == 0 ? true : false}
                    />
                    <span className="slider round"></span>
                  </label>
                  <label className="automation__endpoint-selector-text margin-sinvenc">
                    Sin vencimiento
                  </label>
                </div>
              </div>

              {gondoleStatus == 0 && gondoleStatus.length >0? 
              (
                <>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                
                    <input
                      className="dateInput mt-2 vtexskuconsult__input"
                      type={'date'}
                      placeholder="Desde : "
                      onChange={handleDateFrom}
                      value={dateFrom}
                    />
                    <input
                      className=" mt-2 vtexskuconsult__input"
                      type={'text'}
                      placeholder="HH:mm"
                      size={5}                
                      onChange={handleHourFrom}
                      value={hourFrom}
                    />
                    <BsClock 
                        title="Hora desde"                   
                        className='icon-date-2'
                        size={20} 
                      />
                  
                </div>  

                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <input
                  className="input mt-2 vtexskuconsult__input"
                  type={'date'}
                  placeholder="Hasta : "
                  onChange={handleDateTo}
                  value={dateTo}
                />
                <input
                    className=" mt-2 vtexskuconsult__input"
                    type={'text'}
                    placeholder="HH:mm"
                    size={5}
                    onChange={handleHourTo}
                    value={hourTo}
                  />
                  <BsClock 
                      title="Hora Hasta"                   
                      className='icon-date-2'
                      size={20} 
                    />
              </div>
                </>
              ): null
              }
        </div>


        <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container"
             style={{display: typeGondole != 'default' || loadingCategories?'flex':'none'}}>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 jc-flexend">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                ID
                <TooltipPanel                     
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.Form.ID.tooltip')
                    )[0]}
                  />                
              </label>
            </div>
          </div>
          <div className="col-lg-7 col-md-8 col-sm-8 col-xs-8">
            <div className="shelves__endpoint-selector-container">
              <input
                className="input vtexskuconsult__input col-lg-8 col-md-8 col-sm-8 col-xs-8"
                onChange={handleAppendClass}
                type={'text'}
                disabled
                value={templateappendClass}
              />
            </div>
          </div>
        </div>
        
        <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container"
             style={{display: typeGondole != 'default' || loadingCategories?'flex':'none'}}>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 jc-flexend">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                <FormattedMessage
                  id="app.vitrinas-editar.evento-GA"
                  defaultMessage="Evento GA"
                />
                <TooltipPanel                     
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.Form.EventoGA.tooltip')
                    )[0]}
                  />        
              </label>
            </div>
          </div>
          <div className="col-lg-7 col-md-8 col-sm-8 col-xs-8">
            <div className="shelves__endpoint-selector-container">
              <input
                className="input disabled vtexskuconsult__input col-lg-4 col-md-4 col-sm-4 col-xs-4"
                onChange={handleBwSection}
                disabled
                type={'text'}
                value={templatebwsection}
              />
              <input
                className="input vtexskuconsult__input col-lg-4 col-md-4 col-sm-4 col-xs-4"
                onChange={handleBwSectionAdic}
                type={'text'}
                value={templatebwsectionAdic}
              />
            </div>
          </div>
        </div>

        {
          /*
          * AUDIENCIAS. Usuarios ADMIN y OWNER solo pueden agregar vitrinas personalizadas. 
          */
        }
        { (typeGondole === 'cdp' || isGondolaCdp ) && (showAcordionAudiences || arrayAudienciasRelated.length > 0) && (user.data.userType == 9 || user.data.userType == 1) ? (
          <div className="box_accordion ">
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FormattedMessage
                  id="app.submenu.audiencias"
                  defaultMessage="Audiencias"
                />
                <TooltipPanel                     
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.edit.Form.acordion.title.tooltip')
                  )[0]}
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className="">
                  <div className="rule-config__title-container row">
                    <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">                      
                    
                    {arrayAudienciasRelated.map((item: any, index: any) => {
                      return ( 
                        <>
                          <RowsAudienceRelated
                            selectedIndex={getIndexAudiences(item.label)}
                            item={item}                            
                            arrayAudiencesForSelect={arrayAudiencesForSelect}
                            loadingAudiences={loadingAudiences}
                            arrayAudienciasRelated={arrayAudienciasRelated}
                            fncDeleteItemAudiencia = {ConfirmDeleteAudience}
                            fncOnChangeAudiences = {onChangeSelectAudiences}
                            key={index}
                            fncOnBlurSelectAudiencias={fncOnBlurSelectAudiencias}
                          />
                        </>
                      )})
                    }
                    
                    </div>
                    <div
                        className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        style={{ position: 'relative', left: '3%' }}
                      >
                        <a
                          className={arrayAudienciasRelated.length >= 5 ? "manage-grid-button btn btn-border btn-agregar disabled": "manage-grid-button btn btn-border btn-agregar"}
                          onClick={AddAudience}
                        >
                          <BsFillPlusCircleFill size={25} /> &nbsp;
                          {intl.formatMessage(
                            { id: 'app.audiencias.add' },
                            { defaultMessage: 'Agregar audiencia' }
                          )}                          
                        </a>
                        <span className={arrayAudienciasRelated.length >= 5 ? 'text-danger small' : 'text-danger small display-none'}>
                          <FormattedMessage
                            id="app.cdp.add.related.audience.message-limit"
                            defaultMessage="La cantidad máxima de audiencias relacionadas es de 5."
                          />
                        </span>
                    </div>
                    
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </div>
        ) : null }

        {/* PANEL PARA EL ADMIN */}
        {user.data.userType === 1 || user.data.userType == 9? (
          <div className="conteidox2 box-specification"
              style={{display: typeGondole != 'default' || loadingCategories?'block':'none'}} >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right box-icon-admin">
              <BsPersonCheckFill title="Admin" size={25} /> <small>Admin</small>
            </div>
            <div className="row mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container  mt-4 margin-0 hide">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 jc-flexend display-none margin-32">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    Target
                  </label>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 display-none">
                <div className="shelves__endpoint-selector-container">
                  <Select
                    key={'slttargets123'}
                    id="slttargets"
                    title=""
                    value={targetsType}
                    handlerChange={handleChangeTarget}
                    itemSelected={'plp'}
                    disabled={typeGondole === 'default'}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 jc-flexend margin-32">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    Verificación Producto
                    <TooltipPanel                     
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.carrousel.edit.Form.urlObject.tooltip')
                    )[0]}
                  />  
                  </label>
                </div>
              </div>
              { /* } 
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 ">
                <div className="shelves__endpoint-selector-container">
                  <textarea
                    key={'i563'}
                    className="input shelves__input-w100"
                    disabled
                    rows={5}
                    value={templateurlObjectResult}
                  ></textarea>
                </div>
              </div>
              { */ }
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <div className="shelves__endpoint-selector-container">
                  <div className="shelves__preview-button">
                    <button
                      className="ppal_button btn btn-primary disp-none"
                      onClick={() => {
                        navigator.clipboard.writeText(templateurlObjectResult.replace('<%CLIENT.BRANCHOFFICE%>','1').replace('<%CLIENT.WHITELABEL%>',''))
                        setCopyUrl(true)
                      }}
                    >  { /* <BsFiles size="25" /> 
                      <FormattedMessage
                          id="app.vitrinas-editar.copiar-url"
                          defaultMessage="Copiar URL"
                        />          */ }
                        Copiar
                    </button>
                        <br/>
                    <button
                      className="btn btn-info"
                      onClick={VerifyResultsRule}
                    >                     
                      validar resultados
                    </button>

                    { /* 
                    <FormattedMessage
                        id="app.vitrinas-editar.copiar-url"
                        defaultMessage="Copiar URL"
                      />
                      */ }
                    {copyUrl ? (
                      <label
                        className="text-success"
                        style={{
                          left: '12%',
                          margin: 'auto',
                          position: 'relative'
                        }}
                      >
                        { /* <BsCheck2All title="Copiado" size={25} /> */ }                        
                        <FormattedMessage
                          id="app.vitrinas-editar.copiar-url-copy"
                          defaultMessage="Copiado!"
                        />
                      </label>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

      {showVerify
        ? (
        <div className="shelves button mt-4 box-specification">
          <div className="row mb-4 shelves__endpoint-selector-container padding-left-70">
            <p>Se encontraron <strong>{arrayVerify.length>0 ? arrayVerify.length : '0'}</strong> resultados. 
              <a href="#" onClick={handleShowBlockVerify}>
                &nbsp; <span className='badge badge-dark'>Cerrar</span>
              </a>
            </p>             
            <div className="row">            
            {arrayVerify.length > 0
              ? arrayVerify.map((item: any) => {
                return(
                <>
                <Card style={{ width: '12rem', margin: '3px' }}>
                  <Card.Img variant="top" style={{ height: '100px' }} src={item.UrlImagen} />
                  <Card.Body>
                    <Card.Title className='h6 lnk-tit'><a href={item.Link} rel="noreferrer" target='_blank'>{item.Descripcion}</a></Card.Title>
                    <Card.Text>
                      Sku: {item.Sku} IdProducto: {item.IdArticulo} IdCategoria:{item.IdCategoria} 
                      <br/><strong> $ {item.PrecioInternet} </strong> <br/>
                    </Card.Text>              
                  </Card.Body>
                </Card>
                </>
                )
              }) 
              : null
            }
            </div>            
          </div>          
        </div>
      ) : null
    }

        {additionalfields.length > 0 && (user.data.userType === 1 && user.data.userType === 9)? (
          <div className="shelves button mt-4 box-specification"
               style={{display: typeGondole != 'default' || loadingCategories?'flex':'none'}} > { /* panel-addtional-fields */}
            <div className="row mb-4 shelves__endpoint-selector-container padding-left-70">
              <div className="row">
                {additionalfields.length > 0
                  ? additionalfields.map((opt: any) => {
                      let id = 0
                      switch (opt.type) {
                        case 'input':
                          return (
                            <div
                              key={id++}
                              className="shelves__endpoint-selector-container row"
                            >
                              <InputText
                                title={opt.label}
                                id={opt.labelId}
                                value={opt.value}
                                readOnly={false}
                                handler={inputOnChange}
                              />
                            </div>
                          )
                        case 'select':
                          return (
                            <div
                              key={id++}
                              className="shelves__endpoint-selector-container row"
                            >
                              <Select
                                id={opt.labelId}
                                title={opt.label}
                                value={opt.value}
                                handlerChange={handleChangeCategory}
                                itemSelected={categorySelected}
                                disabled={false}
                              />
                            </div>
                          )
                        case 'boolean':
                          return (
                            <div
                              key={id++}
                              className="shelves__endpoint-selector-container row"
                            >
                              <Check
                                id={opt.labelId}
                                title={opt.label}
                                value={opt.value}
                              />
                            </div>
                          )

                        case 'numeric':
                          return opt.labelId !== 'IdCategorySelect' ? (
                            <div
                              key={id++}
                              className="shelves__endpoint-selector-container row"
                            >
                              <Numeric
                                id={opt.labelId}
                                title={opt.label}
                                value={opt.value}
                              />
                            </div>
                          ) : null

                        case 'service':
                          return (
                            <div
                              key={id++}
                              className="shelves__endpoint-selector-container row"
                            >
                              <InputText
                                title={opt.label}
                                id={opt.labelId}
                                value={opt.value}
                                readOnly={true}
                                handler={inputOnChange}
                              />
                            </div>
                          )

                        default:
                          return ''
                      }
                    })
                  : ''}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="row shelves button">
        {loading ? (
          <div style={{ margin: 'inherit', textAlign: 'center' }}>
            <Loading
              textLoading={intl.formatMessage(
                { id: 'app.vitrinas-spinner.espere' },
                { defaultMessage: 'Espere...' }
              )}
            />
          </div>
        ) : (
          ''
        )}
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
          <div className="shelves__preview-button">
            <div className="shelves__preview-button">
              <button
                className="ppal_button btn btn-primary"
                id="saveRule"
                onClick={() => {
                  canIsaveAction()
                }}
              >
                <FormattedMessage
                  id="app.vitrinas-editar.guardar"
                  defaultMessage="GUARDAR"
                />
              </button>
            </div>
            <div className="shelves__preview-button">
              <button
                id={'btn-regresar'}
                className="manage-grid-button shelves__title-icon"
                onClick={() => {
                  cleanRegisterAction() // borra registro de uso de vitrina, para que otro pueda editarla
                  editar()
                }}
              >
                <FormattedMessage
                  id="app.vitrinas-editar.volver"
                  defaultMessage="Volver"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormCarrusel
