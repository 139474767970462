import React from 'react'
import {  useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import { TooltipPanel } from '../../../Globals/TooltipPanel'

const DecimalsDiscount: React.FC<{ title: string }> = ({
  title
}) => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  return (
    <div className="rule-config-subComponents__container">
      <div className="row a-center">
        <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
          {title}
          <TooltipPanel 
            ObjectTooltip={user.data.tooltips.filter((
              (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.DecimalsDiscount.tooltip')
            )[0]}
          />
        </h6>
        <select
          className="col-lg-8 col-md-8 col-sm-8 col-xs-8  ancho-alto-select"
          id="decimalpercent"
          style={{ width: '64%' }}
        >
          <option value="false" selected>
            No
          </option>
          <option value="true">Yes</option>
        </select>        
      </div>
    </div>
  )
}

export default DecimalsDiscount
