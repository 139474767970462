import React, { useState } from 'react'
import { BsBoxArrowDown, BsBoxArrowInLeft, BsBoxArrowRight } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import { ProgressBar } from '../../Globals/ProgressBar'
import './subscription.css'
import Papa from 'papaparse'
import { config } from '../../../config'
import { FormattedMessage, useIntl } from 'react-intl'
import { ShowAlert } from '../../Globals'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const Unsuscribe = ({ shelves }: any) => {
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const [progressBar, setProgresBar] = useState('' as any)  
  const [jsonFileInput, setJsonFileInput] = useState('')
  const [jsonFile, setJsonFile] = useState<any>([])
  const [total, setTotal] = useState(0 as number)
  const [messageResultApi, setMessageResultApi] = useState<string>('')
  const [bdAll, setBdAll] = useState(false)
  const [dateFrom, setDateFrom] = useState('')
  const [dateUntil, setDateUntil] = useState('')  
  const [tokenValue, setTokenValue] = useState<any>('')
  const intl = useIntl()

  const AlertToken = () => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setTokenValue(result.value)
        importarEmail(result.value)
      }
    })
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.audiencias' },
        { defaultMessage: 'Audiencias' }
      ),
      text,
      icon:
        iconStr === 1
          ? 'success'
          : iconStr === 2
          ? 'error'
          : iconStr === 4
          ? 'info'
          : 'warning',
      showConfirmButton: true,      
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const postSuscribe = async (d: any, tokenGoogle: string) => {
    const unSuscribe = {
      Emails: d // d.map((a: any) => a.email)
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        tokenGoogle,
        username: user.data.username,
        'Content-Type': 'application/json',
        Accept: '* / *',
        credentials: 'include'
      },
      body: JSON.stringify(unSuscribe)
    }
    const res = await fetch(
      `${config.base_server_path}/Automation/ImportUnSubscribe?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
    const data = await res.json()

    if (data.StatusCode === 0) {
      setMessageResultApi('ok api')
    } else if (data.StatusCode === -1) {
      MyAlert(data.Message, 3)      
      setMessageResultApi('error api')
    } else {
      console.log('..')
    }
  }

  const handleBdAll = () => {
    setBdAll(!bdAll)
  }

  const handleFile = (e: any) => {
    if (e.target.files[0].size > 20000000) {
      // 20MB
      MyAlert('El archivo que intenta subir supera el máximo permitido de 20MB.', 3)     
    } else {
      if (
        e.target.files[0].type === 'text/csv' ||
        e.target.files[0].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const pepa = true
        Papa.parse(e.target.files[0], {
          header: false,
          delimiter: ';',
          skipEmptyLines: true,          
          complete: function (results) {
            console.log('results: ', results.data)            
            if (pepa) {
              const arrayEmails = results.data.map(function (es: any) {
                return es[0] // el papa.parse, retorna un array dentro de otro array
              })
              setJsonFile(arrayEmails)
            } else {
              MyAlert(intl.formatMessage(
                { id: 'app.modal.upload-csv.error-headers' },
                { defaultMessage: 'Los titulos de cabezera deben ser iguales a los que se muestran en el ejemplo' }
              ), 3)
            }
          }
        })
      } else {
        MyAlert('Las extensiones permitidas son *.csv o *.xls/xlsx', 3)
      }
    }
  }

  const getTotal = async () => {
    MyAlert('Calculando...', 4)

    const datos = {
      DateFrom: dateFrom,
      DateTo: dateUntil
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(datos)
    }
    const res = await fetch(
      `${config.base_server_path}/Automation/GetTotalUnSubscribe?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
    const data = await res.json()
    MyAlert(data.Message, 4)
    if (data.StatusCode === 0) {
      setTotal(data.Entity.Total)      
    }
  }

  const getExportarAll = async () => {
    MyAlert('Calculando...', 3)

    const datos = {
      DateFrom: dateFrom,
      DateTo: dateUntil,
      Total: 1
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        'Content-Type': 'application/json',
        responseType: 'blob',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(datos)
    }
    const res = await fetch(
      `${config.base_server_path}/Automation/ExportUnsubscribe?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
    const data = await res.blob()

    console.log('OK!')
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Unsucripcion.csv.zip')
    document.body.appendChild(link)
    link.click()

  }

  const getExportarDate = async () => {
    MyAlert('Calculando...', 4)

    const datos = {
      DateFrom: dateFrom,
      DateTo: dateUntil,
      Total: 0
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        'Content-Type': 'application/json',
        responseType: 'blob',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(datos)
    }
    const res = await fetch(
      `${config.base_server_path}/Automation/ExportUnsubscribe?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
    const data = await res.blob()

    console.log('OK!')
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Unsuscripcion.csv.zip')
    document.body.appendChild(link)
    link.click()
    
  }

  const handleExportar = () => {
    if (bdAll) {
      getExportarAll()
    } else {
      if (dateFrom === '' || dateUntil === '') {
        MyAlert('Se debe seleccionar la Fecha Desde y Fecha Hasta', 3)        
      } else {
        getExportarDate()
      }
    }
  }

  const importarEmail = async (tokenGoogle: string) => {
    if (jsonFile.length > 0) {
      MyAlert('Procesando. Espere por favor...', 4)

      let dataCopy = structuredClone(jsonFile)
      const limitSend = 2000
      for (let i = 0; i <= jsonFile.length; i += limitSend) {
        dataCopy = jsonFile.slice(i, i + limitSend)

        await postSuscribe(dataCopy, tokenGoogle)

        let porc = i + limitSend
        if (i + limitSend > jsonFile.length) porc = jsonFile.length

        let prog1 = Math.round((100 * i + limitSend) / jsonFile.length)
        if (prog1 > 100) prog1 = 100

        if (prog1 <= 100) {
          if (messageResultApi !== 'error api') {
            setProgresBar(prog1)
            MyAlert(`${prog1}% | Procesando ${porc} correos...`,4,10000)
          }
        }
      }

      setTimeout(() => {
        setProgresBar('0')
        setJsonFileInput('')        
      }, 3000)
    } else {
      MyAlert('El archivo csv no tiene emails o está vacio.', 3)      
    }
  }

  const handleTotal = () => {
    if (dateFrom === '' || dateUntil === '') {
      MyAlert('Se debe seleccionar la Fecha Desde y Fecha Hasta', 3)      
    } else {
      getTotal()
    }
  }

  const handleDateFrom = (e: any) => {
    const fechaDesde = e.target.value
    setDateFrom(fechaDesde)
  }
  const handleDateUntil = (e: any) => {
    const fechaHasta = e.target.value
    setDateUntil(fechaHasta)
  }

  return (
    <div className="automation__wrapper box_sha">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 automation__endpoint-selector-container">
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <div className="automation__endpoint-selector-container">
              <BsBoxArrowInLeft className="manage-grid-button subscription__title-icon" />
              <h1 className="subscription__title-text">
                <FormattedMessage
                  id="app.suscribir.importar-email"
                  defaultMessage="IMPORTAR EMAIL"
                />
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="automation__endpoint-selector-container">
            <label className="automation__endpoint-selector-text">
              <FormattedMessage
                id="app.suscribir.archivo"
                defaultMessage="ARCHIVO"
              />
            </label>
            <input
              className="input"
              type={'file'}
              key={jsonFileInput}
              onChange={handleFile}
            />
          </div>
          <p className="automation__p">
            <p className="automation__p">
              <FormattedMessage
                id="app.suscribir.extencion"
                defaultMessage="La extensión se sugiere que sea *.csv/*.xls - Peso máximo 20Mb. Ejemplo de archivo:"
              />
              <TooltipPanel 
                ObjectTooltip={user.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.carrousel.cdp.desuscribe.Form.import.tooltip')
                )[0]}
              />
            </p>
            <p className="automation__p text_example_csv_1">
              usuario0@correo.com;
              <br />
              usuario1@correo.com;
              <br />
              usuario2@correo.com;
              <br />
              usuario3@correo.com;
              <br /> ...{' '}
            </p>
          </p>
        </div>
      </div>

      <div className="row automation button">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 automation__buttons-container">
          <div className="automation__preview-button">
            <button
              className="ppal_button btn btn-primary"
              // eslint-disable-next-line no-unneeded-ternary
              disabled={jsonFile.length <= 0 ? true : false}
              onClick={AlertToken}
            >
              <FormattedMessage
                id="app.suscribir.importar"
                defaultMessage="IMPORTAR"
              />
            </button>
          </div>
          <div className="automation__preview-button" >
            <button className="manage-grid-button automation__title-icon" style={{width: '220px'}}>
              <BsBoxArrowDown size="25" />
              <a
                style={{ color: '#fff!important' }}
                href="https://storage.cdn.braindw.com/ndwVtex_produccion/script/files/2019/11/25/Ejemplo_suscriptos_emails.csv"
              >
                <FormattedMessage
                  id="app.suscribir.descargar-ejemplo"
                  defaultMessage="Descargar ejemplo"
                />
              </a>
            </button>
          </div>
        </div>
        {Boolean(progressBar) && (
          <ProgressBar bgcolor={'#2FAD7E'} completed={progressBar} />
        )}        
      </div>

      <div className="row mt-4">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 automation__endpoint-selector-container mt-4">
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <div className="automation__endpoint-selector-container">
              <BsBoxArrowRight className="manage-grid-button subscription__title-icon" />
              <h1 className="subscription__title-text">
                <FormattedMessage
                  id="app.suscribir.exportar-email"
                  defaultMessage="EXPORTAR EMAIL"
                />
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="automation__endpoint-selector-container  mt-2">
            <label className="automation__endpoint-selector-text">
              <FormattedMessage
                id="app.suscribir.seleccionar-dias"
                defaultMessage="SELECCIONAR DÍAS"
              />
              <TooltipPanel 
                ObjectTooltip={user.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.carrousel.cdp.desuscribe.Form.seldias.tooltip')
                )[0]}
              />
            </label>
            <input
              className="dateInput mt-2 vtexskuconsult__input"
              type={'date'}
              placeholder="Desde : "
              onChange={handleDateFrom}
            />
            <input
              className="input mt-2 vtexskuconsult__input"
              type={'date'}
              placeholder="Hasta : "
              onChange={handleDateUntil}
            />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 automation__endpoint-selector-container mt-4">
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <div className="automation__endpoint-selector-container">
              <label className="automation__endpoint-selector-text mt-0">
                <FormattedMessage
                  id="app.suscribir.totalizador"
                  defaultMessage="TOTALIZADOR"
                />
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.cdp.desuscribe.Form.total.tooltip')
                  )[0]}
                />
              </label>
              <input
                className="inputTotal mt-0"
                type={'text'}
                disabled
                value={total}
              />
            </div>
          </div>

          <div className="row subscription buttonImp col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <div className="">
              <div className="subscription__total-button">
                <button className="ppal_button" onClick={handleTotal}>
                  <FormattedMessage
                    id="app.suscribir.contabilizar"
                    defaultMessage="CONTABILIZAR"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="subscription col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <div className="automation__endpoint-selector-container">
              <label className="text_label_bd mt-0">
                <FormattedMessage
                  id="app.suscribir.descargar-base-completa"
                  defaultMessage="Descargar la base completa"
                />
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.cdp.desuscribe.Form.downloadb.tooltip')
                  )[0]}
                />
              </label>
              <input
                type={'checkbox'}
                className="inputCheckBox mt-0"
                onChange={handleBdAll}
              />
              <div className="automation__preview-button">
                <button className="ppal_button" onClick={handleExportar}>
                  <FormattedMessage
                    id="app.suscribir.exportar"
                    defaultMessage="EXPORTAR"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unsuscribe
