import React from 'react'
import MenuContainer from '../../Menu/MenuContainer'
import MenuOptions from '../../Menu/MenuOptions'
import { subAutomationOptions } from '../../../utils/secondMenuOptions'

const HomeMenu = () => {
  const handleAction = () => {
    console.log()
  }

  return (
    <>
      <MenuContainer>
        <MenuOptions
          tag="BrainDW | Dashboard - Home"
          options={subAutomationOptions}
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default HomeMenu
