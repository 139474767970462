import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom'

const CustomLink: React.FC<LinkProps> = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })
  const mediaMatch = window.matchMedia('(min-width: 929px)')

  const desktopStyle = {
    // textDecoration: match != null ? 'underline' : 'none',
    // background: (match != null) ? '#fff' : 'none',
    color: match != null ? '#C1A9D9' : 'white'
  }

  const mobileStyle = {
    TextDecoration: 'none',
    background: match != null ? '#caf7e3' : 'none'
  }

  const generalStyle = !mediaMatch.matches ? mobileStyle : desktopStyle

  return (
    <>
      <Link style={generalStyle} to={to} {...props}>
        {children}
      </Link>
    </>
  )
}

export default CustomLink
