/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
import React, { useCallback, useEffect, useState } from 'react'
import DataTable, { Alignment, TableColumn } from 'react-data-table-component'
import { FormattedMessage, useIntl } from 'react-intl'
import { IoCloudDownloadOutline } from 'react-icons/io5'
import Select from 'react-select'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { FiPlus } from 'react-icons/fi'
import { BsFiletypePdf } from 'react-icons/bs'

import { Loading } from '../../Globals/Loading'
import Service from '../../../services'
import { useClientContext } from '../../../contexts/ClientContext'
import { HeaderTitle, RowWrapper } from './Plans'
import {
  FormField,
  FormGroup,
  FormLabel
} from '../../Home/System/ProfileMshopContainer'

interface GetColorProps {
  isDragAccept: boolean
  isDragReject: boolean
  isFocused: boolean
  isRequired: boolean
}

const getColor = (props: GetColorProps) => {
  if (props.isDragAccept) {
    return '#FFB245'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#F29718'
  }
  if (props.isRequired) {
    return '#FFD293'
  }
  return '#eeeeee'
}

const FileContainer = styled.div<GetColorProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${({ isDragAccept, isDragReject, isFocused, isRequired }) =>
    getColor({ isDragAccept, isDragReject, isFocused, isRequired })};
  border-style: solid;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:hover {
    border-color: #ffd293;
  }
  height: 50px;
  justify-content: center;
`

const RequiredMessage = styled.p`
  color: #ffb245;
  margin: 0;
  margin-top: 12px;
`
const Button = styled.button`
  background-color: #00868b !important;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin: 10px 8px;
  width: 175px;
  min-height: 40px;
  color: #fff !important;
  &:hover {
    color: white !important;
  }
`

export const ContainerIcon = styled.div`
  background-color: #6529a1;
  padding: 0.54rem;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
`
interface BocksI {
  CreateDate: string
  PlanId: string
  // Description: string
  Amount: string
  Status: string
}

const blocksMock: BocksI[] = [
  {
    CreateDate: '01/01/2024',
    PlanId: 'Trial',
    // Description: 'FREE - 30 Days Trial',
    Amount: 'U$S 0.00',
    Status: 'Pagado'
  }
]

const FacturacionTable = ({ user }: any) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false)
  const [file, setFile] = useState<any>()
  const [invoice, setInvoice] = useState([])
  const [uploadForm, setUploadForm] = useState<boolean>(false)
  const [plans, setPlans] = useState([])
  const methods = useForm()
  const intl = useIntl()
  const { selectedClient } = useClientContext()

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf']
    },
    // maxSize: MAX_SIZE,
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0])
      methods.setValue('file', acceptedFiles.length ? acceptedFiles[0] : null)
    }
  })

  const getPlansInfo = useCallback(async () => {
    try {
      const { data } = await Service.get<any>(`/Mshop/planList`)
      if (data.length) {
        setPlans(data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getInvoice = useCallback(async () => {
    try {
      const { data } = await Service.get<any>(
        `/Mshop/invoicesList?idClient=${selectedClient?.idClient}&page=1&per_page=10`
      )
      if (data.Entities.length) {
        setInvoice(data.Entities)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const DownloadInvoice = async (invoiceId: any) => {
    try {
      const response = await Service.get<any>(
        `/Mshop/invoiceDetail?idClient=${selectedClient?.idClient}&invoiceId=${invoiceId}`,
        {
          responseType: 'arraybuffer'
        }
      )

      if (response.data !== null) {
        console.log('ResponseList', response.data)

        const blob = new Blob([response.data], { type: 'application/pdf' })

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Factura_Mshop_BDW.pdf')
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const removeFile = useCallback(() => {
    setFile(null)
    methods.setValue('file', null)
  }, [file, methods])

  const submitForm = useCallback(
    async (data) => {
      try {
        if (!selectedClient) return
        if (!data.date) {
          methods.setError('date', {
            type: 'required',
            message: 'Fecha requerida'
          })
          methods.setFocus('date')
          return
        }
        if (!data.plan) {
          methods.setError('plan', {
            type: 'required',
            message: 'Monto es requerido'
          })
          methods.setFocus('plan')
          return
        }
        if (!data.amount) {
          methods.setError('amount', {
            type: 'required',
            message: 'Monto es requerido'
          })
          methods.setFocus('amount')
          return
        }
        if (!data.status) {
          methods.setError('status', {
            type: 'required',
            message: 'Monto es requerido'
          })
          methods.setFocus('state')
          return
        }
        if (!data.file) {
          methods.setError('file', {
            type: 'custom',
            message: 'Archivo es requerido'
          })
          return
        }
        // eslint-disable-next-line prefer-const
        let formData = new FormData()
        formData.append('invoice', file)
        setLoadingInvoice(true)
        const { data: dataResponse, status } = await Service.post<any, any>(
          `/Mshop/UploadInvoice?idClient=${selectedClient.idClient}&planId=${data.plan.value}&amount=${data.amount}&status=${data.status.value}&date=${data.date}`,
          formData,
          {
            method: 'POST',
            headers: {
              token: user.token,
              userName: user.username,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        if (status === 200 && dataResponse.Status === 'Ok') {
          setUploadForm(false)
          getInvoice()
        }
        methods.reset()
        setLoadingInvoice(false)
      } catch (error) {
        console.log(error)
        setLoadingInvoice(false)
      }
    },
    [user, file]
  )
  useEffect(() => {
    getInvoice()
    getPlansInfo()
  }, [])
  const columns: Array<TableColumn<any>> = [
    {
      id: 'fecha',
      name: 'FECHA',
      selector: (row: any) => row.CreateDate.split('T')[0],
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'id',
      minWidth: '80px'
    },
    {
      id: 'plan',
      name: 'PLAN',
      selector: (row: any) => row.Description,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'title',
      minWidth: '80px'
    },
    {
      id: 'monto',
      name: 'MONTO',
      selector: (row: any) => row.Amount,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'title',
      minWidth: '80px'
    },
    {
      id: 'estado',
      name: 'ESTADO',
      selector: (row: any) => row.Status,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'title',
      minWidth: '80px'
    },
    {
      id: 'descarga',
      name: 'DESCARGAR',
      cell: (row) => {
        return (
          <>
            <IoCloudDownloadOutline
              className="smart-icons"
              size={20}
              cursor={'pointer'}
              data-tip={'Update'}
              style={{ marginRight: 8, strokeWidth: 1 }}
              color="#6529A1"
              onClick={() => DownloadInvoice(row.InvoiceId)}
            />
          </>
        )
      },
      reorder: true,
      center: true,
      sortField: 'mobile-thumb',
      minWidth: '80px'
    }
  ]
  const isFileTooLarge =
    fileRejections.length > 0 &&
    fileRejections[0].errors[0].code === 'file-too-large'
  return (
    <RowWrapper
      className="row mt-4"
      style={{ position: 'relative', marginBlock: '25px' }}
    >
      <div className="col-sm-3">
        <HeaderTitle style={{ display: 'inline-flex', marginBottom: 32 }}>
          <FormattedMessage
            id="app.facturacion.title"
            defaultMessage="Facturas"
          />
        </HeaderTitle>
      </div>
      {user.userType === 1 && (
        <>
          <ContainerIcon onClick={() => setUploadForm((prev) => !prev)}>
            <FiPlus
              size={'30px'}
              color="white"
              style={{
                transform: uploadForm ? 'rotate(45deg)' : 'rotate(0deg)',
                transition: 'all 0.5s ease'
              }}
            />
          </ContainerIcon>
          <div className="col-sm-12">
            {uploadForm && (
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(submitForm)}>
                  <div
                    style={{
                      display: 'grid',
                      marginBottom: 40,
                      gap: '8px',
                      gridTemplateColumns: 'repeat(5, 1fr)'
                    }}
                  >
                    <div className="">
                      <FormGroup>
                        <FormField
                          type="date"
                          id="date"
                          // placeholder="Razón social"
                          {...methods.register('date')}
                          style={{
                            border: 'none',
                            borderBottom:
                              methods.formState.errors?.date &&
                              '1px solid #d32f2f'
                          }}
                        />
                        <FormLabel
                          style={{
                            color:
                              methods.formState.errors?.date &&
                              '#d32f2f !important'
                          }}
                        >
                          <FormattedMessage
                            id="app.system.billing-date"
                            defaultMessage="Fecha"
                          />
                          <span style={{ color: 'red' }}> *</span>
                        </FormLabel>
                      </FormGroup>
                    </div>
                    <div className="">
                      <Controller
                        name="plan"
                        control={methods.control}
                        render={({
                          field: { onChange, name, value },
                          formState: { errors }
                        }) => (
                          <FormGroup>
                            <Select
                              name={name}
                              styles={{
                                singleValue: (prev) => ({
                                  ...prev,
                                  cursor: 'pointer',
                                  fontSize: '13px'
                                }),
                                option: (prev) => ({
                                  ...prev,
                                  cursor: 'pointer',
                                  fontSize: '13px'
                                }),
                                placeholder: (prev) => ({
                                  ...prev,
                                  fontSize: 13
                                })
                              }}
                              defaultValue={value}
                              placeholder="Seleccionar"
                              options={
                                // [
                                // 'Avanzado-Mensual',
                                // 'Avanzado-Semestral'
                                // ].
                                plans
                                  .filter((d: any) =>
                                    d.Name.toLowerCase().includes('avanzado')
                                  )
                                  .map((plan: any) => ({
                                    label: plan.Name,
                                    value: plan.Id
                                  }))
                              }
                              onChange={onChange}
                            />
                            <FormLabel
                              style={{
                                color: errors?.plan && '#d32f2f'
                              }}
                            >
                              <FormattedMessage
                                id="app.system.billing-plan"
                                defaultMessage="Plan"
                              />
                              <span style={{ color: 'red' }}> *</span>
                            </FormLabel>
                          </FormGroup>
                        )}
                      />
                    </div>
                    <div className="">
                      <FormGroup>
                        <FormField
                          type="number"
                          id="amount"
                          placeholder="Monto"
                          {...methods.register('amount')}
                          style={{
                            borderBottom:
                              methods.formState.errors?.amount &&
                              '1px solid #d32f2f'
                          }}
                        />
                        <FormLabel
                        // style={{
                        //   color: formState.errors?.companyName && '#d32f2f'
                        // }}
                        >
                          <FormattedMessage
                            id="app.system.billing-amount"
                            defaultMessage="Monto"
                          />
                          <span style={{ color: 'red' }}> *</span>
                        </FormLabel>
                      </FormGroup>
                    </div>
                    <div className="">
                      <Controller
                        name="status"
                        control={methods.control}
                        render={({
                          field: { onChange, name, value },
                          formState: { errors }
                        }) => (
                          <FormGroup>
                            <Select
                              name={name}
                              styles={{
                                singleValue: (prev) => ({
                                  ...prev,
                                  cursor: 'pointer',
                                  fontSize: '13px'
                                }),
                                option: (prev) => ({
                                  ...prev,
                                  cursor: 'pointer',
                                  fontSize: '13px'
                                }),
                                placeholder: (prev) => ({
                                  ...prev,
                                  fontSize: 13
                                })
                              }}
                              defaultValue={value}
                              placeholder="Seleccionar"
                              options={['Pendiente', 'Pagado'].map((op) => ({
                                label: op,
                                value: op
                              }))}
                              value={value}
                              onChange={onChange}
                            />
                            <FormLabel
                              style={{
                                color: errors?.state && '#d32f2f'
                              }}
                            >
                              <FormattedMessage
                                id="app.system.billing-status"
                                defaultMessage="Estado"
                              />
                              <span style={{ color: 'red' }}> *</span>
                            </FormLabel>
                          </FormGroup>
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end'
                      }}
                    >
                      <Controller
                        name="file"
                        control={methods.control}
                        render={({
                          field: { name, ref, value },
                          formState: { errors }
                        }) => {
                          if (value && file) {
                            return (
                              <div
                                style={{
                                  marginBottom: 5,
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  position: 'relative'
                                }}
                              >
                                <BsFiletypePdf size={35} color="#6529a1" />
                                <p
                                  style={{
                                    fontSize: 13,
                                    fontStyle: 'italic',
                                    margin: 0
                                  }}
                                >
                                  {file.name.length > 20
                                    ? `${file.name.slice(0, 20)}...`
                                    : file.name}
                                </p>
                                <FiPlus
                                  size={15}
                                  color="red"
                                  style={{
                                    transform: 'rotate(45deg)',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    cursor: 'pointer'
                                  }}
                                  onClick={removeFile}
                                />
                              </div>
                            )
                          }
                          return (
                            <>
                              {errors?.[name] != null && (
                                <RequiredMessage>
                                  {errors[name]?.message}
                                </RequiredMessage>
                              )}
                              {isFileTooLarge && (
                                <RequiredMessage>
                                  El archivo sobrepasa el limite
                                </RequiredMessage>
                              )}
                              <FileContainer
                                {...getRootProps({
                                  className: 'dropzone',
                                  isDragActive,
                                  isFocused,
                                  isDragAccept,
                                  isDragReject
                                })}
                                isRequired={errors?.[name] != null}
                              >
                                <input
                                  {...getInputProps()}
                                  name={name}
                                  ref={ref}
                                />
                                <p
                                  style={{
                                    fontSize: 10,
                                    fontStyle: 'italic',
                                    margin: 0,
                                    fontWeight: 'bold'
                                  }}
                                >
                                  Click para Subir Factura
                                </p>
                                <p
                                  style={{
                                    fontSize: 10,
                                    fontStyle: 'italic',
                                    margin: 0
                                  }}
                                >
                                  El formato debe ser PDF
                                </p>
                              </FileContainer>
                            </>
                          )
                        }}
                      />
                    </div>
                    <Button
                      className="btn"
                      id="saveBrandPriority"
                      style={{ textTransform: 'uppercase' }}
                      disabled={loadingInvoice}
                    >
                      Guardar
                    </Button>
                  </div>
                </form>
              </FormProvider>
            )}
          </div>
        </>
      )}
      <DataTable
        subHeaderAlign={Alignment.RIGHT}
        columns={columns}
        data={invoice}
        progressPending={loading}
        progressComponent={
          <Loading
            textLoading={intl.formatMessage({
              id: 'app.vitrinas-spinner.espere',
              defaultMessage: 'Espere...'
            })}
          />
        }
        // subHeader
        customStyles={{
          subHeader: {
            style: {
              justifyContent: 'space-between',
              marginBottom: '1rem',
              padding: '0 1rem !important'
            }
          },
          rows: {
            style: {
              minHeight: 80
            }
          }
        }}
        noDataComponent={intl.formatMessage({
          id: 'app.vitrinas.nodata',
          defaultMessage: 'Sin Resultados.'
        })}
        responsive
      />
    </RowWrapper>
  )
}

export default FacturacionTable
