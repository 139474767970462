import { useEffect, useState } from 'react'
import {
  BsFillPlusCircleFill,
  BsFillTrashFill,
  BsCheck2Circle,
  BsCheckCircleFill,
  BsExclamationTriangleFill,
  BsAsterisk,
  BsInfoCircle,
BsYoutube
} from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import Alert from 'react-bootstrap/Alert'

import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import { useClientContext } from '../../../contexts/ClientContext'

const EditSpecificationsOrigin = ({
  categories,
  categoriesArray,
  loadingCategories,
  setCategoryNotExist,
  id,
  oneRelatedCategorie,
  specificationByCategory,
  setCategoryOriginsValues,
  getSpecificationsByCategory,
  handleAlert
}: any) => {
  const objSpec = oneRelatedCategorie.OriginSpecification?.map(
    (i: any, index: any) => ({
      specification: [],
      value: [{ v: i.v, vId: i.vId }],
      specs: { k: i.k, kId: i.kId, v: i.v, vId: i.vId },
      id: 'origin_SpecDeleteBtn' + `${index}`,
      oneRelated: true
    })
  )
  const [specificationOrigin, setSpecificationsOrigin] = useState<any>(
    oneRelatedCategorie.OriginSpecification?.length > 0 ? objSpec : []
  )
  const [specValue, setSpecValue] = useState<any>('')
  const [selectCategorie, setSelectCategorie] = useState<any>(
    oneRelatedCategorie.IdCategoryOrigin == null
      ? '0'
      : oneRelatedCategorie.IdCategoryOrigin
  )
  const [idCategoryOrigin, setIdCategoryOrigin] = useState<any>(
    oneRelatedCategorie.IdCategoryOrigin
  )
  const [originSpec, setOriginSpec] = useState(1)
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const intl = useIntl()
  const dispatch = useDispatch()
  const [show, setShow] = useState(true)

  const YTVideo = () => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.tooltip.YT.title' },
        { defaultMessage: 'Especificaciones' }
      ),
      html: `<div className="container mg-t-30 video-responsive video-home">
              <iframe
                className="iframe-video"
                width="690"
                height="388"
                src="https://www.youtube.com/embed/3B5tSNKXr6c"
                allowFullScreen
              ></iframe>
            </div>`,
      color: '#1c684c',
      showCancelButton: true,
      showConfirmButton: false,      
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 900
    })
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time: number = 3000,
    isToast: boolean = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-categories.cross-selling' },
        { defaultMessage: 'CRUCE DE CATEGORÍAS' }
      ),
      text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const addSpecificationOrigin = () => {
    const rows = [
      ...specificationOrigin,
      {
        specification: specificationByCategory.map((i: any) => {
          return { kId: i.FieldId, k: i.Name }
        }),
        value: [],
        id:
          'origin_SpecDeleteBtn' +
          JSON.stringify(`${specificationOrigin.length}` + `${originSpec}`)
      }
    ]
    setSpecificationsOrigin(rows)
    setOriginSpec(originSpec + 1)
  }

  const deleteSpecificationOrigin = (item: any) => {
    setSpecificationsOrigin(
      specificationOrigin.filter((e: { id: any }) => e.id !== item)
    )
  }

  const getSpecificationsValues = async (fieldId: any, index: any) => {
    const newArr = [...specificationOrigin]
    Service.get<any>(
      `ManageCarrousel/GetSpecificationsValues?idHashScript=${selectedScript?.idHashScript}&fieldId=${fieldId}`,
      { headers: { token: token.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          newArr[index].value = response.data.map(
            (i: any) => [{ v: i.Value, vId: i.FieldValueId }][0]
          )
          setSpecificationsOrigin(newArr)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const AlertToken = (value: any) => {
    ShowAlert({
      title: 'Cambio de categoria',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.select-category.borrar' },
          {
            defaultMessage:
              'Cada vez que cambie de categoría, se eliminaran las especificaciones seleccionadas <br/> Confirma la eliminación de las especificaciones?'
          }
        ) +
        '</span>',
      inputLabel: '',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.continuar' },
        { defaultMessage: 'Continuar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cancelar' },
        { defaultMessage: 'Cancelar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    })
      .then((result) => {
        if (result.isConfirmed) {
          getSpecificationsByCategory(value, 'origin')
          setSelectCategorie(value)
          setIdCategoryOrigin(value)
          setSpecificationsOrigin([])
        }
      })
      .catch((error) => {
        MyAlert('API-ERROR: ' + `${error}`, 3)
      })
  }

  const handleSelectCategorie = (event: any) => {
    if (specificationOrigin.length > 0) {
      // AlertToken(event.target.value)
      AlertToken(event.value)
    } else {
      // setSelectCategorie(event.target.value)
      setSelectCategorie(event.value)
      // setIdCategoryOrigin(event.target.value)
      setIdCategoryOrigin(event.value)
      // getSpecificationsByCategory(event.target.value, 'origin')
      getSpecificationsByCategory(event.value, 'origin')
    }
  }

  const handleIdCategorie = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match('^[0-9]*$') != null) {
      setIdCategoryOrigin(event.target.value)
    }
  }

  const handleValidateCategoryInput = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const filterCategory = categories.filter(
      (e: { id: any }) => e.id == idCategoryOrigin
    )
    if (filterCategory.length > 0) {
      if (specificationOrigin.length > 0) {
        AlertToken(idCategoryOrigin)
      } else {
        setSelectCategorie(
          categories.filter((e: { id: any }) => e.id == idCategoryOrigin)[0].id
        )
        getSpecificationsByCategory(idCategoryOrigin, 'origin')
      }
      setCategoryNotExist(false)
      document.querySelector('.check-origin-success')?.classList.add('success')
      document.querySelector('.check-origin-error')?.classList.remove('success')
    } else {
      setCategoryNotExist(true)
      document
        .querySelector('.check-origin-success')
        ?.classList.remove('success')
      document.querySelector('.check-origin-error')?.classList.add('success')
    }
  }

  const handleSelectSpecification = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newArr = [...specificationOrigin]
    const index = event.target.id
    getSpecificationsValues(event.target.value, event.target.id)
    newArr[parseInt(index)].specs = {
      k: event.target.selectedOptions[0].dataset.k,
      kId:
        `${event.target.selectedOptions[0].dataset.kid}` +
        ':' +
        `${event.target.selectedOptions[0].dataset.k}`,
      v: '',
      vId: ''
    }
  }

  const handleSpecificationValues = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSpecValue(event.target.value)
    const newArr = [...specificationOrigin]
    const index = event.target.id
    newArr[parseInt(index)].specs.v = event.target.value
    newArr[parseInt(index)].specs.vId = event.target.value
  }

  useEffect(() => {
    const newArr = [...specificationOrigin]
    if (newArr.length > 0) {
      specificationOrigin.map(
        (item: any, index: any) =>
          (newArr[index].specification = specificationByCategory.map(
            (i: any) => {
              return { kId: i.FieldId, k: i.Name }
            }
          ))
      )
    }
    setCategoryOriginsValues({
      Id: oneRelatedCategorie.Id == 0 ? null : oneRelatedCategorie.Id,
      IdCategoryOrigin: selectCategorie,
      OriginSpecification:
        specificationOrigin.map((specs: any) => {
          return specs.specs
        })[0] == undefined
          ? null
          : specificationOrigin.map((specs: any) => {
              return specs.specs
            })
    })
  }, [
    specificationOrigin,
    idCategoryOrigin,
    selectCategorie,
    specificationByCategory,
    specValue
  ])

  useEffect(() => {
    const newArr = [...specificationOrigin]
    specificationOrigin.map(async (item: any, index: any) => {
      return await (newArr[index].value = getSpecificationsValues(
        item.specs.kId.replace(/[^0-9]/g, ''),
        index
      ))
    })
  }, [])

  return (
    <>
      <h6 className="titulo">
        <FormattedMessage
          id="app.related-categories.add-cross-selling.category-origin"
          defaultMessage="ORIGEN DE CATEGORIA"
        />
      </h6>
      <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container ai-baseline">
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 jc-flexend">
          <div className="shelves__endpoint-selector-container">
            <label className="shelves__endpoint-selector-text mb-2">
              <FormattedMessage
                id="app.related-categories.add-cross-selling.select-category"
                defaultMessage="Seleccionar Categoria"
              />
              (<BsAsterisk size={8} color={'red'} />)
              <TooltipPanel 
                ObjectTooltip={token.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CatRelacionada.CatOrigen.tooltip')
                )[0]}
              />              
            </label>
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <select
            onChange={handleSelectCategorie}
            value={selectCategorie}
            name="IdCategoryOrigin_Select"
            className="col-lg-8 col-md-8 col-sm-8 col-xs-8 box-select-endpoint select_cross error"
            id="IdCategoryOrigin_Select"
          >
            <option value="0">
              {intl.formatMessage(
                {
                  id: 'app.related-categories.add-cross-selling.select-category'
                },
                { defaultMessage: 'Seleccionar Categoria' }
              )}
            </option>
            {categories.length > 0
              ? categories.map((categorie: any, index: any) => (
                  <>
                    <option value={categorie.id}>
                      {categorie.categoryPathString}
                    </option>
                  </>
                ))
              : ''}
          </select>

          {categoriesArray.length > 0 ? (
            <Select
              className="basic-single box-select-endpoint"
              classNamePrefix="select"
              defaultValue={categoriesArray[parseInt(selectCategorie)]}
              isDisabled={false}
              isLoading={loadingCategories}
              isClearable={false}
              isRtl={false}
              isSearchable={true}
              name="categoriasSlt"
              id="categoriasSlt"
              options={categoriesArray}
              placeholder={intl.formatMessage(
                {
                  id: 'app.related-categories.add-cross-selling.select-category'
                },
                { defaultMessage: 'Seleccionar Categoria' }
              )}
              onChange={handleSelectCategorie}
            />
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        </div>
      </div>
      <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container ai-baseline">
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 jc-flexend">
          <div className="shelves__endpoint-selector-container">
            <label className="shelves__endpoint-selector-text mb-2">
              <FormattedMessage
                id="app.related-categories.add-cross-selling.id-category"
                defaultMessage="Id Categoria"
              />
              <TooltipPanel 
                ObjectTooltip={token.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CatRelacionada.IdCateOrigen.tooltip')
                )[0]}
              />
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <input
            className="input vtexskuconsult__input col-lg-8 col-md-8 col-sm-8 col-xs-8"
            type="text"
            onChange={handleIdCategorie}
            value={idCategoryOrigin}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
          <button
            className="manage-grid-button btn btn-border"
            onClick={handleValidateCategoryInput}
          >
            <BsCheck2Circle size={25} id={'check-id-categoria'} />
            <FormattedMessage
              id="app.related-categories.add-cross-selling.validar"
              defaultMessage="Validar"
            />            
          </button>
          <TooltipPanel 
              ObjectTooltip={token.data.tooltips.filter((
                (t:any) => t.idTooltip === 'app.related.valid.category.origin.title.especification.tooltip')
              )[0]}
            />
        </div>
        <div
          className="col-lg-1 col-md-1 col-sm-1 col-xs-1 check-origin-success"
          style={{ display: 'none' }}
        >
          <div className="manage-grid-button">
            <BsCheckCircleFill
              size={25}
              color={'green'}
              id={'check-id-categoria'}
            />
          </div>
        </div>
        <div
          className="col-lg-3 col-md-3 col-sm-3 col-xs-3 check-origin-error"
          style={{ display: 'none' }}
        >
          <div className="">
            <BsExclamationTriangleFill
              size={25}
              color={'red'}
              id={'check-id-categoria'}
            />
            <FormattedMessage
              id="app.related-categories.add-cross-selling.category.not.exist"
              defaultMessage="No existe la categoría"
            />
          </div>
        </div>
      </div>

      {specificationOrigin.map((item: any, index: any) => {
        return (
          <>
            <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container box-specification">
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    <FormattedMessage
                      id="app.related-categories.add-cross-selling.specification"
                      defaultMessage="Especificacion"
                    />
                    <TooltipPanel 
                          ObjectTooltip={token.data.tooltips.filter((
                            (t:any) => t.idTooltip === 'app.related.category.specification.origin.title.especification.tooltip')
                          )[0]}
                        />
                  </label>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <select
                  onChange={handleSelectSpecification}
                  id={index}
                  className="col-lg-10 col-md-10 col-sm-10 col-xs-10 box-select-endpoint select_cross"
                >
                  <option value="seleccionar">
                    {intl.formatMessage(
                      {
                        id: 'app.related-categories.add-cross-selling.select-specification'
                      },
                      { defaultMessage: 'Seleccionar especificación' }
                    )}
                  </option>
                  {item.specification.map((i: any) => {
                    return (
                      <option
                        key={i.kId}
                        data-k={i.k}
                        data-kId={i.kId}
                        value={i.kId}
                        selected={
                          i.kId == item.specs?.kId?.replace(/[^0-9]/g, '')
                            ? true
                            : false
                        }
                      >
                        {i.k}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    <FormattedMessage
                      id="app.related-categories.add-cross-selling.value"
                      defaultMessage="Valor"
                    />
                    <TooltipPanel 
                          ObjectTooltip={token.data.tooltips.filter((
                            (t:any) => t.idTooltip === 'app.related.category.specification.origin.title.value.tooltip')
                          )[0]}
                        />
                  </label>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <select
                  onChange={handleSpecificationValues}
                  id={index}
                  className="col-lg-10 col-md-10 col-sm-10 col-xs-10 box-select-endpoint select_cross"
                >
                  <option value="">
                    {intl.formatMessage(
                      {
                        id: 'app.related-categories.add-cross-selling.select-specification-value'
                      },
                      { defaultMessage: 'Seleccionar valor' }
                    )}
                  </option>
                  {item.value.length > 0
                    ? item.value.map((i: any) => {
                        return (
                          <option
                            key={i.vId}
                            id={i.vId}
                            selected={i.v == item.specs?.v ? true : false}
                          >
                            {i.v}
                          </option>
                        )
                      })
                    : ''}
                </select>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                <button
                  className="manage-grid-button btn-add-espec"
                  onClick={() => {
                    deleteSpecificationOrigin(item.id)
                  }}
                >
                  <BsFillTrashFill size={25} id={'red'} />
                </button>
              </div>
            </div>
          </>
        )
      })}
      {selectedScript?.clientPlatform != 'Magento' ? (
        <div className="tabs-sel">
          <div className="container-btn-spec">
            <button
              className="manage-grid-button btn btn-border"
              style={{ position: 'relative', left: '16%' }}
              onClick={addSpecificationOrigin}
            >
              <BsFillPlusCircleFill size={25} />
              {intl.formatMessage(
                {
                  id: 'app.related-categories.add-cross-selling.specification'
                },
                { defaultMessage: 'Especificación' }
              )}
            </button>
            <a
              id={'btn-nada'}
              key={'btn-nada'}
              className={'btn btn-default'}
              style={{
                cursor: 'pointer',
                display: 'block',
                left: '16%',
                position: 'relative'
              }}
              onClick={() => YTVideo()}
            >    
              &nbsp;          
              <BsYoutube color={'red'} />
            </a>
          </div>
          {show ? (
            <Alert
              variant="secondary"
              onClose={() => setShow(false)}
              dismissible
              className="especificacion_texto_info"
            >
              <Alert.Heading>
                <BsInfoCircle />
                {intl.formatMessage(
                  {
                    id: 'app.related-categories.add-cross-selling.specification.info_title'
                  },
                  { defaultMessage: 'Especificación' }
                )}
              </Alert.Heading>
              <p>
                {intl.formatMessage(
                  {
                    id: 'app.related-categories.add-cross-selling.specification.info_text'
                  },
                  { defaultMessage: 'Especificación' }
                )}
              </p>
            </Alert>
          ) : null}
        </div>
      ) : (
        <Alert
          variant="secondary"
          key={'secondary'}
          className="especificacion_texto_info"
        >
          Especificaciones no disponibles para entornos Magento
        </Alert>
      )}
    </>
  )
}

export default EditSpecificationsOrigin
