/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import './UrlBlocks.css'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { config } from '../../../config'
import { FormattedMessage, useIntl } from 'react-intl'
import { ShowAlert } from '../../../components/Globals'
import { useClientContext } from '../../../contexts/ClientContext'

const UrlBlocksForm = ({
  dataUrlBlocks,
  loadURl,
  handlerReload,
  classLoading
}: any) => {
 
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const intl = useIntl()
  const dispatch = useDispatch()

  const [tokenValue, setTokenValue] = useState<any>('')
  const [Value, setValue] = useState<any>('')
  const [enabledSave, setEnabledSave] = useState<any>('0')

  const [uRLBlockedCopy, setURLBlockedCopy] = useState<any>([])

  const [uRLBlocked_1, setURLBlocked_1] = useState<any>([])
  const [uRLBlocked_2, setURLBlocked_2] = useState<any>([])
  const [uRLBlocked_3, setURLBlocked_3] = useState<any>([])
  const [uRLBlocked_4, setURLBlocked_4] = useState<any>([])
  const [uRLBlocked_5, setURLBlocked_5] = useState<any>([])
  const [uRLBlocked_6, setURLBlocked_6] = useState<any>([])
  const [uRLBlocked_7, setURLBlocked_7] = useState<any>([])
  const [uRLBlocked_8, setURLBlocked_8] = useState<any>([])
  const [uRLBlocked_9, setURLBlocked_9] = useState<any>([])
  const [uRLBlocked_10, setURLBlocked_10] = useState<any>([])

  const handleEditarUrl = (e: any) => {
    // if(isValidUrl(e.target.value)){ //Puede ser como una URL, asi que no validamos por las dudas
    const filtered = uRLBlockedCopy.find((url: any) => url.Key === e.target.id)
    // setURLBlockedCopy(filtered);
    setValue(e.target.value)
    setEnabledSave('1')
    if (filtered !== undefined) {
      filtered.Value = e.target.value
      switch (e.target.id) {
        case 'URLBlocked_1':
          setURLBlocked_1(filtered)
          break
        case 'URLBlocked_2':
          setURLBlocked_2(filtered)
          break
        case 'URLBlocked_3':
          setURLBlocked_3(filtered)
          break
        case 'URLBlocked_4':
          setURLBlocked_4(filtered)
          break
        case 'URLBlocked_5':
          setURLBlocked_5(filtered)
          break
        case 'URLBlocked_6':
          setURLBlocked_6(filtered)
          break
        case 'URLBlocked_7':
          setURLBlocked_7(filtered)
          break
        case 'URLBlocked_8':
          setURLBlocked_8(filtered)
          break
        case 'URLBlocked_9':
          setURLBlocked_9(filtered)
          break
        case 'URLBlocked_10':
          setURLBlocked_10(filtered)
          break
      }
    }

    // }
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.bloqueo-urls.titulo' },
        { defaultMessage: 'Bloqueo de URLs' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = () => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: async (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        setTokenValue(result.value)

        try {
          const sendUrl = []
          if (uRLBlocked_1 !== '') sendUrl.push(uRLBlocked_1)
          if (uRLBlocked_2 !== '') sendUrl.push(uRLBlocked_2)
          if (uRLBlocked_3 !== '') sendUrl.push(uRLBlocked_3)
          if (uRLBlocked_4 !== '') sendUrl.push(uRLBlocked_4)
          if (uRLBlocked_5 !== '') sendUrl.push(uRLBlocked_5)
          if (uRLBlocked_6 !== '') sendUrl.push(uRLBlocked_6)
          if (uRLBlocked_7 !== '') sendUrl.push(uRLBlocked_7)
          if (uRLBlocked_8 !== '') sendUrl.push(uRLBlocked_8)
          if (uRLBlocked_9 !== '') sendUrl.push(uRLBlocked_9)
          if (uRLBlocked_10 !== '') sendUrl.push(uRLBlocked_10)

          const requestOptions = {
            method: 'POST',
            headers: {
              token: user.data.token,
              userName: user.data.username,
              tokenGoogle: result.value,
              key: 'URLBlocked',
              'Content-Type': 'application/json',
              Accept: '* / *',
              credentials: 'include'
            },
            body: JSON.stringify(sendUrl)
          }

          fetch(
            `${config.base_server_path}/ManageCarrousel/UpdateScriptClientKeyValue?idHashScript=${selectedScript?.idHashScript}`,
            requestOptions
          )
            .then(async (response) => await response.json())
            .then((result) => {
              if (result.StatusCode < 0 || result < 0) {
                if (result === -5)
                  MyAlert(
                    intl.formatMessage(
                      { id: 'app.token-invalido' },
                      { defaultMessage: 'El token ingresado es inválido.' }
                    ),
                    3
                  )
                if (result !== -5 && result !== -6)
                  MyAlert(
                    intl.formatMessage(
                      { id: 'app.api.error' },
                      {
                        defaultMessage:
                          'Ocurrió un Error al actualizar los datos.'
                      }
                    ),
                    2
                  )
              } else {
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.api.update.ok' },
                    { defaultMessage: 'Se actualizaron con éxito los datos.' }
                  ),
                  1
                )
                handlerReload('updt')
                setEnabledSave('0')
              }
              setTokenValue('')
            })
            .catch((error: any) => {
              MyAlert(
                `${intl.formatMessage(
                  { id: 'app.api.error' },
                  {
                    defaultMessage: 'Ocurrió un Error al actualizar los datos.'
                  }
                )}` + `${error}`,
                2
              )

              if (error.response.data === -6) {
                dispatch(logout(user.data.token))
              } else {
                MyAlert('API-ERROR: ' + `${error}`, 3)
              }
            })
        } catch (error) {
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.error' },
              { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
            ) + `${error}`,
            2
          )
        }
      }
    })
  }

  useEffect(() => {
    if (dataUrlBlocks.length > 0 || uRLBlockedCopy.length > 0) {
      const urlsArray =
        dataUrlBlocks.length > 0 ? dataUrlBlocks : uRLBlockedCopy
      setURLBlockedCopy(urlsArray)
      urlsArray.map((url: any) => {
        const uRLBlocked_ = {
          Id: url.Id,
          Key: url.Key,
          Type: url.Type,
          Value: url.Value,
          Position: url.Position,
          IdScriptClient: url.IdScriptClient,
          IdDashboardUser: url.IdDashboardUser,
          DateAdded: url.DateAdded
        }

        switch (url.Key) {
          case 'URLBlocked_1':
            setURLBlocked_1(uRLBlocked_)
            break
          case 'URLBlocked_2':
            setURLBlocked_2(uRLBlocked_)
            break
          case 'URLBlocked_3':
            setURLBlocked_3(uRLBlocked_)
            break
          case 'URLBlocked_4':
            setURLBlocked_4(uRLBlocked_)
            break
          case 'URLBlocked_5':
            setURLBlocked_5(uRLBlocked_)
            break
          case 'URLBlocked_6':
            setURLBlocked_6(uRLBlocked_)
            break
          case 'URLBlocked_7':
            setURLBlocked_7(uRLBlocked_)
            break
          case 'URLBlocked_8':
            setURLBlocked_8(uRLBlocked_)
            break
          case 'URLBlocked_9':
            setURLBlocked_9(uRLBlocked_)
            break
          case 'URLBlocked_10':
            setURLBlocked_10(uRLBlocked_)
            break
        }
      })
    } else {
      // No trajo datos, es nuevo
      const arryUrls = []
      for (let y = 1; y <= 10; y++) {
        const uRLBlocked_ = {
          Id: 0,
          Key: 'URLBlocked_' + `${y}`,
          Type: 'text',
          Value: '',
          Position: y,
          IdScriptClient: 0,
          IdDashboardUser: 0,
          DateAdded: '2022-09-20'
        }
        arryUrls.push(uRLBlocked_)
      }

      setURLBlockedCopy(arryUrls)
      setValue('cambio')
    }
  }, [loadURl, Value])

  return (
    <>
      <div
        className={
          classLoading !== ''
            ? `${classLoading}` + ' shelves__wrapper mg-t-30 sombra_caja'
            : ' shelves__wrapper mg-t-30 sombra_caja'
        }
      >
        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_1
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_001'}
                id={uRLBlocked_1 !== '' ? uRLBlocked_1.Key : 'idk1'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_1 !== '' ? uRLBlocked_1.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_2
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_002'}
                id={uRLBlocked_2 !== '' ? uRLBlocked_2.Key : 'idk2'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_2 !== '' ? uRLBlocked_2.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_3
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_003'}
                id={uRLBlocked_3 !== '' ? uRLBlocked_3.Key : 'idk3'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_3 !== '' ? uRLBlocked_3.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_4
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_004'}
                id={uRLBlocked_4 !== '' ? uRLBlocked_4.Key : 'idk4'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_4 !== '' ? uRLBlocked_4.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_5
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_005'}
                id={uRLBlocked_5 !== '' ? uRLBlocked_5.Key : 'idk5'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_5 !== '' ? uRLBlocked_5.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_6
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_006'}
                id={uRLBlocked_6 !== '' ? uRLBlocked_6.Key : 'idk6'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_6 !== '' ? uRLBlocked_6.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_7
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_007'}
                id={uRLBlocked_7 !== '' ? uRLBlocked_7.Key : 'idk7'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_7 !== '' ? uRLBlocked_7.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_8
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_008'}
                id={uRLBlocked_8 !== '' ? uRLBlocked_8.Key : 'idk8'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_8 !== '' ? uRLBlocked_8.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_9
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_009'}
                id={uRLBlocked_9 !== '' ? uRLBlocked_9.Key : 'idk9'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_9 !== '' ? uRLBlocked_9.Value : ''}
              />
            </div>
          </div>
        </div>

        <div className="row margin_fila col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div className="shelves__endpoint-selector-container">
              <label className="shelves__endpoint-selector-text mb-2">
                URLBlocked_10
              </label>
            </div>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <div className="shelves__endpoint-selector-container">
              <input
                key={'inp_010'}
                id={uRLBlocked_10 !== '' ? uRLBlocked_10.Key : 'idk10'}
                className="input shelves__input-w100 caja_input"
                onChange={handleEditarUrl}
                type={'text'}
                value={uRLBlocked_10 !== '' ? uRLBlocked_10.Value : ''}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row shelves button">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
          <div className="shelves__preview-button">
            <div className="shelves__preview-button">
              <button
                className="ppal_button btn btn-primary"
                disabled={enabledSave === '1' ? false : true}
                onClick={AlertToken}
              >
                <FormattedMessage
                  id="app.vitrinas-editar.guardar"
                  defaultMessage="GUARDAR"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UrlBlocksForm
