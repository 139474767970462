import React from 'react'
import './style.css'

const Logo: React.FC = () => {
  return (
    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
      <div className="logo-area">
        <img
          className="logo-img"
          src={require('../../img/Brain-Marca.png')}
          alt="Logo de Braindw"
        />
      </div>
    </div>
  )
}

export default Logo
