/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BsPencilSquare,
  BsTrash,
  BsFillBookmarksFill,
  BsFillBookmarkPlusFill,
  BsBell,
  BsBellSlash
} from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import CrossSellingForm from './CrossSellingForm'
import RelatedCategoriesGrid from './RelatedCategoriesGrid'
import './relatedCategories.css'
import '../Global.css'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../../components/Globals'
import { config } from '../../../config'
import Papa from 'papaparse'
import { logout } from '../../../state/actions'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const RelatedCategoriesContainer = () => {
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const [addNewCrossSelling, setAddNewCrossSelling] = useState<boolean>(false)
  const [editCrossSelling, setEditCrossSelling] = useState<boolean>(false)
  const [tabSuscripcion, setTabSuscripcion] = useState(false)
  const [categorieId, setCategorieId] = useState<any>('')
  const toggleCrosseling = () => {
    setAddNewCrossSelling(!addNewCrossSelling)
    setEditCrossSelling(false)
    setCategorieId('')
  }
  const intl = useIntl()
  const [tokenValue, setTokenValue] = useState<any>('')
  const [reloadDataTable, setReloadDataTable] = useState<boolean>(false)
  const [sectionSelected, setSectionSelected] = useState<any>('')
  const [jsonFile, setJsonFile] = useState<any>([])

  const dispatch = useDispatch()

  const handleTab = () => {
    setTabSuscripcion(!tabSuscripcion)
  }

  const AlertToken = (section: string, items: any) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && section !== '') {
        setTokenValue(result.value)
        const requestOptions = {
          method: 'POST',
          headers: {
            token: user.data.token,
            tokenGoogle: result.value,
            username: user.data.username,
            'Content-Type': 'application/json',
            Accept: '*/*',
            credentials: 'include'
          }
        }
        fetch(
          `${config.base_server_path}/ManageCarrousel/DeleteCrossSelling?idHashScript=${selectedScript?.idHashScript}&section=${section}`,
          requestOptions
        )
          .then(async (response) => await response.json())
          .then((res) => {
            if (res.StatusCode < 0 || res < 0) {
              if (res === -5)
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.token-invalido' },
                    { defaultMessage: 'El token ingresado es inválido.' }
                  ),
                  3
                )
              if (res !== -5 && res !== -6)
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.api.error' },
                    {
                      defaultMessage:
                        'Ocurrió un Error al actualizar los datos.'
                    }
                  ),
                  2
                )
            } else {
              MyAlert(res.Message, 1)
              // reload dataTable
              setReloadDataTable(!reloadDataTable)
            }
            setTimeout(() => {
              setTokenValue('')
            }, 3000)
          })
          .catch((error) => {
            if (error.response.data === -6) {
              dispatch(logout(user.data.token))
            } else {
              MyAlert('API-ERROR: ' + `${error}`, 3)
            }
          })
      } else {
        postCsv(items, result.value)
      }
    })
  }

  const MyAlert = (text: string, iconStr: number) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-categories.cross-selling' },
        { defaultMessage: 'CRUCE DE CATEGORÍAS' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: 3000,
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertDelete = (text: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-categories.cross-selling' },
        { defaultMessage: 'CRUCE DE CATEGORÍAS' }
      ),
      text,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      focusConfirm: false,
      input: 'select',
      inputOptions: {
        Product: 'Product',
        CheckOut: 'CheckOut'
      },
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.borrar' },
        { defaultMessage: 'Borrar' }
      ),
      confirmButtonColor: '#3085d6',
      width: 500,
      timerProgressBar: true
    }).then((result) => {
      if (result.isConfirmed) {
        setSectionSelected(result.value)
        AlertToken(result.value, [])
      } else if (result.isDenied) {
        alert('Changes are not saved')
      }
    })
  }

  const HandlerFile = (text: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-categories.cross-selling' },
        { defaultMessage: 'CRUCE DE CATEGORÍAS' }
      ),
      text,
      input: 'file',
      html: intl.formatMessage(
        { id: 'app.related-categories.add-cross-selling.csv.text' },
        { defaultMessage: 'Seleccione' }
      ),
      inputAttributes: {
        accept: 'text/csv*',
        'aria-label': 'Seleccione el CSV'
      },
      showConfirmButton: true,
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      width: 500,
      timerProgressBar: true
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value != null) {
          if (result.value.size > 20000000) {
            // 20MB
            MyAlert(
              intl.formatMessage(
                { id: 'app.suscribir.file.zise' },
                { defaultMessage: '20Mb max' }
              ),
              2
            )
          } else {
            if (
              result.value.type === 'text/csv' ||
              result.value.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
              // csv|xls
              Papa.parse(result.value, {
                header: true,
                // delimiter: ';', asume , o ; según el delimitador de la 1° fila
                skipEmptyLines: true,
                complete: async function (results) {
                  if (results.data.length > 0) {
                    if (
                      results.meta.fields?.[0] === 'IdCategoryOrigin' &&
                      results.meta.fields?.[1] === 'IdCategoryDestination' &&
                      results.meta.fields?.[2] === 'Section'
                    ) {
                      console.log('results: ', results.data)
                      setJsonFile(results.data)
                      /****/
                      AlertToken('', results.data)
                      /****/
                    } else {
                      MyAlert(
                        intl.formatMessage(
                          { id: 'app.modal.upload-csv.error-headers' },
                          {
                            defaultMessage:
                              'Los titulos de cabezera deben ser iguales a los que se muestran en el ejemplo'
                          }
                        ),
                        2
                      )
                    }
                  } else {
                    MyAlert(
                      intl.formatMessage(
                        { id: 'app.suscribir.file.empty' },
                        { defaultMessage: '20Mb max' }
                      ),
                      2
                    )
                  }
                }
              })
            } else {
              MyAlert(
                intl.formatMessage(
                  { id: 'app.suscribir.file.ext' },
                  { defaultMessage: '*.cvs or excel file' }
                ),
                2
              )
            }
          }
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.related-categories.add-cross-selling.csv.empty' },
              { defaultMessage: '*.cvs or excel file' }
            ),
            2
          )
        }
      }
    })
  }

  const postCsv = async (d: any, tokenGoogle: string) => {
    const cross = { items: d }
    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        username: user.data.username,
        tokenGoogle,
        'Content-Type': 'application/json',
        Accept: '* / *',
        credentials: 'include'
      },
      body: JSON.stringify(cross)
    }
    const res = await fetch(
      `${config.base_server_path}/ManageCarrousel/CreateCrossSellingFromJson?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
    const data = await res.json()

    if (data.StatusCode < 0 || data < 0) {
      if (data === -5)
        MyAlert(
          intl.formatMessage(
            { id: 'app.token-invalido' },
            { defaultMessage: 'El token ingresado es inválido.' }
          ),
          3
        )
      if (data === -6) {
        MyAlert(
          intl.formatMessage(
            { id: 'app.token-expirado' },
            { defaultMessage: 'Su sessión ha caducado. vuelve a ingresar.' }
          ),
          3
        )
        dispatch(logout(user.data.token))
      }
      if (data !== -5 && data !== -6)
        MyAlert(
          intl.formatMessage(
            { id: 'app.api.error' },
            { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
          ),
          2
        )
      if (data.StatusCode === -1) MyAlert(data.Message, 2)
    } else {
      MyAlert(data.Message, 1)
      // reload dataTable
      setReloadDataTable(!reloadDataTable)
    }
  }

  const handlerDeleteBySection = () => {
    AlertDelete(
      intl.formatMessage(
        { id: 'app.related-categories.delete-cross-selling-by-section-title' },
        { defaultMessage: 'Seleccione la Sección que desea borrar.' }
      )
    )
  }

  useEffect(() => {}, [reloadDataTable])

  return (
    <>
      <div className="container container-body-tab">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {!addNewCrossSelling ? (
              <div>
                <button
                  className="ppal_button btn btn-primary mt-0"
                  onClick={() => toggleCrosseling()}
                >
                  <BsFillBookmarkPlusFill className="m-rig" width={20} />
                  <FormattedMessage
                    id="app.related-categories.add-new-cross-selling"
                    defaultMessage="AGREGAR NUEVO CRUCE DE CATEGORÍAS"
                  />
                </button>
                <button
                  className="btn btn-secondary m-lef"
                  onClick={() => HandlerFile('Seleccione le CSV')}
                >
                  <BsFillBookmarksFill className="m-rig" width={20} />
                  <FormattedMessage
                    id="app.related-categories.add-new-cross-selling-by-file"
                    defaultMessage="AGREGAR NUEVO CRUCE DE CATEGORÍAS DESDE ARCHIVO"
                  />
                </button>
                <button
                  className="btn btn-warning m-lef"
                  onClick={() => handlerDeleteBySection()}
                >
                  <BsTrash className="m-rig" width={20} />
                  <FormattedMessage
                    id="app.related-categories.delete-cross-selling-by-section"
                    defaultMessage="Eliminar Cruce por sección"
                  />
                </button>
                {' '}
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.cdp.grid-related-categories.buttons.tooltip')
                  )[0]}
                />
            
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="container mg-t-30">
          <div className="alert-heading">
            {intl.formatMessage(
              { id: 'app.tooltips.header.title.crossselling' },
              { defaultMessage: 'Venta Cruzada' }
            )}
          </div>
          <p>
            {intl.formatMessage(
              { id: 'app.tooltips.header.p.crossselling' },
              { defaultMessage: 'Venta Cruzada' }
            )}
          </p>
        </div>

        {addNewCrossSelling ? (
          <CrossSellingForm back={() => toggleCrosseling()} id={categorieId} />
        ) : (
          <RelatedCategoriesGrid
            edit={toggleCrosseling}
            setCategorieId={setCategorieId}
            setEditCrossSelling={setEditCrossSelling}
            handlerReloadDataTable={reloadDataTable}
          />
        )}
      </div>
    </>
  )
}

export default RelatedCategoriesContainer
