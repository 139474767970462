const HomeLogoSvg = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="90 220 350 400"
      style={{
        enableBackground: 'new 0 0 595.3 841.9'
      }}
      xmlSpace="preserve"
    >

      <g>
        <path
          d="M298,557.6c-30.2,0-60.4,0-90.6,0c-8.7,0-11-2.3-11-11.1c0-26.9,0-53.9,0-80.8c0-5.3,0-5.3-5.4-5.3
		c-5.5,0-11,0.1-16.5-0.1c-9.6-0.2-16.5-4.9-19.9-13.8c-3.4-8.6-2.2-16.8,4.5-23.5c29.1-29.3,58.3-58.4,87.5-87.6
		c14.7-14.7,29.5-29.5,44.2-44.2c6.2-6.2,9.3-6.2,15.6,0.1c15.3,15.4,30.6,30.8,45.9,46.2c28,28.1,56.1,56.1,84.1,84.1
		c4.9,4.8,7.5,10.4,7.3,17.3c-0.3,11.8-9.8,21.3-21.6,21.5c-5.7,0.1-11.5,0.2-17.2,0c-3.6-0.2-4.7,0.9-4.7,4.6
		c0.1,27.3,0.1,54.6,0.1,81.9c0,8.2-2.4,10.7-10.7,10.7C359.1,557.6,328.5,557.6,298,557.6z M266.2,542.4
		C266.2,542.4,266.2,542.4,266.2,542.4c16.7,0,33.4,0,50.1,0c5,0,5.1,0,5.1-4.9c0-17.2,0-34.4,0-51.6c0-1.6-0.1-3.3-0.4-4.8
		c-2.4-11.7-13.1-20.1-24.2-19.1c-12.3,1.2-21.4,11.1-21.5,23.7c-0.1,10.4,0,20.7-0.1,31.1c0,5-2.9,8-7.4,8.1
		c-4.5,0.1-7.5-2.8-7.7-7.8c-0.2-5.2-0.2-10.5,0-15.7c0.2-7.6-0.7-15.2,0.7-22.8c3.8-20.3,21.9-33,39.7-31.5
		c21.9,1.9,36.1,18.2,36.1,40.2c0,16.6,0,33.2,0,49.8c0,5.5,0,5.5,5.6,5.5c12.5,0,24.9,0,37.4,0c5.4,0,5.4,0,5.4-5.3
		c0-21.6,0-43.2,0-64.7c0-5.9-0.1-11.7,0-17.6c0.1-6,2.6-8.6,8.5-9.3c1.5-0.2,3-0.2,4.5-0.2c7.7,0,15.5,0,23.2,0
		c3.2,0,5.6-1.5,6.8-4.4c1.1-2.9,0.6-5.7-1.7-7.9c-1-1-1.9-2-2.9-2.9c-13.8-13.8-27.5-27.5-41.3-41.3
		c-26.7-26.7-53.4-53.4-80.2-80.2c-3.7-3.7-3.7-3.7-7.5,0.1c-3.2,3.2-6.4,6.4-9.5,9.5c-37.8,37.8-75.7,75.7-113.5,113.5
		c-2.7,2.7-4.2,5.6-2.8,9.3c1.5,3.8,4.8,4.2,8.3,4.2c7.7-0.1,15.5-0.1,23.2,0c8.7,0.1,11.3,2.8,11.3,11.5c0,26.9,0.1,53.9-0.1,80.8
		c0,3.8,1.2,4.9,4.9,4.9C233,542.3,249.6,542.4,266.2,542.4z"
        />
        <path
          d="M257.8,375.6c-0.2-2.4,1.2-4.4,2.9-6.1c10.5-10.5,20.9-21,31.4-31.4c4.2-4.1,8.3-4.2,12.5-0.1
		c10.6,10.5,21.2,21.1,31.7,31.7c3.4,3.4,3.5,8.1,0.6,11c-3.3,3.3-7.5,3.2-11.2-0.5c-8.2-8.1-16.3-16.1-24.3-24.3
		c-2.2-2.3-3.6-2.5-5.9-0.1c-7.6,8-15.5,15.8-23.3,23.7c-2.1,2.1-4.3,3.8-7.5,3.6C260.8,383,257.8,379.9,257.8,375.6z"
        />
        <path
          d="M369.1,420.6c-2.5-0.1-4.3-1.1-5.8-2.6c-5.3-5.3-10.6-10.5-15.8-15.8c-3.5-3.6-3.6-7.8-0.5-10.9
		c3-3,7.5-3,11,0.3c5.4,5.3,10.8,10.7,16.1,16.1c2.4,2.5,3,5.4,1.6,8.6C374.4,419.1,371.9,420.2,369.1,420.6z"
        />
      </g>
    </svg>
  )
}

export default HomeLogoSvg
