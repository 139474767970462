import React from 'react'
import '../components/Menu/style.css'
import { FormattedMessage } from 'react-intl'
import { ManageShelvesContainer } from '../components/Home'
import { ExportLogsContainer } from '../components/Home/System/'
import { BrandPriorityContainer } from '../components/Home/BrandPriority'
import { AutomationContainer } from '../components/Home/Automation'
import { SubscriptionContainer } from '../components/Home/Subscription'
import { UrlBlocksContainer } from '../components/Home/UrlBlocks'
import RelatedCategoriesAndSkusContainer from '../components/Home/RelatedCategories/RelatedCategoriesAndSkusContainer'

import CDPContainer from '../components/Home/CDP/CDPContainer'
import RemoveData from '../components/Home/CDP/RemoveData'
import ConsumptionCotainer from '../components/Home/CDP/ConsumptionCotainer'
import CDPUGridImported from '../components/Home/CDP/CDPUGridImported'
import ProfileAndPassUser from '../components/Home/System/ProfileAndPassUser'
import UsersContainer from '../components/Home/System/UsersContainer'
import BrandPrioritySearch from '../components/Home/BrandPriority/BrandPrioritySearch'
import Campain from '../components/Campain/Campain'
// import DashboardCampain from '../components/Campain/DashboardCampain'
// import Segmentos from '../components/Campain/Segmentos'
// import Clientes from '../components/Campain/Clientes'
import Analytics from '../components/Campain/Analytics'
// import Productos from '../components/Campain/Productos'
// import Novedades from '../components/Campain/Novedades'
import { UploadImageForm } from '../components/Home/Images/UploadImagesForm'
import SmartImagesList from '../components/Home/Images/SmartImagesList'
import SmartImagesBindsContainer from '../components/Home/Images/imageBinds/SmartImagesBindsContainer'
import ListByBlock from '../components/Home/Images/imageCarousel/ListByBlock'
import { SmartImagesBindsForm } from '../components/Home/Images/imageBinds/SmartImagesBindsForm'

// import Marketing from '../components/Campain/Marketing'

import MshopContainerCarrousel from '../components/Mshop/Carrousel/MshopContainerCarrousel'
import MshopContainerFacturacion from '../components/Mshop/Facturacion-Plan/MshopContainerFacturacion'
// import ClientesActividad from '../components/Campain/ClientesActividad'
// import Prioridades from '../components/Campain/Prioridades'
// import ClientesPerfil from '../components/Campain/ClientesPerfil'
// import Clients from '../components/Campain/Clients'

// const _class = "bdw-menu-icon";

export const subOptions = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="app.submenu.manage-carrousel"
        defaultMessage="Manejo de Góndolas (Reglas)"
      />
    ),
    icon: '',
    component: <ManageShelvesContainer />,
    link: 'manage-carrousel'
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.menu.crosselling"
        defaultMessage="Venta Cruzada"
      />
    ),
    icon: '',
    component: <RelatedCategoriesAndSkusContainer />,
    link: 'related-categories'
  },
  {
    id: 4,
    name: (
      <FormattedMessage
        id="app.submenu.url-block"
        defaultMessage="Bloqueo de Urls (Vtex IO)"
      />
    ),
    icon: '',
    component: <UrlBlocksContainer />,
    link: 'url-blocked'
  }
  // {
  //   id: 5,
  //   name: (
  //     <FormattedMessage
  //       id="app.submenu.configurar-regla"
  //       defaultMessage="Configurar Regla"
  //     />
  //   ),
  //   icon: "",
  //   component: <RuleConfigContainer />,
  //   link: "configurar-regla",
  // },
]

export const subSearchOptions = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="app.brandpriority-search-option"
        defaultMessage="Carruseles Búsqueda"
      />
    ),
    icon: '',
    component: <BrandPrioritySearch />,
    link: 'brand-priority-search'
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.brandpriority-lowercase"
        defaultMessage="Prioridad de Marca"
      />
    ),
    icon: '',
    component: <BrandPriorityContainer />,
    link: 'brand-priority'
  }
]

export const subCampainOptions = [
  // {
  //   id: 0,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-dashboard"
  //       defaultMessage="Dashboard"
  //     />
  //   ),
  //   icon: '',
  //   component: <DashboardCampain />,
  //   link: 'dashboardcampain'
  // },
  // {
  //   id: 1,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-clientes"
  //       defaultMessage="Clientes"
  //     />
  //   ),
  //   icon: '',
  //   component: <ClientesActividad />,
  //   link: 'clients'
  // },
  // {
  //   id: 3,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-segmento"
  //       defaultMessage="Segmentos"
  //     />
  //   ),
  //   icon: '',
  //   component: <Segmentos />,
  //   link: 'segments'
  // },
  // {
  //   id: 4,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-campañas"
  //       defaultMessage="Campañas"
  //     />
  //   ),
  //   icon: '',
  //   component: <Campain />,
  //   link: 'campain'
  // },
  // {
  //   id: 5,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-analytics"
  //       defaultMessage="Analytics"
  //     />
  //   ),
  //   icon: '',
  //   component: <Analytics />,
  //   link: 'analytics'
  // },
  // {
  //   id: 6,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-productos"
  //       defaultMessage="Productos"
  //     />
  //   ),
  //   icon: '',
  //   component: <Productos />,
  //   link: 'productos'
  // },
  // {
  //   id: 7,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-novedades"
  //       defaultMessage="Novedades"
  //     />
  //   ),
  //   icon: '',
  //   component: <Novedades />,
  //   link: 'novedades'
  // },
  // {
  //   id: 8,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-ventas"
  //       defaultMessage="Ventas"
  //     />
  //   ),
  //   icon: '',
  //   component: <Marketing />,
  //   link: 'Marketing'
  // },
  // {
  //   id: 9,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-ventas"
  //       defaultMessage="Grupo de Envíos"
  //     />
  //   ),
  //   icon: '',
  //   component: <Prioridades />,
  //   link: 'Prioridades'
  // }
]

export const subCampainOptionsMusimundo = [
  // {
  //   id: 0,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-dashboard"
  //       defaultMessage="Dashboard"
  //     />
  //   ),
  //   icon: '',
  //   component: <DashboardCampain />,
  //   link: 'dashboardcampain'
  // },
  // {
  //   id: 1,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-clientes"
  //       defaultMessage="Clientes"
  //     />
  //   ),
  //   icon: '',
  //   component: <ClientesActividad />,
  //   link: 'clients'
  // },
  // {
  //   id: 3,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-segmento"
  //       defaultMessage="Segmentos"
  //     />
  //   ),
  //   icon: '',
  //   component: <Segmentos />,
  //   link: 'segments'
  // },
  // {
  //   id: 4,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-campañas"
  //       defaultMessage="Campañas"
  //     />
  //   ),
  //   icon: '',
  //   component: <Campain />,
  //   link: 'campain'
  // },
  {
    id: 5,
    name: (
      <FormattedMessage
        id="app.subCampainOptions-analytics"
        defaultMessage="Métricas"
      />
    ),
    icon: '',
    component: <Analytics />,
    link: 'analytics'
  }
  // {
  //   id: 6,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-productos"
  //       defaultMessage="Productos"
  //     />
  //   ),
  //   icon: '',
  //   component: <Productos />,
  //   link: 'productos'
  // },
  // {
  //   id: 7,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-novedades"
  //       defaultMessage="Novedades"
  //     />
  //   ),
  //   icon: '',
  //   component: <Novedades />,
  //   link: 'novedades'
  // },
  // {
  //   id: 8,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-ventas"
  //       defaultMessage="Ventas"
  //     />
  //   ),
  //   icon: '',
  //   component: <Marketing />,
  //   link: 'Marketing'
  // },
  // {
  //   id: 9,
  //   name: (
  //     <FormattedMessage
  //       id="app.subCampainOptions-ventas"
  //       defaultMessage="Grupo de Envíos"
  //     />
  //   ),
  //   icon: '',
  //   component: <Prioridades />,
  //   link: 'Prioridades'
  // }
]

export const subAutomationOptions = [
  // {
  //   id: 1,
  //   name: (
  //     <FormattedMessage
  //       id="app.submenu.configurar-regla"
  //       defaultMessage="Configurar Regla"
  //     />
  //   ),
  //   icon: "",
  //   component: <RuleConfigContainer />,
  //   link: "configurar-regla",
  // },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.submenu.audiencias"
        defaultMessage="Audiencias"
      />
    ),
    icon: '',
    component: <AutomationContainer />,
    link: 'audiencias'
  },
  {
    id: 3,
    name: (
      <FormattedMessage
        id="app.submenu.sucripcion-desuscripcion"
        defaultMessage="Sucripción / Desuscripción"
      />
    ),
    icon: '',
    component: <SubscriptionContainer />,
    link: 'sucripcion-desuscripcion'
  }
  // {
  //   id: 4,
  //   name: (
  //     <FormattedMessage
  //       id="app.submenu.campanias"
  //       defaultMessage="Campañas"
  //     />
  //   ),
  //   icon: "",
  //   component: <RuleConfigContainer />,
  //   link: "campanias",
  // },
  // {
  //   id: 5,
  //   name: (
  //     <FormattedMessage
  //       id="app.submenu.templates"
  //       defaultMessage="Templates"
  //     />
  //   ),
  //   icon: "",
  //   component: <RuleConfigContainer />,
  //   link: "templates",
  // },
  // {
  //   id: 6,
  //   name: (
  //     <FormattedMessage
  //       id="app.submenu.ajustes"
  //       defaultMessage="Ajustes"
  //     />
  //   ),
  //   icon: "",
  //   component: <RuleConfigContainer />,
  //   link: "ajustes",
  // },
  // {
  //   id: 7,
  //   name: (
  //     <FormattedMessage
  //       id="app.submenu.seguimiento"
  //       defaultMessage="Seguimiento"
  //     />
  //   ),
  //   icon: "",
  //   component: <RuleConfigContainer />,
  //   link: "seguimiento",
  // },
  // {
  //   id: 8,
  //   name: (
  //     <FormattedMessage
  //       id="app.submenu.test-email"
  //       defaultMessage="Testeos de mails"
  //     />
  //   ),
  //   icon: "",
  //   component: <RuleConfigContainer />,
  //   link: "test-email",
  // }
]

export const systemSubMenuOptions = [
  {
    id: 1,
    name: <FormattedMessage id="app.submenu.profile" defaultMessage="Perfil" />,
    icon: '',
    component: <ProfileAndPassUser />,
    link: 'user-profile',
    protected: false
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.submenu.logs"
        defaultMessage="Logs de Actividad"
      />
    ),
    icon: '',
    component: <ExportLogsContainer />,
    link: 'export-logs',
    protected: true
  },
  // {
  //   id: 3,
  //   name: (
  //     <FormattedMessage
  //       id="app.submenu.articles-panel"
  //       defaultMessage="Artículos Panel"
  //     />
  //   ),
  //   icon: '',
  //   component: <ArticlesTableContainer />,
  //   link: 'articles-table-panel'
  // },

  {
    id: 4,
    name: <FormattedMessage id="app.submenu.user" defaultMessage="Usuarios" />,
    icon: '',
    component: <UsersContainer />,
    link: 'user-users',
    protected: true
  }
]

export const systemSubMenuOptionsForUsers = [
  {
    id: 1,
    name: <FormattedMessage id="app.submenu.profile" defaultMessage="Perfil" />,
    icon: '',
    component: <ProfileAndPassUser />,
    link: 'user-profile',
    protected: false
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.submenu.logs"
        defaultMessage="Logs de Actividad"
      />
    ),
    icon: '',
    component: <ExportLogsContainer />,
    link: 'export-logs',
    protected: true
  },

  {
    id: 3,
    name: <FormattedMessage id="app.submenu.user" defaultMessage="Usuarios" />,
    icon: '',
    component: <UsersContainer />,
    link: 'user-users',
    protected: true
  }
]

export const cdpSubMenuOptions = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="app.submenu.create-audience"
        defaultMessage="Crear Audiencia"
      />
    ),
    icon: '',
    component: <CDPContainer />,
    link: 'create-audience'
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.cdp.import-audience"
        defaultMessage="Importar Audiencia"
      />
    ),
    icon: '',
    component: <CDPUGridImported />,
    link: 'import-audience'
  },
  {
    id: 3,
    name: (
      <FormattedMessage
        id="app.submenu.sucripcion-desuscripcion"
        defaultMessage="Sucripción / Desuscripción"
      />
    ),
    icon: '',
    component: <SubscriptionContainer />,
    link: 'sucripcion-desuscripcion'
  },
  {
    id: 4,
    name: (
      <FormattedMessage id="app.submenu.consumos" defaultMessage="Consumos" />
    ),
    icon: '',
    component: <ConsumptionCotainer />,
    link: 'consumption'
  },
  {
    id: 5,
    name: (
      <FormattedMessage
        id="app.submenu.remove-data"
        defaultMessage="Remover Datos"
      />
    ),
    icon: '',
    component: <RemoveData />,
    link: 'remove-data'
  }
]

export const cdpSubMenuOptionsForUsers = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="app.submenu.create-audience"
        defaultMessage="Crear Audiencia"
      />
    ),
    icon: '',
    component: <CDPContainer />,
    link: 'create-audience'
  }
]

export const CampainSubMenu = [
  {
    id: 0,
    name: (
      <FormattedMessage
        id="app.brandpriority-search-option"
        defaultMessage="Campañas"
      />
    ),
    icon: '',
    component: <Campain />,
    link: 'campain/Campain'
  },
  {
    id: 1,
    name: (
      <FormattedMessage
        id="app.brandpriority-search-option"
        defaultMessage="Campañas2"
      />
    ),
    icon: '',
    component: <Campain />,
    link: 'campain/campain'
  }
]
export const SubMenuMshopOptions = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="app.submenu.mshop.carrusel"
        defaultMessage="Carruseles"
      />
    ),
    icon: '',
    component: <MshopContainerCarrousel />,
    link: 'mshop-carrusel',
    path: 'mshop-carrusel'
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.submenu.mshop.fact-plan"
        defaultMessage="Facturación"
      />
    ),
    icon: '',
    component: <MshopContainerFacturacion />,
    link: 'mshop-billing',
    path: 'mshop-billing'
  }
]

export const SubMenuSmartImagesOptions = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="app.submenu.upload-smart-images"
        defaultMessage="Subir Imágenes"
      />
    ),
    icon: '',
    component: <UploadImageForm />,
    link: 'upload-images',
    path: 'upload-images',
    dynamic: true
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.submenu.upload-smart-images"
        defaultMessage="Subir Imágenes"
      />
    ),
    icon: '',
    component: <UploadImageForm />,
    link: 'upload-images-update',
    path: 'upload-images-update/:id',
    dynamic: true
  },
  {
    id: 3,
    name: (
      <FormattedMessage
        id="app.submenu.list-smart-images"
        defaultMessage="Mis imágenes"
      />
    ),
    icon: '',
    component: <SmartImagesList />,
    link: 'smart-images-list',
    path: 'smart-images-list'
  },
  {
    id: 4,
    name: (
      <FormattedMessage
        id="app.submenu.bind-smart-images"
        defaultMessage="Carruseles"
      />
    ),
    icon: '',
    component: <SmartImagesBindsContainer />,
    link: 'setup-smart-images',
    path: 'setup-smart-images'
  },
  {
    id: 5,
    icon: '',
    component: <ListByBlock />,
    link: 'setup-smart-images-listByBlock',
    path: 'setup-smart-images-listByBlock/:block',
    dynamic: true
  },
  {
    id: 6,
    icon: '',
    component: <SmartImagesBindsForm />,
    link: 'setup-smart-images-block-create',
    path: 'setup-smart-images-block-create/:block',
    dynamic: true
  },
  {
    id: 7,
    icon: '',
    component: <SmartImagesBindsForm />,
    link: 'setup-smart-images-create',
    path: 'setup-smart-images-create',
    dynamic: true
  },
  {
    id: 8,
    icon: '',
    component: <SmartImagesBindsForm />,
    link: 'setup-smart-images-update',
    path: 'setup-smart-images-update/:block/:id',
    dynamic: true
  }
]
export default {
  cdpSubMenuOptions,
  cdpSubMenuOptionsForUsers,
  subOptions,
  subAutomationOptions,
  systemSubMenuOptions,
  systemSubMenuOptionsForUsers,
  CampainSubMenu
}
