import React, { KeyboardEventHandler, useCallback, useEffect } from 'react'
import { useController } from 'react-hook-form'
import styled from 'styled-components'
import CreatableSelect from 'react-select/creatable'
interface Option {
  readonly label: string
  readonly value: string
}

const createOption = (label: string, value: any = label) => ({
  label,
  value
})

export const RequiredMessage = styled.p`
  color: #ffb245;
  margin-top: 12px;
`

const TagInput = (props: {
  name: string
  tags?: Option[]
  isUpdate: boolean
  promiseOptions: any
  updateSelectedTags: Option[] | []
}) => {
  const [inputValue, setInputValue] = React.useState('')
  const [value, setValue] = React.useState<readonly Option[]>([])
  const {
    field: { onChange, name },
    formState: { errors }
  } = useController({ name: props.name })

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      if (inputValue.length === 0) return
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          // eslint-disable-next-line no-case-declarations
          let tagId: any = null
          event.preventDefault()
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (props.tags?.length) {
            tagId = props.tags.find(
              (tag) =>
                tag.label.toLocaleLowerCase() ===
                inputValue.trim().toLocaleLowerCase()
            )?.value
          }
          if (value.some((tag) => tag.label === inputValue.trim())) return
          console.log(createOption(inputValue.trim(), tagId))
          setValue((prev) => [...value, createOption(inputValue.trim(), tagId)])
          setInputValue('')
          onChange([...value, createOption(inputValue.trim(), tagId)])
          tagId = null
      }
    },
    [value]
  )

  useEffect(() => {
    if (props.isUpdate) {
      onChange(props.updateSelectedTags)
      setValue(props.updateSelectedTags)
    }
  }, [props.isUpdate])
  return (
    <>
      <CreatableSelect
        inputValue={inputValue}
        // isClearable
        isMulti
        options={typeof props.tags === 'string' ? undefined : props.tags}
        // loadOptions={props.promiseOptions}
        // menuIsOpen={false}
        onChange={(newValue) => {
          onChange(newValue)
          setValue(newValue)
        }}
        onInputChange={(newValue) => {
          setInputValue(newValue)
        }}
        onKeyDown={handleKeyDown}
        placeholder="Ingresa las etiquetas"
        value={value}
      />
      {errors?.[name] != null && (
        <RequiredMessage>{errors[name]?.message}</RequiredMessage>
      )}
    </>
  )
}

export default TagInput
