import React from 'react'
import MenuContainer from '../../Menu/MenuContainer'
import MenuOptions from '../../Menu/MenuOptions'
import { subSearchOptions } from '../../../utils/secondMenuOptions'

const SubMenuBrandPriority = () => {
  const handleAction = () => {
    console.log()
  }

  return (
    <>
      <MenuContainer>
        <MenuOptions
          tag="BrainDW | Busqueda"
          options={subSearchOptions}
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default SubMenuBrandPriority
