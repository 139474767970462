/* eslint-disable array-callback-return */
import React from 'react'

const BestOffer: React.FC<{
  title: string
  values: any  
}> = ({ title }) => {
  return (
    <div className="rule-config-subComponents__container hiden-rule">
      <div className="row a-center hiden-rule">
        <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal hiden-rule">
          {title}
        </h6>
        <input
          className="col-lg-9 col-md-9 col-sm-9 col-xs-9 vtexskuconsult__input hiden-rule"
          id="bestoffer"
          style={{ width: '64%', background: '#fcfbfb' }}
          type="text"
          defaultValue={1}
        />
      </div>
    </div>
  )
}

export default BestOffer
