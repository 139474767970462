/* eslint-disable @typescript-eslint/no-extraneous-class */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { config } from '../config'

class Service {
  private static axiosInstance: AxiosInstance

  static init() {
    this.axiosInstance = axios.create({
      baseURL: `${config.base_server_path}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      }
    })
  }

  static async get<AxiosResponse>(url: string, config?: AxiosRequestConfig) {
    return await Service.axiosInstance.get<AxiosResponse>(url, config)
  }

  static async post<ApiResponse, DataType>(
    url: string,
    data?: DataType,
    config?: AxiosRequestConfig
  ) {
    return await Service.axiosInstance.post<
      DataType,
      AxiosResponse<ApiResponse>
    >(url, data, config)
  }
}

export default Service
