/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/store'
import './system.css'
import { BsEraser } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { ShowAlert } from '../../Globals'
import { config } from '../../../config'
import { logout } from '../../../state/actions'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const ExportLogsForm = ({
  dateFromFilter,
  dateToFilter,
  setDateFromFilter,
  setDateToFilter,
  handleFiltrar,
  filter
}: any) => {
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const [loading, setLoading] = useState(false)

  const [total, setTotal] = useState(0 as number)
  const [bdAll, setBdAll] = useState(false)
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [tokenValue, setTokenValue] = useState<any>('')
  const intl = useIntl()
  const dispatch = useDispatch()

  const AlertToken = () => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    })
      .then((result) => {
        if (result.isConfirmed && result.value !== '') {
          setTokenValue(result.value)
          getExportarData(result.value)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.logs' },
        { defaultMessage: 'Log de actividades' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      // toast: true,
      width: 500,
      timerProgressBar: true
    })
  }

  const handleDateFrom = (e: any) => {
    const fechaDesde = e.target.value
    setDateFrom(fechaDesde)
    setDateFromFilter(fechaDesde)
  }
  const handleDateTo = (e: any) => {
    const fechaHasta = e.target.value
    setDateTo(fechaHasta)
    setDateToFilter(fechaHasta)
  }
  const handleBdAll = () => {
    setBdAll(!bdAll)
  }
  const handleExportar = () => {
    if (bdAll) {
      AlertToken()
    } else {
      if (dateFrom === '' || dateTo === '') {
        MyAlert(
          intl.formatMessage(
            { id: 'app.system.export.csv.no-date-selected' },
            {
              defaultMessage: 'Se debe seleccionar la Fecha Desde y Fecha Hasta'
            }
          ),
          2
        )
      } else {
        AlertToken()
      }
    }
  }

  const getExportarData = async (tokenGoogle: string) => {
    if (!selectedScript) {
      return;
    }
    MyAlert(
      intl.formatMessage(
        { id: 'app.vitrinas-spinner.espere' },
        { defaultMessage: 'Espere...' }
      ),
      3,
      3000
    )
    const datos = {
      DateFrom: dateFrom,
      DateTo: dateTo,
      Total: total
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        token: user.data.token,
        username: user.data.username,
        tokenGoogle,
        'Content-Type': 'application/json',
        responseType: 'blob',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(datos)
    }
    const res = await fetch(
      `${config.base_server_path}/ScriptClient/ExportLogsByClient?idHashScript=${selectedScript?.idHashScript}`,
      requestOptions
    )
      .then(async (response) => {
        const respuesta = await response.blob()
        return respuesta
      })
      .then((response: any) => {
        if (response?.type === 'application/json') {
          MyAlert(
            intl.formatMessage(
              { id: 'app.related-skus.export-error' },
              {
                defaultMessage:
                  'No se encontraron registros, para la sección a exportar.'
              }
            ),
            2
          )
          setTokenValue('')
        } else {
          const company = selectedScript?.idHashScript.split('_')[0]
          const d = new Date()
          const currDate =
            `${d.getFullYear()}` +
            '_' +
            `${d.getMonth() + 1}` +
            '_' +
            `${d.getDate()}` +
            '_' +
            `${d.getHours()}` +
            `${d.getMinutes()}` +
            `${d.getSeconds()}`
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          bdAll
            ? link.setAttribute(
                'download', 
                `ActivityLogs_${company.toUpperCase()}_${currDate}.csv.zip`)
            : link.setAttribute(
                'download',
                `ActivityLogs_${company.toUpperCase()}_From_${dateFrom}_To_${dateTo}.csv.zip`
              )
          document.body.appendChild(link)
          link.click()
          setTokenValue('')
        }
      })
      .catch(() => {
        MyAlert(
          intl.formatMessage(
            { id: 'app.api.error' },
            { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
          ),
          2
        )
      })
  }

  useEffect(() => {
    setLoading(true)
    bdAll ? setTotal(1) : setTotal(0)
    setLoading(false)
  }, [bdAll, dateFrom, dateTo])

  return (
    <div className="automation__wrapper">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className=" automation__endpoint-selector-container mt-4 display-block">
            <div className="automation__endpoint-selector-container  mt-2">
              <label className="automation__endpoint-selector-text align-left">
                <FormattedMessage
                  id="app.suscribir.seleccionar-dias"
                  defaultMessage="SELECCIONAR DÍAS"
                />
                <TooltipPanel 
                ObjectTooltip={user.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.form.logs.exports.days.from.tooltip')
                )[0]}
              />
              </label>
              <input
                className="dateInput mt-2 vtexskuconsult__input"
                id="dateTo"
                type={'date'}
                placeholder={intl.formatMessage(
                  { id: 'app.system.export.csv.date-From' },
                  { defaultMessage: 'Desde : ' }
                )}
                onChange={handleDateFrom}
              />
              <input
                className="input mt-2 vtexskuconsult__input"
                id="dateFrom"
                type={'date'}
                placeholder={intl.formatMessage(
                  { id: 'app.system.export.csv.date-To' },
                  { defaultMessage: 'Hasta : ' }
                )}
                onChange={handleDateTo}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className=" automation__endpoint-selector-container mt-4">
            <div className="subscription col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="automation__endpoint-selector-container row">
                <label className="text_label_bd mt-2 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <FormattedMessage
                    id="app.suscribir.descargar-base-completa"
                    defaultMessage="Descargar la base completa"
                  />
                  <TooltipPanel 
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === 'app.form.logs.exports.db.complete.tooltip')
                    )[0]}
                  />
                </label>
                <input
                  type={'checkbox'}
                  className="inputCheckBox mt-2 col-lg-3 col-md-3 col-sm-3 col-xs-3"
                  onChange={handleBdAll}
                />
                <div className="automation__preview-button col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <button className="ppal_button" onClick={handleExportar}>
                    <FormattedMessage
                      id="app.suscribir.exportar"
                      defaultMessage="EXPORTAR"
                    />
                  </button>
                </div>

                <div className="automation__preview-button col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  {filter == true ? (
                    <button className="btn-success" onClick={handleFiltrar}>
                      <BsEraser />
                    </button>
                  ) : (
                    <button
                      className="btn-success"
                      onClick={handleFiltrar}
                      disabled={
                        dateFromFilter !== '' || dateToFilter !== ''
                          ? false
                          : true
                      }
                    >
                      <FormattedMessage
                        id="app.submenu.logs-filter"
                        defaultMessage="FILTRAR"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExportLogsForm
