import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { HeaderContainer, MenuContainer } from '../components'
import MenuOptions from '../components/Menu/MenuOptions'
import { mainMenuOptionsAdmin, mainMenuOptions } from '../utils/mainMenuOptions'
import { RootState } from '../state/store'
import { useClientContext } from '../contexts/ClientContext'
import { Loading } from '../components/Globals/Loading'
// import MshopInactive from './MshopInactive'
// import MshopInvalidGrant from './MshopInvalidGrant'

const Dashboard = () => {
  const [menuOption, setMenuOption] = useState<boolean>(false)
  const { clientLoading } = useClientContext()

  const handleAction = () => {
    setMenuOption(!menuOption)
  }

  const user = useSelector<RootState, any>((state: any) => state.userAuth)
  // console.log(
  //   '[BDW - Mshop]-',
  //   user.data.isActiveAppMshop,
  //   user.data.disabledAppBy
  // )
  return (
    <>
      <HeaderContainer />
      <div className="main-menu-dashboard">
        <MenuContainer option={menuOption}>
          <MenuOptions
            tag="BrainDW | Dashboard"
            options={
              user.data.userType === 1
                ? mainMenuOptionsAdmin
                : // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
                  mainMenuOptions.filter((op) => op.protected === false)
            }
            onAction={handleAction}
          />
        </MenuContainer>
      </div>
      {clientLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 200
          }}
        >
          <Loading textLoading="Cargando datos" />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default Dashboard
