export const Loading = (props: { textLoading: string }) => {
  const { textLoading } = props

  return (
    <div className="spinner_v">
      <div
        id="spinner"
        className="spinner-border"
        style={{ color: '#b6b6b6' }}
        role="status"
      >
        <span className="sr-only"></span>
      </div>
      <small className="spinner_text" style={{ color: '#b6b6b6' }}>
        {textLoading}
      </small>
    </div>
  )
}
