import React from 'react'

const Numeric: React.FC<{ id: string; title: string; value: any }> = ({
  id,
  title,
  value
}) => {
  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const limitNumber = parseInt(event.target.value)
    value(limitNumber)
  }
  return (
    <div className="automation__endpoint-selector-container row">
      <label className="automation__endpoint-selector-text mb-2 col-lg-4 col-md-4 col-sm-4 col-xs-4">
        {title}
      </label>
      <input
        id={id}
        key={id}
        className="input col-lg-8 col-md-8 col-sm-8 col-xs-8"
        style={{ width: '15%' }}
        type={'number'}
        placeholder={title}
        onChange={inputOnChange}
        value={value}
      />
    </div>
  )
}

export default Numeric
