import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './state/store'
import Service from './services'
import LanguageProvider from './components/Globals/LanguageProvider'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import './index.css'

const persistor = persistStore(store)

const runApp = async () => {
  Service.init()
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LanguageProvider>
          <Router>
            <App />
          </Router>
        </LanguageProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )
}

runApp()
