/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import clamp from 'lodash.clamp'
import swap from 'lodash-move'
import { IoIosDesktop, IoIosPhonePortrait } from 'react-icons/io'
import { selectedImgI } from './SmartImagesBindsForm'

interface Props {
  images: selectedImgI[]
  handleRemoveSelectedImage: (id: number) => void
  changeImgMode: (id: number, mode: boolean) => void
  handleImgOrder: (newOrder: number[]) => void
  handleUrlSelectImage: (e: React.ChangeEvent<any>, id: number) => void
}
const CustomFigureSelect = styled(animated.figure)`
  position: absolute;
  width: 340px;
  height: 366px;
  touch-action: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  padding: 1rem;
  border-radius: 8px;
`
const ListImagesByTag = styled.div`
  height: 400px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-y: auto;
  gap: 10px 35px;
  &::-webkit-scrollbar {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #d9d9d9;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }
`
const CustomButtonSelect = styled(Button)`
  margin-top: 1rem;
  background-color: #ffb245;
  border: none;
  font-size: 14px;
  &:hover {
    border: none;
  }
  &:focus-visible {
    box-shadow: none;
  }
`
const fn =
  (order: number[], active = false, originalIndex = 0, curIndex = 0, x = 0) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          // y: curIndex * 1 + y,
          x: curIndex * 380 + x, // curIndex * 1 + x,
          scale: 0.95,
          zIndex: 1,
          shadow: 15,
          immediate: (key: string) => key === 'y' || key === 'zIndex'
        }
      : {
          // y: order.indexOf(index) * 1,
          x: order.indexOf(index) * 380, // order.indexOf(index) * 1,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false
        }

function ImagesSelectedContainer({
  images,
  handleRemoveSelectedImage,
  changeImgMode,
  handleImgOrder,
  handleUrlSelectImage
}: Props) {
  const order = useMemo(
    () => ({ current: images.map((_, index) => index) }),
    [images.length]
  ) // Store indicies as a local ref, this represents the item order
  const [springs, api] = useSprings(images.length, fn(order.current)) // Create springs, each corresponds to an item, controlling its transform, scale, etc.
  const bind = useDrag(({ args: [originalIndex], active, movement: [x] }) => {
    const curIndex = order.current.indexOf(originalIndex)
    const curPosition = clamp(
      Math.round((curIndex * 100 + x) / 100),
      0,
      images.length - 1
    )
    const newOrder = swap(order.current, curIndex, curPosition)
    api.start(fn(newOrder, active, originalIndex, curIndex, x)) // Feed springs new style data, they'll animate the view without causing a single render
    if (!active) {
      order.current = newOrder
      handleImgOrder(newOrder)
    }
  })
  useEffect(() => {
    return () => {}
  }, [images])

  return (
    <div className="ctn">
      <div
        style={{
          background: '#fff',
          border: '1px solid #dbdbdb',
          padding: '1rem',
          borderRadius: '8px',
          height: 460,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 18
        }}
      >
        <ListImagesByTag>
          {springs.map(({ zIndex, shadow, scale, x }, i) => (
            <CustomFigureSelect
              {...bind(i)}
              key={i}
              style={{
                zIndex,
                boxShadow: shadow.to(
                  (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                ),
                x,
                scale
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ color: '#666' }}>Prioridad {images[i].order}</div>
                <span>
                  <IoIosPhonePortrait
                    size={28}
                    color={images[i].imgMobileMode ? '#2BAE7E' : '#666'}
                    cursor={'pointer'}
                    onClick={() => changeImgMode(images[i].id, true)}
                  />
                  <IoIosDesktop
                    size={28}
                    color={images[i].imgMobileMode ? '#666' : '#2BAE7E'}
                    cursor={'pointer'}
                    onClick={() => changeImgMode(images[i].id, false)}
                  />
                </span>
              </div>
              <img
                src={
                  images[i].imgMobileMode
                    ? images[i].urlmobile
                    : images[i].urlmain
                }
                alt={images[i].title}
                style={{
                  width: '80%',
                  marginTop: 12,
                  marginBottom: 12,
                  userSelect: 'none',
                  WebkitUserSelect: 'none',
                  pointerEvents: 'none',
                  maxHeight: 200
                }}
              />
              <figcaption
                style={{
                  textAlign: 'right',
                  marginBottom: 12,
                  width: '100%'
                }}
              >
                <Form.Group>
                  <Form.Control
                    type="text"
                    // {...methods.register('title')}
                    value={images[i].link}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleUrlSelectImage(e, images[i].id)}
                    aria-describedby="identify text"
                    placeholder="Link"
                  />
                </Form.Group>
                <CustomButtonSelect
                  className=""
                  onClick={() => handleRemoveSelectedImage(images[i].id)}
                >
                  Eliminar
                </CustomButtonSelect>
              </figcaption>
            </CustomFigureSelect>
          ))}
        </ListImagesByTag>
      </div>
    </div>
  )
}

export default ImagesSelectedContainer
