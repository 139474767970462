import React from 'react'

const InputText: React.FC<{
  id: string
  title: string
  value: any
  handler: any
  readOnly: boolean
}> = ({ id, title, value, handler, readOnly }) => {
  /* const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const limitNumber = parseInt(event.target.value);
    setMinStock(limitNumber);
    value(limitNumber);
  }; */
  return (
    <div className="automation__endpoint-selector-container mb-2 mt-2 row">
      <label className="automation__endpoint-selector-text col-lg-2 col-md-2 col-sm-2 col-xs-2">
        {title}
      </label>
      <input
        id={id}
        key={id}
        className="input col-lg-10 col-md-10 col-sm-10 col-xs-10"
        type={'text'}
        disabled={readOnly}
        style={{ width: '15% !important' }}
        placeholder={title}
        value={value}
        onChange={handler}
      />
    </div>
  )
}

export default InputText
