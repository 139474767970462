import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from '../constants/userConstants'

const defaultState: StateResponse = {
  loading: false,
  error: undefined
}

const userLoginReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true
      }
    case USER_LOGIN_SUCCESS:
      return {
        loading: true
      }
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export default userLoginReducer
