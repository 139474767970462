import InfoIcon from '../Globals/svg/InfoIcon'
import { ShowAlert } from '../../components/Globals'
import { useIntl } from 'react-intl'

export const Tooltip = ({ text }: any) => {  
  const intl = useIntl()

  const MyAlertInfo = (text: string, time = 25000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.carrousel.rules.tooltip.title' },
        { defaultMessage: 'Ayuda' }
      ),
      toast: true,
      timer: time,
      html: text,

      icon: 'info',
      showConfirmButton: false,
      showCloseButton: true,
      position: 'top-end',
      width: 500,
      timerProgressBar: true
    })
  }

  return (
    <>
      <a
          id={'btn-nada'}
          key={'btn-nada'}
          style={{
            cursor: 'pointer',
            display: 'contents'
          }}
          onClick={() =>
            MyAlertInfo( text )
          }
        >
        <InfoIcon />
      </a>
    </>
  )
}