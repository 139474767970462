import {
  USER_AUTH_FAIL,
  USER_AUTH_LOGOUT,
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS,
  USER_UPDATE_PHOTO
} from '../constants/authConstants'


const defaultState: StateResponse<UserLoggedIn> = {
  loading: false,
  error: undefined,
  data: undefined
}

export const userAuthReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case USER_AUTH_REQUEST:
      return {
        loading: true
      }
    case USER_AUTH_SUCCESS:
      return {
        loading: true,
        data: action.payload
      }
    case USER_AUTH_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    case USER_AUTH_LOGOUT:
      return {}
    case USER_UPDATE_PHOTO:
      return{
        ...state,
        loading:true,
        data:{
          ...state.data,
          photo:action.payload?.photo
        }
      }
    default:
      return state
  }
}
