/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import Service from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../state/actions'
import { ShowAlert } from '../../Globals'
import { RootState } from '../../../state/store'
import { config } from '../../../config'

const ArticlesPanelForm = ({ create, edit, back, idArticle }: any) => {
  const [articlePanel, setArticlePanel] = useState<any>('')
  const [title, setTitle] = useState<any>('')
  const [linkTitle, setLinkTitle] = useState<any>('')
  const [linkUrlImage, setLinkUrlImage] = useState<any>('')
  const [resume, setResume] = useState<any>('')
  const [text, setText] = useState<any>('')
  const [enabled, setEnabled] = useState<any>(0)
  const [saveInsertValues, setInsertValues] = useState<any>({})
  const [saveUpdateValues, setUpdateValues] = useState<any>({})

  const dispatch = useDispatch()

  const intl = useIntl()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const token = useSelector<RootState, any>((state) => state.userAuth)

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.system.articles-panel.account' },
        { defaultMessage: 'Artículos de Home' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: !isToast
    })
  }

  const getOneArticlePanel = () => {
    Service.get<any>(`/Panel/GetOneArticlePanel?idArticle=${idArticle}`, {
      headers: {
        token: token.data.token
      }
    })
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setArticlePanel(response.data)
          setTitle(response.data.title)
          setLinkTitle(response.data.link)
          setLinkUrlImage(response.data.urlImage)
          setResume(response.data.resume)
          setText(response.data.text)
          setEnabled(response.data.enabled)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const insertArticlePanel = () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        token: token.data.token,
        username: user.data.username,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(saveInsertValues)
    }
    fetch(`${config.base_server_path}/Panel/InsertArticlePanel`, requestOptions)
      .then(async (response) => await response.json())
      .then((res) => {
        MyAlert(res.Message, 1)
        back()
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const updateArticlePanel = () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        token: token.data.token,
        username: user.data.username,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      },
      body: JSON.stringify(saveUpdateValues)
    }
    fetch(`${config.base_server_path}/Panel/UpdateArticlePanel`, requestOptions)
      .then(async (response) => await response.json())
      .then((res) => {
        MyAlert(res.Message, 1)
        back()
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  useEffect(() => {
    if (create == false) getOneArticlePanel()
  }, [create, edit])

  useEffect(() => {
    setInsertValues({
      title,
      link: linkTitle,
      resume,
      text,
      urlImage: linkUrlImage,
      enabled
    })
    setUpdateValues({
      id: idArticle,
      title,
      link: linkTitle,
      urlImage: linkUrlImage,
      resume,
      text,
      enabled
    })
  }, [title, linkTitle, linkUrlImage, resume, text, enabled])
  return (
    <>
      <div className="rule-config__wrapper sombra_caja">
        <div className="rule-config__title-container row">
          <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
          <div className="automation__wrapper">
            <div className="row">
              <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <div className="shelves__endpoint-selector-container">
                    <label className="shelves__endpoint-selector-text mb-2">
                      <FormattedMessage
                        id="app.system.articles-panel.title"
                        defaultMessage="Titulo"
                      />
                    </label>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                  <input
                    className="input vtexskuconsult__input col-lg-10 col-md-10 col-sm-10 col-xs-10"
                    value={title}
                    onChange={(e: any) => setTitle(e.target.value)}
                    placeholder={intl.formatMessage(
                      {
                        id: 'app.system.articles-panel.form.title-placeholder'
                      },
                      { defaultMessage: 'Título de artículo' }
                    )}
                  />
                </div>
              </div>
              <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <div className="shelves__endpoint-selector-container">
                    <label className="shelves__endpoint-selector-text mb-2">
                      <FormattedMessage
                        id="app.system.articles-panel.link-title"
                        defaultMessage="Link Titulo"
                      />
                    </label>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                  <input
                    className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                    value={linkTitle}
                    onChange={(e: any) => setLinkTitle(e.target.value)}
                    placeholder={intl.formatMessage(
                      {
                        id: 'app.system.articles-panel.form.link-title.placeholder'
                      },
                      {
                        defaultMessage:
                          'Link del artículo que se verá al presionar Leer más'
                      }
                    )}
                  />
                </div>
              </div>
              <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <div className="shelves__endpoint-selector-container">
                    <label className="shelves__endpoint-selector-text mb-2">
                      <FormattedMessage
                        id="app.system.articles-panel.link-url-image"
                        defaultMessage="Link de Imagen"
                      />
                    </label>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                  <input
                    className="input vtexskuconsult__input col-lg-4 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                    value={linkUrlImage}
                    onChange={(e: any) => setLinkUrlImage(e.target.value)}
                    placeholder={intl.formatMessage(
                      {
                        id: 'app.system.articles-panel.form.url-image.placeholder'
                      },
                      { defaultMessage: 'Url de la imagen' }
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    <FormattedMessage
                      id="app.system.articles-panel.resume"
                      defaultMessage="Resumen"
                    />
                  </label>
                </div>
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                <textarea
                  className="input vtexskuconsult__input vtexskuconsult__textarea col-lg-10 col-md-10 col-sm-10 col-xs-10"
                  value={resume}
                  onChange={(e: any) => setResume(e.target.value)}
                  placeholder={intl.formatMessage(
                    { id: 'app.system.articles-panel.form.resume.placeholder' },
                    { defaultMessage: 'Resumen no más de dos líneas' }
                  )}
                />
              </div>
              <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <div className="shelves__endpoint-selector-container">
                    <label className="shelves__endpoint-selector-text mb-2">
                      <FormattedMessage
                        id="app.system.articles-panel.text"
                        defaultMessage="Texto"
                      />
                    </label>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                  <textarea
                    className="input vtexskuconsult__input vtexskuconsult__textarea col-lg-10 col-md-10 col-sm-10 col-xs-10"
                    value={text}
                    onChange={(e: any) => setText(e.target.value)}
                    placeholder={intl.formatMessage(
                      { id: 'app.system.articles-panel.form.text.placeholder' },
                      { defaultMessage: 'Texto no más de 3 líneas' }
                    )}
                  />
                </div>
              </div>
              <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <div className="shelves__endpoint-selector-container">
                    <label className="shelves__endpoint-selector-text mb-2">
                      <FormattedMessage
                        id="app.system.articles-panel.enabled"
                        defaultMessage="Activado"
                      />
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <input
                    type={'checkbox'}
                    className="input vtexskuconsult__input col-lg-2 col-md-2 col-sm-2 col-xs-2"
                    onChange={(e: any) =>
                      setEnabled(e.target.checked === false ? 0 : 1)
                    }
                    // eslint-disable-next-line no-unneeded-ternary
                    checked={enabled === 0 ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row shelves button botonera">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
          <div className="shelves__preview-button">
            <div className="shelves__preview-button">
              <button
                onClick={
                  create == true ? insertArticlePanel : updateArticlePanel
                }
                className="ppal_button btn btn-primary"
                id="saveBrandPriority"
              >
                <FormattedMessage
                  id="app.related-categories.add-cross-selling.guardar"
                  defaultMessage="GUARDAR"
                />
              </button>
              <div className="shelves__preview-button">
                {Boolean(create) || Boolean(edit) ? (
                  <button
                    id={'btn-regresar'}
                    className="manage-grid-button shelves__title-icon"
                    onClick={() => {
                      back()
                    }}
                  >
                    <FormattedMessage
                      id="app.related-categories.add-cross-selling.volver"
                      defaultMessage="VOLVER"
                    />
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ArticlesPanelForm
