const CheckoutGreen = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="0 90 550 300"
      style={{ enableBackground: 'new 0 0 595.3 841.9' }}
      xmlSpace="preserve"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          
          d="M180,4860c-27-27-27-93,0-120c18-18,33-20,198-20h177l105-497c72-340,111-504,123-520c25-32,97-33,127-1   c27,29,26,56-5,196l-5,22l1767-2c1974-3,1797,4,1883-75c65-58,82-106,89-251c5-107,9-126,25-138c30-21,92-18,117,7   c19,19,20,30,17,167c-3,141-4,150-35,214c-41,88-113,160-201,201l-67,32l-1815,3l-1815,2l-83,383c-68,316-86,385-103,400   c-19,15-47,17-250,17C213,4880,199,4879,180,4860z"
        />
        <path
          
          d="M2556,3654c-283-63-524-251-651-509c-79-160-111-342-93-525c43-449,411-813,858-848c50-4,130-4,180,0   c450,36,819,402,859,855c22,254-49,495-204,690c-116,147-302,271-485,324C2920,3670,2660,3677,2556,3654z M2930,3484   c44-9,118-36,180-66c93-45,115-61,201-147s102-108,147-201c65-136,85-217,85-350c-1-133-20-215-85-350c-45-93-61-115-147-201   c-82-82-110-103-191-142c-129-64-228-88-360-88c-434,0-780,346-780,781c0,349,223,648,557,750C2669,3510,2780,3514,2930,3484z"
        />
        <path
          
          d="M2885,2820l-229-230l-122,120c-114,113-123,120-161,120c-52,0-83-31-83-84c0-36,10-48,146-187   c149-151,195-184,237-174c13,4,147,130,297,280l273,274l-6,38c-8,47-38,73-86,73C3117,3050,3101,3036,2885,2820z"
        />
        <path
          
          d="M858,3595c-45-25-48-63-18-205c15-69,116-543,224-1055c109-511,209-966,222-1010c36-115,93-206,193-305   c89-89,186-149,297-185l51-16l-30-57c-26-48-32-70-35-143c-3-63,0-100,12-137c97-291,490-341,655-84c65,102,75,225,27,344l-24,58   h648h648l-25-60c-32-81-33-191-2-275c31-82,121-173,202-203c79-30,195-30,274,0c80,29,172,121,201,201c31,82,30,201-2,280l-24,57   h284c271,0,285,1,304,20c24,24,27,87,6,116c-14,19-44,19-1518,24c-1674,6-1533,0-1688,76c-91,46-200,151-248,240   c-17,34-32,67-32,73c0,8,63,11,210,11c197,0,211,1,230,20c13,13,20,33,20,60s-7,47-20,60c-19,19-33,20-253,20h-233l-215,1017   c-167,796-218,1022-234,1040C939,3607,893,3614,858,3595z M2208,771c133-68,138-260,8-336c-73-43-185-28-237,31   c-67,77-64,213,7,275C2045,792,2141,805,2208,771z M4128,771c133-68,138-260,8-336c-73-43-185-28-237,31c-67,77-64,213,7,275   C3965,792,4061,805,4128,771z"
        />
        <path
          
          d="M4660,3260c-16-16-20-33-20-92c0-144-20-345-44-444c-61-248-183-468-365-658c-315-326-711-505-1188-536   c-82-5-286-10-455-10c-295,0-309-1-328-20c-28-28-27-93,1-121c20-20,25-21,487-16c462,4,468,4,591,31c738,160,1269,646,1428,1306   c22,90,26,135,30,321c5,211,5,217-16,238C4753,3287,4688,3288,4660,3260z"
        />
        <path
          
          d="M2020,1500c-13-13-20-33-20-60s7-47,20-60s33-20,60-20s47,7,60,20s20,33,20,60s-7,47-20,60s-33,20-60,20   S2033,1513,2020,1500z"
        />
      </g>
    </svg>
  )
}

export default CheckoutGreen
