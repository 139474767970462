import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  userAuthReducer,
  userLoginReducer,
  langSelectedReducer,
  clientSelectedReducer,
  scriptSelectedReducer,
  createAudiencesReducer
} from './reducers'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

export const config = {
  key: 'root',
  storage
}

const reducers = combineReducers({
  userLogin: userLoginReducer,
  userAuth: userAuthReducer,
  langSelected: langSelectedReducer,
  clientSelected: clientSelectedReducer,
  scriptSelected: scriptSelectedReducer,
  createAudiences: createAudiencesReducer
})

const middleware = [thunk]

const persisted = persistReducer(config, reducers)

const store = createStore(
  persisted,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store

export type RootState = ReturnType<typeof store.getState>
