import React, { useEffect, useState } from 'react'
import ScrollReveal from 'scrollreveal'
import anime from 'animejs'
import './style_mshop.css'
import ModalMshop from './ModalMshop'
import { FiCheckCircle, FiMinusCircle } from 'react-icons/fi'
import { Link } from 'react-router-dom'
const MshopContainer: React.FC = () => {
  useEffect(() => {
    const e = document.documentElement
    if (e !== null) {
      e.classList.remove('no-js')
      e.classList.add('js')
      const sr = ScrollReveal()
      sr.reveal('.ms_feature, .ms_pricing-table-inner', {
        duration: 600,
        distance: '20px',
        easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
        origin: 'bottom',
        interval: 100
      })

      e.classList.add('anime-ready')

      anime
        .timeline({
          targets: '.ms_hero-figure-box-05'
        })
        .add({
          duration: 400,
          easing: 'easeInOutExpo',
          scaleX: [0.05, 0.05],
          scaleY: [0, 1],
          perspective: '500px',
          delay: anime.random(0, 400)
        })
        .add({
          duration: 400,
          easing: 'easeInOutExpo',
          scaleX: 1
        })
        .add({
          duration: 800,
          rotateY: '-15deg',
          rotateX: '8deg',
          rotateZ: '-1deg'
        })

      // Resto del código...

      anime({
        targets:
          '.ms_hero-figure-box-01, .ms_hero-figure-box-02, .ms_hero-figure-box-03, .ms_hero-figure-box-04, .ms_hero-figure-box-08, .ms_hero-figure-box-09, .ms_hero-figure-box-10',
        duration: anime.random(600, 800),
        delay: anime.random(600, 800),
        rotate: [
          anime.random(-360, 360),
          function (e: any) {
            return e.getAttribute('data-rotation')
          }
        ],
        scale: [0.7, 1],
        opacity: [0, 1],
        easing: 'easeInOutExpo'
      })
    }
  }, [])
  const [openModal, setOpenModal] = useState(false)
  const handleModal = () => {
    setOpenModal(!openModal)
  }

  return (
    <div className="ms_has-animations">
      <div className="ms_body-wrap">
        <header className="ms_site-header">
          <div className="landing_ms_container">
            <div className="ms_site-header-inner">
              <div className="landing_ms_brand header-brand">
                <h1 className="m-0">
                  <a href="#">
                    <img
                      className="ms_header-logo-image"
                      src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/17/logo_claim.svg"
                      alt="Logo"
                    />
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </header>

        <main>
          <section className="ms_hero">
            <div className="landing_ms_container">
              <div className="ms_hero-inner">
                <div className="ms_hero-copy">
                  <h1 className="ms_hero-title mt-0">
                    Personaliza tus contenidos y aumenta tus ventas.
                  </h1>
                  <p className="ms_hero-paragraph">
                    Ofrece al usuario productos basados en sus intereses a
                    través de los carruseles de Brain y optimiza la experiencia
                    de navegación en tu tienda.
                  </p>
                  <div className="ms_hero-cta">
                    {/* <input type="email" name="email" placeholder="E-mail" /> */}
                    <Link
                      className="ms_button ms_button-primary"
                      to={'/mshop-tutorial'}
                      onClick={() => {
                        window.scroll({
                          top: 0,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }}
                    >
                      Comenzar Prueba Gratis
                    </Link>
                  </div>
                </div>
                <div className="ms_hero-figure anime-element">
                  <div className="ms_illust">
                    <img src="https://storage.cdn.braindw.com/pruebaio_produccion/script/files/2023/11/10/Teammeeting_TwoColor.svg" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ms_features section">
            <div className="landing_ms_container">
              <div className="ms_section-inner ms_has-bottom-divider">
                <div className="ms_features-wrap">
                  <div className="ms_feature text-center ms_is-revealing">
                    <div className="ms_feature-inner">
                      <div className="ms_feature-icon">
                        <img
                          src="https://storage.cdn.braindw.com/pruebaio_produccion/script/files/2024/05/07/icon01.svg"
                          alt="Feature 01"
                        />
                      </div>
                      <h4 className="ms_feature-title mt-3">Aumenta</h4>
                      <p className="ms_text-sm mb-0">
                        Tasa de conversión a ventas, ofreciendo contenido más
                        relevante.
                      </p>
                    </div>
                  </div>
                  <div className="ms_feature text-center ms_is-revealing">
                    <div className="ms_feature-inner">
                      <div className="ms_feature-icon">
                        <img
                          src="https://storage.cdn.braindw.com/pruebaio_produccion/script/files/2024/05/07/icon02.svg"
                          alt="Feature 02"
                        />
                      </div>
                      <h4 className="ms_feature-title mt-3">Personaliza</h4>
                      <p className="ms_text-sm mb-0">
                        La experiencia de navegación, adaptándola a los
                        intereses y al historial de cada usuario.
                      </p>
                    </div>
                  </div>
                  <div className="ms_feature text-center ms_is-revealing">
                    <div className="ms_feature-inner">
                      <div className="ms_feature-icon">
                        <img
                          src="https://storage.cdn.braindw.com/pruebaio_produccion/script/files/2024/05/07/icon03.svg"
                          alt="Feature 03"
                        />
                      </div>
                      <h4 className="ms_feature-title mt-3">Automatiza</h4>
                      <p className="ms_text-sm mb-0">
                        Carruseles con algoritmos de personalización y actívalos
                        en distintas secciones de tu tienda.
                      </p>
                      <p className="text_claim m-0">
                        **Pruébalo gratis durante 15 días y comprueba los
                        resultados. **
                      </p>
                    </div>
                  </div>
                  <div className="ms_hero-cta mt-3">
                    {/* <input type="email" name="email" placeholder="E-mail" /> */}
                    <Link
                      className="ms_button ms_button-primary"
                      to={'/mshop-tutorial'}
                      onClick={() => {
                        window.scroll({
                          top: 0,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }}
                    >
                      Comenzar Prueba Gratis
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ms_pricing section">
            <div className="landing_ms_container-sm">
              <div className="ms_pricing-inner ms_section-inner">
                <div className="ms_pricing-header text-center">
                  <h2 className="ms_section-title mt-0">
                    Pruébalo gratis durante 15 días y comprueba los resultados.
                  </h2>
                  <p className="ms_text-sm mb-0">
                    El período de prueba contiene todas las funcionalidades del
                    plan avanzado. Una vez concluído, podrás optar por
                    adquirirlo o por por trasladarte a un plan básico.
                  </p>
                </div>
                <div className="ms_pricing-tables-wrap">
                  <div className="ms_pricing-table">
                    <div className="ms_pricing-table-inner ms_is-revealing">
                      <div className="ms_pricing-table-main">
                        <div className="ms_pricing-table-features-title text-xs text-center mt-3 pb-3">
                          PLAN BÁSICO
                        </div>
                        <ul className="ms_pricing-table-features list-reset">
                          <li>
                            <FiCheckCircle color="#6529A1" />
                            <span>Carrusel últimos navegados en Home</span>
                          </li>
                          <li className="opacity-tag">
                            <FiMinusCircle color="#242424" />
                            <span>Carruseles personalizados en Home</span>
                          </li>
                          <li className="opacity-tag">
                            <FiMinusCircle color="#242424" />
                            <span>Carruseles personalizados en Categoría</span>
                          </li>
                          <li className="opacity-tag">
                            <FiMinusCircle color="#242424" />
                            <span>
                              Carruseles personalizados en Ficha de Producto
                            </span>
                          </li>
                          <li className="opacity-tag">
                            <FiMinusCircle color="#242424" />
                            <span>
                              Carruseles personalizados en Búsqueda Positiva
                            </span>
                          </li>
                          <li className="opacity-tag">
                            <FiMinusCircle color="#242424" />
                            <span>
                              Carruseles personalizados en Búsqueda Negativa
                            </span>
                          </li>
                        </ul>
                        <div className="ms_pricing-table-header mt-3 pb-3">
                          <div className="ms_pricing-table-price text-center">
                            <span className="ms_pricing-table-price-amount h1">
                              Gratis
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ms_pricing-table1">
                    <div className="ms_pricing-table-inner ms_is-revealing">
                      <div className="ms_pricing-table-main">
                        <div className="ms_pricing-table-features-title text-xs text-center mt-3 pb-3">
                          PLAN AVANZADO
                        </div>
                        <ul className="ms_pricing-table-features list-reset">
                          <li>
                            <FiCheckCircle color="#6529A1" />
                            <span>Carrusel últimos navegados en Home</span>
                          </li>
                          <li>
                            <FiCheckCircle color="#6529A1" />
                            <span>Carruseles personalizados en Home</span>
                          </li>
                          <li>
                            <FiCheckCircle color="#6529A1" />
                            <span>Carruseles personalizados en Categoría</span>
                          </li>
                          <li>
                            <FiCheckCircle color="#6529A1" />
                            <span>
                              Carruseles personalizados en Ficha de Producto
                            </span>
                          </li>
                          <li>
                            <FiCheckCircle color="#6529A1" />
                            <span>
                              Carruseles personalizados en Búsqueda Positiva
                            </span>
                          </li>
                          <li>
                            <FiCheckCircle color="#6529A1" />
                            <span>
                              Carruseles personalizados en Búsqueda Negativa
                            </span>
                          </li>
                        </ul>
                        <div className="ms_pricing-table-header mt-3 pb-3">
                          <div className="ms_pricing-table-price text-center">
                            <p className="text-xxs m-0">desde</p>
                            <span className="ms_pricing-table-price-amount h1">
                              $10.500
                            </span>
                            <span className="text-xs color_primary">/mes</span>
                          </div>
                          <div className="ms_pricing-table-price text-center"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ms_hero-cta">
                    {/* <input type="email" name="email" placeholder="E-mail" /> */}
                    <Link
                      className="ms_button ms_button-primary"
                      to={'/mshop-tutorial'}
                      onClick={() => {
                        window.scroll({
                          top: 0,
                          left: 0,
                          behavior: 'smooth'
                        })
                      }}
                    >
                      Comenzar Prueba Gratis
                    </Link>
                  </div>
                </div>
              </div>
              <div className="ms_cta-inner">
                <h2 className="ms_section-title mt-0">
                  Transforma hoy Brain y descubre el potencial de la
                  personalización.
                </h2>
              </div>
            </div>
          </section>
        </main>

        <footer className="ms_site-footer">
          <div className="landing_ms_container">
            <div className="ms_site-footer-inner">
              <div className="landing_ms_brand footer-brand">
                <a href="#">
                  <img
                    className="ms_header-logo-image mb-4"
                    src="https://storage.cdn.braindw.com/braindw_developer/script/files/2024/05/17/logo_claim.svg"
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="ms_footer-copyright">
                &copy; 2024 Brain, todos los derechos reservados.
              </div>
            </div>
          </div>
        </footer>
      </div>
      {openModal && (
        <ModalMshop
          title="No tienes dominio delegado"
          msjError="Para instalar BrainDW en MercadoShops necesitarás obtener un dominio propio."
          msjRecomendation="Te recomendamos revisar el siguiente enlace para aprender más."
          btnText="Volver a la tienda"
          handleModal={handleModal}
        />
      )}

      <script src="dist/js/main.min.js"></script>
    </div>
  )
}

export default MshopContainer
