/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { TooltipPanel } from '../../../Globals/TooltipPanel'
import { useSelector } from 'react-redux'
import Service from '../../../../services'
import { RootState } from '../../../../state/store'
import Select from 'react-select'
import { useClientContext } from '../../../../contexts/ClientContext'

const RadioBtn: React.FC<{
  title: string
  values: any
  data: any
  idInput: any
  sectionId: any
  idEndpoint: any
}> = ({
  values,
  title,
  data,
  idInput,
  sectionId,
  idEndpoint
}) => {
  const intl = useIntl()
  const [rdBtn, setrdBtn] = useState(data.defaultValue)
  const valueRdBtn = data.options.map((e: any) => {
    return e.value
  })
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const handledBtn = (event: any) => {
    setrdBtn(event.target.value)
    if (event.target.value == '') {
      setToggleRadBtnManual(true)
    } else {
      setToggleRadBtnManual(false)
    }
  }

  const handleInput = (event: any) => {
    setrdBtn(event.target.value)
  }

  const valueParams = values
    .map((item: any) => {
      if (item[0] == idInput) {
        return item
      }
    })
    .filter((x: any) => x !== undefined)[0]

  const [categoriesArrayObj, setCategoriesArrayObj] = useState<any>([])
  const [loadingCategories, setLoadingCategories] = useState(false)

  const categoriesArray: Array<{
    value: any
    label: any
    disabled: any
  }> = []

  const [categoryValue, setCategoryValue] = useState<any>(
    valueParams == undefined ? '<%IDCATEGORY%>' : valueParams[1]
  )
  const [crossCategory, setCrossCategory] = useState<string>('')
  const handleChange = (event: any) => {
    setCategoryValue(event.value)
    setCrossCategory('')
  }
  const inputOnChangeCategory = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCategoryValue(event.target.value)
    setCrossCategory('')
  }

  const setValuesRdBtn = () => {
    if (valueRdBtn.includes(rdBtn) === true) {
      setToggleRadBtnManual(false)
    } else {
      setToggleRadBtnManual(true)
    }
  }

  const { selectedScript } = useClientContext()
  useEffect(() => {
    setValuesRdBtn()
    setLoadingCategories(true)
    Service.get<any>(
      `/ConfigurationRule/GetAllCategoriesVtex?idHashScript=${selectedScript?.idHashScript}`,
      { headers: { token: user.data.token } }
    ).then((response) => {
      if (Object.entries(response.data).length > 0) {
        categoriesArray.push({
          value: '<%IDCATEGORY%>',
          label: intl.formatMessage(
            { id: 'app.related-categories.add-cross-selling.select-category' },
            { defaultMessage: 'Seleccionar Categoria' }
          ),
          disabled: false
        })
      }

      response.data.forEach((item: any, index: any) =>
        categoriesArray.push({
          value: item.id,
          label: item.categoryPathString,
          disabled: false
        })
      )
      setCategoriesArrayObj(categoriesArray)
      setLoadingCategories(false)
    })
  }, [selectedScript])

  const [toggleRadBtnManual, setToggleRadBtnManual] = useState(false)

  return (
    <div className="rule-config-subComponents__container">
      <div className="row">
        <h5 className="col-lg-6 col-md-6 col-sm-6 col-xs-6 vtexskuconsult__text_modal align-left">
          {title}
          <TooltipPanel 
            ObjectTooltip={user.data.tooltips.filter((
              (t:any) => t.idTooltip === `app.carrousel.rules.tooltip.RadioBtn.idEndpoint.${idEndpoint}.title.tooltip`)
            )[0]}
          />          
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 vtexskuconsult__text_modal_g">
          <form>
            {Object.entries(categoriesArrayObj).length > 0 ? (
              <div className="row mt-2 mb-2 ai-baseline">
                <div className=" col-lg-1 col-md-1 col-sm-1 col-xs-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radiobtn"
                    onChange={(e) => handledBtn(e)}
                    value={'radiobtn-category'}
                  />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                  <Select
                    className="basic-single select2-categos-sw "
                    classNamePrefix="select"
                    defaultValue={
                      valueParams !== undefined
                        ? categoriesArrayObj[valueParams[1]]
                        : categoriesArrayObj[0]
                    }
                    isDisabled={false}
                    isLoading={loadingCategories}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="categoriasSlt"
                    id="categoriasSlt"
                    options={categoriesArrayObj}
                    placeholder={intl.formatMessage(
                      {
                        id: 'app.related-categories.add-cross-selling.select-category'
                      },
                      { defaultMessage: 'Seleccionar Categoria' }
                    )}
                    onChange={handleChange}
                  />
                </div>
                <div className=" col-lg-1 col-md-1 col-sm-1 col-xs-1">
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.RadioBtn.select.tooltip')
                  )[0]}
                />                               
                </div>
              </div>
            ) : (
              ''
            )}
            {data.options.map((opt: any) => {
              return (
                <div
                  className=" row mt-2 mb-2 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  key={opt.value}
                >
                  <div className=" col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={opt.value}
                      name="radiobtn"
                      onChange={(e) => handledBtn(e)}
                      checked={rdBtn === opt.value}
                    />
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    <label className="form-check-label ml_10">
                      {opt.label}
                    </label>                    
                  </div>
                  <div className=" col-lg-1 col-md-1 col-sm-1 col-xs-1" style={{marginLeft: '20px'}}>
                  <TooltipPanel 
                    ObjectTooltip={user.data.tooltips.filter((
                      (t:any) => t.idTooltip === `app.carrousel.rules.tooltip.RadioBtn.input.idEndpoint.${idEndpoint}.parameter.${idInput}.tooltip`)
                    )[0]}
                  />                    
                  </div>
                </div>
              )
            })}
            <div className=" row mt-2 mb-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className=" col-lg-1 col-md-1 col-sm-1 col-xs-1">
                <input
                  className="form-check-input"
                  type="radio"
                  value={''}
                  name="radiobtn"
                  onChange={(e) => handledBtn(e)}
                  checked={toggleRadBtnManual}
                />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                <label className="form-check-label ml_10">
                  {intl.formatMessage(
                    {
                      id: 'app.carrousel.rules.tooltip.RadioBtn.input.cargaManual'
                    },
                    { defaultMessage: 'Carga Manual' }
                  )}
                </label>                
              </div>
              <div className=" col-lg-1 col-md-1 col-sm-1 col-xs-1" style={{marginLeft: '20px'}}>
              <TooltipPanel 
                ObjectTooltip={user.data.tooltips.filter((
                  (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.RadioBtn.input.tooltip.cargaManual')
                )[0]}
              />
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 automation__title-container ai-baseline">
          {rdBtn == 'radiobtn-category' ? (
            <input
              className="input_form_radBton mt-2 mb-2"
              id={idInput}
              type="text"
              defaultValue={valueParams !== undefined ? valueParams[1] : ''}
              value={`${crossCategory}` + `${categoryValue}`}
              onChange={inputOnChangeCategory}
            />
          ) : (
            <input
              type="text"
              className={
                toggleRadBtnManual
                  ? 'input_form_radBton mt-2 mb-2'
                  : 'input_form_radBton mt-2 mb-2 disabled-input-modal'
              }
              value={rdBtn}
              id={idInput}
              onChange={(e) => handleInput(e)}
            />
          )}
          <TooltipPanel 
            ObjectTooltip={user.data.tooltips.filter((
              (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.RadioBtn.select.input.tooltip')
            )[0]}
          />          
        </div>
      </div>
    </div>
  )
}

export default RadioBtn
