import React from 'react'
import MenuContainer from '../../Menu/MenuContainer'
import MenuOptions from '../../Menu/MenuOptions'
import { SubMenuSmartImagesOptions } from '../../../utils/secondMenuOptions'
// import { RootState } from '../../../state/store'
// import { useSelector } from 'react-redux'

const SubMenuSmartImages = () => {
  const handleAction = () => {
    console.log()
  }
  // const user = useSelector<RootState, any>((state) => state.userAuth)

  return (
    <>
      <MenuContainer>
        <MenuOptions
          tag="BrainDW | System"
          options={SubMenuSmartImagesOptions}
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default SubMenuSmartImages
