import { useEffect, useState } from 'react'
import { AiOutlineArrowRight } from '../../utils/icons'
import { Modal } from '../Globals/Modal'
import { FormattedMessage, useIntl } from 'react-intl'

const TokenModal = ({
  isOpen,
  toggleModal,
  checkbox,
  setToken,
  responseStatus,
  onClick
}: any) => {
  const [errorMessage, setErrorMessage] = useState<any>('')
  const [inputValue, setInputValue] = useState<any>('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setToken(event.target.value)
  }
  const intl = useIntl()

  useEffect(() => {
    setInputValue('')
  }, [isOpen])

  const handleClick = (event: any) => {
    event.preventDefault()
    setInputValue('')
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          toggleModal()
          setErrorMessage('')
          setInputValue('')
        }}
        title={intl.formatMessage(
          { id: 'app.modal.ingrese.token.title' },
          { defaultMessage: 'Ingrese el token' }
        )}
        myWidth={'50%'}
        content={''}
      >
        <form onSubmit={handleClick}>
          <div>
            <span style={{ fontSize: 'larger', opacity: '75%' }}>
              <FormattedMessage
                id="app.modal.ingrese.token.texto"
                defaultMessage="Cada vez que modifiques contenido del sitio, y por razones de seguridad, es necesario que valides el código que la app 'Google Authenticator' te da, luego haz click en Enviar"
              />
            </span>
          </div>
          <div className="input-group-bdw" style={{ padding: '20px' }}>
            <div
              className="nk-int-st"
              style={{ width: '50%', marginRight: '10px' }}
            >
              <input
                name="token"
                type="number"
                value={inputValue}
                inputMode="numeric"
                className="form-control"
                placeholder="Token"
                maxLength={6}
                onChange={handleChange}
              />
            </div>
            {/* <span className="input-group-addon nk-ic-st-pro">
                            <BsCheck2Square className="bdw-icon-user" />
                        </span> */}
            <button
              type="submit"
              id="btn-login-token"
              className="btn btn-login btn-token-verde btn-success btn-float"
              onClick={onClick}
            >
              <AiOutlineArrowRight className="bdw-icon-arrow" />
            </button>
          </div>
          {errorMessage !== '' ? (
            <div style={{ color: '#e92e2ec9' }}>
              <span>{errorMessage}</span>
            </div>
          ) : (
            ''
          )}
        </form>
      </Modal>
    </>
  )
}

export default TokenModal
