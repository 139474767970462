/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import {
  HomeLogoSvg,
  PDPLogoSvg,
  PLPLogoSvg,
  PurchaseLogoSvg,
  SearchLogoSvg,
  ShowAlert
} from '../../Globals'
import ShelvesOptionsGridTable from './ShelvesOptionsGridTable'
import Pagination from '../../Globals/Pagination'
import { BsSearch } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import Service from '../../../services'
import FormCarrusel from './FormCarrusel'
import { logout } from '../../../state/actions'
import CatalogoLogoSvg from '../../Globals/svg/CatalogoLogoSvg'
import CheckoutGreen from '../../Globals/svg/CheckoutGreen'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import VitrinasShelvesGridTable from './VitrinasShelvesGridTable'
import { useClientContext } from '../../../contexts/ClientContext'

const ShelvesOptionsGrid = ({
  shelves,
  handlerDataReloadPage,
  handlerShowIframe
}: any) => {
  const [section, setSection] = useState<string>('')
  const [option, setOption] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [optionsPerPage, setOptionsPerPage] = useState<number>(25)
  const [editarCarrusel, setEditarCarrusel] = useState<boolean>(false)
  const [showGridVitrinas, setShowGridVitrinas] = useState<boolean>(false)
  const [infoEdit, setInfoEdit] = useState<any>({})
  const [textSearch, setTextSearch] = useState('')
  const [doReloadGrid, setDoReloadGrid] = useState('')
  const [newOptions, setNewOptions] = useState<any>([])

  const [optionSelected, setOptionSelected] = useState<any>()

  const token = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const [viewSpinner, setViewSpinner] = useState<boolean>(false)

  const dispatch = useDispatch()
  const intl = useIntl()

  const tableHeaders = [
    {
      id: 0,
      name: intl.formatMessage(
        { id: 'app.vitrinas.table.column1' },
        { defaultMessage: 'TITULO' }
      )
    },
    {
      id: 1,
      name: intl.formatMessage(
        { id: 'app.system.user.panel.table.active' },
        { defaultMessage: 'Estado' }
      ).toUpperCase()
    },
    {
      id: 2,
      name: intl.formatMessage(
        { id: 'app.vitrinas.table.column2-1' },
        { defaultMessage: 'REGLA' }
      )
    },
    {
      id: 3,
      name: intl.formatMessage(
        { id: 'app.vitrinas.table.column3' },
        { defaultMessage: 'ID' }
      )
    },
    {
      id: 4,
      name: intl.formatMessage(
        { id: 'app.vitrinas.table.column4' },
        { defaultMessage: 'ACCIONES' }
      )
    }
  ]

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const tryRequire = (path: string) => {
    if (path.includes('Home')) {
      return <HomeLogoSvg />
    } else if (path.includes('Product')) {
      return <PDPLogoSvg />
    } else if (path.includes('PLP')) {
      return <PLPLogoSvg />
    } else if (path.includes('Category')) {
      return <CatalogoLogoSvg />
    } else if (path.includes('Checkout')) {
      return <CheckoutGreen />
    } else if (path.includes('Collection')) {
      return <PurchaseLogoSvg />
    } else if (path.includes('Search')) {
      return <SearchLogoSvg />
    } else {
      return <PurchaseLogoSvg />
    }
  }

  const handleShelvesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const button: HTMLButtonElement = event.currentTarget
    setSection(button.name)
    const otherButtons = document.querySelectorAll('.botones_target')
    ;[].forEach.call(otherButtons, function (el: any) {
      el.classList.remove('sectionBackground')
    })
    button.classList.add('sectionBackground')
    handlerShowIframe('')
    setTextSearch('')
  }

  const editCarrusel = (e: any) => {
    setEditarCarrusel(!editarCarrusel)
    setInfoEdit(e)
  }

  const DataReloadGrid = (e: string) => {
    setDoReloadGrid('recargar')
  }

  const indexOfLastOption = currentPage * optionsPerPage
  const indexOfFirstOption = indexOfLastOption - optionsPerPage
  const currentOptions = option.slice(indexOfFirstOption, indexOfLastOption)

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const paginationControl = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const button: HTMLButtonElement = event.currentTarget
    if (
      button.name === 'siguiente' &&
      currentPage < Math.ceil(option.length / optionsPerPage)
    ) {
      setCurrentPage(currentPage + 1)
    }

    if (button.name === 'anterior' && currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleSetOptionsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const number = parseInt(event.target.value)
    setOptionsPerPage(number)
    setCurrentPage(1)
  }

  const searchTable = (search: string) => {
    const filterOptions = option
    const pushOptions: any[] = []
    filterOptions
      .filter((item: { action: any | string[] }) => {
        if (search === '') return true
        if (
          Boolean(
            item.action.template_titulo
              .toLowerCase()
              .includes(search.toLowerCase())
          ) ||
          Boolean(
            item.action.template_link_boton
              .toLowerCase()
              .includes(search.toLowerCase())
          ) ||
          Boolean(
            item.action.template_bwsection
              .toLowerCase()
              .includes(search.toLowerCase())
          ) ||
          Boolean(
            item.action.template_appendClass
              .toLowerCase()
              .includes(search.toLowerCase())
          )
        ) {
          return true
        }
        return false
      })
      .map((item: any) => {
        pushOptions.push(item)
      })
    setNewOptions(pushOptions)
  }

  const handlerShowGridVitrinas = (active: boolean, item: any) => {
    setShowGridVitrinas(active)
    setOptionSelected(item)
  }

  const reloadData = () => {
    setViewSpinner(true)
      Service.get<any>(
        `/ManageCarrousel/GetContentsByTargetGondole?idHashScript=${selectedScript?.idHashScript}&target=${section}`,
        { headers: { token: token.data.token } }
      )
        .then((response) => {
          if (response.headers.statuscode === '0') {
            setOption(response.data)
            setNewOptions(response.data)
            setCurrentPage(1)

            const otherButtons = document.querySelectorAll('.botones_target')
            ;[].forEach.call(otherButtons, function (el: any) {
              if (el.name === section) {
                el.classList.add('sectionBackground')
              }
            })

            if (textSearch !== '') {
              searchTable(textSearch)
            } else {
              setNewOptions([])
            }
          } else {
            setOption('')
          }
          setViewSpinner(false)
        })
        .catch((error) => {
          if (error.response.data === -6) {
            dispatch(logout(token.data.token))
          } else {
            MyAlert('API-ERROR: ' + `${error}`, 3)
          }
        })
      setNewOptions(option)
  }

  useEffect(() => {
    if (section !== '' && !editarCarrusel && textSearch === '' ) {
      setViewSpinner(true)
      Service.get<any>(
        `/ManageCarrousel/GetContentsByTargetGondole?idHashScript=${selectedScript?.idHashScript}&target=${section}`,
        { headers: { token: token.data.token } }
      )
        .then((response) => {
          if (response.headers.statuscode === '0') {
            setOption(response.data)
            setNewOptions(response.data)
            setCurrentPage(1)

            const otherButtons = document.querySelectorAll('.botones_target')
            ;[].forEach.call(otherButtons, function (el: any) {
              if (el.name === section) {
                el.classList.add('sectionBackground')
              }
            })

            if (textSearch !== '') {
              searchTable(textSearch)
            } else {
              setNewOptions([])
            }
          } else {
            setOption('')
          }
          setViewSpinner(false)
        })
        .catch((error) => {
          if (error.response.data === -6) {
            dispatch(logout(token.data.token))
          } else {
            MyAlert('API-ERROR: ' + `${error}`, 3)
          }
        })
      setNewOptions(option)
    }
  }, [section, textSearch, editarCarrusel, doReloadGrid])

  useEffect(() => {
    if (doReloadGrid !== '') {
      reloadData()
    }
  }, [doReloadGrid])


  return (
    <>
      {editarCarrusel ? (
        <FormCarrusel
          editar={editCarrusel}
          dataCarrusel={infoEdit}
          handlerDataReloadPage={handlerDataReloadPage}
          handlerTextSearch={setTextSearch}
        />
      ) : showGridVitrinas ? (
        <>
          <VitrinasShelvesGridTable
            options={
              textSearch !== ''
                ? newOptions.slice(0, 5)
                : currentOptions.slice(0, 5)
            } // DARILO. TEMPORAL. SOLO 5 ELEMENTOS
            headers={tableHeaders}
            editar={editCarrusel}
            classLoading={viewSpinner ? 'spinner_opacity' : ''}
            optionSelected={optionSelected}
            fncShowGridVitrinas={setShowGridVitrinas}            
          />
        </>
      ) : (
        <>
          <div className="row">
            {shelves
              ? shelves.map((opt: any) => {
                  let id = 0
                  const key = Object.keys(opt)[0]
                  const value = opt[key]
                  return (
                    <button
                      key={id++}
                      name={key}
                      value={key}
                      onClick={handleShelvesClick}
                      className="section-box shelf-inner botones_target sombra-generica border-boton"
                    >
                      <span className="counter">{value}</span>
                      {tryRequire(key)}
                    </button>
                  )
                })
              : ''}
          </div>
          {option.length >= 1 ? (
            <>
              <div className="show-options row mt-4">
                <div
                  className="col col-lg-6 col-md-6 col-sm-6 col-xs-6"
                  style={{ marginTop: '10px;' }}
                >
                  <p className="show-options__text">
                    <FormattedMessage
                      id="app.vitrinas.mostrar"
                      defaultMessage="MOSTRAR"
                    />
                    <select
                      onChange={handleSetOptionsPerPage}
                      className="show-options__selector select_paginas"
                      name="options-per-page"
                      id="options-per-page"
                      value={optionsPerPage}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    <FormattedMessage
                      id="app.vitrinas.entradas"
                      defaultMessage="ENTRADAS"
                    />
                  </p>
                </div>
                <div
                  className="col col-lg-6 col-md-6 col-sm-6 col-xs-6"
                  style={{ textAlign: 'right' }}
                >
                  {viewSpinner ? (
                    <div className="spinner_v">
                      <div
                        id="spinner"
                        className="spinner-border text-success "
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                      <small className="text-success spinner_text">
                        <FormattedMessage
                          id="app.vitrinas-spinner.espere"
                          defaultMessage="Espere..."
                        />
                      </small>
                    </div>
                  ) : null}

                  <input
                    type="text"
                    id="inp_text_search"
                    className="box-searchfor"
                    value={textSearch}
                    onChange={(e: any) => {
                      setTextSearch(e.target.value)
                      searchTable(e.target.value)
                    }}
                    placeholder={intl.formatMessage(
                      { id: 'app.vitrinas.buscar' },
                      { defaultMessage: 'BUSCAR POR...' }
                    )}
                    data-tip={useIntl().formatMessage(
                      { id: 'app.vitrinas.buscar' },
                      { defaultMessage: 'BUSCAR POR...' }
                    )}
                  ></input>
                  <BsSearch title="Buscar vitrina" size={25} />
                  <ReactTooltip
                    delayShow={10}
                    data-backgroundColor={'#2fad7e'}
                  />
                </div>
              </div>
              <div>
                <ShelvesOptionsGridTable
                  options={textSearch !== '' ? newOptions : currentOptions}
                  headers={tableHeaders}
                  editar={editCarrusel}
                  classLoading={viewSpinner ? 'spinner_opacity' : ''}
                  sectionName={section}
                  fncShowGridVitrinas={handlerShowGridVitrinas}
                  DataReloadGrid={DataReloadGrid}
                />
              </div>
              <div className="main-pagination-container">
                <div className="pagination-container">
                  <h6 className="pagination-container__text">
                    {intl.formatMessage(
                      { id: 'app.audiencias-tabla.mostrando' },
                      { defaultMessage: 'Mostrando' }
                    ) +
                      ` ${
                        textSearch !== ''
                          ? newOptions.length
                          : currentOptions.length
                      } ` +
                      intl.formatMessage(
                        { id: 'app.audiencias-tabla.de' },
                        { defaultMessage: 'De' }
                      ) +
                      ` ${
                        textSearch !== '' ? newOptions.length : option.length
                      }`}
                  </h6>
                </div>
                <div className="pagination-container">
                  <button
                    className="pagination-control"
                    name="siguiente"
                    onClick={paginationControl}
                  >
                    <h6 className="pagination-container__text">
                      <FormattedMessage
                        id="app.vitrinas.siguiente"
                        defaultMessage="Siguiente"
                      />
                    </h6>
                  </button>
                  <Pagination
                    optionsPerPage={optionsPerPage}
                    totalOptions={
                      textSearch !== '' ? newOptions.length : option.length
                    }
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                  <button
                    className="pagination-control"
                    name="anterior"
                    onClick={paginationControl}
                  >
                    <h6 className="pagination-container__text">
                      <FormattedMessage
                        id="app.vitrinas.anterior"
                        defaultMessage="Anterior"
                      />
                    </h6>
                  </button>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </>
      )}
    </>
  )
}

export default ShelvesOptionsGrid
