import { useCallback } from 'react'
import { useController } from 'react-hook-form'
import AsyncSelect from 'react-select/async'

interface InputOption {
  readonly value: string
  readonly label: string
}

interface Props {
  isMulti?: boolean
  options?: InputOption[]
  placeholder?: string
  name: string
  promiseOptions: any // (inputValue: string) => Promise<any>
}

const InputSelectCustom = (props: Props) => {
  const {
    field: { onChange, name, value },
    formState: { errors }
  } = useController({ name: props.name })
  const handleChange = useCallback(
    (e: any) => {
      const isDuplicated = value.some(
        (val: InputOption) => val.value === e.value
      )
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!isDuplicated && !props.isMulti) {
        onChange([...value, e])
        return
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (props.isMulti) {
        onChange([...e])
      }
    },
    [value, props.isMulti]
  )

  return (
    <AsyncSelect
      isMulti={props.isMulti}
      cacheOptions
      name={name}
      defaultOptions={props.options}
      loadOptions={props.promiseOptions}
      placeholder={props.placeholder}
      styles={{
        control: (prevStyles) => ({
          ...prevStyles,
          minHeight: '36.5px',
          border: `1px solid ${errors[name] != null ? '#DB164B' : '#ced4da'}`,
          height: '36.5px',
          backgroundColor: '#fff !important'
        }),
        valueContainer: (prevStyles) => ({
          ...prevStyles,
          padding: '0px 8px'
        }),
        placeholder: (prevStyles) => ({
          ...prevStyles,
          fontSize: 14,
          fontStyle: errors[name] != null ? 'italic' : 'normal',
          color: errors[name] != null ? '#DB164B' : 'hsl(0, 0%, 50%)'
        })
      }}
      onChange={(e) =>
        value != undefined
          ? handleChange(e)
          : props.isMulti ?? false
          ? onChange(e)
          : onChange([e])
      }
    />
  )
}

export default InputSelectCustom
