import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import Service from '../services'
import { useClientContext } from './ClientContext'

type PlansNameType = 'Basico' | 'Trial' | 'Avanzado'

interface PlanStatusProps {
  Address: string
  City: string
  CompanyName: string | null
  ExpirationDate: string | null
  IdPlan: number
  PlanName: PlansNameType
  NextPaymentDate?: string | null
}

interface MshopContextProviderProps {
  children: ReactNode
}

interface MshopContextProps {
  planStatus: PlanStatusProps | null
}
export const MshopContext = createContext<MshopContextProps | null>(null)

export const MshopContextProvider = ({
  children
}: MshopContextProviderProps) => {
  const [planStatus, setPlanStatus] = useState<PlanStatusProps | null>(null)
  const { selectedClient } = useClientContext()
  // const client = useSelector<RootState, Client>((state) => state.clientSelected)
  const getStatus = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!selectedClient) {
        return
      }
      const { data } = await Service.get<any>(
        `/Mshop/planDetail?idClient=${selectedClient.idClient}`
      )
      if (data.Status === 'Ok') {
        setPlanStatus(data.Response)
      }
    } catch (error) {
      console.log('error fetching plan details')
    }
  }, [selectedClient])

  useEffect(() => {
    getStatus()
  }, [selectedClient])

  return (
    <MshopContext.Provider value={{ planStatus }}>
      {children}
    </MshopContext.Provider>
  )
}

export const useMshopContext = () => {
  const context = useContext(MshopContext)
  if (context === undefined || context === null) {
    throw new Error('there is something wrong with mshop context')
  }
  return context
}
