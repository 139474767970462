/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { BsFillCreditCard2BackFill } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'

import { RootState } from '../../../state/store'
import Service from '../../../services'
import FacturacionInfo from './FacturacionInfo'
import FacturacionTable from './FacturacionTable'
import { useMshopContext } from '../../../contexts/MshopContext'
import { ContainerIcon, PlanTextHeader, RowWrapper } from './Plans'

export enum PlansOptions {
  BASIC = 'BASIC',
  ADVANCED_MONTH = 'ADVANCED_MONTH',
  ADVANCED_SEMI_ANNUAL = 'ADVANCED_SEMI_ANNUAL',
  TRIAL = 'TRIAL'
}

export enum PlansPrice {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  SEMI_ANNUAL = 'SEMI_ANNUAL'
}

const MshopContainerFacturacion = () => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const navigate = useNavigate()
  const { planStatus } = useMshopContext()

  const formatDate = useCallback((date: any) => {
    const fechaOriginal = new Date(date)
    const dia = fechaOriginal.getDate()
    const mes = fechaOriginal.getMonth() + 1
    const anio = fechaOriginal.getFullYear()
    return `${dia}/${mes}/${anio}`
  }, [])

  useEffect(() => {
    Service.get<any>(`/Account/ProfileStatus`, {
      headers: { token: user.data.token }
    })
      .then((response) => {
        if (
          response.data.Status !== 'OK' &&
          response.data.Response.Platform === 'MercadoShop' &&
          response.data.Response.Message !== 'Los datos están completos'
        ) {
          navigate('/dashboardmshop/profile')
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [])
  return (
    <>
      <div className="container mg-t-30">
        {planStatus && (
          <RowWrapper className="row">
            <div
              className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex"
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div
                className="col col-xs-4 d-flex"
                style={{ alignItems: 'center' }}
              >
                <ContainerIcon>
                  <BsFillCreditCard2BackFill size={'30px'} color="white" />
                </ContainerIcon>
                <PlanTextHeader>
                  <FormattedMessage
                    id="app.facturacion.title"
                    defaultMessage="FACTURACIÓN"
                  />
                </PlanTextHeader>
              </div>
              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 d-flex"
                style={{ alignItems: 'center', justifyContent: 'end' }}
              >
                {(planStatus?.ExpirationDate ??
                  planStatus?.NextPaymentDate) && (
                  <p className="mr-2" style={{ margin: 0 }}>
                    {planStatus?.ExpirationDate
                      ? 'Se expira el'
                      : 'Se Renueva el'}{' '}
                    <span style={{ color: '#00868B', fontWeight: 'bold' }}>
                      {formatDate(
                        planStatus?.ExpirationDate ??
                          planStatus?.NextPaymentDate
                      )}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </RowWrapper>
        )}
        <FacturacionInfo detailsPlan={planStatus} />
        <FacturacionTable user={user.data} />
      </div>
    </>
  )
}

export default MshopContainerFacturacion
