/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect, useState } from 'react'
import { BsPencilSquare } from 'react-icons/bs'
import './automation.css'
import '../Global.css'
import CreateAudiences from './CreateAudiences'
import AudiencesTable from './AudiencesTable'
import { FormattedMessage, useIntl } from 'react-intl'
import { Loading } from '../../Globals/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import { logout } from '../../../state/actions'
import Service from '../../../services'
import { ShowAlert } from '../../Globals'
import { useClientContext } from '../../../contexts/ClientContext'

const AutomationContainer = () => {
  const [newAudience, setNewAudience] = useState(false)
  const [isDbExist, setDbExist] = useState(true)
  const [loading, setLoading] = useState(false)
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()
  const dispatch = useDispatch()
  const intl = useIntl()

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: !isToast
    })
  }

  useEffect(() => {
    setLoading(true)
    Service.get<any>(
      `Automation/IsExistDbAutomation?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          token: token.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          if (response.data.StatusCode === 0) {
            setDbExist(true)
          } else {
            setDbExist(false)
          }
          setLoading(false)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + error, 3)
        }
      })
  }, [isDbExist])

  return (
    <>
      <div className="container mg-t-30">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="automation__title-container">
              <BsPencilSquare className="manage-grid-button automation__title-icon" />
              <h1 className="automation__title-text">
                <FormattedMessage
                  id="app.audiencias"
                  defaultMessage="AUDIENCIAS"
                />
              </h1>
            </div>
            <div className="container mg-t-0">
              <p>
                {intl.formatMessage(
                  { id: 'app.tooltips.header.p.audiencias' },
                  { defaultMessage: 'Manejo de carruseles' }
                )}
              </p>
            </div>
          </div>
        </div>
        {
          isDbExist ? <CreateAudiences newAudience={setNewAudience} /> : ''
          // <div className="alert alert-warning" role="alert">
          //     <FormattedMessage id="app.submenu.audiencias.sin.resultados" defaultMessage="No existe la Base de datos Automation"/>
          //   </div>
        }
      </div>

      {loading ? (
        <div className="container mg-t-30">
          <Loading
            textLoading={intl.formatMessage(
              { id: 'app.vitrinas-spinner.espere' },
              { defaultMessage: 'Espere...' }
            )}
          />
        </div>
      ) : null}

      <div className="container mg-t-30">
        {isDbExist ? (
          <AudiencesTable
            newAudience={newAudience}
            setNewAudience={setNewAudience}
          />
        ) : null}
      </div>
    </>
  )
}

export default AutomationContainer
