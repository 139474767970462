/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-dynamic-delete */
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import EditSpecificationsOrigin from './EditSpecificationsOrigin'
import EditSpecificationsDestiny from './EditSpecificationsDestiny'
import { ShowAlert } from '../../Globals'
import { config } from '../../../config'
import { BsAsterisk } from 'react-icons/bs'
import { Loading } from '../../Globals/Loading'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const CrossSellingForm = ({ id, back }: any) => {
  const [categories, setCategories] = useState<any>([])
  const [categoriesArrayObj, setCategoriesArrayObj] = useState<any>([])
  const [oneRelatedCategorie, setOneRelatedCategorie] = useState<any>('')
  const [section, setSection] = useState<any>(
    oneRelatedCategorie.Section !== null
      ? oneRelatedCategorie.Section
      : 'Select'
  )
  const [brandsByIdCategory, setBrandsByIdCategory] = useState<any>([])
  const [specificationByCategory, setSpecificationByCategory] = useState<any>(
    []
  )
  const [specificationByCategoryDestiny, setSpecificationByCategoryDestiny] =
    useState<any>([])
  const [categoryOriginsValues, setCategoryOriginsValues] = useState<any>([])
  const [categoryDestinyValues, setCategoryDestinyValues] = useState<any>([])
  const [tokenValue, setTokenValue] = useState<any>('')
  const [categoryNotExist, setCategoryNotExist] = useState<any>(false)
  const [loading, setLoading] = useState(false)
  const [loadingCategories, setLoadingCategories] = useState(false)
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()
  const dispatch = useDispatch()
  const intl = useIntl()

  const categoriesArray: Array<{
    value: any
    label: any
    disabled: any
  }> = []

  const asArray = Object.entries(categoryDestinyValues)
  const filtered = asArray.filter(([key]) => key === 'DestinationSpecification')
  if (filtered.length > 0) {
    Object.assign(
      categoryOriginsValues,
      delete categoryOriginsValues.IdClusterProduct
    )
  } else {
    Object.assign(
      categoryOriginsValues,
      delete categoryOriginsValues.IdCategoryDestination
    )
    Object.assign(
      categoryOriginsValues,
      delete categoryOriginsValues.DestinationSpecification
    )
  }
  Object.assign(categoryOriginsValues, categoryDestinyValues)
  Object.assign(categoryOriginsValues, { Section: section })
  Object.assign(categoryOriginsValues, {
    OriginSpecification: categoryOriginsValues?.OriginSpecification?.filter(
      function (element: any) {
        return element !== undefined
      }
    )
  })
  Object.assign(categoryOriginsValues, {
    DestinationSpecification:
      categoryOriginsValues?.DestinationSpecification?.filter(function (
        element: any
      ) {
        return element !== undefined
      })
  })
  function clean(obj: any) {
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        delete obj[propName]
      }
    }
    return obj
  }
  clean(categoryOriginsValues)

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.related-categories.cross-selling' },
        { defaultMessage: 'CRUCE DE CATEGORÍAS' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertInfo = (text: string, time = 25000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.carrousel.rules.tooltip.title' },
        { defaultMessage: 'Ayuda' }
      ),
      toast: true,
      timer: time,
      html: text,
      icon: 'info',
      showConfirmButton: false,
      showCloseButton: true,
      position: 'top-end',
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = () => {
    const saveButton = document.getElementById('saveRelatedCategory')
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setTokenValue(result.value)
        saveButton?.classList.add('disabled')
        setLoading(true)
        const requestOptions = {
          method: 'POST',
          headers: {
            token: user.data.token,
            tokenGoogle: result.value,
            username: user.data.username,
            'Content-Type': 'application/json',
            Accept: '*/*',
            credentials: 'include'
          },
          body: JSON.stringify(categoryOriginsValues)
        }
        fetch(
          `${config.base_server_path}/ManageCarrousel/CreateCrossSelling?idHashScript=${selectedScript?.idHashScript}`,
          requestOptions
        )
          .then(async (response) => await response.json())
          .then((res) => {
            if (res.StatusCode < 0 || res < 0) {
              if (res === -5)
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.token-invalido' },
                    { defaultMessage: 'El token ingresado es inválido.' }
                  ),
                  3
                )
              if (res !== -5 && res !== -6)
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.api.error' },
                    {
                      defaultMessage:
                        'Ocurrió un Error al actualizar los datos.'
                    }
                  ),
                  2
                )
              if (res.StatusCode === -1) MyAlert(res.Message, 2)
              setLoading(false)
              saveButton?.classList.remove('disabled')
            } else {
              MyAlert(res.Message, 1)
              setLoading(false)
              back()
              // reload dataTable
              // setReloadDataTable(!reloadDataTable);
            }
            setTimeout(() => {
              setTokenValue('')
            }, 3000)
          })
          .catch((error) => {
            if (error.response.data === -6) {
              dispatch(logout(user.data.token))
            } else {
              MyAlert('API-ERROR: ' + `${error}`, 3)
            }
          })
      }
    })
  }

  const validateInputs = () => {
    const section = categoryOriginsValues.Section
    const idCategoryOrigin = categoryOriginsValues.IdCategoryOrigin
    const idCategoryDestination =
      filtered.length > 0
        ? categoryOriginsValues.IdCategoryDestination
        : categoryOriginsValues.IdClusterProduct
    if (
      section !== undefined &&
      idCategoryOrigin !== undefined &&
      idCategoryDestination !== undefined &&
      idCategoryOrigin !== idCategoryDestination
    ) {
      AlertToken()
    } else if (
      idCategoryOrigin === idCategoryDestination &&
      categoryNotExist === false
    ) {
      MyAlert(
        intl.formatMessage(
          { id: 'app.related-categories.add-cross-selling.same-category' },
          {
            defaultMessage:
              'La categoría de destino no puede ser la misma que la categoria de origen.'
          }
        ),
        2
      )
    } else {
      MyAlert(
        intl.formatMessage(
          { id: 'app.related-categories.add-cross-selling.section.empty' },
          {
            defaultMessage:
              'Debe completar los campos: Seccion, Categoria de Origen, Categoria de Destino.'
          }
        ),
        2
      )
    }
  }

  const getSpecificationsByCategory = async (
    idCategory: any,
    categoryType: any
  ) => {
    Service.get<any>(
      `ManageCarrousel/GetSpecificationsByCategory?idHashScript=${selectedScript?.idHashScript}&idCategory=${idCategory}`,
      { headers: { token: user.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          if (categoryType === 'origin') {
            setSpecificationByCategory(response.data)
          } else if (categoryType === 'destiny') {
            setSpecificationByCategoryDestiny(response.data)
          } else {
            setSpecificationByCategory(response.data)
            setSpecificationByCategoryDestiny(response.data)
          }
        } else {
          setSpecificationByCategory('')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const getOneRelatedCategories = async () => {
    Service.get<any>(
      `ManageCarrousel/GetOneRelatedCategories?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
      { headers: { token: user.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setOneRelatedCategorie(response.data)
          if (response.data.IdCategoryOrigin !== null) {
            getSpecificationsByCategory(
              response.data.IdCategoryOrigin,
              'origin'
            )
          }
          if (response.data.IdCategoryDestination !== null) {
            getSpecificationsByCategory(
              response.data.IdCategoryDestination,
              'destiny'
            )
            getBrandsByIdCategory(response.data.IdCategoryDestination).catch(
              (error) => console.log(error)
            )
          }
        } else {
          setOneRelatedCategorie('')
        }
      })
      .catch((error) => {
        if (error.response?.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const getBrandsByIdCategory = async (id: any) => {
    Service.get<any>(
      `ManageCarrousel/GetBrandsByIdCategory?idHashScript=${selectedScript?.idHashScript}&idCategory=${id}`,
      { headers: { token: user.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setBrandsByIdCategory(response.data)
        } else {
          setBrandsByIdCategory('')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const getGetCategories = async () => {
    setLoadingCategories(true)
    Service.get<any>(
      `ManageCarrousel/GetCategories?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          token: user.data.token,
          start: '0',
          end: '1000',
          count: '1000'
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          setCategories(response.data.data)
          response.data.data.forEach((item: any, index: any) =>
            categoriesArray.push({
              value: item.id,
              label: item.categoryPathString,
              disabled:
                item.id == categoryOriginsValues?.IdCategoryOrigin
                  ? true
                  : false
            })
          )
          setCategoriesArrayObj(categoriesArray)
        } else {
          setCategories('')
        }
        setLoadingCategories(false)
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
        setLoadingCategories(false)
      })
  }

  const handleSection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSection(event.target.value)
  }

  useEffect(() => {
    getGetCategories()
    getOneRelatedCategories()
  }, [])

  useEffect(() => {
    setSection(
      oneRelatedCategorie.Section !== null ? oneRelatedCategorie.Section : ''
    )
  }, [oneRelatedCategorie])

  return (
    <>
      {oneRelatedCategorie ? (
        <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container mr-2">
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <h6>
              <FormattedMessage
                id="app.related-categories.add-cross-selling"
                defaultMessage="AGREGAR CRUCE DE CATEGORÍAS"
              />
            </h6>
          </div>
          <div className="shelves__wrapper">
            <h6 className="titulo">
              <FormattedMessage
                id="app.related-categories.add-cross-selling.section-to-apply"
                defaultMessage="SECCION A APLICAR"
              />
            </h6>

            <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 jc-flexend">
                <div className="shelves__endpoint-selector-container">
                  <label className="shelves__endpoint-selector-text mb-2">
                    <FormattedMessage
                      id="app.related-categories.add-cross-selling.select-section"
                      defaultMessage="SELECCIONAR SECCION"
                    />
                    (<BsAsterisk size={8} color={'red'} />)
                    <TooltipPanel 
                        ObjectTooltip={user.data.tooltips.filter((
                          (t:any) => t.idTooltip === 'app.carrousel.rules.tooltip.CatRelacionada.Seccion.tooltip')
                        )[0]}
                      />
                  </label>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <select
                  onChange={handleSection}
                  value={section}
                  name="Section"
                  className="col-lg-8 col-md-8 col-sm-8 col-xs-8 box-select-endpoint select_cross"
                  id="Section"
                >
                  <option value="" id="SectionOption">
                    Select
                  </option>
                  <option value="Product">Product</option>
                  <option value="CheckOut">CheckOut</option>
                </select>
              </div>
            </div>
            <hr className="separador-hr" />

            <EditSpecificationsOrigin
              categories={categories}
              categoriesArray={categoriesArrayObj}
              loadingCategories={loadingCategories}
              setCategoryNotExist={setCategoryNotExist}
              specificationByCategory={specificationByCategory}
              oneRelatedCategorie={oneRelatedCategorie}
              id={selectedScript?.idHashScript}
              setCategoryOriginsValues={setCategoryOriginsValues}
              getSpecificationsByCategory={getSpecificationsByCategory}
              handleAlert={AlertInfo}
            />

            <EditSpecificationsDestiny
              categories={categories}
              categoriesArray={categoriesArrayObj}
              loadingCategories={loadingCategories}
              setCategoryNotExist={setCategoryNotExist}
              brandsByIdCategory={brandsByIdCategory}
              categoryOriginsValues={categoryOriginsValues}
              specificationByCategory={specificationByCategoryDestiny}
              oneRelatedCategorie={oneRelatedCategorie}
              id={selectedScript?.idHashScript}
              setCategoryDestinyValues={setCategoryDestinyValues}
              GetBrandsByIdCategory={getBrandsByIdCategory}
              getSpecificationsByCategory={getSpecificationsByCategory}
              handleAlert={AlertInfo}
            />
          </div>

          <div className="row shelves button botonera">
            {loading ? (
              <div style={{ margin: 'inherit', textAlign: 'center' }}>
                <Loading
                  textLoading={intl.formatMessage(
                    { id: 'app.vitrinas-spinner.espere' },
                    { defaultMessage: 'Espere...' }
                  )}
                />
              </div>
            ) : (
              ''
            )}
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
              <div className="shelves__preview-button">
                <div className="shelves__preview-button">
                  <button
                    className="ppal_button btn btn-primary"
                    id="saveRelatedCategory"
                    onClick={validateInputs}
                  >
                    <FormattedMessage
                      id="app.related-categories.add-cross-selling.guardar"
                      defaultMessage="GUARDAR"
                    />
                  </button>
                </div>
                <div className="shelves__preview-button">
                  <button
                    id={'btn-regresar'}
                    className="manage-grid-button shelves__title-icon"
                    onClick={() => {
                      back()
                    }}
                  >
                    <FormattedMessage
                      id="app.related-categories.add-cross-selling.volver"
                      defaultMessage="VOLVER"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default CrossSellingForm
