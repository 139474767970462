/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Service from '../../../../services'
import { RootState } from '../../../../state/store'
import { Modal } from '../../../Globals/Modal'
import { ShowAlert } from '../../../../components/Globals'
import '../ruleConfig.css'
import {
  BestOffer,
  CategoryCodes,
  CodeType,
  DbGroupSkuId,
  DbGroupValues,
  DecimalsDiscount,
  Email,
  ExcludeBreadCrumbCategories,
  ExcludeKey,
  FilterAttributes,
  IdCategory,
  LimitByCategory,
  LimitProducts,
  MatchGroupInput,
  MinStock,
  NotInSku,
  OnlySale,
  OrderInput,
  PrioritySku,
  ProductClusterIds,
  ProductIdSpecification,
  ProductLimit,
  RefreshMetadata,
  SearchTerm
} from './index'
import RadioBtn from './RadioBtn'
import { TooltipPanel } from '../../../Globals/TooltipPanel'
import { useClientContext } from '../../../../contexts/ClientContext'

const orderOptionsDefault = [
  {
    id: 0,
    order: 'None',
    value: 0
  },
  {
    id: 1,
    order: 'Percentage',
    value: 1
  },
  {
    id: 2,
    order: 'Higher Price',
    value: 2
  },
  {
    id: 3,
    order: 'Savings',
    value: 3
  },
  {
    id: 4,
    order: 'Discount',
    value: 4
  },
  {
    id: 5,
    order: 'By Tag Percentage',
    value: 5
  },
  {
    id: 6,
    order: 'Lower Price',
    value: 7
  }
]

const orderOptionsCustom = [
  {
    id: 0,
    order: 'None',
    value: 0
  },
  {
    id: 1,
    order: 'Porcentaje de descuento',
    value: 1
  },
  {
    id: 3,
    order: 'Valor en dinero del ahorro',
    value: 3
  },
  {
    id: 2,
    order: 'Precio más alto',
    value: 2
  },
  {
    id: 6,
    order: 'Precio más bajo',
    value: 7
  },
  {
    id: 4,
    order: 'Orden aleatorio',
    value: 4
  }
]

const RuleModal = ({
  isOpen,
  toggleModal,
  endConfigured,
  setIdEndpoint,
  templateurlObjectResult,
  idEndpoint,
  keySectionId
}: any) => {
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const [endpoints, setEndpoints] = useState<any>([])
  const [fieldsRules, setFieldsRules] = useState<any>([])
  const [paramsRules, setParamsRules] = useState<any>([])

  const [previewEndpoint, setPreviewEndpoint] = useState<boolean>(false)
  const [paramsArray, setParamsArray] = useState<any>([])
  const [paramsValues, setParamsValues] = useState<any>('')
  const [endpointConfigured, setEndpointConfigured] = useState<any>('')
  const [paramsWithNoValue, setParamsWithNoValue] = useState<any>([])
  const [paramsSplit, setParamsSplit] = useState<any>('')
  const [idEndpointSelected, setIdEndpointSelected] = useState<any>('')
  let [endpointSelected, setEndpointSelected] = useState('Elegir Endpoint')
  const intl = useIntl()
  const { selectedScript, selectedClient  } = useClientContext()

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  useEffect(() => {
    Service.get<any>(
      `ConfigurationRule/GetEndPoint?idClient=${selectedClient?.idClient}`,
      {
        headers: { token: user.data.token }
      }
    ).then((response) => {
      setEndpoints(response.data)

      if (idEndpoint > 0) {
        LoadFieldsRulesByEndPoint(idEndpoint)
      } else {
        setFieldsRules('')
        setParamsRules('')
      }
    })
  }, [selectedClient])

  function parametroNuevo(x: any) {
    const params = [
      'bestoffer',
      'categorycodes',
      'codetype',
      'dbGroupValues',
      'dbGroupSkuId',
      'decimalpercent',
      'email',
      'excludebreadcrumbcategories',
      'excludekey',
      'filter_attributes',
      'idcategory',
      'categories',
      'limitbycategory',
      'limit',
      'limitProducts',
      'limitproducts',
      'idproduct',
      'minstock',
      'order',
      'skus',
      'notinsku',
      'parentcode',
      'prioritysku',
      'productclusterids',
      'productidspecification',
      'refreshMetaData',
      'refreshmetadata',
      'sale',
      'hash',
      'ft',
      'qt',
      'sku',
      'ext',
      'sc',
      'whitelabel',
      'company',
      'token',
      'branchoffice',
      'Section',
      'guid',
      'branchOffice',
      user.data.userType == 2 ? 'productidspecification' : ''
    ]
    for (let i = 0; i <= params.length; i++) {
      if (!params.includes(x.key)) {
        return x
      }
    }
  }

  const LoadFieldsRulesByEndPoint = (idEndpoint: any) => {
    Service.get<any>(
      `ManageCarrousel/GetFieldsRulesByEndPoint?idHashScript=${selectedScript?.idHashScript}&keySection=${keySectionId}&endPointId=${idEndpoint}`,
      {
        headers: { token: user.data.token }
      }
    )
      .then((response) => {
        setFieldsRules(response.data)
        console.log(fieldsRules)
        Service.get<any>(
          `ManageCarrousel/GetParametersRulesByClient?idHashScript=${selectedScript?.idHashScript}`,
          {
            headers: { token: user.data.token }
          }
        )
          .then((response) => {
            setParamsRules(response.data)
            console.log(paramsRules)
          })
          .catch((error) => {
            setParamsRules('')
            MyAlert('API-RULES-PARAMS-ERROR: ' + `${error}`, 3)
          })
      })
      .catch((error) => {
        setFieldsRules('')
        MyAlert('API-RULES-ENDPOINTS-ERROR: ' + `${error}`, 3)
      })
  }

  const handleChangeEndpoint = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    LoadFieldsRulesByEndPoint(event.target.selectedOptions[0].id)

    let queryString = ''
    const params =
      event.target.value.indexOf('?') > 0
        ? event.target.value.split('?')[1].split('&')
        : ''
    const newParams = []
    for (let i = 0; i < params.length; i++) {
      const key = params[i].split('=')[0]
      const val =
        (document.getElementById(key) as HTMLInputElement) !== null
          ? (document.getElementById(key) as HTMLInputElement).value
          : params[i].match(/[^=]+$/) == null
          ? ''
          : params[i].match(/[^=]+$/)?.[0]
      newParams.push({ key, val })
      if (queryString != '') queryString += '&'
      queryString += `${key}` + '=' + `${val}`
    }
    setParamsArray(params)
    setParamsValues(newParams.filter(parametroNuevo))
    setEndpointSelected((endpointSelected = event.target.value))
    setEndpointConfigured(event.target.value.split('?')[0] + '?' + queryString)
    setIdEndpoint(event.target.selectedOptions[0].id)
    setIdEndpointSelected(event.target.selectedOptions[0].id)
  }

  const handlePreview = () => {
    if (!selectedScript) {
      return;
    }
    setPreviewEndpoint(true)
    const enpointAmpersand = endpointSelected.replace('?', '&')
    const urlSearchParams = new URLSearchParams(enpointAmpersand)
    let queryString = ''
    const params = endpointSelected.split('?')[1].split('&')
    for (let i = 0; i < params.length; i++) {
      const key = params[i].split('=')[0]
      let val =
        (document.getElementById(key) as HTMLInputElement) !== null
          ? (document.getElementById(key) as HTMLInputElement).value
          : urlSearchParams.get(key)
      if (key == 'hash') val = selectedScript.idHashScript
      if (key == 'company') val = selectedScript.companyVtex
      if (queryString != '') queryString += '&'
      queryString += `${key}` + '=' + `${val}`
    }
    setEndpointConfigured(
      endpointSelected
        .split('?')[0]
        .replace('<%COMPANY%>', selectedScript.companyVtex) +
        '?' +
        queryString
    )
  }

  const saveEndpoint = () => {
     if (!selectedScript) {
       return
     }
    const enpointAmpersand = endpointSelected.replace('?', '&')
    const urlSearchParams = new URLSearchParams(enpointAmpersand)
    let queryString = ''
    const params = endpointSelected.split('?')[1].split('&')
    for (let i = 0; i < params.length; i++) {
      const key = params[i].split('=')[0]
      let val =
        (document.getElementById(key) as HTMLInputElement) !== null
          ? (document.getElementById(key) as HTMLInputElement).value
          : urlSearchParams.get(key)
      if (key == 'hash') val = selectedScript.idHashScript
      if (key == 'company') val = selectedScript.companyVtex
      if (queryString != '') queryString += '&'
      queryString += `${key}` + '=' + `${val}`
    }
    endConfigured(
      endpointSelected
        .split('?')[0]
        .replace('<%COMPANY%>', selectedScript.companyVtex) +
        '?' +
        queryString
    )
    toggleModal()
  }

  useEffect(() => {
    if (templateurlObjectResult?.length > 0) {
      const params = templateurlObjectResult.split('?')[1].split('&')
      setParamsSplit(params.map((item: any) => item.split('=')))
      const queryString: any[] = []
      params.forEach(function (arrayItem: any, arrayIndex: any, array: any) {
        queryString.push(array[arrayIndex].match(/^[\w ]+/)[0])
      })
      setEndpointSelected(templateurlObjectResult)
      setParamsWithNoValue(queryString)
    }
  }, [templateurlObjectResult])

  useEffect(() => {
    if (isOpen == true) {
      const paramsWithNoValue: any[] = []
      if (paramsArray.length > 0) {
        paramsArray.forEach(function (
          arrayItem: any,
          arrayIndex: any,
          array: any
        ) {
          paramsWithNoValue.push(array[arrayIndex].match(/^[\w ]+/)[0])
        })
      }
      setParamsWithNoValue(paramsWithNoValue)
    }
  }, [paramsArray])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={toggleModal}
        title={intl.formatMessage(
          { id: 'app.configurar-regla' },
          { defaultMessage: 'CONFIGURAR REGLA' }
        )}
        myWidth={'80%'}
        content={''}
      >
        <div className="row">
          <div className="row panel">
            <h6 className="rule-config__endpoint-selector-text_modal col-lg-3 col-md-3 col-sm-3 col-xs-3 align-right">
              <FormattedMessage
                id="app.vitrinas.table.column2-2"
                defaultMessage="Regla de Negocios"
              />
              <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.carrousel.rules.modal.rule.bussiness.tooltip')
                  )[0]}
                />              
            </h6>
            <select
              className="col-lg-6 col-md-6 col-sm-6 col-xs-6 box-select-endpoint"
              onChange={handleChangeEndpoint}
            >
              <option>Elegir Endpoint</option>
              {endpoints.map((endpoint: any) => {
                return (
                  <option
                    id={endpoint.IdEndpoint}
                    value={
                      endpoint.Url !== '' ? endpoint.Url : endpoint.UrlGeneric
                    }
                    key={endpoint.id}
                    selected={idEndpoint == endpoint.IdEndpoint}
                  >
                    {endpoint.Name}
                  </option>
                )
              })}
            </select>
          </div>

          {fieldsRules.length > 0
            ? fieldsRules.map((param: any) => {
                return (
                  <div
                    key={param.key}
                    className="rule-config-subComponents__container"
                  >
                    <RadioBtn
                      title={
                        param.labelOptionGroup == ''
                          ? 'Seleccione una opción'
                          : param.labelOptionGroup
                      }
                      values={paramsSplit}
                      data={param}
                      idInput={param.parameter}
                      sectionId={keySectionId}
                      idEndpoint={idEndpointSelected != '' && idEndpointSelected != undefined ? idEndpointSelected :idEndpoint} // {idEndpoint}
                    />
                  </div>
                )
              })
            : ''}

          {paramsWithNoValue.includes('sale') == true &&
          paramsRules.includes('sale') == false ? (
            <OnlySale 
              values={paramsSplit}               
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('order') == true &&
          paramsRules.includes('order') == false ? (
            <OrderInput
              title="Ordenamiento [order]"
              orderOptions={
                idEndpointSelected == 9 || idEndpointSelected == 29
                  ? orderOptionsCustom
                  : orderOptionsDefault
              }
              values={paramsSplit}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('categorycodes') == true &&
          paramsRules.includes('categorycodes') == false ? (
            <CategoryCodes
              title="[categorycodes]"
              values={paramsSplit}              
            />
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsArray.includes(`decimalpercent=true`) == true &&
          paramsRules.includes('decimalpercent=true') == false ? (
            <DecimalsDiscount
              title="Aplica Decimales en % Descuento [decimalpercent]"
            />
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsArray.includes(`decimalpercent=false`) == true &&
          paramsRules.includes('decimalpercent=false') == false ? (
            <DecimalsDiscount
              title="Aplica Decimales en % Descuento [decimalpercent]"
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('idcategory') == true &&
          paramsRules.includes('idcategory') == false ? (
            <IdCategory
              values={paramsSplit}
              param={'idcategory'}              
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('parentcode') == true &&
          paramsRules.includes('parentcode') == false ? (
            <IdCategory
              values={paramsSplit}
              param={'parentcode'}              
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('categories') == true &&
          paramsRules.includes('categories') == false ? (
            <IdCategory
              values={paramsSplit}
              param={'categories'}              
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('limitbycategory') == true &&
          paramsRules.includes('limitbycategory') == false ? (
            <LimitByCategory
              title="[limitbycategory]"
              values={paramsSplit}              
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('limitproducts') == true &&
          paramsRules.includes('limitproducts') == false ? (
            <LimitProducts
              values={paramsSplit}
              param={'limitproducts'}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('limit') == true &&
          paramsRules.includes('limit') == false ? (
            <LimitProducts
              values={paramsSplit}
              param={'limit'}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('limitProducts') == true &&
          paramsRules.includes('limitProducts') == false ? (
            <LimitProducts
              values={paramsSplit}
              param={'limitProducts'}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('minstock') == true &&
          paramsRules.includes('minstock') == false ? (
            <MinStock
              title="Stock Mínimo [minstock]"
              values={paramsSplit}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('prioritysku') == true &&
          paramsRules.includes('prioritysku') == false ? (
            <PrioritySku
              title="SKU Priorizados [prioritysku]"
              values={paramsSplit}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('notinsku') == true &&
          paramsRules.includes('notinsku') == false ? (
            <NotInSku
              title="SKU Excluidos [notinsku]"
              values={paramsSplit}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('productclusterids') == true &&
          paramsRules.includes('productclusterids') == false ? (
            <ProductClusterIds
              title="Código de Cluster Colección [productclusterids]"
              values={paramsSplit}
            />
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsArray.includes('refreshMetaData=true') == true &&
          paramsRules.includes('refreshMetaData=true') == false ? (
            <RefreshMetadata
              title="Actualizar Datos [refreshmetadata]"
              isActive={true}              
            />
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsArray.includes('refreshMetaData=false') == true &&
          paramsRules.includes('refreshMetaData=false') == false ? (
            <RefreshMetadata
              title="Actualizar Datos [refreshmetadata]"
              isActive={false}
            />
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsArray.includes('refreshmetadata=true') == true &&
          paramsRules.includes('refreshmetadata=true') == false ? (
            <RefreshMetadata
              title="Actualizar Datos [refreshmetadata]"
              isActive={true}
            />
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsArray.includes('refreshmetadata=false') == true &&
          paramsRules.includes('refreshmetadata=false') == false ? (
            <RefreshMetadata
              title="Actualizar Datos [refreshmetadata]"
              isActive={false}
            />
          ) : (
            ''
          )}

          {/* {paramsWithNoValue.includes("ft") ? (
                        <Search title="Actualizar Datos [refreshmetadata]" ft={intl.formatMessage({ id: "app.configurar-regla.termino-busqueda" }, { defaultMessage: "Search Term [ft]" })} qt={intl.formatMessage({ id: "app.configurar-regla.limite-producto" }, { defaultMessage: "Product Limit [qt]" })} />
                    ) : (
                        ""
                    )} */}

          {paramsWithNoValue.includes('ft') == true &&
          paramsRules.includes('ft') == false ? (
            <SearchTerm
              title={intl.formatMessage(
                { id: 'app.configurar-regla.termino-busqueda' },
                { defaultMessage: 'Search Term [ft]' }
              )}
              values={paramsSplit}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('qt') == true &&
          paramsRules.includes('qt') == false ? (
            <ProductLimit
              title={intl.formatMessage(
                { id: 'app.configurar-regla.limite-producto' },
                { defaultMessage: 'Product Limit [qt]' }
              )}
              values={paramsSplit}
            />
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsWithNoValue.includes('idproduct') == true &&
          paramsRules.includes('idproduct') == false ? (
            <MatchGroupInput
              title="Lista de ProductIds [idproduct]"
              id={'idproduct'}
              values={paramsSplit}
            />
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('sku') == true &&
          paramsRules.includes('sku') == false ? (
            <>
              <MatchGroupInput
                title="Lista de SKU [sku]"
                id={'sku'}
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('skus') == true &&
          paramsRules.includes('skus') == false ? (
            <>
              <MatchGroupInput
                title="Lista de SKU [sku]"
                id={'skus'}
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('excludekey') == true &&
          paramsRules.includes('excludekey') == false ? (
            <>
              <ExcludeKey
                title="Clave de exclusión [excludekey]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsWithNoValue.includes('filter_attributes') == true &&
          paramsRules.includes('filter_attributes') == false ? (
            <>
              <FilterAttributes
                title="Filtro por Atributos [filter_attributes]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {user.data.userType == 1 &&
          paramsWithNoValue.includes('productidspecification') == true &&
          paramsRules.includes('productidspecification') == false ? (
            <>
              <ProductIdSpecification
                title="ProductID para heredar Especificaciones [productidspecification]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('excludebreadcrumbcategories') == true &&
          paramsRules.includes('excludebreadcrumbcategories') == false ? (
            <>
              <ExcludeBreadCrumbCategories
                title="Excluir categorías de migas de pan [excludebreadcrumbcategories]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('dbGroupValues') == true &&
          paramsRules.includes('dbGroupValues') == false ? (
            <>
              <DbGroupValues
                title="Grupo de Valores de Base de Datos [dbGroupValues]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('dbGroupSkuId') == true &&
          paramsRules.includes('dbGroupSkuId') == false ? (
            <>
              <DbGroupSkuId
                title="Grupo de Sku Id de Base de Datos [dbGroupSkuId]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('email') == true &&
          paramsRules.includes('email') == false ? (
            <>
              <Email
                title="Email [email]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('codetype') == true &&
          paramsRules.includes('codetype') == false ? (
            <>
              <CodeType
                title="Tipo de Codigo [codetype]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsWithNoValue.includes('bestoffer') == true &&
          paramsRules.includes('bestoffer') == false ? (
            <>
              <BestOffer
                title="Mejor Oferta [bestoffer]"
                values={paramsSplit}
              />
            </>
          ) : (
            ''
          )}

          {paramsValues.length > 0
            ? paramsValues.map((param: any) => {
                return (
                  <div
                    key={param.key}
                    className="rule-config-subComponents__container"
                  >
                    <div className="row">
                      <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
                        {param.key}
                      </h6>
                      <input
                        className="col-lg-9 col-md-9 col-sm-9 col-xs-9 vtexskuconsult__input"
                        id={param.key}
                        style={{ width: '64%', background: '#fcfbfb' }}
                        type="text"
                        defaultValue={param.val}
                      />
                    </div>
                  </div>
                )
              })
            : ''}
        </div>

        {endpointSelected === 'Elegir Endpoint' ? (
          ''
        ) : (
          <div className="row rule-config__bottom-buttons mt-4">
            {user.data.userType == 1 ? (
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 rule-config__preview-button">
                <button
                  className="btn btn-default"
                  id="preview-endpoint"
                  onClick={handlePreview}
                >
                  Preview Endpoint
                </button>
              </div>
            ) : null}
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <button
                className="ppal_button btn btn-primary"
                onClick={saveEndpoint}
              >
                <FormattedMessage
                  id="app.modal-regla.guardar"
                  defaultMessage="Guardar"
                />
              </button>
            </div>
          </div>
        )}

        {previewEndpoint && user.data.userType == 1 ? (
          <div className="rule-config-subComponents__container">
            <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
              EndPoint Original:
            </h6>
            <textarea
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 textarea-endpoint"
              rows={3}
              value={endpointSelected}
            />
            <br />
            <br />
            <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
              <FormattedMessage
                id="app.modal-regla.endpoint-configurado"
                defaultMessage="EndPoint Configurado"
              />
              :
            </h6>
            <textarea
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 textarea-endpoint"
              rows={3}
              value={endpointConfigured}
            />
          </div>
        ) : (
          ''
        )}
      </Modal>
    </>
  )
}

export default RuleModal
