const Pagination = ({
  optionsPerPage,
  totalOptions,
  paginate,
  currentPage
}: any) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalOptions / optionsPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => {
          const isSelected = number === currentPage
          return (
            <>
              <li key={number} className="page-item">
                <button
                  onClick={() => paginate(number)}
                  className={`page-link__custom ${isSelected ? 'active' : ''}`}
                >
                  {number}
                </button>
              </li>
            </>
          )
        })}
      </ul>
    </nav>
  )
}

export default Pagination
