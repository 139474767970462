import { atom } from 'jotai'

export const homeAtom: any = atom('')
export const plpAtom: any = atom('')
export const pdpAtom: any = atom('')
export const searchAtom: any = atom('')
export const searchNoResultAtom: any = atom('')

export const homeAtomOrderChange: any = atom(false)
export const plpAtomOrderChange: any = atom(false)
export const pdpAtomOrderChange: any = atom(false)
export const searchAtomOrderChange: any = atom(false)
export const searchNoResultAtomOrderChange: any = atom(false)
