/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Service from '../../services'
import { RootState } from '../../state/store'
import { CustomLink, ShowAlert } from '../Globals'
import './style.css'
import { useClientContext } from '../../contexts/ClientContext'

const MenuOptions: React.FC<mainMenuOptionsProps> = ({
  onAction,
  options,
  tag
}) => {
  const navs = options
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const intl = useIntl()
  const { selectedScript } = useClientContext()

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.audience-list' },
        { defaultMessage: 'Lista de audiencias' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: 'Cerrar',
      confirmButtonText: 'Cerrar',
      toast: isToast,
      position: isToast ? 'top-end' : 'center',
      width: 500,
      timerProgressBar: true
    })
  }

  useEffect(() => {
    document.title = tag
    changeMenu(window.location.pathname.split('/').pop())
  }, [])

  const changeMenu = (link: any) => {
    Service.get<any>(
      `/Account/ChangeMenu?menu=${link}&idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          token: token.data.token
        }
      }
    ).then((response) => {
      if (
        response.data.StatusCode === -9 &&
        (link === 'create-audience' ||
          link === 'import-audience' ||
          link === 'remove-data')
      ) {
        MyAlert(response.data.Message, 3)
      }
    })
  }

  return (
    <>
      {navs
        .filter((nav) => !nav.dynamic)
        .map((nav) => {
          if (!nav.dynamic) {
            return (
              <li className="customLink-container li-menu-pri" key={nav.id}>
                <CustomLink
                  to={nav.link}
                  onClick={() => {
                    onAction()
                    changeMenu(nav.link)
                  }}
                >
                  {nav.icon} {nav.name}
                </CustomLink>
              </li>
            )
          }
          return null
        })}
    </>
  )
}

export default MenuOptions
