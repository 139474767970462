import React, { useEffect, useState } from 'react'
import { BsBell, BsBellSlash } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import './subscription.css'
import '../Global.css'
import Suscribe from './Suscribe'
import Unsuscribe from './Unsuscribe'
import { Loading } from '../../Globals/Loading'
import { RootState } from '../../../state/store'
import { logout } from '../../../state/actions'
import Service from '../../../services'
import { ShowAlert } from '../../Globals'
import { useClientContext } from '../../../contexts/ClientContext'

const SubscriptionContainer = () => {
  const { selectedScript } = useClientContext()
  const [tabSuscripcion, setTabSuscripcion] = useState(false)
  const [isDbExist, setDbExist] = useState(true)
  const [loading, setLoading] = useState(false)
  const token = useSelector<RootState, any>((state) => state.userAuth)

  const dispatch = useDispatch()
  const intl = useIntl()

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.suscribir.suscribir-email' },
        { defaultMessage: 'SUSCRIBIR EMAIL' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      // toast: true,
      width: 500,
      timerProgressBar: true
    })
  }

  useEffect(() => {
    setLoading(true)
    Service.get<any>(
      `Automation/IsExistDbAutomation?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          token: token.data.token
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          if (response.data.StatusCode === 0) {
            setDbExist(true)
          } else {
            setDbExist(false)
          }
          setLoading(false)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }, [isDbExist])

  const handleTab = () => {
    setTabSuscripcion(!tabSuscripcion)
  }

  return (
    <>
      <div className="container mg-t-30">
        <div className="alert-heading">
          {intl.formatMessage({
            id: 'app.tooltips.header.title.sucripcionDesuscripcion'
          })}
        </div>
        <p>
          {intl.formatMessage({
            id: 'app.tooltips.header.p.sucripcionDesuscripcion'
          })}
        </p>
      </div>
      <div className="container mg-t-30">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {
              isDbExist ? (
                <div className="subscription__title-container">
                  <div
                    className={
                      tabSuscripcion
                        ? 'subscription__wrapper_disable d-flex mt-3'
                        : 'subscription__wrapper d-flex'
                    }
                    onClick={handleTab}
                  >
                    <BsBell className="manage-grid-button subscription__title-icon" />
                    <h1 className="subscription__title-text">
                      <FormattedMessage
                        id="app.suscribir.suscribir-email"
                        defaultMessage="SUSCRIBIR EMAIL"
                      />
                    </h1>
                  </div>
                  <div
                    className={
                      tabSuscripcion
                        ? 'subscription__wrapper d-flex'
                        : 'subscription__wrapper_disable d-flex mt-3'
                    }
                    onClick={handleTab}
                  >
                    <BsBellSlash className="manage-grid-button subscription__title-icon" />
                    <h1 className="subscription__title-text">
                      <FormattedMessage
                        id="app.suscribir.desuscribir-email"
                        defaultMessage="DESUSCRIBIR EMAIL"
                      />
                    </h1>
                  </div>
                </div>
              ) : (
                ''
              )
              // <div className="alert alert-warning" role="alert">
              //     <FormattedMessage id="app.submenu.audiencias.sin.resultados" defaultMessage="No existe la Base de datos Automation"/>
              //   </div>
            }

            {!tabSuscripcion ? (
              isDbExist ? (
                <Suscribe />
              ) : null
            ) : isDbExist ? (
              <Unsuscribe />
            ) : null}
          </div>
        </div>

        {loading ? (
          <div className="container mg-t-30">
            <Loading
              textLoading={intl.formatMessage(
                { id: 'app.vitrinas-spinner.espere' },
                { defaultMessage: 'Espere...' }
              )}
            />
          </div>
        ) : null}
      </div>
      {selectedScript?.idHashScript !== '' ? (
        <div className="container mg-t-30"></div>
      ) : (
        ''
      )}
    </>
  )
}

export default SubscriptionContainer
