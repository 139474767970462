import {
  CLIENT_RESET,
  CLIENT_SELECTED,
  CLIENT_SELECTED_ERROR
} from '../constants/clientConstants'

const defaultState: Client = {
  clientDetail: {
    id: 0,
    client: ''
  }
}

export const clientSelectedReducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case CLIENT_SELECTED:
      return {
        clientDetail: action.payload
      }
    case CLIENT_RESET:
      return {
        clientDetail: {
          id: 0,
          client: ''
        }
      }
    case CLIENT_SELECTED_ERROR:
      return {
        client: action.payload
      }
    default:
      return state
  }
}
