/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import {  useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import { TooltipPanel } from '../../../Globals/TooltipPanel'

const skuOptions = [
  {
    id: 0,
    name: 'MatchGroup 1',
    endpoint: '<%matchgroup_1%>'
  },
  {
    id: 1,
    name: 'MatchGroup 2',
    endpoint: '<%matchgroup_2%>'
  },
  {
    id: 2,
    name: 'MatchGroup 3',
    endpoint: '<%matchgroup_3%>'
  },
  {
    id: 3,
    name: 'MatchGroup 4',
    endpoint: '<%matchgroup_4%>'
  }
]

const MatchGroupInput: React.FC<{
  title: string
  id: string
  values: any  
}> = ({ title, id, values }) => {
  const valueParams = values
    .map((item: any) => {
      if (item[0] == id) {
        return item
      }
    })
    .filter((x: any) => x !== undefined)[0]
  const [sku, setSku] = useState<any>(
    valueParams !== undefined ? valueParams[1] : ''
  )

  const handleChange = (event: React.InputHTMLAttributes<HTMLInputElement>) => {
    setSku(event.value)
  }

  const handleButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const button: HTMLButtonElement = event.currentTarget
    setSku(button.name)
  }

  const user = useSelector<RootState, any>((state) => state.userAuth)

  return (
    <div className="rule-config-subComponents__container">
      <div className="row a-center">
        <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal">
          {title}
          <TooltipPanel 
            ObjectTooltip={user.data.tooltips.filter((
              (t:any) => t.idTooltip === `app.carrousel.rules.tooltip.MatchGroupInput.${id}.tooltip`)
            )[0]}
          />
        </h6>
        <input
          name={id}
          id={id}
          type="text"
          onChange={handleChange}
          style={{ width: '64%', background: '#fcfbfb' }}
          className="col-lg-9 col-md-9 col-sm-9 col-xs-9 vtexskuconsult__input"
          value={sku}
        ></input>
        
      </div>
      <div className="button_container_rule">
        {skuOptions.map((option) => {
          return (
            <button
              onClick={handleButton}
              name={option.endpoint}
              value={option.name}
              className="shelves__title-icon"
              key={option.id}
            >
              {option.name}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default MatchGroupInput
