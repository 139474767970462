/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useState, useMemo, useRef } from 'react'
import DataTable, { Alignment } from 'react-data-table-component'
import { BsEraser, BsPlusCircle, BsSearch, BsUpload, BsTrash } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { config } from '../../../config'
import styled from 'styled-components'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import { Loading } from '../../Globals/Loading'
import CDPUploadFile from './CDPUploadFIle'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useClientContext } from '../../../contexts/ClientContext'

const CDPUGridImported = ({
  edit,
  handlerReloadDataTable,
  handleSyncAllTerms,
  syncAllTermsFlag,
  setAudienceId,
  setAudienceName,
  setAudienceDescription,
  setQueryEditValues
}: any) => {
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const [segmentsImported, setSegmentsImported] = useState<any>([])
  const [totalRows, setTotalRows] = useState(0)
  const [totalRowsAux, setTotalRowsAux] = useState(0)
  const [perPage, setPerPage] = useState(100)
  const [filterText, setFilterText] = useState<any>('')
  const [sortField, setSortField] = useState<any>('idSegment')
  const [sortDirection, setSortDirection] = useState<any>('desc')
  const [loading, setLoading] = useState(false)
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false)

    const [importToCDP, setImportToCDP] = useState<boolean>(false)
    const [file, setFile] = useState<any>(null);
    const [status, setStatus] = useState<string>("");
    const [keySelected, setKeySelected] = useState<any>([]);

  const toggleImportToCDP = () => setImportToCDP(!importToCDP)

  const { selectedScript } = useClientContext()

  const dispatch = useDispatch()
  const intl = useIntl()
  
  const getDataReload = async (
    page: any,
    textSearch: string,
    sortFie: string,
    sortDir: string
  ) => {
    setLoading(true)
    Service.get<any>(
      `Automation/GetScriptFilesImported?idHashScript=${
        selectedScript?.idHashScript
      }&page=${page}&per_page=${perPage}&filterText=${filterText.toLowerCase()}&sortField=${sortFie}&sortDirection=${sortDir}`,
      {
        headers: {
          token: user.data.token
        }
      }
    )
      .then((response) => {
        if (response.status === 200) {
          
          if (response.data.length > 0) {
            setSegmentsImported(response.data)
            setTotalRows(10)
            setTotalRowsAux(10)
          }
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        } else {
          setSegmentsImported('')
          document.getElementById('add-new-audience')?.classList.add('disabled')
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        }
      })
  }

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    setStatus(""); // Reset status
    event.preventDefault();
    const formData = new FormData();
    formData.append("files", file);    
      Service.post<any, any>(
        `/Automation/UploadCdpFile?idHashScript=${selectedScript?.idHashScript}`,
        formData,
        {
          method: 'POST',
          headers: {
            token: user.data.token,
            username: user.data.username,
            'Content-Type': 'multipart/form-data',
            Accept: '*/*',
            credentials: 'include'
          }
        }
      ).then((res) => {
        if (res.headers.statuscode === '0') {
          setStatus('subido')          
          MyAlert("Se subió correctamente el archivo cdp.", 1
          )
          getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
            console.log(error)
          ) 
        } else {
          setStatus('error de subida')
          MyAlert('Ocurrió un error al subir el archivo.', 3 )         
        }
        setFile(null)
      }).catch((error) => {
        setFile(null)
        setStatus('error de subida: '+error)
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })

  };
  
  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.cdp.audience-list' },
        { defaultMessage: 'Lista de audiencias' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast?'top-end':'center',
      width: 500,
      timerProgressBar: true      
    })
  }

  const TextField = styled.input`
    width: 25%;
    border: none;
    background: none;
    border-bottom: 2px solid #ccc !important;
    margin: 0px 0px 20px 0px;
    height: 32px;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `

  const ClearButton = styled.button`
    border: none;
    height: 38px;
    width: 38px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  `

  const customStyles = {
    header: {
      style: {
        fontSize: '14px',
        color: '#000',
        backgroundColor: 'transparent',
        minHeight: '56px',
        paddingLeft: '16px',
        paddingRight: '8px'
      }
    },
    rows: {
      style: {
        minHeight: '72px' // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px !important', // override the cell padding for head cells
        paddingRight: '20px !important'
      },
      draggingStyle: {
        cursor: 'move'
      }
    },
    cells: {
      style: {
        margingLeft: '10px', // override the cell padding for data cells
        margingRight: '10px',
        wordBreak: undefined,
        overflow: 'wrap'
      },
      draggingStyle: {},
      class: 'text-center'
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      '&:nth-of-type(n)': {
        color: '#fff',
        backgroundColor: '#666',
        borderBottomColor: '#ccc'
      }
    },
    highlightOnHoverStyle: {
      color: '#dfdfdf',
      backgroundColor: '#eee',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: '#000',
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: '#efefef'
    },
    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    pagination: {
      style: {
        color: '#000',
        fontSize: '14px',
        minHeight: '56px',
        backgroundColor: 'transparent',
        // borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#ddd'
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '5px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#fff',
        // fill: theme.button.default,
        backgroundColor: '#e9ecef',
        '&:disabled': {
          cursor: 'unset',
          color: '#2fad7e'
          // fill: theme.button.disabled,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: '#2fad7e',
          color: '#fff'
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#fff'
        }
      }
    }
  }

  const handlerSetChangePerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLoading(true)
    setPerPage(parseInt(event.target.value))
    // setFilterText('')

    const selc = document.querySelectorAll('select[aria-label="MOSTRANDO"]').length > 0 
      ?  document.querySelectorAll('select[aria-label="MOSTRANDO"]')
      : document.querySelectorAll('select[aria-label="SHOW"]')
    const select = document.getElementById(
      'options-per-page'
    ) as HTMLSelectElement
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].text === event.target.value) {
        const yourSelect = selc[0] as HTMLSelectElement
        yourSelect.options[i].selected = true
        const ev = new Event('change', { bubbles: true })
        yourSelect.dispatchEvent(ev)

        break
      }
    }
    setLoading(false)
  }

  const changeTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 2) {
      setFilterText(event.target.value)
    }
  }

  const DeleteAlertToken = (FileKey: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'El token no fue informado.' }
          )
        } else {
          return ''
        }
      }
      
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        deleteFileKey(FileKey, result.value)
      }
    })
  }

  const deleteFileKey = (
    FileKey: string,    
    tokenGoogle: string
  ) => {    
    setLoading(true)
    Service.post<any, any>(
      `/Automation/DeleteCdpFile?idHashScript=${selectedScript?.idHashScript}&FileKey=${FileKey}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          username: user.data.username,
          tokenGoogle,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    ).then((respone) => {
        if (respone.status === 200) {
          if (respone.data == -5){
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          }else{
            MyAlert('Se eliminó con éxito el archivo de S3.', 1)          
            getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
              console.log(error)
            )
          }          
        }else{
          MyAlert('Ocurrió un Error al eliminar el archivo.',2)
        }
        setLoading(false)        
      })
      .catch((error) => {
        if(error.response.data==6){
          dispatch(logout(user.data.token))
        }else{
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }        
        setLoading(false)
      })

      
  }
  
  const FilterComponent = ({ filterText, onFilter, onClear }: any) => (
    <>
      <div className="row w-100 hide">
        <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 header-1">
          <p className="show-options__text">
            <FormattedMessage
              id="app.vitrinas.mostrar"
              defaultMessage="MOSTRAR"
            />
            <select
              onChange={handlerSetChangePerPage}
              className="show-options__selector select_paginas"
              name="options-per-page"
              id="options-per-page"
            >
              <option value="5" selected={perPage === 5 ? true : false}>
                5
              </option>
              <option value="10" selected={perPage === 10 ? true : false}>
                10
              </option>
              <option value="15" selected={perPage === 15 ? true : false}>
                15
              </option>
              <option value="20" selected={perPage === 20 ? true : false}>
                20
              </option>
              <option value="100" selected={perPage === 100 ? true : false}>
                100
              </option>
            </select>
            <FormattedMessage
              id="app.vitrinas.entradas"
              defaultMessage="ENTRADAS"
            />
          </p>
        </div>
        <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 header-2">
          <p className="w-20-pc"></p>
          <TextField
            id="search"
            type="text"
            placeholder={useIntl().formatMessage(
              { id: 'app.vitrinas.buscar' },
              { defaultMessage: 'BUSCAR POR...' }
            )}
            onChange={changeTextSearch}
            data-tip={useIntl().formatMessage(
              { id: 'app.vitrinas.buscar.tooltip' },
              { defaultMessage: 'Ingresa al meno 3 caracteres para búscar' }
            )}
          />
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

          {filterText === '' ? (
            <ClearButton type="button" onClick={onClear}>
              <BsSearch
                title={
                  'Buscar ' +
                  intl.formatMessage(
                    { id: 'app.cdp.audience-list' },
                    { defaultMessage: 'Lista de audiencias' }
                  )
                }
                size={25}
              />
            </ClearButton>
          ) : (
            <ClearButton type="button" onClick={onClear}>
              <BsEraser title="Limpiar búsqueda" size={25} />
            </ClearButton>
          )}
        </div>
      </div>
    </>
  )

  const sendReadCdpFile = (row: any) =>{
    setKeySelected(row)
    toggleImportToCDP()
  }

  const columns = [
    {
      id: 'Link',
      name: 'Link(key)',
      selector: (row: any) => row.FileKey,      
      reorder: true,
      center: true,      
      minWidth: '250px'
    },
    {
      id: 'Status',
      name: 'Status',
      selector: (row: any) => row.Tag,
      center: true,
      minWidth: '80px',
      width: '30%'
    },
    {
      id: 'acc_categ',
      name: intl.formatMessage(
        { id: 'app.cdp.import-audience-table.import' },
        { defaultMessage: 'Importar a CDP' }
      ),
      cell: (row: any) => (
        <>
          <button
            className="manage-grid-button btn-grilla-upd"
            data-id={row.FileKey+row.Tag}
            onClick={() => sendReadCdpFile(row)}
            data-tip={'Procesar el archivo importado.'}
          >
            <BsPlusCircle
              size={25}
              title={intl.formatMessage(
                { id: 'app.suscribir.importar' },
                { defaultMessage: 'IMPORTAR' }
              )}
            />
          </button>
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
          
          <button
            className="manage-grid-button-del btn-grilla-del"
            data-id={row.FileKey}
            onClick={() => {
              DeleteAlertToken(row.FileKey)
            }}
            data-tip={'Eliminar el archivo importado'}
          >
            <BsTrash                      
              color='rgb(230 114 60)'
              title={'Eliminar el archivo importado'}
              size={25}
            />
          </button>
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
        </>
      ),
      allowOverflow: true,
      button: true,
      center: true,
      width: '180px'
    }
  ]

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      setResetPaginationToggle(!resetPaginationToggle)
      setFilterText('')
    }

    if (filterText === '') {
      setTotalRows(totalRowsAux)
    } else {
      getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
        console.log(error)
      )
      setTimeout(() => {
        const input = document.getElementById('search') as HTMLInputElement
        input.value = filterText
        if (input != null) input.focus()
      }, 200)
    }

    return (
      <FilterComponent
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  useEffect(() => {
    getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }, [resetPaginationToggle, handlerReloadDataTable])

  return (
    <div className="container mg-t-30 container " style={{ display: 'block' }}>
      <div className="cdp_upload__title-container">
        <div className="rule-config__title-icon">
          <BsUpload />
        </div>
        <h1 className="rule-config__title-text">
          <FormattedMessage
            id="app.cdp.import-audience-table.import-files"
            defaultMessage="Importar Archivos"
          />
        </h1>
        
        &nbsp;<span className='text-danger'>(CDP)</span>
      </div>
      <div className="container mg-b-15">
        <p>
          {intl.formatMessage(
            { id: 'app.tooltips.header.p.cdp-importarAudiencia' },
            { defaultMessage: 'CDP' }
          )}
        </p>
      </div>
      {!importToCDP ? (
        <div className="automation__wrapper border-shadow">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="automation__endpoint-selector-container">
              <form onSubmit={handleSubmit}>
                <label className="automation__endpoint-selector-text">
                  <FormattedMessage
                    id="app.audiencias.archivo"
                    defaultMessage="ARCHIVO"
                  />
                </label>
                <input 
                  type="file" 
                  onChange={(e) => setFile(e.target.files!=null?e.target.files[0]:null)} 
                /> 
                &nbsp;
                <button 
                  type="submit" 
                  className='btn btn-dark'
                  disabled={!(file)}>
                  <BsUpload />
                  &nbsp;
                  Aubir archivo
                </button>
                  &nbsp;
                {status!='' ? <span className='badge badge-success'>{status}</span> : <span className='badge badge-danger'>{status}</span>}
              </form>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="row">
        {segmentsImported.length >= 1 && totalRows > 0 ? (
          !importToCDP ? (
            <>
              <div>
                <DataTable
                  subHeaderAlign={Alignment.RIGHT}
                  columns={columns}
                  data={segmentsImported}
                  progressPending={loading}
                  progressComponent={
                    <Loading
                      textLoading={intl.formatMessage(
                        { id: 'app.vitrinas-spinner.espere' },
                        { defaultMessage: 'Espere...' }
                      )}
                    />
                  }                  
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponentMemo}
                  customStyles={customStyles}                  
                  noDataComponent={intl.formatMessage(
                    { id: 'app.vitrinas.nodata' },
                    { defaultMessage: 'Sin Resultados.' }
                  )}
                  responsive
                />
              </div>
            </>
          ) : (
            <CDPUploadFile 
              back={toggleImportToCDP}
              fileKeySelected={keySelected}
            />
          )
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default CDPUGridImported
