/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react'
import styled from 'styled-components'

import CarrouselTabHome from './CarrouselTabHome'
import CarrouselTabPLP from './CarrouselTabPLP'
import CarrouselTabPDP from './CarrouselTabPDP'
import CarrouselTabSearch from './CarrouselTabSearch'
import CarrouselTabSearchNoResult from './CarrouselTabSearchNoResult'
import {
  HomeLogoSvg,
  PDPLogoSvg,
  PLPLogoSvg,
  PurchaseLogoSvg,
  SearchLogoSvg
} from '../../Globals'
import CatalogoLogoSvg from '../../Globals/svg/CatalogoLogoSvg'
import CheckoutGreen from '../../Globals/svg/CheckoutGreen'
import { useMshopContext } from '../../../contexts/MshopContext'

const TabButtonContainer = styled.div`
  position: relative;
  height: 80px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 6px;
`

interface TabButtonProps {
  color?: string
  ['background-color']?: string
  width?: string
  cursor?: string
  svgFillColor?: string
  svgFillColorOnHover?: string
  hover?: {
    color?: string
    ['background-color']?: string
    width?: string
    cursor?: string
  }
}
const TabButton = styled.button<TabButtonProps>`
  border: none;
  background-color: ${(props) =>
    props['background-color'] ? props['background-color'] : 'white'};
  color: ${({ color }) => (color ? color : '#2e2e2e')};
  outline: none;
  width: ${({ width }) => (width ? width : '100%')};
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  border-radius: 4px;
  border-right: 1px solid #bdbbbb;
  border-bottom: 1px solid #bdbbbb;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${({ hover }) =>
      hover?.['background-color'] ? hover['background-color'] : 'white'};
    color: ${({ hover }) => (hover?.color ? hover.color : '#2e2e2e')};
    cursor: ${({ hover }) => (hover?.cursor ? hover.cursor : 'pointer')};
  }
  > svg {
    fill: ${({ svgFillColor }) => (svgFillColor ? svgFillColor : '#2e2e2e')};
  }
  &:hover > svg {
    fill: ${({ svgFillColorOnHover }) =>
      svgFillColorOnHover ? svgFillColorOnHover : 'white'};
  }
`

const TabSpan = styled.span`
  overflow: initial;
  text-overflow: initial;
  white-space: pre-wrap;
  line-height: 18px;
  padding-top: 5px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  width: auto;
  margin-right: 15px;
`
const CarrouselTabContent = () => {
  const [selectedTab, setSelectedTab] = useState('HOME')
  const { planStatus } = useMshopContext()

  const handleTabClick = (tabNumber: any) => {
    setSelectedTab(tabNumber)
  }

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'HOME':
        return (
          <div>
            <CarrouselTabHome />
          </div>
        )
      case 'PLP':
        return (
          <div>
            <CarrouselTabPLP />
          </div>
        )
      case 'PDP':
        return (
          <div>
            <CarrouselTabPDP />
          </div>
        )
      case 'SCH':
        return (
          <div>
            <CarrouselTabSearch />
          </div>
        )
      case 'SCHNR':
        return (
          <div>
            <CarrouselTabSearchNoResult />
          </div>
        )
      default:
        return <CarrouselTabHome />
    }
  }
  const tryRequire = (path: string) => {
    if (path.includes('HOME')) {
      return <HomeLogoSvg />
    } else if (path.includes('PDP')) {
      return <PDPLogoSvg />
    } else if (path.includes('PLP')) {
      return <PLPLogoSvg />
    } else if (path.includes('Category')) {
      return <CatalogoLogoSvg />
    } else if (path.includes('Checkout')) {
      return <CheckoutGreen />
    } else if (path.includes('Collection')) {
      return <PurchaseLogoSvg />
    } else if (path.includes('SCH') || path.includes('SCHNR')) {
      return <SearchLogoSvg />
    } else {
      return <PurchaseLogoSvg />
    }
  }
  return (
    <>
      <TabButtonContainer>
        {Object.entries({
          HOME: 'INICIO',
          PLP: 'CATEGORÍA',
          PDP: 'FICHA DE PRODUCTO',
          SCH: 'BÚSQUEDA POSITIVA',
          SCHNR: 'BÚSQUEDA NEGATIVA'
        }).map(([key, value]) => {
          if (selectedTab === key) {
            return (
              <TabButton
                key={key}
                name={key}
                value={key}
                onClick={() => {
                  if (
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    planStatus?.ExpirationDate &&
                    planStatus.PlanName !== 'Basico'
                  ) {
                    handleTabClick(key)
                  }
                }}
                background-color="#00868b"
                hover={{
                  'background-color': '#00868b',
                  color: 'white'
                }}
                svgFillColor="white"
                svgFillColorOnHover="white"
                color="white"
              >
                <TabSpan>{value}</TabSpan>
                {tryRequire(key)}
              </TabButton>
            )
          }
          if (planStatus?.PlanName === 'Basico') {
            return (
              <TabButton
                key={key}
                name={key}
                value={key}
                onClick={() => {
                  if (
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    planStatus?.ExpirationDate &&
                    planStatus.PlanName !== 'Basico'
                  ) {
                    handleTabClick(key)
                  }
                }}
                svgFillColorOnHover="#2e2e2e"
                style={{ opacity: '40%' }}
              >
                <TabSpan className="counter mshop-tab-span">{value}</TabSpan>
                {tryRequire(key)}
              </TabButton>
            )
          }
          return (
            <TabButton
              key={key}
              name={key}
              value={key}
              onClick={() => {
                if (
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  planStatus &&
                  planStatus?.PlanName !== 'Basico'
                ) {
                  handleTabClick(key)
                }
              }}
              hover={{
                'background-color': '#00868b',
                color: 'white'
              }}
            >
              <TabSpan className="counter mshop-tab-span">{value}</TabSpan>
              {tryRequire(key)}
            </TabButton>
          )
        })}
      </TabButtonContainer>
      <div style={{ marginTop: '20px' }}>{renderTabContent()}</div>
    </>
  )
}

export default CarrouselTabContent
