import React from 'react'
import { FormattedMessage } from 'react-intl'

const OnlySale: React.FC<{ values: any }> = ({ values }) => {
  return (
    <div className="rule-config-subComponents__container hiden-rule">
      <div className="row a-center hiden-rule">
        <h6 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 vtexskuconsult__text_modal hiden-rule">
          <FormattedMessage
            id="app.carrousel.rules.tooltip.Solo-Ofertas.label"
            defaultMessage="Solo Ofertas"
          />
        </h6>
        <select
          className="col-lg-8 col-md-8 col-sm-8 col-xs-8 ancho-alto-select hiden-rule"
          id="sale"
          style={{ width: '64%' }}
        >
          <option value="0" selected>
            No
          </option>
          <option value="1">Yes</option>
        </select>
      </div>
    </div>
  )
}

export default OnlySale
