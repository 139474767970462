/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect } from 'react';

const useOutsideClick = (ref:any, callback:any) => {
  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
